<template>
  <v-card
    :width="width"
    :height="height"
    :color="transparent ? 'transparent' : undefined"
    :flat="transparent"
    :loading="!transparent && loading"
  >
    <div style="height: 50px" v-if="showTitle" class="d-flex flex-no-wrap align-end">
      <slot name="title">
        <div class="ml-3 text-h6">{{title}}</div>
      </slot>
      <div class="ml-auto pr-2">
        <slot name="actions"></slot>
      </div>
    </div>
    <div class="chart-wrapper" style="height: 100%">
      <div
        v-if="loading"
        class="text-caption font-weight-light d-flex align-center justify-center"
        :style="{
          height: '100%'
        }"
      >
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <template v-else-if="chartData.datasets.length > 0">
        <LineChart
          v-if="type == 'line'"
          :chart-data="chartData"
          :styles="computedStyle"
          :options="chartOptions"
        >
        </LineChart>
        <BarChart
          v-else-if="type == 'bar'"
          :chart-data="chartData"
          :styles="computedStyle"
          :options="chartOptions"
        >
        </BarChart>
        <HorizontalBar
          v-else-if="type == 'horizontalBar'"
          :chart-data="chartData"
          :styles="computedStyle"
          :options="chartOptions"
        >
        </HorizontalBar>
      </template>
      <div 
        v-else 
        class="text-caption font-weight-light d-flex align-center justify-center"
        :style="{
          height: computedStyle['height']
        }"
      >
        <slot name="no-data">
          <v-card class="pa-1 ma-5" outlined style="width:94%;height: 90%;">
          <div class="d-flex align-center" style="width:100%; height: 100%;">
            <div class="text-center" style="width: 100%;">
              {{noDataText}}
            </div>
          </div>
        </v-card>
        </slot>
      </div>
    </div>
    <div 
      style="height: 70px" 
      class="px-2 d-flex align-center justify-end"
      v-if="showFooter"
    >
      <v-slot name="footer"></v-slot>
    </div>
  </v-card>
</template>

<script>
import LineChart from './LineChart.vue'
import BarChart from './BarChart.vue'
import HorizontalBar from './HorizontalBar.vue'
import chartMixin from './chart.mixin'

export default {
  name: "GroupBasedChart",
  mixins: [chartMixin],
  components: {
    LineChart,
    BarChart,
    HorizontalBar
  },
  data: function() {
    return {

    }
  },
  mounted: function() {

  },
  props: {
    type: {
      type: String,
      default: 'line',
    },
    data: {
      type: Array,
      default: () => {
        /* 
          Array structure:
          [
            {
              name: String (a uniq name representing the data)
              label: String (a name to show for those data),
              data: [
                ... (whatever you want, define getX and getY functions)
              ]
            }
          ]
        */
        return []
      }
    },
    getXValue: {
      type: Function,
      default: (object) => {
        return object[Object.keys(object)[0]]
      }
    },
    getYValue: {
      type: Function,
      default: (object) => {
        return object[Object.keys(object)[1]]
      }
    },
    maintainAspectRatio: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: true
    },
    noDataText: {
      type: String,
      default: 'DATI INSUFFICIENTI'
    },
    title: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "400px"
    },
    width: {
      type: String,
      default: "600px"
    },
    styles: {
      type: Object,
      default: function() {
        return { }
      }
    },
    colors: {
      type: Array,
      default: undefined
    },
    pointColors: {
      type: Array,
      default: undefined
    },
    maxValue:{
      type: Number,
      default: undefined
    },
    minValue:{
      type: Number,
      default: 0
    },
    xAxesModifier: {
      type: Array,
      default: function () {
        return [{
          display: true,
        }]
      }
    },
    otherOptions: {
      type: Object,
      default: function() {
        return {}
      }
    },
    colorOnDataset: {
      type: Boolean,
      default: false
    },
    additionalYAxesModifier: {
      type: Object,
      default: function() {
        return {}
      }
    },
    tooltipLabelModifier: {
      type: Function,
      default: function(tooltipItem) {
        return tooltipItem.yLabel
      }
    }
  },
  methods: {
    
  },
  computed: {
    chartOptions() {
      return {
        maintainAspectRatio: this.maintainAspectRatio,
        responsive: this.responsive,
        tooltips: {
          enabled: true,
          callbacks: {
            label: this.tooltipLabelModifier
          }
        },
        scales: {
          yAxes:[{
            display: true,
            ticks:{
              min: this.minValue,
              max: this.maxValue
            },
            ...this.additionalYAxesModifier
          }],
          xAxes: this.xAxesModifier,
        },
        ...this.otherOptions
      }
    },
    computedStyle() {
      const heightToRemove = 0
      if(this.showTitle) heightToRemove += 50
      if(this.showFooter) heightToRemove += 70

      return {
        ...this.styles,
        height: "calc(" + this.height + " - " + heightToRemove + "px)",
      }
    },
    chartData() {
      let datasets = [], labels = []
      for(let i = 0; i < this.data.length; i += 1) {
        if(!this.data[i].data || !Array.isArray(this.data[i].data)) continue
        const datasetName = this.data[i].name
        const datasetLabel = this.data[i].label
        let datasetHash = {}, datasetData = []

        for(let k = 0; k < this.data[i].data.length; k += 1) {
          const xValue = this.getXValue(this.data[i].data[k])
          if (!labels.includes(xValue))
            labels.push(xValue)

          if(!!datasetHash[xValue])
            datasetHash[xValue] += Number(this.getYValue(this.data[i].data[k]))
          else
            datasetHash[xValue] = Number(this.getYValue(this.data[i].data[k]))
        }

        for(let k = 0; k < labels.length; k += 1) {
          datasetData.push(datasetHash[labels[k]])
        }

        if(!this.colors || !this.colors.length)
          datasets.push({
            label: datasetLabel,
            color: this.getColor(i),
            fill: false,
            backgroundColor: this.data[i].backgroundColor,
            pointBackgroundColor: 'white', 
            borderWidth: 1, 
            pointBorderColor: 'black',
            data: datasetData
          })
        else 
          datasets.push({
            label: datasetLabel,
            color: this.colors[i % this.colors.length],
            fill: false,
            backgroundColor: this.colors,
            borderColor: !!this.pointColors ? this.pointColors: 'black',
            pointBackgroundColor: !!this.pointColors ? this.pointColors[i % this.pointColors.length] : 'black', 
            borderWidth: 2, 
            pointBorderColor: !!this.pointColors ? this.pointColors[i % this.pointColors.length] :'black',
            data: datasetData,
          })
        
      }

      return {
        labels: labels.map(lab => lab),
        datasets: datasets
      }
    }
  }
}

</script>