import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import DateHandler from "@/services/common/dateHandler";

class paymentDebitsManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentDebits/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(paymentDebit) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentDebits/create', paymentDebit).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /*
    paymentDebit: {
      amount: number,
      openedAt: date,
      type: string,
      reason: string,
      bill: {
        id: number,
      } Bill,
      customer: {
        id: number,
      } Customer,
      cashPaid: number,
      cardPaid: number,
      checkPaid: number,
      ghostPaid: number,
      otherPaid: number,
      documentType: string,
      cashDesk: {
        id: number,
      } CashDesk,
      dataInvoice: required invoice fields,
    }
  */
  createAndPay(paymentDebit) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentDebits/createAndPay', paymentDebit).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(paymentDebit) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentDebits/update', paymentDebit).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(paymentDebit) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!paymentDebit.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/payments/paymentDebits/delete', {
        id: paymentDebit.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(paymentDebitId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/payments/paymentDebits/get', { id: paymentDebitId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  fields() {
    return Promise.resolve([
      { text: 'Cliente', value: 'customer', type: 'custom' },
      { text: 'Ammontare', value: 'amount', type: 'custom' },
      { text: 'Stato', value: 'status', type: 'custom' },
      { text: 'Data', value: 'openedAt', type: 'date' },
    ])
  }

  operatorFields() {
    return Promise.resolve([
      { text: 'Operatore/Azienda', value: 'operator', type: 'custom' },
      { text: 'Ammontare', value: 'amount', type: 'custom' },
      { text: 'Stato', value: 'status', type: 'custom' },
      { text: 'Data', value: 'openedAt', type: 'date' },
    ])
  }

  _fieldsToExport() {
    return {
      'Operatore/Azienda': 'fullname',
      "Ammontare": {
        field: "amount",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        },
      },
      "Stato": {
        field: "status",
        callback: (status) => {
          let statuses = [
            { key: "beginning", text: "Non pagato" },
            { key: "inProgress", text: "Pagato parzialmente" },
            { key: "finished", text: "Pagato totalmente" },
          ]

          return statuses.find(z => z.key == status).text
        },
      },
      "Data": {
        field: "openedAt",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
    }
  }
}

export default new paymentDebitsManagment();
