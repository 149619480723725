<template>
  <v-form v-model="formValid">
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="pa-1">
        <v-text-field
          v-model="businessName"
          prepend-icon="mdi-account-arrow-right"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          rounded
          label="Ragione Sociale o Cognome *"
          hide-details="auto"
          @input="setField('businessName', $event)"
        ></v-text-field>
      </v-col>
      <v-col class="pa-1">
        <v-text-field
          v-model="VATNumber"
          :rules="[max255CharRule, presenceRule]"
          filled
          dense
          rounded
          hide-details="auto"
          prepend-icon="mdi-file-code-outline"
          label="Partita IVA *"
          @input="setField('VATNumber', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="px-1">
        <v-text-field
          v-model="email"
          :rules="[max255CharRule]"
          filled
          dense
          rounded
          label="Email"
          prepend-icon="mdi-email"
          hide-details="auto"
          @input="setField('email', $event)"
        ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          v-model="fiscalCode"
          :rules="[max255CharRule, fiscalCodeValid]"
          filled
          dense
          rounded
          hide-details="auto"
          label="Codice Fiscale"
          prepend-icon="mdi-card-account-details"
          @input="setField('fiscalCode', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-2 mb-1">
      <v-col class="px-1">
        <v-text-field
          v-model="address"
          filled
          dense
          rounded
          :rules="[max255CharRule, presenceRule]"
          prepend-icon="mdi-google-maps"
          label="Indirizzo *"
          hide-details="auto"
          @input="setField('address', $event)"
        ></v-text-field>
      </v-col>
      <v-col class="px-1">
        <v-text-field
          v-model="city"
          :rules="[max255CharRule]"
          prepend-icon="mdi-city-variant-outline"
          filled
          dense
          rounded
          label="Località"
          hide-details="auto"
          @input="setField('city', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="pa-1">
        <v-text-field
          v-model="cap"
          label="CAP"
          prepend-icon="mdi-email-newsletter"
          hide-details="auto"
          filled
          dense
          rounded
          maxlength="5"
          :rules="[max255CharRule]"
          @input="updateLocalField('cap')"
        ></v-text-field>
      </v-col>
      <v-col class="pa-1">
        <v-text-field
          v-model="province"
          label="Provincia"
          prepend-icon="mdi-earth-box"
          hide-details="auto"
          :rules="[max255CharRule]"
          filled
          dense
          rounded
          maxlength="2"
          @input="updateLocalField('province')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="pa-1">
        <v-text-field
          v-model="destinataryCode"
          label="Codice Destinatario *"
          prepend-icon="mdi-qrcode"
          hide-details="auto"
          :rules="[max255CharRule, presenceRule]"
          filled
          dense
          rounded
          @input="updateLocalField('destinataryCode')"
        ></v-text-field>
      </v-col>
      <v-col class="pa-1">
        <v-text-field
          v-model="lotteryCode"
          label="Codice Lotteria"
          prepend-icon="mdi-clover"
          hide-details="auto"
          filled
          dense
          rounded
          :rules="[max255CharRule]"
          @input="updateLocalField('lotteryCode')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="pa-1">
        <v-text-field
          v-model="firstname"
          label="Nome"
          prepend-icon="mdi-account"
          hide-details="auto"
          :rules="[max255CharRule]"
          filled
          dense
          rounded
          @input="updateLocalField('firstname')"
        ></v-text-field>
      </v-col>
    </v-row> 
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="pa-1">
        <v-text-field
          v-model="telephone"
          :rules="[max255CharRule]"
          filled
          dense
          rounded
          label="Telefono"
          prepend-icon="mdi-deskphone"
          hide-details="auto"
          @input="setField('telephone', $event)"
        ></v-text-field>
      </v-col>
      <v-col class="pa-1">
        <v-text-field
          v-model="cellphone"
          :rules="[max255CharRule]"
          filled
          dense
          rounded
          label="Cellulare"
          prepend-icon="mdi-cellphone-basic"
          hide-details="auto"
          @input="setField('cellphone', $event)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1 mb-1">
      <v-col class="pa-1">
        <v-textarea
          v-model="notes"
          filled
          rounded
          prepend-icon="mdi-pencil"
          hide-details="auto"
          label="Note"
          @input="setField('notes', $event)"
        ></v-textarea>
      </v-col>
      <v-col class="pa-1">
        <v-autocomplete
          v-model="paymentMethods"
          :items="paymentMethodsItems"
          filled
          dense
          rounded
          prepend-icon="mdi-cash"
          hide-details="auto"
          deletable-chips
          multiple
          chips
          label="Modalità di pagamento"
          @input="setField('paymentMethods', $event)"
        ></v-autocomplete>
        <!-- <TagsAutocomplete
          class="mt-5"
          multiple
          v-model="tags"
          dense
          return-object
          @input="setField('tags', $event)"
        ></TagsAutocomplete> -->
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import suppliesNameMapping from "@/services/suppliers/suppliesNameMappings.js";
import paymentMethodsNameMapping from "@/services/suppliers/paymentMethodsNameMappings.js";
import supplierForm from "@/services/suppliers/supplier.form.js";

export default {
  name: "SuppliersRegistryEditFormTab",
  components: {
    TagsAutocomplete,
  },
  data: function () {
    return {
      id: undefined,
      businessName: undefined,
      address: undefined,
      city: undefined,
      supplies: [],
      telephone: undefined,
      cellphone: undefined,
      email: undefined,
      notes: undefined,
      paymentMethods: undefined,
      fiscalCode: undefined,
      VATNumber: undefined,
      cap: undefined,
      province: undefined,
      firstname: undefined,
      destinataryCode: undefined,
      lotteryCode: undefined,
      tags: [],
      suppliesItems: [],
      paymentMethodsItems: [],
      formValid: false,
      loadingSupplier: false,
      infoToSend: {},
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      fiscalCodeValid: (v) => {
        const rgxToCheck =
          /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;
        return rgxToCheck.test(v) || v == undefined || v == "";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function () {
    this.updateLocalData()
    suppliesNameMapping.options().then((options) => {
      this.suppliesItems = options;
    });

    paymentMethodsNameMapping.options().then((options) => {
      this.paymentMethodsItems = options;
    });

    this.handleObjectChanges(supplierForm.supplier)
    var self = this
    supplierForm.on('update', function(data) {
      self.handleObjectChanges(data.supplier)
    })
  },
  methods: {
    setField(name, value) {
      supplierForm.updateField(name, value)
    },
    updateLocalData() {
      if (!!supplierForm.supplier.dataInvoice) {
        this.infoToSend.id = supplierForm.supplier.dataInvoice.id;
        const localAttributes = [
          "mail",
          "vatNumber",
          "destinataryCode",
          "lastname",
          "firstname",
          "address",
          "cap",
          "city",
          "province",
          "paymentTypeNatureExemptionId",
          "paymentConditionId",
          "paymentTermId",
          "docPrint",
          "lotteryCode",
          "fiscalCode",
        ];
        localAttributes.forEach((nameAttribute) => {

          
          this[nameAttribute] =
            supplierForm.supplier.dataInvoice[nameAttribute];
          this.infoToSend[nameAttribute] =
            supplierForm.supplier.dataInvoice[nameAttribute];
        });
      }
    },
    updateLocalField(field) {
      if (!!supplierForm.supplier.dataInvoice) {
        //Update Case
        if (typeof this[field] == "object") {
          if (!!this[field]) this.infoToSend[field] = this[field].id;
          else this.infoToSend[field] = null;
        } else this.infoToSend[field] = this[field];

        supplierForm.updateField("dataInvoice", this.infoToSend);
      } else {
        // Creation case
        supplierForm.updateField("dataInvoice", {});
        if (typeof this[field] == "object") {
          if (!!this[field]) this.infoToSend[field] = this[field].id;
          else this.infoToSend[field] = null;
        } else this.infoToSend[field] = this[field];
        supplierForm.updateField("dataInvoice", this.infoToSend);
      }
    },
    handleObjectChanges(supplier) {
      if(!!supplier) {
        this.businessName = supplier.businessName;
        this.address = supplier.address;
        this.city = supplier.city;
        this.supplies = supplier.supplies;
        this.telephone = supplier.telephone;
        this.cellphone = supplier.cellphone;
        this.email = supplier.email;
        this.notes = supplier.notes;
        this.paymentMethods = supplier.paymentMethods;
        this.fiscalCode = supplier.fiscalCode;
        this.VATNumber = supplier.VATNumber;
        this.loadingSupplier = false;
        this.tags = supplier.tags
      }
      if(!!supplier && !!supplier.dataInvoice) {
        this.cap = supplier.dataInvoice.cap
        this.province = supplier.dataInvoice.province
        this.destinataryCode = supplier.dataInvoice.destinataryCode
        this.firstname = supplier.dataInvoice.firstname
        this.lotteryCode = supplier.dataInvoice.lotteryCode
        this.infoToSend = {
          id: supplier.dataInvoice.id,
          cap: supplier.dataInvoice.cap,
          province: supplier.dataInvoice.province,
          destinataryCode: supplier.dataInvoice.destinataryCode,
          firstname: supplier.dataInvoice.firstname,
          lotteryCode: supplier.dataInvoice.lotteryCode
        }
      }
    }
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }

      this.$store.dispatch("registries/suppliers/setValidState", newVal);
    },
  },
  computed: {
  },
};
</script>

<style></style>