<template>
  <FullScreenDialog
    v-model="paymentDebitDialog"
    title="Nuovo Pagamento"
    :routeFather="pathToGoBack"
    :canClose="(!!fromRouter)"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in paymentDebitTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in paymentDebitTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component" v-bind="{ preCompiledForm: preCompiledForm , intermediaryOperator: intermediaryOperator}" ></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="closeDialog"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
        :loading="loading"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "./form/GeneralFormTab.vue"
import paymentDebitsService from '@/services/paymentDebits/paymentDebits.service.js'
import paymentDebitForm from '@/services/paymentDebits/paymentDebit.form.js'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";

export default {
  name: "PaymentTransactionsGivenNewForm",
  components: {
    FullScreenDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      paymentDebitDialog: this.openDialog,
      paymentDebitTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      formValid: false,
      paymentDebit: undefined,
      originalUrl: window.location.pathname,
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    paymentDebitForm.resetPaymentDebit()
    paymentDebitForm.updateField('documentType', 'receipt')
    
    paymentDebitForm.on('update', function(data) {
      self.paymentDebit = data.paymentDebit
    })

    paymentDebitForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/payments/paymentsGiven/list"
    },
    tabName: {
      type: String,
      default: 'general'
    },
    preCompiledForm: {
      type: Object,
      default: undefined
    },
    intermediaryOperator: {
      type: Boolean,
      default: false,
    },
    fromRouter: {
      type: Boolean,
      default: true 
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.paymentDebitTabs.length; i++) {
        if(this.paymentDebitTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      cashDeskService.getOpened().then((cashDesk) => {
        this.paymentDebit.cashDesk = cashDesk
        paymentDebitsService.createAndPay(this.paymentDebit).then((result) => {
          this.loading = false;
          (!!this.fromRouter) ? this.$router.push({path: this.pathToGoBack}) : this.$emit('close',{reload: true})
        })
      })
    },
    closeDialog(){
      (!!this.fromRouter) ? this.$router.push({path: this.pathToGoBack}) : this.$emit('close')
    }
  },
  watch: {
    openDialog(newVal) {
      this.paymentDebitDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.paymentDebitTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>