import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class bookingService {
  constructor() {

  }

  getBookingServiceMapping() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/booking/getBookingServiceMapping').then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  saveBookingServiceMapping(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/booking/saveBookingServiceMapping', params).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  getCarpeDiemSettings() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/booking/getCarpeDiemSettings').then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  saveCarpeDiemSettings(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/booking/saveCarpeDiemSettings', params).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  getVoucherInfo(voucherCode) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/booking/getVoucherInfo', {
        voucherCode: voucherCode
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  getVoucherList(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/booking/getVoucherList', {
        ...params
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  redeemVoucher(id) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/booking/voucherRedeem', {
        id: id
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }
}

export default new bookingService();