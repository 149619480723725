import authService from "@/services/auth/auth.service";
const packageTypesRoutes = [
  {
    path: "/registries/packageTypes",
    name: "PackageTypesRegistryNewForm",
    component: () => import("@/components/areas/registries/packageTypes/PackageTypesRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/packageTypes/:id/edit",
    name: "PackageTypesRegistryEditForm",
    component: () => import("@/components/areas/registries/packageTypes/PackageTypesRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/registries/packageTypes/list",
    name: "PackageTypesRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/packageTypes/PackageTypesRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default packageTypesRoutes;