import EventsHandler from "@/services/common/eventsHandler.js"
import devicesDatabase from "@/services/devices/devices.database"

class CameraDevice extends EventsHandler {
  constructor() {
    super()
    this._deviceList = []
    this._stream = undefined
    this._device = undefined
  }

  loadFavouriteDevice() {
    return new Promise((resolve, reject) => {
      devicesDatabase.getDevice('camera').then((device) => {
        this._device = device
        this._executeAsyncCallbacksOf('connect', { device: device }).then(() => {
          resolve()
        })
      })
    })
  }

  isDeviceConnected() {
    return !!this._device
  }

  device(serialize=false) {
    if(serialize) {
      return !!this._device ? this._serializeDevice(this._device) : undefined
    } else {
      return this._device
    }
  }

  requestDevice() {
    return new Promise((resolve, reject) => {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            this._stream = stream
            resolve(stream)
          })
          .catch((error) => {
            reject(error)
          });
      }
    })
  }

  getDeviceOptions() {
    return new Promise((resolve, reject) => {
      this.requestDevice().then((stream) => {
        this.stopStream()
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          devices = devices.filter(el => el.kind == 'videoinput')
          this._deviceList = devices
          resolve(devices.map(el => this._serializeDevice(el)))
        }).catch((error) => {
          reject(error)
        })
      }).catch((error) => {
        reject(error)
      })
    })
  }

  stopStream() {
    if(this._stream) {
      this._stream.getTracks().forEach(function (track) {
        track.stop();
      });
      this._stream = undefined
    }
  }

  selectDevice(device) {
    return new Promise((resolve, reject) => {
      this._device = device
      devicesDatabase.saveDevice('camera', device).then(() => {
        this._executeAsyncCallbacksOf('connect', { device: device }).then(() => {
          resolve()
        })
      })
    })
  }

  _serializeDevice(usbDevice) {
    return {
      groupId: usbDevice.groupId,
      label: usbDevice.label
    }
  }
}

export default new CameraDevice()