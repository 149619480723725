import authService from "@/services/auth/auth.service";
import currentUser from '@/services/currentUser.service'
const serviceTypesCategoriesRoutes = [
  {
    path: "/registries/serviceTypesCategories",
    name: "ServiceTypesCategoriesRegistryNewForm",
    component: () => import("@/components/areas/registries/serviceTypesCategories/ServiceTypesCategoriesRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()      
      if (currentUser.isSystem()) {
        next()
      } else {      
        next(from)
      }
    }
  },
  {
    path: "/registries/serviceTypesCategories/:id/edit",
    name: "ServiceTypesCategoriesRegistryEditForm",
    component: () => import("@/components/areas/registries/serviceTypesCategories/ServiceTypesCategoriesRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()      
      if (currentUser.isSystem()) {
        next()
      } else {      
        next(from)
      }
    }
  },
  {
    path: "/registries/serviceTypesCategories/list",
    name: "ServiceTypesCategoriesRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/serviceTypesCategories/ServiceTypesCategoriesRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default serviceTypesCategoriesRoutes;