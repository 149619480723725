import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class TimeLogsService {
  constructor() { }

  badge({ barcode, operator, note }) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      let params = {}
      if (!barcode && (!operator || !operator.id)) {
        reject(new Error('barcode or operator must be defined'))
      } else if(!!barcode) {
        params.barcode = barcode
      } else {
        params.operator = operator
      }

      if(!!note) {
        params.note = note
      }

      axios.post(apiUrl + '/presences/timeLogs/badge', params).then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(new Error(response.results))
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  checkOperatorTimeLog(operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      let params = {}
      if (!operator || !operator.id) {
        reject(new Error('operator must be defined'))
      } 

      params.operator = operator
      
      axios.post(apiUrl + '/presences/timeLogs/checkOperatorTimeLog', params).then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(new Error(response.results))
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
  /* 
    filters {
      month: Number from 0 to 11,
      year: Number,
      operator: {
        ...
        id: Number
      }
    }
  */
  print(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if(!filters) {
        reject(new Error('filters must be defined'))
        return
      } else if(!filters.month || !filters.year) {
        reject(new Error('month and year must be defined'))
        return
      } else if(!filters.operator || !filters.operator.id) {
        reject(new Error('operator must be defined'))
        return
      }

      let monthsMapped = {
        1: 'Gennaio',
        2: 'Febbraio',
        3: 'Marzo',
        4: 'Aprile',
        5: 'Maggio',
        6: 'Giugno',
        7: 'Luglio',
        8: 'Agosto',
        9: 'Settembre',
        10: 'Ottobre',
        11: 'Novembre',
        12: 'Dicembre'
      }

      axios.postWithDownload(apiUrl + '/presences/timeLogs/print', {
        ...filters
      }, `Cedolino_`+ filters.operator.name + `_`+ monthsMapped[filters.month] + `_` + filters.year + `.pdf`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  printAll(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if(!filters) {
        reject(new Error('filters must be defined'))
        return
      } else if(!filters.month || !filters.year) {
        reject(new Error('month and year must be defined'))
        return
      }

      let monthsMapped = {
        1: 'Gennaio',
        2: 'Febbraio',
        3: 'Marzo',
        4: 'Aprile',
        5: 'Maggio',
        6: 'Giugno',
        7: 'Luglio',
        8: 'Agosto',
        9: 'Settembre',
        10: 'Ottobre',
        11: 'Novembre',
        12: 'Dicembre'
      }
      
      axios.postWithDownload(apiUrl + '/presences/timeLogs/printAll', {
        ...filters
      }, `Tutti_i_Cedolini_` + monthsMapped[filters.month] + `_` + filters.year + `.pdf`).then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  update(timeLog) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if(!timeLog || !timeLog.id) {
        reject(new Error('timeLog must be defined'))
      }

      axios.post(apiUrl + '/presences/timeLogs/update', timeLog).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  list(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/timeLogs/list', { filters }).then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  fastList() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/timeLogs/fastList').then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(new Error(response.results))
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default new TimeLogsService()
