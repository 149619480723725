export default [
  {
    path: "/:billId/payBar",
    name: "PayBillBar",
    component: () => import("@/components/bar/daily-reservation/CashDialogBar.vue"),
    props: (route) => {
      return {
        dialog: true,
        goBackTo: {
          name: !!route.params.goBack ? route.params.goBack : "Bar",
          params: {
            selectedBill: route.params.bill
          }
        },
        billId: route.params.billId,
        bill: route.params.bill,
        customerCard: route.params.customerCard,
        tableId: route.params.tableId,
        cashDeskLog: route.params.cashDeskLog,
        discountBar: route.params.discountBar,
        partialPayment: route.params.partialPayment,
        customerId: route.params.customerId,
      }
    },
  },
  {
    path: "/customerCards/:customerCardId/pay",
    name: "PayCustomerCardLoadBar",
    component: () => import("@/components/homepage/sections/CustomerCardLoadDialog.vue"),
    props: (route) => {
      return {
        dialog: true,
        goBackTo: {
          name: "Homepage",
          params: {
            selectedBill: route.params.bill,
          },
          query: {
            openCardDetailDialog: true
          }
        },
        customerCard: route.params.customerCard,
        customerCardId: route.params.customerCardId,
        loadValue: Number(route.query.loadValue)
      }
    },
  },
]