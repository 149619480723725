import eventsHandler from '@/services/common/eventsHandler'
import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import authService from '@/services/auth/auth.service'

class CurrentUser extends eventsHandler {
  constructor() {
    super()   
    this._user = undefined
    this._loadingUserPromise = undefined

    let self = this
    authService.on('logout', async () => await self.loadUser())
    authService.on('login', async () => await self.loadUser())
  }

  getUser() {
    return new Promise((resolve, reject) => {
      if (this._user) {
        resolve(this._user)
      } else {
        this.loadUser().then(() => {
          resolve(this._user)
        }).catch((err) => {
          reject(err)
        })
      }
    })
  }

  isSystem() {
    return !!this._user ? this._user.system : false
  }

  loadUser() {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      let previousId = this._user ? this._user.id : undefined
      authService.isAuthenticated().then((isAuthenticated) => {
        if (!isAuthenticated) {
          this._user = undefined
          resolve()
          
          this._executeAsyncCallbacksOf('load', this._user).then(() => { })
          if (!!previousId) { this._executeAsyncCallbacksOf('change', this._user).then(() => { }) }
        } else {
          axios.get(apiUrl + '/users/users/me').then((response) => {
            if (response.success) {
              this._user = response.results
              resolve()
              
              this._executeAsyncCallbacksOf('load', this._user).then(() => { })
              if (previousId != this._user.id) { this._executeAsyncCallbacksOf('change', this._user).then(() => { }) }
            } else {
              reject(response.results)
            }
          }).catch((err) => {
            reject(err)
          })
        }
      })
    })
  }
}
 export default new CurrentUser()