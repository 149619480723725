<template>
  <div style="width: 100%; height: 100%" id="chart">
    <TitleWithBackButton 
      title="Riepilogo Periodo Commerciale" 
      back-route="Analytics" 
      icon="mdi-graphql"
    >
    </TitleWithBackButton>
    <div>
      <div 
        class="d-flex align-center ml-3 mt-3 flex-wrap"
      >
        <RangeDateSelector 
          :start-date.sync="startDate" 
          :end-date.sync="endDate" 
          :loading="loading" 
          :disabled="loading"
          :max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            ? 'fit-content' : '50vw'"
        >
        </RangeDateSelector>
        <div 
          class="ml-7"
          :style="{
            'max-width': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
              ? '60vw' : '20vw',
            'margin-top': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ?
              '10px' : '0px'
          }"
        >
          <TagsCategoriesAutocomplete 
            label="Scelta Tag" 
            v-model="selectedTagCategories" 
            return-object
            :prepend-icon="null" 
            :filters="{ typeCost: false, excludeInBalance: false, notGeneral: true }"
          >
          </TagsCategoriesAutocomplete>
        </div>
        <v-btn 
          class="mr-2 ml-2" 
          text 
          @click="calculate" 
          :loading="loading" 
          :disabled="!startDate || !endDate"
        >
          Calcola
        </v-btn>
        <v-btn
          class="btn-export"
          @click="exportToExcel"
          :loading="loading"
        ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
        <v-btn
          class="mr-2 ml-2"
          :class="{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm}"
          text
          @click="print"
          :loading="loading"
          :disabled="!startDate || !endDate"
        ><v-icon> mdi-printer </v-icon></v-btn>

        <v-spacer></v-spacer>

        <v-btn 
          icon 
          large 
          :disabled="checkObjectLength() || loading" 
          style="float: right; margin-right: 2.5%"
          @click="() => { dialogBarcode = !dialogBarcode }"
          :color="!!isB ? 'red' : ''"
        >
          <v-icon>mdi-shield-account-variant</v-icon>
        </v-btn>
      </div>

      <div v-if="loading" class="text-center mt-2">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <div v-else-if="checkObjectLength()" class="text-center font-weight-light mt-2">
        Nessun risultato
      </div>

      <div>
        <div>
          <v-col class="mt-5">
            <v-row class="d-flex justify-center">
              <PieChart :chart-data="generalChartData" 
              :styles="{
                height: '300px',
                width: '300px'
              }" 
              :options="{
                mainAspectRatio: true,
                responsive: true,
                legend: {
                  display: false
                },
                showTooltips: false,
              }"
            >
              </PieChart>
            </v-row>
            <v-row class="pt-2 d-flex justify-center">
              <TypeDataTable 
                class="mb-10" 
                :show-tooltips="true" 
                fixed-headers
                headersColor="transparent" 
                style="background-color: transparent;"
                :tooltip-delay="1500" 
                :headers="[
                  { text: '', value: 'color', type: 'color' },
                  { text: '', value: 'name' },
                  { text: 'Totale', value: 'total', type: 'price' },
                  { text: 'Percentuale', value: 'percentage' },
                  { text: 'N° Tran.', value: 'count' },
                  { text: 'Fiche Media', value: 'avg', type: 'price' },
                  { text: 'N° Clienti', value: 'customersCount'}
                ]" 
                :items="generalTableData" 
                :show-select="false"
                :select-on-row-click="false" 
                :show-actions="false" 
                :disablePagination="true"
              >
              </TypeDataTable>
            </v-row>
          </v-col>
        </div>

        <div v-if="!checkObjectLength()" class="px-5">
          <span class="pl-5 mb-5 subtitle">Δ Valore</span>
          <v-divider></v-divider>
          <v-row class="mt-5 d-flex flex-nowrap" style="overflow-x:auto">
            <v-col v-for="(result, key) in results" :key="key" :cols="Math.min(3, Math.round(12 / (results.length + 1)))" class="px-1">
              <v-expansion-panels popout flat v-model="result.panel" multiple class="mb-5">
                <v-expansion-panel disabled>
                  <v-expansion-panel-header class="d-flex justify-center" style="font-size: 1.3rem">
                    {{ result.productAndService.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center">
                      <PieChart 
                        :chart-data="result.productAndServiceChartData" 
                        :styles="{
                          height: '300px',
                          width: '300px'
                        }" 
                        :options="{
                          mainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false
                          },
                          showTooltips: false,
                        }"
                      >
                      </PieChart>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: '€', value: 'total', type: 'price' },
                          { text: '%', value: 'percentage' },
                          { text: 'N°', value: 'count' },
                          { text: 'F.M.', value: 'avg', type: 'price' }
                        ]" 
                        :items="result.productAndServiceRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Penetrazione Acquisti vs Clienti
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center">
                      <v-col class="d-flex justify-center">
                        <PieChart 
                          :chart-data="result.purchaseCustomersTotalChartData" 
                          :styles="{
                            height: '150px',
                            width: '150px'
                          }" 
                          :options="{
                            mainAspectRatio: true,
                            responsive: true,
                            legend: {
                              display: false
                            },
                            showTooltips: false,
                          }"
                        >
                        </PieChart>
                      </v-col>
                      <v-col class="d-flex justify-center">
                        <PieChart :chart-data="result.purchaseCustomersGenderWithItemsChartData" 
                        :styles="{
                          height: '150px',
                          width: '150px'
                        }" 
                        :options="{
                          mainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false
                          },
                          showTooltips: false,
                        }"
                      >
                        </PieChart>
                      </v-col>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        class="mb-5 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: 'N°', value: 'count' },
                          { text: '%', value: 'percentage' },
                        ]" 
                        :items="result.purchaseCustomersTotalRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                    <v-row class="pt-1 d-flex justify-center px-1">
                      <TypeDataTable 
                        class="mb-5 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: 'N°', value: 'count' },
                          { text: '%', value: 'percentage' },
                          { text: 'F.M.', value: 'avgItems', type: 'price' },
                          { text: 'N° Pz.', value: 'countItems' },
                          { text: 'X̅ Pz.', value: 'avgCountItems' },
                        ]" 
                        :items="result.purchaseCustomersGenderWithItemsRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                    <v-row class="pt-1 d-flex justify-center px-1">
                      <TypeDataTable 
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: 'N°', value: 'count' },
                          { text: '%', value: 'percentage' },
                        ]" 
                        :items="result.purchaseCustomersGenderWithoutItemsRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Totale Servizi vs Passaggi Cassa
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center">
                      <PieChart 
                        :chart-data="result.servicesCustomersTotalChartData" 
                        :styles="{
                          height: '300px',
                          width: '300px'
                        }" 
                        :options="{
                          mainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false
                          },
                          showTooltips: false,
                        }"
                      >
                      </PieChart>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        v-model="servicesCustomersTotalSelected"
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: '%', value: 'percentage' },
                          { text: 'N°', value: 'count' },
                          { text: 'M.S.', value: 'avg' }
                        ]" 
                        :items="result.servicesCustomersTotalRows" 
                        :show-select="false" 
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <PieChart 
                        v-if="!servicesCustomersReload"
                        :chart-data="result[servicesCustomersChartData]" 
                        :styles="{
                          height: '300px',
                          width: '300px'
                        }" 
                        :options="{
                          mainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false
                          },
                          showTooltips: false,
                        }"
                      >
                      </PieChart>
                      <div v-else>
                        <v-progress-circular
                          :size="300"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        v-model="servicesCustomersDetailsKeysRow"
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: 'N°', value: 'count' },
                          { text: '%', value: 'percentage' },
                          { text: 'M. S.', value: 'avg' }
                        ]" 
                        :items="result[servicesCustomersRows]" 
                        :show-select="false" 
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <GroupBasedChart 
                        no-data-text="Selezionare una fascia di età"
                        height="200px" 
                        width="100%"
                        type="bar"
                        transparent
                        :colors="servicesCustomersDetailsColors"
                        :point-colors="[]"
                        :getYValue="
                          (object) => {
                            return object[`countServices`];
                          }
                        "
                        :getXValue="
                          (object) => {
                            return object[`tagName`];
                          }
                        "
                        :tooltip-label-modifier="
                          (tooltipLabel) => {
                            return 'Quantità: ' + tooltipLabel.yLabel;
                          }
                        "
                        :x-axes-modifier="xAxesServicesCustomersDetail"
                        :data="[servicesCustomersDetailsChartData(result)]"
                        :loading="servicesCustomersDetailReload"
                      >
                      </GroupBasedChart>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        v-if="!servicesCustomersDetailReload"
                        no-data-text="Selezionare una fascia di età"
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: '%', value: 'percentage' },
                          { text: 'N°', value: 'count' },
                        ]" 
                        :items="servicesCustomersDetailsRows(result)" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Transazioni di cassa e Clienti
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center">
                      <GroupBasedChart 
                        height="200px" 
                        width="100%"
                        type="bar"
                        transparent
                        :colors="paymentTransactionsColors"
                        :point-colors="[]"
                        :getYValue="
                          (object) => {
                            return object[`count`];
                          }
                        "
                        :getXValue="
                          (object) => {
                            return object[`name`];
                          }
                        "
                        :tooltip-label-modifier="
                          (tooltipLabel) => {
                            return 'Quantità: ' + tooltipLabel.yLabel;
                          }
                        "
                        :x-axes-modifier="xAxesServicesCustomersDetail"
                        :data="[result.paymentTransactionsTotalChartData]"
                        :loading="servicesCustomersDetailReload"
                      >
                      </GroupBasedChart>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        v-if="!servicesCustomersDetailReload"
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: '%', value: 'percentage' },
                          { text: 'N°', value: 'count' },
                        ]" 
                        :items="result.paymentTransactionsTotalRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                    <v-row class="d-flex justify-center">
                      <GroupBasedChart 
                        height="200px" 
                        width="100%"
                        type="bar"
                        transparent
                        :colors="paymentTransactionsCustomersColors"
                        :point-colors="[]"
                        :getYValue="
                          (object) => {
                            return object[`count`];
                          }
                        "
                        :getXValue="
                          (object) => {
                            return object[`name`];
                          }
                        "
                        :tooltip-label-modifier="
                          (tooltipLabel) => {
                            return 'Quantità: ' + tooltipLabel.yLabel;
                          }
                        "
                        :x-axes-modifier="xAxesServicesCustomersDetail"
                        :data="[result.paymentTransactionsCustomersChartData]"
                        :loading="servicesCustomersDetailReload"
                      >
                      </GroupBasedChart>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        v-if="!servicesCustomersDetailReload"
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: '%', value: 'percentage' },
                          { text: 'N°', value: 'count' },
                        ]" 
                        :items="result.paymentTransactionsCustomersRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Penetrazione Marketing
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="pt-2">
                      vs Transazioni di Cassa
                    </v-row>
                    <v-row>
                      <TypeDataTable 
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'name' },
                          { text: 'N°', value: 'count' },
                          { text: '%', value: 'percentage' },
                        ]" 
                        :items="result.marketingTransactionsRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                    <v-row>
                      vs Clienti
                    </v-row>
                    <v-row>
                      <TypeDataTable 
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'name' },
                          { text: 'N°', value: 'count' },
                          { text: '%', value: 'percentage' },
                        ]" 
                        :items="result.marketingCustomersRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col :cols="Math.min(3, Math.round(12 / (results.length + 1)))">
              <v-expansion-panels popout flat v-model="commercial.panel" multiple class="mb-5">
                <v-expansion-panel disabled>
                  <v-expansion-panel-header class="d-flex justify-center" style="font-size: 1.3rem">
                    {{ commercial.productAndService.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="d-flex justify-center">
                      <PieChart 
                        :chart-data="commercial.productAndServiceChartData" 
                        :styles="{
                          height: '300px',
                          width: '300px'
                        }" 
                        :options="{
                          mainAspectRatio: true,
                          responsive: true,
                          legend: {
                            display: false
                          },
                          showTooltips: false,
                        }"
                      >
                      </PieChart>
                    </v-row>
                    <v-row class="pt-2 d-flex justify-center px-1">
                      <TypeDataTable 
                        class="mb-10 balance-table" 
                        headersColor="transparent" 
                        style="background-color: transparent; width:100%"
                        :headers="[
                          { text: '', value: 'color', type: 'color' },
                          { text: '', value: 'name' },
                          { text: '€', value: 'total', type: 'price' },
                          { text: '%', value: 'percentage' },
                          { text: 'N°', value: 'count' },
                          { text: 'F.M.', value: 'avg', type: 'price' }
                        ]" 
                        :items="commercial.productAndServiceRows" 
                        :show-select="false" 
                        :select-on-row-click="false"
                        :show-actions="false" 
                        :disablePagination="true" 
                        dense 
                        disable-sort
                      >
                      </TypeDataTable>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import balanceService from '@/services/balance/balance.service.js'
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js"
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue"
import FlowyGraph from "@/components/areas/analytics/FlowyGraph.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import operatorService from '@/services/operators/operators.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import PieChart from "@/components/charts/PieChart";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import _ from "lodash";
import html2canvas from '@/assets/js/html2canvas.min.js'
import * as XLSX from "xlsx";

export default {
  name: "BalanceByTags",
  components: {
    RangeDateSelector,
    TitleWithBackButton,
    TagsCategoriesAutocomplete,
    FlowyGraph,
    TypeDataTable,
    StandardDialog,
    ManualBarcodeInput,
    PieChart,
    GroupBasedChart
  },
  data: function () {
    let startDate = new Date();
    startDate.setDate(1)
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    return {
      selectedTagCategories: undefined,
      startDate: startDate,
      endDate: endDate,
      loading: false,
      active: undefined,
      open: undefined,
      search: undefined,
      appendSummary: undefined,
      generalTotal: 0,
      pinnedItem: undefined,
      isB: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      headersTables: [
        { text: "INTESTAZIONE", value: "rowHeader", type: "tooltip" },
        { text: "VALORE", value: "value" },
      ],
      results: {},
      commercial: {},
      tags: {},
      resultsNameMapping: {
        'Estetica': 'beauticianData',
        'Parrucchiere': 'barberData',
        'Bistrot': 'bistrotData',
      },
      colorSet: ['rgba(138,119,226,0.6)', 'rgba(99,143,239,0.6)', 'rgba(63,162,241,0.6)', 'rgba(54,178,235,0.6)', 'rgba(80,191,225,0.6)'],
      generalChartData: {},
      generalTableData: [],
      servicesCustomersTotalSelected: undefined,
      servicesCustomersReload: false,
      servicesCustomersDetailsKeysRow: undefined,
      servicesCustomersDetailReload: false,
      servicesCustomersDetailsColors: ['#ff595e', '#ff924c', '#ffca3a', '#c5ca30', '#8ac926', '#36949d', '#1982c4', '#4267ac', '#565aa0', '#6a4c93'],
      paymentTransactionsColors: ['#cdb4db', '#ffd6e0', '#90f1ef'],
      paymentTransactionsCustomersColors: ['#cdb4db', '#f5ebe0', '#ffd6e0', '#90f1ef'],
      xAxesServicesCustomersDetail: [{
        gridLines: {
          drawOnChartArea: false
        },
        ticks: {
          callback: function(label) {
            if(label.length > 11) {
              return label.substring(0, 8) + '...'
            } else {
              return label
            }
          }
        },
      }],
      fileName: ""
    }
  },
  mounted: async function () {
    let response = await tagsCategoriesService.list(1, 4000, { 'typeCost': false, excludeInBalance: false, notGeneral: true })
    this.selectedTagCategories = response.rows.splice(0, 3)
    this.calculate()
  },
  methods: {
    exportToExcel() {
      let workbook = XLSX.utils.book_new();
      //Creazione e popolamento prima tabella (Generale)
      let ws = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws, [
        [
          '',
          'Totale',
          'Percentuale',
          'N° Tran.',
          'Fiche Media',
          'N° Clienti'
        ],
        ...this.generalTableData.map((el) => {
          return [
            el.name,
            el.total,
            el.percentage,
            el.count,
            !!el.avg ? Number((Number(el.avg)).toFixed(2)) : "",
            el.customersCount
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws, "Riepilogo Periodo Commerciale");


      //Creazione e popolamento tabelle per Tag selezionati (Bistrot, Estetica ...)
      for (let i = 0; i < Object.keys(this.results).length; i++) {
        let ws1 = XLSX.utils.aoa_to_sheet([[]]);
        let tag = Object.keys(this.results)[i]
        let tagArguments = this.results[tag]
        let productAndServiceRow = []
        let purchaseCustomersTotalRows = []
        let purchaseCustomersGenderWithItemsRows = []
        let purchaseCustomersGenderWithoutItemsRows = []
        let servicesCustomersTotalRows = []
        let servicesCustomersFemaleRows = []
        let servicesCustomersFemaleDetailsFirst = []
        let servicesCustomersFemaleDetailsSecond = []
        let servicesCustomersFemaleDetailsThird = []
        let servicesCustomersFemaleDetailsFourth = []
        let servicesCustomersFemaleDetailsFifth = []
        let servicesCustomersFemaleDetailsSixth = []
        let servicesCustomersFemaleDetailsSeventh = []
        let servicesCustomersFemaleDetailsEighth = []
        let servicesCustomersMaleRows = []
        let servicesCustomersMaleDetailsFirst = []
        let servicesCustomersMaleDetailsSecond = []
        let servicesCustomersMaleDetailsThird = []
        let servicesCustomersMaleDetailsFourth = []
        let servicesCustomersMaleDetailsFifth = []
        let servicesCustomersMaleDetailsSixth = []
        let servicesCustomersMaleDetailsSeventh = []
        let servicesCustomersMaleDetailsEighth = []
        let paymentTransactionsTotalRows = []
        let paymentTransactionsCustomersRows = []
        let marketingTransactionsRows = []
        let marketingCustomersRows = []
        //Aggiunta prima tabella per tag selezionati
        for (let j = 0; j < tagArguments.productAndServiceRows.length; j++) {
          productAndServiceRow.push(tagArguments.productAndServiceRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [PENETRAZIONE ACQUISTI VS CLIENTI]
        for (let j = 0; j < tagArguments.purchaseCustomersTotalRows.length; j++) {
          purchaseCustomersTotalRows.push(tagArguments.purchaseCustomersTotalRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [PENETRAZIONE ACQUISTI VS CLIENTI]
        for (let j = 0; j < tagArguments.purchaseCustomersGenderWithItemsRows.length; j++) {
          purchaseCustomersGenderWithItemsRows.push(tagArguments.purchaseCustomersGenderWithItemsRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [PENETRAZIONE ACQUISTI VS CLIENTI]
        for (let j = 0; j < tagArguments.purchaseCustomersGenderWithoutItemsRows.length; j++) {
          purchaseCustomersGenderWithoutItemsRows.push(tagArguments.purchaseCustomersGenderWithoutItemsRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA]
        for (let j = 0; j < tagArguments.servicesCustomersTotalRows.length; j++) {
          servicesCustomersTotalRows.push(tagArguments.servicesCustomersTotalRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA]
        for (let j = 0; j < tagArguments.servicesCustomersFemaleRows.length; j++) {
          servicesCustomersFemaleRows.push(tagArguments.servicesCustomersFemaleRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO 0-15]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["0-15"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["0-15"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === '0-15')
                tagArguments.servicesCustomersDetails["female"]["0-15"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["0-15"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsFirst.push(tagArguments.servicesCustomersDetails["female"]["0-15"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO 16-25]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["16-25"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["16-25"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === '16-25')
                tagArguments.servicesCustomersDetails["female"]["16-25"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["16-25"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsSecond.push(tagArguments.servicesCustomersDetails["female"]["16-25"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO 26-35]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["26-35"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["26-35"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === '26-35')
                tagArguments.servicesCustomersDetails["female"]["26-35"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["26-35"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsThird.push(tagArguments.servicesCustomersDetails["female"]["26-35"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO 36-45]
        if(!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["36-45"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["36-45"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === '36-45')
                tagArguments.servicesCustomersDetails["female"]["36-45"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["36-45"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsFourth.push(tagArguments.servicesCustomersDetails["female"]["36-45"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO 46-55]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["46-55"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["46-55"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === '46-55')
                tagArguments.servicesCustomersDetails["female"]["46-55"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["46-55"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsFifth.push(tagArguments.servicesCustomersDetails["female"]["46-55"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO 56-65]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["56-65"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["56-65"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === '56-65')
                tagArguments.servicesCustomersDetails["female"]["56-65"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["56-65"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsSixth.push(tagArguments.servicesCustomersDetails["female"]["56-65"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO Over 65]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["Over 65"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["Over 65"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === 'Over 65')
                tagArguments.servicesCustomersDetails["female"]["Over 65"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["Over 65"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsSeventh.push(tagArguments.servicesCustomersDetails["female"]["Over 65"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - DONNA - DETTAGLIO Senza Definizione]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["female"] && !!tagArguments.servicesCustomersDetails["female"]["Senza Definizione"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["female"]["Senza Definizione"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersFemaleRows.length; k++) {
              if (tagArguments.servicesCustomersFemaleRows[k].name === 'Senza Definizione')
                tagArguments.servicesCustomersDetails["female"]["Senza Definizione"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["female"]["Senza Definizione"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersFemaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersFemaleDetailsEighth.push(tagArguments.servicesCustomersDetails["female"]["Senza Definizione"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO]
        for (let j = 0; j < tagArguments.servicesCustomersMaleRows.length; j++) {
          servicesCustomersMaleRows.push(tagArguments.servicesCustomersMaleRows[j])
        }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO 0-15]
        if (!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["0-15"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["0-15"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === '0-15')
                tagArguments.servicesCustomersDetails["male"]["0-15"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["0-15"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsFirst.push(tagArguments.servicesCustomersDetails["male"]["0-15"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO 16-25]
        if (!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["16-25"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["16-25"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === '16-25')
                tagArguments.servicesCustomersDetails["male"]["16-25"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["16-25"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsSecond.push(tagArguments.servicesCustomersDetails["male"]["16-25"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO 26-35]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["26-35"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["26-35"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === '26-35')
                tagArguments.servicesCustomersDetails["male"]["26-35"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["26-35"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsThird.push(tagArguments.servicesCustomersDetails["male"]["26-35"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO 36-45]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["36-45"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["36-45"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === '36-45')
                tagArguments.servicesCustomersDetails["male"]["36-45"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["36-45"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsFourth.push(tagArguments.servicesCustomersDetails["male"]["36-45"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO 46-55]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["46-55"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["46-55"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === '46-55')
                tagArguments.servicesCustomersDetails["male"]["46-55"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["46-55"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsFifth.push(tagArguments.servicesCustomersDetails["male"]["46-55"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO 56-65]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["56-65"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["56-65"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === '56-65')
                tagArguments.servicesCustomersDetails["male"]["56-65"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["56-65"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsSixth.push(tagArguments.servicesCustomersDetails["male"]["56-65"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO Over 65]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["Over 65"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["Over 65"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === 'Over 65')
                tagArguments.servicesCustomersDetails["male"]["Over 65"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["Over 65"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsSeventh.push(tagArguments.servicesCustomersDetails["male"]["Over 65"][j])
          }
        //Aggiunta seconda tabella per tag selezionati [TOTALE SERVIZI VS PASSAGGI CASSA - UOMO - DETTAGLIO Senza Definizione]
        if(!!tagArguments.servicesCustomersDetails &&  
          !!tagArguments.servicesCustomersDetails["male"] && !!tagArguments.servicesCustomersDetails["male"]["Senza Definizione"])
          for (let j = 0; j < tagArguments.servicesCustomersDetails["male"]["Senza Definizione"].length; j++) {
            for (let k = 0; k < tagArguments.servicesCustomersMaleRows.length; k++) {
              if (tagArguments.servicesCustomersMaleRows[k].name === 'Senza Definizione')
                tagArguments.servicesCustomersDetails["male"]["Senza Definizione"][j].percentage = Number(((Number(tagArguments.servicesCustomersDetails["male"]["Senza Definizione"][j].countPaymentTransactions) / Number(tagArguments.servicesCustomersMaleRows[k].countTransactions)) * 100).toFixed(2)) + " %"              
            }
            servicesCustomersMaleDetailsEighth.push(tagArguments.servicesCustomersDetails["male"]["Senza Definizione"][j])
          }
        
        //Aggiunta terza tabella per tag selezionati [TRANSAZIONI DI CASSA E CLIENTI]
        for (let j = 0; j < tagArguments.paymentTransactionsTotalRows.length; j++) {
          paymentTransactionsTotalRows.push(tagArguments.paymentTransactionsTotalRows[j])
        }
        //Aggiunta terza tabella per tag selezionati [TRANSAZIONI DI CASSA E CLIENTI - DETTAGLIO ]
        for (let j = 0; j < tagArguments.paymentTransactionsCustomersRows.length; j++) {
          paymentTransactionsCustomersRows.push(tagArguments.paymentTransactionsCustomersRows[j])
        }
        //Aggiunta terza tabella per tag selezionati [PENETRAZIONE MARKETING - VS TRANSAZIONI DI CASSA ]
        for (let j = 0; j < tagArguments.marketingTransactionsRows.length; j++) {
          marketingTransactionsRows.push(tagArguments.marketingTransactionsRows[j])
        }
        //Aggiunta terza tabella per tag selezionati [PENETRAZIONE MARKETING - VS CLIENTI ]
        for (let j = 0; j < tagArguments.marketingCustomersRows.length; j++) {
          marketingCustomersRows.push(tagArguments.marketingCustomersRows[j])
        }
        //Aggiunta tabelle per tag selezionati
        XLSX.utils.sheet_add_aoa(ws1, [
          [
            '',
            '€',
            '%',
            'N°',
            'F.M.',
          ],
          ...productAndServiceRow.map((el) => {
            return [
              el.name,
              el.total,
              el.percentage,
              el.count,
              Number((Number(el.avg)).toFixed(2))
            ]
          }),
          [],
          [" Penetrazione Acquisti vs Clienti "],
          [
            '',
            'N°',
            '%',
          ],
          ...purchaseCustomersTotalRows.map((el) => {
            return [
              el.name,
              el.count,
              el.percentage
            ]
          }),
          [],
          [
            '',
            'N°',
            '%',
            'F.M.',
            'N° Pz.',
            'X Pz.'
          ],
          ...purchaseCustomersGenderWithItemsRows.map((el) => {
            return [
              el.name,
              el.count,
              el.percentage,
              Number((Number(el.avgItems)).toFixed(2)),
              el.countItems,
              el.avgCountItems
            ]
          }),
          [],
          [
            '',
            'N°',
            '%',
          ],
          ...purchaseCustomersGenderWithoutItemsRows.map((el) => {
            return [
              el.name,
              el.count,
              el.percentage
            ]
          }),
          [],
          [" Totale Servizi vs Passaggi Cassa "],
          [
            '',
            '%',
            'N°',
            'M.S.'
          ],
          ...servicesCustomersTotalRows.map((el) => {
            return [
              el.name,
              el.percentage,
              el.count,
              el.avg
            ]
          }),
          [],
          [
            '',
            'N°',
            '%',
            'M.S.'
          ],
          ...servicesCustomersFemaleRows.map((el) => {
            return [
              el.name,
              el.count,
              el.percentage,
              el.avg
            ]
          }),
          [],
          ["0-15"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsFirst.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["16-25"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsSecond.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["26-35"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsThird.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["36-45"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsFourth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["46-55"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsFifth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["56-65"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsSixth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["Over 65"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsSeventh.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["Senza Definizione"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersFemaleDetailsEighth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          [
            '',
            'N°',
            '%',
            'M.S.'
          ],
          ...servicesCustomersMaleRows.map((el) => {
            return [
              el.name,
              el.count,
              el.percentage,
              el.avg
            ]
          }),
          [],
          ["0-15"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsFirst.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["16-25"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsSecond.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["26-35"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsThird.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["36-45"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsFourth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["46-55"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsFifth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["56-65"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsSixth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["Over 65"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsSeventh.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          ["Senza Definizione"],
          [
            '',
            '%',
            'N°'
          ],
          ...servicesCustomersMaleDetailsEighth.map((el) => {
            return [
              el.tagName,
              el.percentage,
              el.countServices
            ]
          }),
          [],
          [" Transazioni di cassa e Clienti "],
          [
            '',
            '%',
            'N°',
          ],
          ...paymentTransactionsTotalRows.map((el) => {
            return [
              el.name,
              el.percentage,
              el.count
            ]
          }),
          [],
          [
            '',
            '%',
            'N°',
          ],
          ...paymentTransactionsCustomersRows.map((el) => {
            return [
              el.name,
              el.percentage,
              el.count
            ]
          }),
          [],
          [" Penetrazione Marketing "],
          [" vs Transazioni di Cassa "],
          [
            '',
            '%',
            'N°',
          ],
          ...marketingTransactionsRows.map((el) => {
            return [
              el.name,
              el.percentage,
              el.count
            ]
          }),
          [],
          [" vs Clienti "],
          [
            '',
            '%',
            'N°',
          ],
          ...marketingCustomersRows.map((el) => {
            return [
              el.name,
              el.percentage,
              el.count
            ]
          })
        ])

        //Aggiungiamo una worksheet per ogni tag selezionato
        XLSX.utils.book_append_sheet(workbook, ws1, tag);
      }

      let commercialArray = []
      //Aggiunta della tabella Commerciale
      for (let i = 0; i < this.commercial["productAndServiceRows"].length; i++) {
        commercialArray.push(this.commercial["productAndServiceRows"][i])        
      }
      let ws2 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws2, [
        [
          '',
          '€',
          '%',
          'N°',
          'F.M.'
        ],
        ...commercialArray.map((el) => {
          return [
            el.name,
            el.total,
            el.percentage,
            el.count,
            !!el.avg ? Number((Number(el.avg)).toFixed(2)) : ""
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws2, "Commerciale");
      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    calculate() {
      if (!this.selectedTagCategories || this.selectedTagCategories.length == 0) {
        this.$delegates.snackbar('Attenzione! Scegliere almeno una categoria')
        return
      }

      const startDate = new Date(this.startDate),
      endDate = new Date(this.endDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      this.fileName = "Riepilogo_Periodo_Commerciale_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls";
      this.loading = true
      balanceService.calculateByTags({
        from: startDate,
        to: endDate,
        tagCategoriesId: this.selectedTagCategories.map(el => el.id),
        ghost: this.isB
      }).then((results) => {
        this.results = {}
        this.tags = {}
        this.generalTableData = []

        let totalDebits = results.paymentDebitDetails.reduce((acc, el) => acc + Number(el.total), 0)
        results.total.total = Number(results.total.total) - totalDebits
        
        this.generalTableData.push({
          name: 'Totale Generale',
          total: Number(results.total.total),
          percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          count: Number(results.total.countTransactions) > 0 ? Number(results.total.countTransactions) : 0,
          avg: (Number(results.total.total)) / Number(results.total.countTransactions),
          customersCount: Number(results.total.countCustomers) > 0 ? Number(results.total.countCustomers) : 0,
        })

        let chartData = {
          labels: [],
          datasets: [
            {
              label: 'Label',
              pointBackgroundColor: 'black',
              borderWidth: 2,
              pointBorderColor: 'black',
              data: [],
              backgroundColor: [],
            }
          ]
        }

        let counter = 0
        this.selectedTagCategories.map((tag) => {
          //qui preparo i dati di ogni categoria presente
          this.tags[tag.name] = { name: tag.name, panel: [0] }

          //#region prodotti e servizi e il generale
          let productAndService = results["productAndServiceTotal"].find(el => el.name == tag.name)

          if (!!productAndService) {
            this.tags[tag.name]["productAndService"] = productAndService

            let findedDebitsService = results["paymentDebitDetails"].find(el => el.tagCategoryName == tag.name && el.type == 'service')
            let findedDebitsProduct = results["paymentDebitDetails"].find(el => el.tagCategoryName == tag.name && el.type == 'item')

            let debitServices = findedDebitsService ? findedDebitsService.total : 0
            let debitProducts = findedDebitsProduct ? findedDebitsProduct.total : 0

            let debitsServicesQuantity = findedDebitsService ? findedDebitsService.quantity : 0
            let debitProductsQuantity = findedDebitsProduct ? findedDebitsProduct.quantity : 0

            productAndService.total = Number(productAndService.total) - Number(debitServices) - Number(debitProducts)
            productAndService.total = Number(productAndService.total).toFixed(2)
            productAndService.totalServices = Number(productAndService.totalServices) - Number(debitServices)
            productAndService.totalServices = Number(productAndService.totalServices).toFixed(2)
            productAndService.totalItems = Number(productAndService.totalItems) - Number(debitProducts)
            productAndService.totalItems = Number(productAndService.totalItems).toFixed(2)

            productAndService.countServiceAndItems = Number(productAndService.countServiceAndItems) - Number(debitsServicesQuantity) - Number(debitProductsQuantity)
            productAndService.countServices = Number(productAndService.countServices) - Number(debitsServicesQuantity)
            productAndService.countItems = Number(productAndService.countItems) - Number(debitProductsQuantity)

            this.tags[tag.name]["productAndServiceRows"] = [
              {
                name: 'Totale',
                total: Number(productAndService.total),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                count: Number(productAndService.countServiceAndItems),
                avg: Number(productAndService.total) / Number(productAndService.countTransactions),
                color: undefined
              },
              {
                name: 'Servizi',
                total: Number(productAndService.totalServices),
                percentage: (
                    Number(productAndService.total) == 0 ? 0 :
                    Number(productAndService.totalServices) / Number(productAndService.total) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                count: Number(productAndService.countServices),
                avg: (Number(productAndService.totalServices) / Number(productAndService.countTransactionsServices)),
                color: '#50bfe19c'
              },
              {
                name: 'Prodotti',
                total: Number(productAndService.totalItems),
                percentage: (
                    Number(productAndService.total) == 0 ? 0 :
                    Number(productAndService.totalItems) / Number(productAndService.total) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                count: Number(productAndService.countItems),
                avg: (Number(productAndService.totalItems) / Number(productAndService.countItems)),
                color: '#8b77e29c'
              },
            ]

            this.tags[tag.name]['productAndServiceChartData'] = {
              labels: ['Servizi', 'Prodotti'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [Number(productAndService.totalServices), Number(productAndService.totalItems)],
                  backgroundColor: ['#50bfe19c', '#8b77e29c'],
                }
              ]
            }
            
            chartData.labels.push(tag.name)
            chartData.datasets[0].data.push((Number(productAndService.totalServices) + Number(productAndService.totalItems)).toFixed(2))
            chartData.datasets[0].backgroundColor.push(this.colorSet[counter % this.colorSet.length])
            this.generalTableData.push({
              color: this.colorSet[counter % this.colorSet.length],
              name: 'Di cui ' + tag.name,
              total: Number(productAndService.totalServices) + Number(productAndService.totalItems),
              percentage: ((((Number(productAndService.totalServices) + Number(productAndService.totalItems)) / results.total.total) || 0) * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              count: Number(productAndService.countTransactions),
              avg: (Number(productAndService.totalServices) + Number(productAndService.totalItems)) / Number(productAndService.countTransactions),
              customersCount: Number(productAndService.countCustomers) > 0 ? Number(productAndService.countCustomers) : 0,
            })
            counter++
          } else {
            chartData.labels.push(tag.name)
            chartData.datasets[0].data.push(Number(0))
            chartData.datasets[0].backgroundColor.push(this.colorSet[counter % this.colorSet.length])
            this.generalTableData.push({
              color: this.colorSet[counter % this.colorSet.length],
              name: 'Di cui ' + tag.name,
              total: 0,
              percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              count: 0,
              avg: 0,
              customersCount: 0,
            })
            counter++

            this.tags[tag.name] = { panel: [0] }
            this.tags[tag.name]["productAndService"] = { name: tag.name, countTransactions: 0, total: 0, totalService: 0, totalProduct: 0 }
            this.tags[tag.name]["productAndServiceRows"] = [
              {
                name: 'Totale',
                total: 0,
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                count: 0,
                avg: 0,
                color: undefined
              },
              {
                name: 'Servizi',
                total: 0,
                percentage: '0.00%',
                count: 0,
                avg: 0,
                color: '#50bfe19c'
              },
              {
                name: 'Prodotti',
                total: 0,
                percentage: '0.00%',
                count: 0,
                avg: 0,
                color: '#8b77e29c'
              },
            ]

            this.tags[tag.name]['productAndServiceChartData'] = {
              labels: ['Servizi', 'Prodotti'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [0, 0],
                  backgroundColor: ['#50bfe19c', '#8b77e29c'],
                }
              ]
            }
          }
          //#endregion

          //#region penetrazione acquisti
          let purchaseCustomers = results["purchaseCustomers"].find(el => el.tagCategoryName == tag.name)

          if(!!purchaseCustomers){
            this.tags[tag.name]["purchaseCustomers"] = purchaseCustomers

            this.tags[tag.name]["purchaseCustomersTotalRows"] = [
              {
                name: 'Clienti Transitati',
                count: Number(purchaseCustomers.countCustomers),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui ACQUISTA',
                count: Number(purchaseCustomers.countCustomersWithItems),
                percentage: (
                    Number(purchaseCustomers.countCustomers) == 0 ? 0 :
                    Number(purchaseCustomers.countCustomersWithItems) / Number(purchaseCustomers.countCustomers) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: '#caffbf'
              },
              {
                name: 'di cui NON ACQUISTA',
                count: Number(purchaseCustomers.countCustomersWithoutItems),
                percentage: (
                    Number(purchaseCustomers.countCustomers) == 0 ? 0 :
                    Number(purchaseCustomers.countCustomersWithoutItems) / Number(purchaseCustomers.countCustomers) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: '#ffadad'
              },
            ]

            this.tags[tag.name]['purchaseCustomersTotalChartData'] = {
              labels: ['ACQUISTA', 'NON ACQUISTA'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [Number(purchaseCustomers.countCustomersWithItems), Number(purchaseCustomers.countCustomersWithoutItems)],
                  backgroundColor: ['#caffbf', '#ffadad'],
                }
              ]
            }
            this.tags[tag.name]["purchaseCustomersGenderWithItemsRows"] = [
              {
                name: 'Clienti che Acquistano',
                count: Number(purchaseCustomers.countCustomersWithItems),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                countItems: purchaseCustomers.countItems,
                avgCountItems: (
                    Number(purchaseCustomers.countCustomersWithItems) == 0 ? 0 :
                    Number(purchaseCustomers.countItems) / Number(purchaseCustomers.countCustomersWithItems)
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 1 }),
                avgItems: (
                  Number(purchaseCustomers.countItems) == 0 ? 0 :
                  Number(purchaseCustomers.totalItems) / Number(purchaseCustomers.countItems)
                ),
                color: undefined
              },
              {
                name: 'di cui DONNA',
                count: Number(purchaseCustomers.countFemaleCustomersWithItems),
                percentage: (
                    Number(purchaseCustomers.countCustomersWithItems) == 0 ? 0 :
                    Number(purchaseCustomers.countFemaleCustomersWithItems) / Number(purchaseCustomers.countCustomersWithItems) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                countItems: purchaseCustomers.countFemaleItems,
                avgCountItems: (
                    Number(purchaseCustomers.countFemaleCustomersWithItems) == 0 ? 0 :
                    Number(purchaseCustomers.countFemaleItems) / Number(purchaseCustomers.countFemaleCustomersWithItems)
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 1 }),
                avgItems: (
                  Number(purchaseCustomers.countFemaleItems) == 0 ? 0 :
                  Number(purchaseCustomers.totalFemaleItems) / Number(purchaseCustomers.countFemaleItems)
                ),
                color: '#ffd6e0'
              },
              {
                name: 'di cui UOMO',
                count: Number(purchaseCustomers.countMaleCustomersWithItems),
                percentage: (
                    Number(purchaseCustomers.countCustomersWithItems) == 0 ? 0 :
                    Number(purchaseCustomers.countMaleCustomersWithItems) / Number(purchaseCustomers.countCustomersWithItems) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                countItems: purchaseCustomers.countMaleItems,
                avgCountItems: (
                    Number(purchaseCustomers.countMaleCustomersWithItems) == 0 ? 0 :
                    Number(purchaseCustomers.countMaleItems) / Number(purchaseCustomers.countMaleCustomersWithItems)
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 1 }),
                avgItems: (
                    Number(purchaseCustomers.countMaleItems) == 0 ? 0 :
                    Number(purchaseCustomers.totalMaleItems) / Number(purchaseCustomers.countMaleItems)
                  ),
                color: '#90f1ef'
              },
            ]

            this.tags[tag.name]['purchaseCustomersGenderWithItemsChartData'] = {
              labels: ['DONNA', 'UOMO'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [Number(purchaseCustomers.countFemaleCustomersWithItems), Number(purchaseCustomers.countMaleCustomersWithItems)],
                  backgroundColor: ['#ffd6e0', '#90f1ef'],
                }
              ]
            }

            this.tags[tag.name]["purchaseCustomersGenderWithoutItemsRows"] = [
              {
                name: 'Clienti che Non Acquistano',
                count: Number(purchaseCustomers.countCustomersWithoutItems),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui DONNA',
                count: Number(purchaseCustomers.countFemaleCustomersWithoutItems),
                percentage: (
                    Number(purchaseCustomers.countCustomersWithoutItems) == 0 ? 0 :
                    Number(purchaseCustomers.countFemaleCustomersWithoutItems) / Number(purchaseCustomers.countCustomersWithoutItems) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui UOMO',
                count: Number(purchaseCustomers.countMaleCustomersWithoutItems),
                percentage: (
                    Number(purchaseCustomers.countCustomersWithoutItems) == 0 ? 0 :
                    Number(purchaseCustomers.countMaleCustomersWithoutItems) / Number(purchaseCustomers.countCustomersWithoutItems) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
            ]
          } else {
            this.tags[tag.name]["purchaseCustomers"] = {}

            this.tags[tag.name]["purchaseCustomersTotalRows"] = [
              {
                name: 'Clienti Transitati',
                count: 0,
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui ACQUISTA',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: '#caffbf'
              },
              {
                name: 'di cui NON ACQUISTA',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: '#ffadad'
              },
            ]

            this.tags[tag.name]['purchaseCustomersTotalChartData'] = {
              labels: ['ACQUISTA', 'NON ACQUISTA'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [0, 0],
                  backgroundColor: ['#c1fba4', '#ff686b'],
                }
              ]
            }

            this.tags[tag.name]["purchaseCustomersGenderWithItemsRows"] = [
              {
                name: 'Clienti che Acquistano',
                count: 0,
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui DONNA',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: '#ffd6e0'
              },
              {
                name: 'di cui UOMO',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: '#90f1ef'
              },
            ]

            this.tags[tag.name]['purchaseCustomersGenderWithItemsChartData'] = {
              labels: ['DONNA', 'UOMO'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [0, 0],
                  backgroundColor: ['#ffd6e0', '#90f1ef'],
                }
              ]
            }

            this.tags[tag.name]["purchaseCustomersGenderWithoutItemsRows"] = [
              {
                name: 'Clienti che Non Acquistano',
                count: 0,
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui DONNA',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
              {
                name: 'di cui UOMO',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: undefined
              },
            ]
          }
          //#endregion
        
          //#region servizi vs passaggi cassa
          let servicesCustomers = results["servicesCustomers"].find(el => el.tagCategoryName == tag.name)
          if(!!servicesCustomers){
            this.tags[tag.name]["servicesCustomers"] = servicesCustomers

            this.tags[tag.name]["servicesCustomersTotalRows"] = [
              {
                name: 'Totale Servizi',
                count: Number(servicesCustomers.countServices),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (
                    Number(servicesCustomers.countTransactions) == 0 ? 0 :
                    Number(servicesCustomers.countServices) / Number(servicesCustomers.countTransactions)
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: undefined
              },
              {
                name: 'di cui DONNA',
                count: Number(servicesCustomers.countFemaleServices),
                percentage: (
                    Number(servicesCustomers.countServices) == 0 ? 0 :
                    Number(servicesCustomers.countFemaleServices) / Number(servicesCustomers.countServices) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (
                  Number(servicesCustomers.countFemaleTransactions) == 0 ? 0 :
                  Number(servicesCustomers.countFemaleServices) / Number(servicesCustomers.countFemaleTransactions)
                ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: '#ffd6e0'
              },
              {
                name: 'di cui UOMO',
                count: Number(servicesCustomers.countMaleServices),
                percentage: (
                    Number(servicesCustomers.countServices) == 0 ? 0 :
                    Number(servicesCustomers.countMaleServices) / Number(servicesCustomers.countServices) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (
                  Number(servicesCustomers.countMaleTransactions) == 0 ? 0 :
                  Number(servicesCustomers.countMaleServices) / Number(servicesCustomers.countMaleTransactions)
                ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: '#90f1ef'
              },
            ]

            this.tags[tag.name]['servicesCustomersTotalChartData'] = {
              labels: ['DONNA', 'UOMO'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [Number(servicesCustomers.countFemaleServices), Number(servicesCustomers.countMaleServices)],
                  backgroundColor: ['#ffd6e0', '#90f1ef'],
                }
              ]
            }

            let servicesCustomersFemaleRowsNameKeys = ['0-15', '16-25', '26-35', '36-45', '46-55', '56-65', 'Over 65', 'Senza Definizione']
            let servicesCustomersFemaleRowsCountKeys = ['female0-15', 'female16-25', 'female26-35', 'female36-45', 'female46-55', 'female56-65', 'femaleOver65', 'femaleUnknown']
            let servicesCustomersFemaleRowsServiceCountKeys = ['femaleServices0-15', 'femaleServices16-25', 'femaleServices26-35', 'femaleServices36-45', 'femaleServices46-55', 'femaleServices56-65', 'femaleServicesOver65', 'femaleServicesUnknown']
            let servicesCustomersFemaleRowsTransactionCountKeys = ['femaleTransactions0-15', 'femaleTransactions16-25', 'femaleTransactions26-35', 'femaleTransactions36-45', 'femaleTransactions46-55', 'femaleTransactions56-65', 'femaleTransactionsOver65', 'femaleTransactionsUnknown']
            let colors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff']

            this.tags[tag.name]["servicesCustomersFemaleRows"] = [
              {
                name: 'di cui DONNA',
                count: Number(servicesCustomers.countFemaleCustomers),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (
                    Number(servicesCustomers.countFemaleTransactions) == 0 ? 0 :
                    Number(servicesCustomers.countFemaleServices) / Number(servicesCustomers.countFemaleTransactions)
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: undefined
              },
            ]

            let data = []
            for(let i = 0; i < servicesCustomersFemaleRowsNameKeys.length; i++){
              this.tags[tag.name]["servicesCustomersFemaleRows"].push(
                {
                  name: servicesCustomersFemaleRowsNameKeys[i],
                  count: Number(servicesCustomers[servicesCustomersFemaleRowsCountKeys[i]]),
                  percentage: (
                      Number(servicesCustomers.countFemaleCustomers) == 0 ? 0 :
                      Number(servicesCustomers[servicesCustomersFemaleRowsCountKeys[i]]) / Number(servicesCustomers.countFemaleCustomers) * 100
                    ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  avg: (
                    Number(servicesCustomers[servicesCustomersFemaleRowsTransactionCountKeys[i]]) == 0 ? 0 :
                    Number(servicesCustomers[servicesCustomersFemaleRowsServiceCountKeys[i]]) / Number(servicesCustomers[servicesCustomersFemaleRowsTransactionCountKeys[i]])
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                  color: colors[i],
                  countTransactions: Number(servicesCustomers[servicesCustomersFemaleRowsTransactionCountKeys[i]])
                })

                data.push(Number(servicesCustomers[servicesCustomersFemaleRowsCountKeys[i]]))
              }
              this.tags[tag.name]['servicesCustomersFemaleChartData'] = {
                labels: servicesCustomersFemaleRowsNameKeys,
                datasets: [
                  {
                    label: 'Label',
                    pointBackgroundColor: 'black',
                    borderWidth: 2,
                    pointBorderColor: 'black',
                    data: data,
                    backgroundColor: colors
                  }
                ]
              }
              
            let servicesCustomersMaleRowsNameKeys = ['0-15', '16-25', '26-35', '36-45', '46-55', '56-65', 'Over 65', 'Senza Definizione']
            let servicesCustomersMaleRowsCountKeys = ['male0-15', 'male16-25', 'male26-35', 'male36-45', 'male46-55', 'male56-65', 'maleOver65', 'maleUnknown']
            let servicesCustomersMaleRowsServiceCountKeys = ['maleServices0-15', 'maleServices16-25', 'maleServices26-35', 'maleServices36-45', 'maleServices46-55', 'maleServices56-65', 'maleServicesOver65', 'maleServicesUnknown']
            let servicesCustomersMaleRowsTransactionCountKeys = ['maleTransactions0-15', 'maleTransactions16-25', 'maleTransactions26-35', 'maleTransactions36-45', 'maleTransactions46-55', 'maleTransactions56-65', 'maleTransactionsOver65', 'maleTransactionsUnknown']
            colors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff']

            this.tags[tag.name]["servicesCustomersMaleRows"] = [
              {
                name: 'di cui UOMO',
                count: Number(servicesCustomers.countMaleCustomers),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (
                    Number(servicesCustomers.countMaleTransactions) == 0 ? 0 :
                    Number(servicesCustomers.countMaleServices) / Number(servicesCustomers.countMaleTransactions)
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: undefined
              },
            ]

            data = []
            for(let i = 0; i < servicesCustomersMaleRowsNameKeys.length; i++){
              this.tags[tag.name]["servicesCustomersMaleRows"].push(
                {
                  name: servicesCustomersMaleRowsNameKeys[i],
                  count: Number(servicesCustomers[servicesCustomersMaleRowsCountKeys[i]]),
                  percentage: (
                      Number(servicesCustomers.countMaleCustomers) == 0 ? 0 :
                      Number(servicesCustomers[servicesCustomersMaleRowsCountKeys[i]]) / Number(servicesCustomers.countMaleCustomers) * 100
                    ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  avg: (
                    Number(servicesCustomers[servicesCustomersMaleRowsTransactionCountKeys[i]]) == 0 ? 0 :
                    Number(servicesCustomers[servicesCustomersMaleRowsServiceCountKeys[i]]) / Number(servicesCustomers[servicesCustomersMaleRowsTransactionCountKeys[i]])
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                  color: colors[i],
                  countTransactions: Number(servicesCustomers[servicesCustomersMaleRowsTransactionCountKeys[i]])
                })

              data.push(Number(servicesCustomers[servicesCustomersMaleRowsCountKeys[i]]))
            }
            this.tags[tag.name]['servicesCustomersMaleChartData'] = {
              labels: servicesCustomersMaleRowsNameKeys,
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: data,
                  backgroundColor: colors
                }
              ]
            }

            let servicesCustomersDetailsObj = {
              female: [],
              male: [],
            }
            let servicesCustomersDetails = results["servicesCustomersDetails"].filter(el => el.tagCategoryName == tag.name)
            
            servicesCustomersDetailsObj.female = _.groupBy(servicesCustomersDetails.filter(el => el.customerGender == 'female'), 'customerAge')
            servicesCustomersDetailsObj.male = _.groupBy(servicesCustomersDetails.filter(el => el.customerGender == 'male'), 'customerAge')

            this.tags[tag.name]['servicesCustomersDetails'] = servicesCustomersDetailsObj
          } else {
            this.tags[tag.name]["servicesCustomers"] = {}

            this.tags[tag.name]["servicesCustomersTotalRows"] = [
              {
                name: 'Totale Servizi',
                count: 0,
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: undefined
              },
              {
                name: 'di cui DONNA',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: '#ffd6e0'
              },
              {
                name: 'di cui UOMO',
                count: 0,
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: '#90f1ef'
              },
            ]

            this.tags[tag.name]['servicesCustomersTotalChartData'] = {
              labels: ['DONNA', 'UOMO'],
              datasets: [
                {
                  label: 'Label',
                  pointBackgroundColor: 'black',
                  borderWidth: 2,
                  pointBorderColor: 'black',
                  data: [0,0],
                  backgroundColor: ['#ffd6e0', '#90f1ef'],
                }
              ]
            }

            let servicesCustomersFemaleRowsNameKeys = ['0-15', '16-25', '26-35', '36-45', '46-55', '56-65', 'Over 65', 'Senza Definizione']
            let colors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff']

            this.tags[tag.name]["servicesCustomersFemaleRows"] = [
              {
                name: 'di cui DONNA',
                count: 0,
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                color: undefined
              },
            ]

            let data = []
            for(let i = 0; i < servicesCustomersFemaleRowsNameKeys.length; i++){
              this.tags[tag.name]["servicesCustomersFemaleRows"].push(
                {
                  name: servicesCustomersFemaleRowsNameKeys[i],
                  count: 0,
                  percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                  color: colors[i],
                  countTransactions: 1
                })

                data.push(0)
              }
              this.tags[tag.name]['servicesCustomersFemaleChartData'] = {
                labels: servicesCustomersFemaleRowsNameKeys,
                datasets: [
                  {
                    label: 'Label',
                    pointBackgroundColor: 'black',
                    borderWidth: 2,
                    pointBorderColor: 'black',
                    data: data,
                    backgroundColor: colors
                  }
                ]
              }
                
              let servicesCustomersMaleRowsNameKeys = ['0-15', '16-25', '26-35', '36-45', '46-55', '56-65', 'Over 65', 'Senza Definizione']
              colors = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff']

              this.tags[tag.name]["servicesCustomersMaleRows"] = [
                {
                  name: 'di cui UOMO',
                  count: 0,
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                  color: undefined
                },
              ]

              data = []
              for(let i = 0; i < servicesCustomersMaleRowsNameKeys.length; i++){
                this.tags[tag.name]["servicesCustomersMaleRows"].push(
                  {
                    name: servicesCustomersMaleRowsNameKeys[i],
                    count: 0,
                    percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                    avg: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }),
                    color: colors[i],
                    countTransactions: 1
                  })

                data.push(0)
              }
              this.tags[tag.name]['servicesCustomersMaleChartData'] = {
                labels: servicesCustomersMaleRowsNameKeys,
                datasets: [
                  {
                    label: 'Label',
                    pointBackgroundColor: 'black',
                    borderWidth: 2,
                    pointBorderColor: 'black',
                    data: data,
                    backgroundColor: colors
                  }
                ]
              }

              this.tags[tag.name]['servicesCustomersDetails'] = {}
            }
          //#endregion
        
          //#region transazioni vs cassa

          let paymentTransactions = results["paymentTransactions"].find(el => el.name == tag.name)

          if (!!paymentTransactions) {
            this.tags[tag.name]["paymentTransactions"] = paymentTransactions

            this.tags[tag.name]["paymentTransactionsTotalRows"] = [
              {
                name: 'Totale',
                count: Number(paymentTransactions.countTransactions),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsColors[0]
              },
              {
                name: 'di cui DONNA',
                count: Number(paymentTransactions.countFemaleTransactions),
                percentage: (
                    Number(paymentTransactions.countTransactions) == 0 ? 0 :
                    Number(paymentTransactions.countFemaleTransactions) / Number(paymentTransactions.countTransactions) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsColors[1]
              },
              {
                name: 'di cui UOMO',
                count: Number(paymentTransactions.countMaleTransactions),
                percentage: (
                    Number(paymentTransactions.countTransactions) == 0 ? 0 :
                    Number(paymentTransactions.countMaleTransactions) / Number(paymentTransactions.countTransactions) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsColors[2]
              },
            ]

            this.tags[tag.name]["paymentTransactionsTotalChartData"] = {
              name: 'Transazioni di Cassa',
              label: 'Transazioni di Cassa',
              data: [{
                  name: 'Totale',
                  count: Number(paymentTransactions.countTransactions),
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'DONNA',
                  count: Number(paymentTransactions.countFemaleTransactions),
                  percentage: (
                      Number(paymentTransactions.countTransactions) == 0 ? 0 :
                      Number(paymentTransactions.countFemaleTransactions) / Number(paymentTransactions.countTransactions) * 100
                    ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'UOMO',
                  count: Number(paymentTransactions.countMaleTransactions),
                  percentage: (
                      Number(paymentTransactions.countTransactions) == 0 ? 0 :
                      Number(paymentTransactions.countMaleTransactions) / Number(paymentTransactions.countTransactions) * 100
                    ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
              ]
            }
            

            this.tags[tag.name]["paymentTransactionsCustomersRows"] = [
              {
                name: 'Totale Trans.',
                count: Number(paymentTransactions.countTransactions),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[0]
              },
              {
                name: 'Totale Clienti',
                count: Number(paymentTransactions.countCustomers),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[1]
              },
              {
                name: 'di cui DONNA',
                count: Number(paymentTransactions.countFemaleCustomers),
                percentage: (
                    Number(paymentTransactions.countCustomers) == 0 ? 0 :
                    Number(paymentTransactions.countFemaleCustomers) / Number(paymentTransactions.countCustomers) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[2]
              },
              {
                name: 'di cui UOMO',
                count: Number(paymentTransactions.countMaleCustomers),
                percentage: (
                    Number(paymentTransactions.countCustomers) == 0 ? 0 :
                    Number(paymentTransactions.countMaleCustomers) / Number(paymentTransactions.countCustomers) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[3]
              },
            ]

            this.tags[tag.name]["paymentTransactionsCustomersChartData"] = {
              name: 'N° Clienti vs Transazioni',
              label: 'N° Clienti vs Transazioni',
              data: [
                {
                  name: 'Transazioni',
                  count: Number(paymentTransactions.countTransactions),
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'Totale Clienti',
                  count: Number(paymentTransactions.countCustomers),
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'DONNA',
                  count: Number(paymentTransactions.countFemaleCustomers),
                  percentage: (
                      Number(paymentTransactions.countCustomers) == 0 ? 0 :
                      Number(paymentTransactions.countFemaleCustomers) / Number(paymentTransactions.countCustomers) * 100
                    ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'UOMO',
                  count: Number(paymentTransactions.countMaleCustomers),
                  percentage: (
                      Number(paymentTransactions.countCustomers) == 0 ? 0 :
                      Number(paymentTransactions.countMaleCustomers) / Number(paymentTransactions.countCustomers) * 100
                    ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
              ]
            }
          } else {
            this.tags[tag.name]["paymentTransactions"] = {}

            this.tags[tag.name]["paymentTransactionsTotalRows"] = [
              {
                name: 'Totale',
                count: Number(0),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsColors[0]
              },
              {
                name: 'di cui DONNA',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsColors[1]
              },
              {
                name: 'di cui UOMO',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsColors[2]
              },
            ]
            
            this.tags[tag.name]["paymentTransactionsTotalChartData"] = {
              name: 'Transazioni di Cassa',
              label: 'Transazioni di Cassa',
              data: [{
                  name: 'Totale',
                  count: Number(0),
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'DONNA',
                  count: Number(0),
                  percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
                {
                  name: 'UOMO',
                  count: Number(0),
                  percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                },
              ]
            }

            this.tags[tag.name]["paymentTransactionsCustomersRows"] = [
              {
                name: 'Totale Trans.',
                count: Number(0),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[0]
              },
              {
                name: 'Totale Clienti',
                count: Number(0),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[1]
              },
              {
                name: 'di cui DONNA',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[2]
              },
              {
                name: 'di cui UOMO',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                color: this.paymentTransactionsCustomersColors[3]
              },
            ]

            this.tags[tag.name]["paymentTransactionsCustomersChartData"] = {
              name: 'N° Clienti vs Transazioni',
              label: 'N° Clienti vs Transazioni',
              data: [
                {
                  name: 'Transazioni',
                  count: Number(0),
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  color: undefined
                },
                {
                  name: 'Totale Clienti',
                  count: Number(0),
                  percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  color: undefined
                },
                {
                  name: 'DONNA',
                  count: Number(0),
                  percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  color: undefined
                },
                {
                  name: 'UOMO',
                  count: Number(0),
                  percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
                  color: undefined
                },
              ]
            }
          }
          //#endregion

          //#region marketing

          let marketing = results["marketing"].find(el => el.name == tag.name)

          if (!!paymentTransactions) {
            this.tags[tag.name]["marketing"] = marketing

            this.tags[tag.name]["marketingTransactionsRows"] = [
              {
                name: 'TOT TRANSAZ.',
                count: Number(marketing.countTransactions),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },              
              {
                name: 'di cui CON PROMO',
                count: Number(marketing.countPromosTransactions),
                percentage: (
                    Number(marketing.countTransactions) == 0 ? 0 :
                    Number(marketing.countPromosTransactions) / Number(marketing.countTransactions) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
              {
                name: 'di cui SENZA PROMO',
                count: Number(marketing.countNotPromoTransactions),
                percentage: (
                    Number(marketing.countTransactions) == 0 ? 0 :
                    Number(marketing.countNotPromoTransactions) / Number(marketing.countTransactions) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
            ]

            this.tags[tag.name]["marketingCustomersRows"] = [
              {
                name: 'TOT CLIENTI',
                count: Number(marketing.countCustomers),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },              
              {
                name: 'di cui CON PROMO',
                count: Number(marketing.countPromosCustomers),
                percentage: (
                    Number(marketing.countCustomers) == 0 ? 0 :
                    Number(marketing.countPromosCustomers) / Number(marketing.countCustomers) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
              {
                name: 'di cui SENZA PROMO',
                count: (Number(marketing.countCustomers) - Number(marketing.countPromosCustomers)),
                percentage: (
                    Number(marketing.countCustomers) == 0 ? 0 :
                    (Number(marketing.countCustomers) - Number(marketing.countPromosCustomers)) / Number(marketing.countCustomers) * 100
                  ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
            ]
          }
          else {
            this.tags[tag.name]["marketing"] = {}

            this.tags[tag.name]["marketingTransactionsRows"] = [
              {
                name: 'TOT TRANSAZ.',
                count: Number(0),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },              
              {
                name: 'di cui CON PROMO',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
              {
                name: 'di cui SENZA PROMO',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
            ]

            this.tags[tag.name]["marketingCustomersRows"] = [
              {
                name: 'TOT CLIENTI',
                count: Number(0),
                percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },              
              {
                name: 'di cui CON PROMO',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
              {
                name: 'di cui SENZA PROMO',
                count: Number(0),
                percentage: (0).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              },
            ]
          }

          //#endregion
        })

        
        this.commercial = {
          productAndService: { name:'Commerciale' },
          panel: [0]
        }

        this.commercial["productAndServiceRows"] = [
          {
            name: 'Totale',
            total: Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total),
            percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            count: Number(results.invoiceTotal.totalInvoices) + Number(results.otherCashesTotal.totalOtherCashes),
            color: undefined
          },
          {
            name: 'Fatture',
            total: Number(results.invoiceTotal.total),
            percentage: (
                Number(results.invoiceTotal.total) == 0 ? 0 :
                Number(results.invoiceTotal.total) / (Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total)) * 100
              ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            count: Number(results.invoiceTotal.totalInvoices),
            avg: (Number(results.invoiceTotal.total) / Number(results.invoiceTotal.totalInvoices)),
            color: '#50bfe19c'
          },
          {
            name: 'Altri Ricavi',
            total: Number(results.otherCashesTotal.total),
            percentage: (
              Number(results.otherCashesTotal.total) == 0 ? 0 :
                Number(results.otherCashesTotal.total) / (Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total)) * 100
              ).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
            count: Number(results.otherCashesTotal.totalOtherCashes),
            avg: (Number(results.otherCashesTotal.total) / Number(results.otherCashesTotal.totalOtherCashes)),
            color: '#8b77e29c'
          }
        ]

        this.commercial['productAndServiceChartData'] = {
          labels: ['Fatture', 'Altri Ricavi'],
          datasets: [
            {
              label: 'Label',
              pointBackgroundColor: 'black',
              borderWidth: 2,
              pointBorderColor: 'black',
              data: [Number(results.invoiceTotal.total), Number(results.otherCashesTotal.total)],
              backgroundColor: ['#50bfe19c', '#8b77e29c'],
            }
          ]
        }

        chartData.labels.push('Commerciale')
        chartData.datasets[0].data.push(Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total))
        chartData.datasets[0].backgroundColor.push(this.colorSet[counter % this.colorSet.length])
        this.generalTableData.push({
          color: this.colorSet[counter % this.colorSet.length],
          name: 'Commerciale',
          total: Number(results.invoiceTotal.total) + Number(results.otherCashesTotal.total),
          percentage: (100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
          count: Number(results.invoiceTotal.totalInvoices) + Number(results.otherCashesTotal.totalOtherCashes),
          customersCount: '-',
        })

        this.generalChartData = chartData
        this.results = this.tags
        this.loading = false
      })
    },
    servicesCustomersDetailsRows(result){
      let mappedKey1 = {
        'servicesCustomersMaleChartData': 'male',
        'servicesCustomersFemaleChartData': 'female'
      }

      let key2
      if(!!this.servicesCustomersDetailsKeysRow && this.servicesCustomersDetailsKeysRow.length > 0){
        key2 = this.servicesCustomersDetailsKeysRow[0].name
      } else {
        return []
      }

      let countTransactions = result[this.servicesCustomersRows].find(elem => {
        return elem.name == key2
      }).countTransactions

      if(!!result.servicesCustomersDetails[mappedKey1[this.servicesCustomersChartData]]){
        if(!!result.servicesCustomersDetails[mappedKey1[this.servicesCustomersChartData]][key2]){
          let tot = 0
          let res = result.servicesCustomersDetails[mappedKey1[this.servicesCustomersChartData]][key2].sort((a,b) => {
            return Number(b.countServices) - Number(a.countServices)
          }).slice(0, 10)

          for(let i = 0; i < res.length; i++){
            tot += Number(res[i].countServices)
          }

          return res.map((elem, i) => {
            return {
              name: elem.tagName,
              count: elem.countServices,
              percentage: (Number(elem.countPaymentTransactions) / Number(countTransactions) * 100).toLocaleString('it-IT', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%',
              color: this.servicesCustomersDetailsColors[i]
            }
          })
        } else {
          return []
        }
      } else {
        return []
      }
    },
    servicesCustomersDetailsChartData(result){
      let mappedKey1 = {
        'servicesCustomersMaleChartData': 'male',
        'servicesCustomersFemaleChartData': 'female'
      }
      let mappedName = {
        'servicesCustomersMaleChartData': 'UOMO',
        'servicesCustomersFemaleChartData': 'DONNA'
      }

      let key2
      if(!!this.servicesCustomersDetailsKeysRow && this.servicesCustomersDetailsKeysRow.length > 0){
        key2 = this.servicesCustomersDetailsKeysRow[0].name
      } else {
        return []
      }

      let data = {
        name: mappedName[this.servicesCustomersChartData] + " - " + key2,
        label: mappedName[this.servicesCustomersChartData] + " - " + key2,
        data: [],
      };

      if(!!result.servicesCustomersDetails[mappedKey1[this.servicesCustomersChartData]]){
        if(!!result.servicesCustomersDetails[mappedKey1[this.servicesCustomersChartData]][key2]){
          data.data = result.servicesCustomersDetails[mappedKey1[this.servicesCustomersChartData]][key2]

          data.data = data.data.sort((a,b) => {
            return Number(b.countServices) - Number(a.countServices)
          }).slice(0, 10)

          for(let i = data.data.length; i < 10; i++){
            data.data.push({
              'tagName': 'Vuoto ' + (i + 1),
              'countServices': 0
            })
          }
        }
      } 

      return data
    },
    changeWithB() {
      /*if (!!this.isB) 
        this.results = this.tags
      else 
        this.results = this.tagsWithB*/

      this.isB = !this.isB
      this.calculate()
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.changeWithB()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    pinItem(item) {
      if (!!this.pinnedItem && item.id == this.pinnedItem.id) this.pinnedItem = undefined
      else this.pinnedItem = item
    },
    print() {
      this.printLoading = true

      html2canvas(document.getElementById('chart'), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Riepilogo_Periodo_Commerciale_" + this.startDate.getDate() + "-" + this.startDate.getMonth() + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error) => {
        console.error(error)
        this.printLoading = false
      });
    },
    checkObjectLength() {
      return Object.keys(this.results).length == 0
    }
  },
  computed: {
    servicesCustomersChartData(){
      this.servicesCustomersReload = true
      let self = this
      setTimeout(() => {
        self.servicesCustomersReload = false
      }, 100)
      if (!this.servicesCustomersTotalSelected || !this.servicesCustomersTotalSelected[0] || this.servicesCustomersTotalSelected[0].name == 'di cui DONNA') {
        return 'servicesCustomersFemaleChartData'
      } else {
        return 'servicesCustomersMaleChartData'
      }
      
    },
    servicesCustomersRows(){
      if (!this.servicesCustomersTotalSelected || !this.servicesCustomersTotalSelected[0] || this.servicesCustomersTotalSelected[0].name == 'di cui DONNA') {
        return 'servicesCustomersFemaleRows'
      } else {
        return 'servicesCustomersMaleRows'
      }
    },
  },
  watch: {
    servicesCustomersDetailsKeysRow(newVal){
      this.servicesCustomersDetailReload = true
      let self = this
      setTimeout(() => {
        self.servicesCustomersDetailReload = false
      }, 100)
    }
  }
}
</script>

<style>
.table-gradient {
  background-image: linear-gradient(-45deg, #50bfe1aa, #8b77e2aa);
  box-shadow: 0px 3px 8px 1px;
}

.subtitle {
  font-size: 1.7rem;
  font-weight: 400;
  color: #000000;
}

.balance-table.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 4px;
}

.v-expansion-panel-header {
  color: rgba(0, 0, 0, 0.87);
}

.v-expansion-panel {
  background-color: transparent !important;
}

.v-expansion-panel--active {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
</style>