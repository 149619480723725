import recentOperationTracker from "@/services/common/recentOperationTracker";

class warehouseRecentOperationsDatabase extends recentOperationTracker {
  constructor() {
    super();
  }

  name() {
    return "warehouse";
  }

  _validateOperation(operation) {
    if (!operation.registry) {
      return "warehouse must be defined";
    } else {
      return true;
    }
  }
}

export default new warehouseRecentOperationsDatabase();
