import authService from "@/services/auth/auth.service";

const cashDesksRoutes = [
  {
    path: "/payments/cashDesks",
    name: "CashDesksNewForm",
    component: () => import("@/components/areas/payments/cashDesks/CashDesksNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/cashDesks/settings",
    name: "CashDesksDeviceSettings",
    component: () => import("@/components/setting/DevicesSettings.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathName: route.query.pathName,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/cashDesks/:id/edit",
    name: "CashDesksEditForm",
    component: () => import("@/components/areas/payments/cashDesks/CashDesksEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathName: route.query.pathName,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/cashDesks/list",
    name: "CashDesksList",
    component: () =>
      import(
        "@/components/areas/payments/cashDesks/CashDesksList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default cashDesksRoutes;