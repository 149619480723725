class ColorsService {
  constructor() {
    this.colors = [
      "#D37EE5", 
      "#92B5DE", 
      "#78989A", 
      "#E89663",
      "#7355CC",
      "#36F2A4",
      "#B9CD8E",
      "#4F542A",
      "#30818B",
      "#0686EE",
      "#8F8FB9",
      "#9F5DB4",
      "#BCB968",
      "#3E30F4",
      "#B13952",
      "#AFB269",
      "#A762DA",
      "#100F2A"
    ]
  }

  random(exclude) {
    if(!exclude) exclude = []
    else if(!Array.isArray(exclude)) exclude = [exclude]
    let choseBetween = this.colors.filter(el => !exclude.includes(el))

    let randomInt = Math.floor(Math.random() * choseBetween.length)
    return choseBetween[randomInt]
  }
}

export default new ColorsService()