import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class serviceTypesCategoriesManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypesCategories/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  archive(serviceTypesCategory) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!serviceTypesCategory.id)
        reject('id undefined')
      
      axios.post(apiUrl + 'registries/serviceTypesCategories/archive', serviceTypesCategory).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(serviceTypesCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypesCategories/create', serviceTypesCategory).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(serviceTypesCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypesCategories/update', serviceTypesCategory).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(serviceTypesCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!serviceTypesCategory.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/serviceTypesCategories/delete', {
        id: serviceTypesCategory.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(serviceTypesCategoryId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/serviceTypesCategories/get', { id: serviceTypesCategoryId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  getServiceTypes(serviceTypesCategoryId){
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/serviceTypes/getServiceTypes', { id: serviceTypesCategoryId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  updateOrderServiceTypes(serviceTypes){
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypes/updateOrderServiceTypes', serviceTypes).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }


  fields() {
    return Promise.resolve([
      { text: 'Nome', value: 'name' },
      { text: 'Descrizione', value: 'description' },
    ])
  }
}

export default new serviceTypesCategoriesManagment();
