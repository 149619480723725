import authService from "@/services/auth/auth.service";
const itemTypesRoutes = [
  {
    path: "/warehouse/itemTypes",
    name: "ItemTypesNewForm",
    component: () =>
      import(
        "@/components/areas/warehouse/itemTypes/ItemTypesNewForm.vue"
      ),
    children: [
      {
        path: "new/general-tab",
        name: "ItemTypesGeneralNewFormTab",
        component: () =>
          import(
            "@/components/areas/warehouse/itemTypes/form/GeneralFormTab.vue"
          ),
      },
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/warehouse/itemTypes/:id/edit",
    name: "ItemTypesEditForm",
    component: () => import("@/components/areas/warehouse/itemTypes/ItemTypesEditForm.vue"),
    children: [
      {
        path: "general-tab",
        name: "ItemTypesGeneralEditFormTab",
        component: () =>
          import(
            "@/components/areas/warehouse/itemTypes/form/GeneralFormTab.vue"
          ),
      }
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/warehouse/itemTypes/list",
    name: "ItemTypesList",
    component: () =>
      import(
        "@/components/areas/warehouse/itemTypes/ItemTypesList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default itemTypesRoutes;