import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import _ from 'lodash'

class packageTypesManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/packageTypes/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(packageType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/packageTypes/create', packageType).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(packageType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/packageTypes/update', packageType).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(packageType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!packageType.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/packageTypes/delete', {
        id: packageType.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(packageTypeId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/packageTypes/get', { id: packageTypeId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }


  fields() {
    return Promise.resolve([
      // here the fields
      { text: 'Nome', value: 'name' }
    ])
  }
}

export default new packageTypesManagment();
