import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import frequentServicesCache from "./frequentServices.cache";
import billsCache from "./bills.cache";
import todayBillsCache from "./todayBills.cache";
import socketService from "@/services/socket/socket.service"
import EventEmitter from "events";
import hash from 'object-hash'

class BillService extends EventEmitter {
  constructor() {
    super()
    this._frequentServicesAlreadyCached = false
    this._todayListAlreadyLoaded = false
    this._todayCacheFilters = []

    socketService.on('bills:createAndAddCustomers', async () => {
      await this.reloadAllTodayCachedFilterList()
      this.emit('today-bills-cached-changes')
    })

    socketService.on('bills:createAndAddManyCustomers', async () => {
      await this.reloadAllTodayCachedFilterList()
      this.emit('today-bills-cached-changes')
    })

    socketService.on('bills:delete', async () => {
      await this.reloadAllTodayCachedFilterList()
      this.emit('today-bills-cached-changes')
    })

    socketService.on('bills:updateCustomerCard', async () => {
      await this.reloadAllTodayCachedFilterList()
      this.emit('today-bills-cached-changes')
    })

    socketService.on('bills:close', async () => {
      await this.reloadAllTodayCachedFilterList()
      this.emit('today-bills-cached-changes')
    })
  }

  get socketConnected() {
    return !!socketService && socketService.connected
  }

  //#region Bills
  async todayCachedList({ filters }) {
    if (!this._todayListAlreadyLoaded) {
      await todayBillsCache.deleteAllCache()
      this._todayListAlreadyLoaded = true
    }

    if (!this._todayCacheFilters.some((f) => hash(f) == hash(filters))) {
      this._todayCacheFilters.push(filters)
    }

    if (await todayBillsCache.cachePresent({ filters })) {
      return await todayBillsCache.get({
        filters: { ...filters }
      })
    } else {
      await this.reloadTodayCachedList({
        filters: { ...filters }
      })
      return await todayBillsCache.get({
        filters: { ...filters }
      })
    }
  }

  async reloadAllTodayCachedFilterList() {
    await this.reloadTodayCachedList({})

    for (let i = 0; i < this._todayCacheFilters.length; i += 1) {
      await this.reloadTodayCachedList({
        filters: this._todayCacheFilters[i]
      })
    }
  }

  async reloadTodayCachedList({ filters }) {
    const bills = await this.todayList(1, 1000, { ...filters })

    await todayBillsCache.cache({ bills: bills.rows, filters: { ...filters } })
  }

  todayList(page, rowPerPage, filters) {
    if (!filters) filters = {};
    filters["today"] = true;
    filters["status"] = "opened";

    return this.list(page, rowPerPage, filters);
  }

  list(page = 1, rowPerPage = 500, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/bills/list", { page, rowPerPage, filters })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        });
    });
  }

  get(bill) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not defined"));
        return;
      }
      axios
        .get(apiUrl + "/payments/bills/get", { id: bill.id })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getPaymentDebits(bill) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not defined"));
        return;
      }
      axios
        .get(apiUrl + "/payments/bills/" + bill.id + "/getPaymentDebits",)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteBill(bill) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/delete", { id: bill.id })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            if (!!response.results && !!response.results.message && response.results.message == 'event group still present') reject('event group still present')
            else reject(new Error(response.results));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateBill(bill) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/update", bill)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  close(bill, cashDesk, documentType = "receipt", infos, tableId) {
    const apiUrl = UrlKeeper.getUrl()

    if (!!bill.billCustomers && !!bill.billCustomers[0])
      bill.billCustomers[0].customer.promosCustomers.forEach(promoCustomer => { delete promoCustomer.promo.gainedItems })

    if (!!infos && !!infos.usedPromos && infos.usedPromos.length > 0) {
      for (let i = 0; i < infos.usedPromos.length; i += 1) {
        delete infos.usedPromos[i].gainedItems
      }
    }

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not defined"));
        return;
      }

      if (!documentType) {
        reject(new Error("receipt is not specified"));
        return;
      } else if (!["invoice", "receipt", "payLater"].includes(documentType)) {
        reject(new Error("document type must be invoice, receipt or payLater"));
        return;
      } else {
        bill["documentType"] = documentType;
      }

      if (!!cashDesk && !!cashDesk.id) {
        bill["cashDeskId"] = cashDesk.id;
      }

      if (!!tableId)
        bill.tableId = Number(tableId)

      axios
        .post(apiUrl + "/payments/bills/close", {
          bill,
          ...infos,
        })
        .then(async (response) => {
          if (response.success) {
            if (!this.socketConnected) {
              await this.reloadAllTodayCachedFilterList()
            }
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  payPartial(bill, cashDesk, documentType = "receipt", infos, tableId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not defined"));
        return;
      }

      if (!documentType) {
        reject(new Error("receipt is not specified"));
        return;
      } else if (!["invoice", "receipt", "payLater"].includes(documentType)) {
        reject(new Error("document type must be invoice, receipt or payLater"));
        return;
      } else {
        bill["documentType"] = documentType;
      }

      if (!!cashDesk && !!cashDesk.id) {
        bill["cashDeskId"] = cashDesk.id;
      }

      if (!!tableId)
        bill.tableId = Number(tableId)

      axios
        .post(apiUrl + "/payments/bills/payPartial", {
          bill,
          ...infos,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Returns frequent customer services
   * @param {Object} params - Parameters
   * @param {Object} params.customer - Customer to get
   * @param {String} params.customer.id - Customer id
   */
  async frequentCustomerServicesCached(params) {
    if (!params || !params.customer || !params.customer.id) {
      throw new Error('customer is not specified')
    }

    if (!this._frequentServicesAlreadyCached) {
      await frequentServicesCache.deleteAllCache()
      this._frequentServicesAlreadyCached = true
    }

    if (await frequentServicesCache.cachePresent({ customer: params.customer })) {
      return await frequentServicesCache.getCustomer({ customer: params.customer })
    } else {
      await this.reloadFrequentCustomerServicesCache({ customer: params.customer })
      return await frequentServicesCache.getCustomer({ customer: params.customer })
    }
  }

  /**
   * Reload frequent customer services cache
   * @param {Object} params - Parameters
   * @param {Object} params.customer - Customer to get
   * @param {String} params.customer.id - Customer id
   */
  async reloadFrequentCustomerServicesCache({ customer }) {
    const frequentServices = await this.frequentCustomerServices(customer)
    await frequentServicesCache.cacheCustomer({ customer: customer, frequentServices: frequentServices })
  }

  async frequentCustomerServices(customer) {
    const apiUrl = UrlKeeper.getUrl()

    if (!customer || !customer.id) {
      throw new Error("customer is not specified")
    }

    const response = await axios
      .post(apiUrl + "/payments/bills/frequentServices", { id: customer.id })

    if (response.success) {
      return response.results;
    } else {
      throw response.results;
    }
  }

  async frequentServicesCached() {
    if (!this._frequentServicesAlreadyCached) {
      await frequentServicesCache.deleteAllCache()
      this._frequentServicesAlreadyCached = true
    }

    if (await frequentServicesCache.cachePresent({})) {
      return await frequentServicesCache.getCustomer({})
    } else {
      await this.reloadFrequentServicesCache({})
      return await frequentServicesCache.getCustomer({})
    }
  }

  async reloadFrequentServicesCache() {
    const frequentServices = await this.frequentSaloonServices({})
    await frequentServicesCache.cacheCustomer({ frequentServices: frequentServices })
  }

  frequentSaloonServices() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {

      axios
        .post(apiUrl + "/payments/bills/frequentServices")
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        });
    });
  }

  //#endregion

  //#region BillCustomers
  addCustomersToSaloon(customers) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customers) {
        reject(new Error("no customer specified"));
        return;
      }

      if (!Array.isArray(customers)) {
        customers = [customers];
      }

      if (!customers.every((el) => !!el.id)) {
        reject(new Error("all customers has to has the id property"));
        return;
      } else if (customers.length == 0) {
        reject(new Error("no customer specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/createAndAddCustomers", customers)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  addManyCustomersToSaloon(customers) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customers) {
        reject(new Error("no customer specified"));
        return;
      }

      if (!Array.isArray(customers)) {
        customers = [customers];
      }

      if (!customers.every((el) => !!el.id)) {
        reject(new Error("all customers has to has the id property"));
        return;
      } else if (customers.length == 0) {
        reject(new Error("no customer specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/createManyAndAddCustomers", customers)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  saloonCustomersList(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!filters["status"]) {
        filters["status"] = "opened";
      }

      axios
        .post(apiUrl + "/payments/bills/customersList", {
          page,
          rowPerPage,
          filters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  removeCustomersFromSaloon(customers) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customers) {
        reject(new Error("no customer specified"));
        return;
      }

      if (!Array.isArray(customers)) {
        customers = [customers];
      }

      if (!customers.every((el) => !!el.id)) {
        reject(new Error("all customers has to has the id property"));
        return;
      } else if (customers.length == 0) {
        reject(new Error("no customer specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/deleteByCustomerId", customers)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }
  //#endregion

  //#region BillServices - BillServiceOperators

  addServiceToBill(bill, service, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not defined"));
        return;
      } else if (!service || !service.id) {
        reject(new Error("service is not defined"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + bill.id + "/services/add", {
          services: [service],
          quantity: quantity
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeServiceFromBill(bill, service, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject("bill is not specified");
        return;
      } else if (!service || !service.id) {
        reject("service is not specified");
        return;
      }

      axios
        .post(
          apiUrl + "/payments/bills/" + bill.id + "/services/remove", {
          services: [service],
          quantity: quantity,
        }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateBillServiceById(billService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject("bill is not specified");
        return;
      } else if (!billService.service || !billService.service.id) {
        reject("service is not specified");
        return;
      }
      axios
        .post(
          apiUrl + "/payments/bills/services/updateById", billService)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateBillItemById(billItem) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject("bill is not specified");
        return;
      } else if (!billItem.item || !billItem.item.id) {
        reject("item is not specified");
        return;
      }
      axios
        .post(
          apiUrl + "/payments/bills/items/updateById", billItem)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteServiceFromBill(bill, service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject("bill is not specified");
        return;
      } else if (!service || !service.id) {
        reject("service is not specified");
        return;
      }

      axios
        .post(
          apiUrl + "/payments/bills/" + bill.id + "/services/delete",
          service
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteAll(bill) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject("bill is not specified");
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + bill.id + "/services/deleteAll")
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  servicesList(bill) {
    return new Promise((resolve, reject) => {
      this.get(bill)
        .then(async (results) => {
          if (!results) {
            localStorage.removeItem('bill')
          } else if (!results.billServices) {
            reject(new Error("bill services not defined"));
          } else {
            if (!!bill.eventGroupId) {
              try {
                let billServicesSorted = await this.servicesListSortedByEventGroup(bill)
                resolve(billServicesSorted)
              } catch (error) {
                console.log(error)
              }
            }
            else
              resolve(results.billServices);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async servicesListSortedByEventGroup(bill) {
    const apiUrl = UrlKeeper.getUrl()
    try {
      let billServicesSorted = await axios
        .get(apiUrl + "/payments/bills/" + bill.id + "/servicesListSortedByEventGroup", {
          billId: bill.id
        })
      return billServicesSorted.results
    } catch (error) {
      throw new Error(error)
    }
  }

  setOperatorsOnBillService(billService, operators) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operators) {
        reject(new Error("operators are not defined"));
        return;
      }

      if (!Array.isArray(operators)) operators = [operators];

      if (operators.some((op) => !op.id)) {
        reject(new Error("operators is not in the right form"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billService.billId +
          "/services/" +
          billService.id +
          "/setOperator",
          operators
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  ghostService(billService, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject(new Error("bill service is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billService.billId +
          "/services/" +
          billService.id +
          "/ghost",
          {
            quantity,
          }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  ghostItem(billItem, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject(new Error("bill item is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billItem.billId +
          "/items/" +
          billItem.id +
          "/ghost",
          {
            quantity,
          }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  unghostService(billService) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject(new Error("bill service is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billService.billId +
          "/services/" +
          billService.id +
          "/unghost"
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  unghostItem(billItem) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject(new Error("bill item is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billItem.billId +
          "/items/" +
          billItem.id +
          "/unghost"
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addOperatorOnBillService(billService, operator, quantity = 1) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operator) {
        reject(new Error("operator is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billService.billId +
          "/services/" +
          billService.id +
          "/addOperator", {
          operator: operator,
          quantity: quantity
        }
          //operator, quantity
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateOperatorFromBillService(billService, operator, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operator) {
        reject(new Error("operator is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billService.billId +
          "/services/" +
          billService.id +
          "/updateOperator", {
          operator: operator,
          quantity: quantity
        }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeOperatorFromBillService(billService, operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billService || !billService.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operator) {
        reject(new Error("operator is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billService.billId +
          "/services/" +
          billService.id +
          "/removeOperator",
          operator
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region  BillReservations
  addCustomersToRestaurant(customerId, entities) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/bills/addCustomersToRestaurant", {
          customerId: customerId,
          entities: entities,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  getBillFromCustomer(cutomerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!cutomerId) {
        reject(new Error("no id specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/getBillFromCustomer", {
          customerId: cutomerId,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  createBillFromRestaurant(table, entities, occasional = false) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/bills/createFromRestaurant", {
          table: table,
          entities: entities,
          occasional: occasional,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  getBillFromReservation(reservation) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!reservation) {
        reject(new Error("no reservation specified"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/getBillFromReservation", {
          reservation: reservation,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  listOccasionalCustomers(from, to) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/payments/bills/listOccasionalCustomers", {
          from: from,
          to: to,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        });
    });
  }

  //#endregion

  //#region  BillItems

  async itemList(bill) {
    const apiUrl = UrlKeeper.getUrl()
    try {
      let billItemsSorted = await axios
        .get(apiUrl + "/payments/bills/" + bill.id + "/itemsList", {
          billId: bill.id
        })
      return billItemsSorted.results.billItems
    } catch (error) {
      throw new Error(error)
    }
  }

  setOperatorsOnBillItem(billItem, operators) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operators) {
        reject(new Error("operators are not defined"));
        return;
      }

      if (!Array.isArray(operators)) operators = [operators];

      if (operators.some((op) => !op.id)) {
        reject(new Error("operators is not in the right form"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billItem.billId +
          "/items/" +
          billItem.id +
          "/setOperator",
          operators
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(response.results);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeOperatorFromBillItem(billItem, operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operator) {
        reject(new Error("operator is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billItem.billId +
          "/items/" +
          billItem.id +
          "/removeOperator",
          operator
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateOperatorFromBillItem(billItem, operator, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operator) {
        reject(new Error("operator is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billItem.billId +
          "/items/" +
          billItem.id +
          "/updateOperator", {
          operator: operator,
          quantity: quantity
        }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addOperatorOnBillItem(billItem, operator, quantity = 1) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!billItem || !billItem.id) {
        reject(new Error("bill service is not defined"));
        return;
      } else if (!operator) {
        reject(new Error("operator is not defined"));
        return;
      }

      axios
        .post(
          apiUrl +
          "/payments/bills/" +
          billItem.billId +
          "/items/" +
          billItem.id +
          "/addOperatorItem", {
          operator: operator,
          quantity: quantity
        }
          //operator, quantity
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  entitiesList(bill) {
    return new Promise((resolve, reject) => {
      this.get(bill)
        .then((results) => {
          if (!!results.id)
            resolve([...results.billServices, ...results.billItems])
          else
            reject(new Error("Bill Not Found"))
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  addItemToBill(bill, item, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject(new Error("bill is not defined"));
        return;
      } else if (!item || (!item.id && !item.barcode)) {
        reject(new Error("item is not defined"));
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + bill.id + "/items/add", {
          item: [item],
          quantity: quantity
        }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results.message));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  removeItemFromBill(bill, item, quantity) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject("bill is not specified");
        return;
      } else if (!item || !item.id) {
        reject("item is not specified");
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + bill.id + "/items/remove", {
          item: [item],
          quantity: quantity
        }
        )
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteItemFromBill(bill, item) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject("bill is not specified");
        return;
      } else if (!item || !item.id) {
        reject("item is not specified");
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + bill.id + "/items/delete", item)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  deleteAllItems(bill) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!bill || !bill.id) {
        reject("bill is not specified");
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + bill.id + "/items/deleteAll")
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  saveXml(transactionId, xml) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!transactionId) {
        reject("transactionId is not specified");
        return;
      }

      axios
        .post(apiUrl + "/payments/bills/" + transactionId + "/saveXml", {
          xml: xml
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updateDocumentNumber(transactionId, documentNumber) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!transactionId) {
        reject("transactionId is not specified");
        return;
      }

      axios
        .post(apiUrl + "/payments/transactions/paymentTransactions/" + transactionId + "/updateDocumentNumber", {
          documentNumber: documentNumber
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject(new Error(response.results));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  logRTPrintedBillData(message, data, xml) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/payments/bills/logRTPrintedBillData", {
          message: message,
          data: data,
          xml: xml
        })
        .then((response) => {
          if (response.success) {
            resolve(undefined);
          } else {
            reject(new Error('Error logging RT printed bill data'));
          }
        });
    });
  }

  //#endregion

  fieldsForCustomers() {
    return Promise.resolve([
      { text: "Cognome", value: "customer.lastname", type: "object.dotted" },
      { text: "Nome", value: "firstname", type: "custom" },
      { text: "Orario Ingresso", value: "openedAt", type: "time" },
      { text: "", value: "birthdayCake", type: "custom" },
      { text: "", value: "newCustomerPromo", type: "custom" },
      { text: "", value: "highPriority", type: "custom" },
      { text: "", value: "infoOnEvent", type: "custom" },
      { text: "", value: "isJunior", type: "custom" },
    ]);
  }
}

export default new BillService();
