<template>
  <div 
    class="hierarchy-container"
    :style="{
      'grid-template-columns': gridTemplateColumns
    }"
  >
    <HierarchyItem
      v-for="(item, index) in visibleItems"
      :value="item.value"
      :selected-index="item.index"
      :key="index"
      :items="item[itemChildren]"
      :level="item.level"
      :hide-tooltip="hideTooltip"
      :tooltip-delay="tooltipDelay"
      :item-key="itemKey"
      :item-children="itemChildren"
      :item-title="itemTitle"
      :item-description="itemDescription"
      :item-color="itemColor"
      @item-click="select"
      :disabled="disabled"
    >
        <template v-slot:final-action="{ item }">
          <slot name="final-action" v-bind="{item}">
            <span class="text-overline">€ {{item.price}}</span>
          </slot>
        </template>
    </HierarchyItem>
  </div>
</template>

<script>
import HierarchyItem from './HierarchyItem.vue'

export default {
  name: "Hierarchy",
  components: {
    HierarchyItem
  },
  data: function() {
    return {
      localHierarchy: [],
      /* 
        [
          ...,
          { 
            selected: { },
            index: Number
          }
        ]
      */
      path: [ ]
    }
  },
  props: {
    hierarchy: {
      type: Array,
      default: () => []
    },
    visibleParentNumber: {
      type: Number,
      default: 2
    },
    itemChildren: {
      type: String,
      default: 'children'
    },
    itemTitle: {
      type: String | Function,
      default: 'title'
    },
    itemDescription: {
      type: String | Function,
      default: 'description'
    },
    itemColor: {
      type: String | Function,
      default: 'color'
    },
    itemKey: {
      type: String,
      default: 'name'
    },
    hideTooltip: {
      type: Boolean,
      default: false
    },
    tooltipDelay: {
      type: String | Number,
      default: 0,
    },
    context: {
      type: String,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!!this.context) {
      if (localStorage.getItem('hierarchy_' + this.context)) {
        try {
          this.path = JSON.parse(localStorage.getItem('hierarchy_' + this.context))
        } catch(e) {
          localStorage.removeItem('hierarchy_' + this.context)
        }
      }
    }
  },
  methods: {
    select(item, index, level) {
      if(level === undefined || level === null) {
        this.path = [{
          selected: item,
          index: index
        }]
      } else if((level + 1) < this.path.length) {
        for(let i = 0; i < this.path.length; i += 1) {
          if(i > (level + 1)) {
            this.$delete(this.path, i)
          } if(i == (level + 1)) {
            this.$set(this.path, i, {
              selected: item,
              index: index
            })
          }
        }
      } else {
        this.path.push({
          selected: item,
          index: index
        })
      }
      
      if (!!this.context) {
        const parsedVisibleHierarchy = JSON.stringify(this.path)
        localStorage.setItem('hierarchy_' + this.context, parsedVisibleHierarchy)
      }

      this.$emit("select", item, level)
      if(!item[this.itemChildren]) {
        this.$emit("final", item, level)
      }
    }
  },
  computed: {
    gridTemplateColumns() {
      return Array.from(Array(this.visibleParentNumber + 1).keys()).map(_ => '1fr').join(' ')
    },
    visibleItems() {
      let visibles = [], totalVisibleNumber = this.visibleParentNumber + 1
      let doesLastPathItemHasChildren = this.path.length > 0 && !!this.path[this.path.length - 1][this.itemChildren] && this.path[this.path.length - 1][this.itemChildren].length > 0

      if(totalVisibleNumber > this.path.length || (this.path.length == totalVisibleNumber && !doesLastPathItemHasChildren)) {
        visibles.push({
          value: !!this.path[0] ? this.path[0].selected : undefined,
          index: !!this.path[0] ? this.path[0].index : undefined,
          [this.itemChildren]: this.hierarchy
        })

        visibles = [...visibles, ...this.path.map((el, index) => {
          return {
            level: index,
            value: !!this.path[index + 1] ? this.path[index + 1].selected : undefined,
            index: !!this.path[index + 1] ? this.path[index + 1].index : undefined,
            [this.itemChildren]: !!el.selected[this.itemChildren] ? el.selected[this.itemChildren] : undefined
          }
        })]

        for(let i = visibles.length; i < totalVisibleNumber; i += 1) {
          visibles.push({
            [this.itemChildren]: undefined
          })
        }
      } else {
        visibles = [...this.path.map((el, index) => {
          return {
            level: index,
            value: !!this.path[index + 1] ? this.path[index + 1].selected : undefined,
            index: !!this.path[index + 1] ? this.path[index + 1].index : undefined,
            [this.itemChildren]: !!el.selected[this.itemChildren] ? el.selected[this.itemChildren] : undefined
          }
        }).slice(-totalVisibleNumber)]
      }
    
      return visibles
    }
  }
}
</script>

<style scoped>
.hierarchy-container {
  display: grid;
  width: 100%;
}

</style>