import authService from "@/services/auth/auth.service";
const ordersRoutes = [
  {
    path: "/warehouse/orders",
    name: "OrdersHome",
    component: () =>
      import("@/components/areas/warehouse/orders/OrderHome.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tab,
        supplierFromVoice: route.params.supplierFromVoice
      };
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/warehouse/orders/list",
    name: "OrderList",
    component: () =>
      import("@/components/areas/warehouse/orders/OrderList.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
      };
    },
  },
];

export default ordersRoutes;
