import NameMapper from '@/services/common/nameMapper.js'

class paymentMethodsNameMapping extends NameMapper {

  _optionsGetter() {
    return Promise.resolve([
      { value: 'check', text: 'Assegno' },
      { value: 'bankTransaction', text: 'Bonifico' },
      { value: 'cash', text: 'Contanti' },
    ])
  }
}

export default new paymentMethodsNameMapping()