<template>
  <div>
    <v-select
      :label="label"
      :no-data-text="label"
      prepend-icon="mdi-tag-multiple"
      dense
      :solo="solo"
      :solo-inverted="soloInverted"
      :filled="filled"
      :rounded="rounded"
      :flat="flat"
      hide-details="auto"
      :value="selectedItems"
      :items="[selectedItems]"
      :rules="rules"
      :error="error"
      :readonly="readonly"
      ref="selectInput"
    >
      <template v-slot:append-item>
        <GroupedSelectList
          v-model="localValue"
          :items="tags"
          :categories-identifier-key="categoriesIdentifierKey"
          :items-identifier-key="itemsIdentifierKey"
          :categories-text-key="categoriesTextKey"
          :items-key="itemsKey"
          :items-text-key="itemsTextKey"
          :multiple="multiple"
          :allow-select="multiple"
          @input="checkSelectedTag($event)"
        ></GroupedSelectList>
      </template>
    </v-select>
   
    <StandardDialog
      v-model="showAlert"
      title="Attenzione"
      :dialog-height="null"
      dialog-width="350px"
      persistent
    >
      <div class="d-flex justify-center mt-2">
        <v-alert 
          class="ma-2" 
          dense 
          outlined 
          type="error" 
          style="width: 250px"
        >
          Tenere in considerazione che aggiungendo più di un tag i calcoli analitici saranno duplicati sui tag selezionati
        </v-alert>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="closeAlertTagDialog">Chiudi</v-btn>
        <v-btn
          @click="confirmAlertTagDialog"
          text
          color="default"
        >Continua</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import Vue from 'vue'
import tagsCategoriesService from '@/services/tagsCategories/tagsCategories.service.js'
import GroupedSelectList from '@/components/common/GroupedSelectList';
import StandardDialog from "@/components/common/StandardDialog";

export default {
  name: "TagsAutocomplete",
  components: {
    GroupedSelectList,
    StandardDialog,
  },
  data: function() {
    return {
      localValue: this.value,
      localLoading: false,
      tags: [],
      selectedItems: '',
      showAlert: false,
      currentEvent: undefined,
    }
  },
  props: {
    value: {
    },
    categoriesIdentifierKey: {
      type: String,
      default: 'id'
    },
    itemsIdentifierKey: {
      type: String,
      default: 'name'
    },
    categoriesTextKey: {
      type: String,
      default: 'name'
    },
    itemsKey: {
      type: String,
      default: 'tags'
    },
    itemsTextKey: {
      type: String,
      default: 'description'
    },
    label: {
      type: String,
      default: 'Tag Abbinato'
    },
    placeholder: {
      type: String,
      default: undefined
    },
    itemValue: {
      type: String,
      default: 'name'
    },
    itemText: {
      type: String,
      default: 'description'
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: String,
      default: 'auto'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    bus: {
      type: Object,
      default: () => new Vue()
    },
    rules: {
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    soloInverted: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
  },
  mounted: function() {
    this.loadTags()

    if(!!this.localValue && Array.isArray(this.localValue)){
      this.selectedItems = this.localValue.map((el) => el.description).join(', ')
      // this.selectedItems = this.localValue[0].categoryObject.name + " - "+ this.localValue.map((el) => el.description).join(', ')
    }
    else 
      this.selectedItems = '' 

    if(!!this.bus) {
      this.bus.$on('reload', () => {
        this.loadTags()
      })
      this.bus.$on('update:tags', (tags) => {
        this.selectTags(tags)
      })
    }
  },
  methods: {
    loadTags() {
      this.localLoading = true
      this.filters.tags = true
      tagsCategoriesService.list(1, 300, this.filters).then((response) => {
        this.tags = response.rows
        this.localLoading = false
      })
    },
    checkSelectedTag(event) {
      if(!this.multiple && this.localValue.length > 1) {
        this.localValue = [this.localValue[this.localValue.length - 1]]
        this.$emit('input', this.localValue)
        return
      }

      if (this.localValue.length == 2) 
        this.showAlert = true
      else 
        this.$emit('input', event)

      this.currentEvent = event
    },
    selectTags(tag) {
      let findedTagCategory = this.tags.find((tc) => tc.tags.some(t => t.id == tag.id))
      if(!findedTagCategory) return
      this.localValue = [{
        ...tag,
        categoryObject: findedTagCategory
      }]
      this.$emit('input', this.localValue)
    },
    closeAlertTagDialog() {
      this.localValue.pop()
      this.showAlert = false
    },
    confirmAlertTagDialog() {
      this.$emit('input', this.currentEvent)
      this.showAlert = false

      setTimeout(() =>{
        this.$refs.selectInput.activateMenu()
        this.$refs.selectInput.focus()
      }, 10)
    }
  },
  computed: {
    doesLoading() {
      return this.localLoading || this.loading
    }
  },
  watch: {
    value(newVal) {
      !!newVal ? this.localValue = newVal : this.localValue = []
      if(Array.isArray(newVal) && newVal.length > 0 && typeof newVal[0] !== 'object'){  // entro quando ho i tag da backend, me li da come array di stringhe
        this.localValue = []
        newVal.map((tagNameString) => {
          let obj = {}
          obj.name = tagNameString
          this.localValue.push(obj)
        })
        this.$emit('input',this.localValue)
      }
      if(!!newVal && Array.isArray(newVal)) {
        // this.selectedItems = newVal[0].categoryObject.name + " - "+ newVal.map((el) => el.description).join(', ')
        this.selectedItems = newVal.map((el) => el.description).join(', ')
      }
      else 
        this.selectedItems = '' 
    }
  }
}
</script>

<style>

</style>