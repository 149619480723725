const _ = require('lodash');

class EventsHandler {
  constructor() {
    this.event_callbacks = {}

    this._executeCallbacksOf = function (event, data = null) {
      if (this.event_callbacks[event] == undefined) return
      for (var i = 0; i < this.event_callbacks[event].length; i++) {
        if (data != null) {
          this.event_callbacks[event][i](data)
        } else {
          this.event_callbacks[event][i]()
        }
      }
    }

    this._executeAsyncCallbacksOf = function (event, data) {
      return new Promise((resolve, reject) => {
        if (this.event_callbacks[event] == undefined) resolve()
        else {
          let promises = []
          for (var i = 0; i < this.event_callbacks[event].length; i++) {
            let currentData
            if (Array.isArray(data) || (typeof data === 'object' && data !== null)) currentData = _.cloneDeep(data)
            else currentData = data

            let results = this.event_callbacks[event][i](data)
            if (results instanceof Promise) {
              promises.push(results)
            } else {
              promises.push(Promise.resolve(results))
            }
          }
          Promise.all(promises).then((results) => { 
            resolve(results) 
          }).catch((error) => {
            reject(error)
          })
        }
      })
    }
  }

  on(event, callback) {
    if (this.event_callbacks[event] == undefined) {
      this.event_callbacks[event] = []
    }
    this.event_callbacks[event].push(callback)
  }

  off(event, callback) {
    if (!event || !this.event_callbacks[event]) return
    this.event_callbacks[event] = this.event_callbacks[event].filter((cb) => { return cb !== callback })
  }
}

module.exports = EventsHandler