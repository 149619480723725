import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class serviceConsumesService {


  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/serviceConsumes/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: filters
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  create(serviceConsume) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/serviceConsumes/create", serviceConsume)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  update(serviceConsume) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/serviceConsumes/update", serviceConsume)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        })
        .catch((err) => {
          reject("error during the call");
        });
    });
  }

  delete(serviceConsume) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!serviceConsume.id) {
        reject("id undefined");
      }

      axios
        .get(apiUrl + "/registries/serviceConsumes/delete", {
          id: serviceConsume.id,
        })
        .then((response) => {
          if (!response.success) {
            reject(response.results);
          } else {
            resolve(response);
          }
        });
    });
  }

  get(serviceConsumeId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/registries/serviceConsumes/get", { id: serviceConsumeId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  save(serviceConsumes) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/registries/serviceConsumes/save", { serviceConsumes })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }


  fields() {
    return Promise.resolve([
      // here the fields
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new serviceConsumesService();
