import authService from "@/services/auth/auth.service";
const taxesRoutes = [
  {
    path: "/payments/taxes",
    name: "TaxesNewForm",
    component: () => import("@/components/areas/payments/taxes/TaxesNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/taxes/:id/edit",
    name: "TaxesEditForm",
    component: () => import("@/components/areas/payments/taxes/TaxesEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/taxes/list",
    name: "TaxesList",
    component: () =>
      import(
        "@/components/areas/payments/taxes/TaxesList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  },
]
export default taxesRoutes;
