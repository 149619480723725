<template>
  <FullScreenDialog
    v-model="presetDialog"
    :routeFather="pathToGoBack"
    @input="$emit('update:open-dialog', $event)"
    :can-close="false"
    :can-close-right="true"
  >

    <template v-slot:toolbar-title>
      <div class="d-flex justify-center align-center">
        <div>
          <v-img
            max-width="125"
            src="@/assets/LOGO_LULUU.png"
          ></v-img>
        </div>
        <div class="ml-2 d-flex align-center">
          <span class="title-custom" style="padding-top: 5px">Modifica Campagna</span> 
        </div>
      </div>
    </template>

    <template v-slot:header-actions>
      <v-card flat color="transparent">
        <v-card-text class="text-left">
          <v-row class="justify-end" style="width: 31vw;">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              class="d-flex align-center flex-wrap"
            >
              <v-row>
                <v-col class="d-flex direction-row flex-wrap justify-end" cols="auto">
                  <v-btn
                    :style="{                          
                      'background-color': status == 'draft' &&
                        new Date() <= new Date(endDate) ? '#ffda85' : '',
                      'font-size': '12px'
                    }"
                    class="ml-2 status-div font-weight-bold"
                    :disabled="(status == 'approved' && (!!eventMarketing.actions && !!eventMarketing.actions.hasStarted))"
                    depressed
                    @click="() => {
                      checkIfInputFired()
                      status = 'draft'
                    }"
                  >
                    DRAFT
                  </v-btn>
                  <v-btn 
                    :style="{
                        'background-color': status == 'approved' &&
                          new Date() <= new Date(startDate) ? '#bfec83' : '',
                        'font-size': '12px'
                      }"
                    class="ml-2 status-div font-weight-bold"
                    :disabled="(status == 'approved' && (!!eventMarketing.actions && !!eventMarketing.actions.hasStarted))"
                    depressed                  
                    @click="() => {
                      checkIfInputFired()
                      status = 'approved'
                    }"
                  >
                    APPROVATO
                  </v-btn>
                  <div 
                    :style="{
                        'background-color': status == 'approved' &&
                          archived == false &&
                          new Date() >= new Date(startDate) && 
                          new Date() <= new Date(endDate) 
                           ? '#9abcea' : 
                           (!!eventMarketing.actions && !!eventMarketing.actions.hasStarted) ?
                          'rgba(0, 0, 0, 0.12)' : '',
                        'font-size': '12px',
                        'padding': '6px 16px 6px 16px'
                      }"
                    class="ml-2 status-div font-weight-bold"
                  >
                    IN CORSO
                  </div>
                  <div 
                    :style="{
                        'background-color': status == 'draft' &&
                          new Date() > new Date(endDate) ? 'grey' : 
                          status == 'approved' &&
                          new Date() > new Date(endDate) ||
                          archived == true ? '#cccccc' : 
                          (!!eventMarketing.actions && !!eventMarketing.actions.hasStarted) ?
                          'rgba(0, 0, 0, 0.12)' : '',
                        'font-size': '12px',
                        'padding': '6px 16px 6px 16px'
                      }"
                    class="ml-2 status-div font-weight-bold"
                  >
                    TERMINATO
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    
    <template v-slot:content>
      <EventMarketingGeneralForm
        v-model="eventMarketing"
        :start-date="startDate"
        :end-date="endDate"
        :valid.sync="formValid"
        :edit-event-type="true"
        @input="checkIfInputFired"
        @update:img="handleSelectedImg"
      ></EventMarketingGeneralForm>
    </template>

    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        :loading="loading"
        @click="closeDialog"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :loading="loading"
        :disabled="!formValid || (!!formValid && !firedInput)"
        @click="submitForm"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
  import Vue from 'vue';
  
  import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
  import EventMarketingGeneralForm from "@/components/areas/marketing/events/EventMarketingGeneralForm.vue";
  
  import marketingEventsService from "@/services/marketingEvents/marketingEvents.service.js";
  
  
  export default {
    name: "EventMarketingEditForm",
    components: {
      FullScreenDialog,
      EventMarketingGeneralForm
    },
    data: function() {
      return {
        selectedImg: null,
        presetDialog: this.openDialog,
        formValid: false,
        loading: false,
        eventMarketing: {},
        bus: new Vue(),
        id: undefined,
        firedInput: false,
        status: 'draft',
        archived: false,
        statusOptions: [
          {
            value: 'draft',
            text: 'Draft'
          }, 
          {
            value: 'approved',
            text: 'Approvato'
          },
        ],
      };
    },
    mounted() {
        this.id = this.$route.params.id
        if (!!this.id) {
            this.fetchEvent()
        }
    },
    props: {
      openDialog: {
        type: Boolean,
        default: false
      },
      pathToGoBack: {
        type: String,
        default: '/marketing/calendar'
      },
      pathName: {
        type: String,
      },
      startDate: {
        type: Date,
        default: () => new Date(),
      },
      endDate: {
        type: Date,
        default: () => new Date(),
      },
      filters: {
        type: Object,
      }
    },
    methods: {
      handleSelectedImg(img) {
        this.selectedImg = img
      },
      submitForm() {
        console.log('selectedImg update', this.selectedImg)
        this.eventMarketing.status = this.status
        this.eventMarketing.selectedImageId = this.selectedImg?.id ?? null
        this.eventMarketing.imageFromLocalSchema = !!this.selectedImg ? this.selectedImg.isUserUploaded : false

        marketingEventsService.update(this.eventMarketing).then(() => {
          this.closeDialog()
        })
      },
      fetchEvent() {
        marketingEventsService.get(this.id).then((result) => {
          if(!!result.actions && !!result.actions.landingPage && !!result.actions.landingPage.blocks) {
            for(let i = 0; i < result.actions.landingPage.blocks.length; i += 1) {
              result.actions.landingPage.blocks[i]['imageLink'] = result.actions.landingPage.blocks[i].image
              delete result.actions.landingPage.blocks[i].image
            }
          }
          
          if (result.archived) {
            this.archived = true
          }
          
          this.status = result.status
          this.eventMarketing = result
        })
      },
      updateObject() {
  
      },
      checkIfInputFired() {
        this.firedInput = true
      }, 
      closeDialog() {
        if(!!this.pathName) {
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
        } else {
          this.$router.push({path:this.pathToGoBack})
        }
      }
    },
    watch: {
      openDialog(newVal) {
        this.presetDialog = newVal;
      },
    },
    computed: {
    }
  }
  </script>
  
  <style>
  </style>