<template>
  <div style="overflow: auto">
    <TitleWithBackButton
      title="Storico Operazioni"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div
      v-if="hasPermission"
      class="export-container"
    >
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
    </div>
    <AdvancedFilter
      v-if="hasPermission"
      style="width:99%; margin-top: 10px"
      v-model="filtersValue"
      :filters="filterTypes"
      :external-filter="externalFilter"
      @apply-filters="applyFilters"
    >
      <template v-slot:custom-operation="{filter}">
        <v-row class="pa-2">
          <v-col>
            <v-checkbox v-model="filter.value['CREATE']" label="Creazione"></v-checkbox>
            <v-checkbox v-model="filter.value['UPDATE']" label="Modifica"></v-checkbox>
            <v-checkbox v-model="filter.value['DELETE']" label="Eliminazione"></v-checkbox>
            <v-checkbox v-model="filter.value['permission']" label="Permessi"></v-checkbox>
          </v-col>
        </v-row>
      </template>
      <template v-slot:chip-custom-operation="{filter}">
        {{ filter.value['CREATE'] ? 'Creazione' : '' }}
        {{ filter.value['UPDATE'] ? 'Modifica' : '' }}
        {{ filter.value['DELETE'] ? 'Eliminazione' : '' }}
        {{ filter.value['permission'] ? 'Permessi' : '' }}
      </template>
      <template v-slot:custom-resource="{filter}">
        <v-row class="pa-2">
          <v-col>
            <v-checkbox 
              v-for="(value, key) in contexts"
              :key="key"
              v-model="filter.value[key]" 
              :label="value">
            </v-checkbox>
          </v-col>
        </v-row>
      </template>
      <template v-slot:chip-custom-resource="{filter}">
        <span 
          v-for="(value, key) in contexts"
          :key="key"
        >
          {{ !!filter.value[key] ? value + ", ": ''}}
        </span>
      </template>
    </AdvancedFilter>
    <v-card style="height: 80%; margin-left: 10px; margin-right: 10px; margin-bottom: 50px; margin-top: 20px">
      <TypeDataTable
        v-if="hasPermission"
        :headers="headers"
        :items="items"
        :loading="loading"
        item-key="id"
        :show-select="false"
        :show-actions="false"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
      >
        <template v-slot:custom-details="{ item }">
          <!-- Permessi -->
          <div v-if="item['originalOperation'] == 'permission'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Accesso: 
                </span>
                {{ item['details'] != undefined ? 
                    item['details'] == true ? 'Consentito' : 'Non Consentito' : 'Non Consentito' }}
              </li> 
            </ul>
            
          </div>

          <!-- Transazioni -->
          <div v-if="item['originalContext'] == 'bills' || item['originalContext'] == 'payment_transactions'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Cliente: 
                </span>
                {{ printCustomerName(item) }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Ammontare: 
                </span>
                {{ printAmount(item['details']) }} €
              </li>

              <li>
                <span style="font-weight: 500;">
                  Numero Documento: 
                </span>
                {{ item['details'] != undefined ? item['details']['info'] != undefined ? 
                    !!item['details']['info']['documentNumber'] ?
                      item['details']['info']['documentNumber'] : 'Non Presente' : 'Non Presente' : 'Non Presente' }}
              </li>
            </ul>
          </div>

          <!-- Spese/Storni -->
          <div v-if="item['originalContext'] == 'payment_debits'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Operatore/Azienda: 
                </span>
                {{ !!item['details'] ? item['details']['subject'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Ammontare: 
                </span>
                {{ !!item['details'] ? item['details']['amount'] + ' €': 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Promo -->
          <div v-if="item['originalContext'] == 'promos'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Nome: 
                </span>
                {{ !!item['details'] ? item['details']['name'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Tipologia: 
                </span>
                {{ printDetails(item['details'], 'gainType') }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Guadagno: 
                </span>
                {{ printDetails(item['details'], 'itemGained') }}
              </li>
            </ul>
          </div>

          <!-- Promo su clienti -->
          <div v-if="item['originalContext'] == 'promos_customers'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Cliente: 
                </span>
                {{ !!item['details'] ? item['details']['customer'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Nome: 
                </span>
                {{ !!item['details'] ? item['details']['promo']['name'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Tipologia: 
                </span>
                {{ printDetails(item['details']['promo'], 'gainType') }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Guadagno: 
                </span>
                {{ printDetails(item['details']['promo'], 'itemGained') }}
              </li>
            </ul>
          </div>

          <!-- Fatture -->
          <div v-if="item['originalContext'] == 'invoices'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Fornitore: 
                </span>
                {{ !!item['details'] ? item['details']['lastname'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Descrizione: 
                </span>
                {{ !!item['details'] ? item['details']['description'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Ammontare: 
                </span>
                {{ !!item['details'] ? !!item['details']['amount'] ? 
                    item['details']['amount'] + ' €': 'Non Definito' : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Tipologia: 
                </span>
                {{ printDetails(item['details'], 'type') }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Stato: 
                </span>
                {{ printDetails(item['details'], 'status') }}
              </li>
            </ul>
          </div>

          <!-- Clienti / Operatori -->
          <div v-if="item['originalContext'] == 'customers' || item['originalContext'] == 'operators'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Nome: 
                </span>
                {{ printDetails(item['details'], 'user') }}
              </li>
            </ul>
          </div>

          <!-- Aziende -->
          <div v-if="item['originalContext'] == 'suppliers'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Nome: 
                </span>
                {{ !!item['details'] ? !!item['details']['businessName'] ? 
                    item['details']['businessName'] : 'Non Definito' : 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Turni Operatore -->
          <div v-if="item['originalContext'] == 'shifts'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Operatore: 
                </span>
                {{ !!item['details'] ? item['details']['operator'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Turno: 
                </span>
                {{ !!item['details'] ? item['details']['timeTable'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Data Turno: 
                </span>
                {{ !!item['details'] ? new Date(item['details']['date']).toLocaleDateString('it-IT') : 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Assenze -->
          <div v-if="item['originalContext'] == 'absences'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Operatore: 
                </span>
                {{ !!item['details'] ? item['details']['operator'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Tipo Assenza: 
                </span>
                {{ !!item['details'] ? item['details']['absenceType'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Inizio: 
                </span>
                {{ !!item['details'] ? new Date(item['details']['start']).toLocaleString('it-IT') : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Fine: 
                </span>
                {{ !!item['details'] ? new Date(item['details']['end']).toLocaleString('it-IT') : 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Assenze -->
          <div v-if="item['originalContext'] == 'absence_types'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Nome: 
                </span>
                {{ !!item['details'] ? item['details']['name'] : 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Turni -->
          <div v-if="item['originalContext'] == 'time_tables'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Nome: 
                </span>
                {{ !!item['details'] ? item['details']['name'] : 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Prodotti -->
          <div v-if="item['originalContext'] == 'items'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Codice Interno: 
                </span>
                {{ !!item['details'] ? item['details']['internalCode'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Descrizione: 
                </span>
                {{ !!item['details'] ? item['details']['description'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Fornitore: 
                </span>
                {{ !!item['details'] ? item['details']['supplier'] : 'Non Definito' }}
              </li>
            </ul>
          </div>

          <!-- Scarico/Carico Magazzino -->
          <div v-if="item['originalContext'] == 'movements'">
            <ul>
              <li>
                <span style="font-weight: 500;">
                  Codice Movimento: 
                </span>
                {{ !!item['details'] ? item['details']['code'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Descrizione: 
                </span>
                {{ !!item['details'] ? item['details']['description'] : 'Non Definito' }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Tipo: 
                </span>
                {{ printDetails(item['details'], 'type') }}
              </li>

              <li>
                <span style="font-weight: 500;">
                  Num Prodotti Movimentati: 
                </span>
                {{ !!item['details'] ? !!item['details']['items'] ? 
                    item['details']['items'].length : 'Non Definito' : 'Non Definito' }}
              </li>
            </ul>
          </div>
        </template>
      </TypeDataTable>
    </v-card>

    <StandardDialog
      v-model="showBarcode"
      title="Visualizza Storico"
      :dialog-height="null"
      dialog-max-width="500px"
      persistent
    >
      <div>
        <v-row>
          <v-col>
            <ManualBarcodeInput
              v-model="openHistoryOperationsBarcode"
              @confirm="openHistoryOperationsPermission"
            >
            </ManualBarcodeInput>
          </v-col>
        </v-row>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          text
          @click="$router.push('/analytics')"
        >
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import TitleWithBackButton from "@/components/common/TitleWithBackButton.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import historyOperationsService from "@/services/historyOperations/historyOperations.service.js";
import analyticNamesMappings from '@/services/analytics/analyticsNameMappings'
import operatorService from '@/services/operators/operators.service.js';
import * as XLSX from "xlsx";


export default {
  name: "HistoryOperations",
  components: {
    TitleWithBackButton,
    TypeDataTable,
    StandardDialog,
    ManualBarcodeInput,
    AdvancedFilter
  },
  data: () => {
    return {
      input1: '',
      input2: null,
      input3: null,
      headers: [
        { text: "Operazione", value: "operation" },
        { text: "Contesto", value: "context" },
        { text: "Operatore", value: "operatorName" },
        { text: "Dettagli", value: "details", type: 'custom'},
        { text: "Data", value: "timestamp" },
      ],
      items: [      
      ],
      page: 1,
      rowPerPage: 200,
      totalPages: 100,
      customer: undefined,
      showBarcode: true,
      hasPermission: false,
      openHistoryOperationsBarcode: undefined,
      loading: false,

      filterTypes: [
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data', label: ['Da', 'A'], color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'custom', field: 'resource', name: 'Contesto', label: 'Contesto', color: "", value: {}, icon: "mdi-animation-outline", blank: {} },
        { type: 'custom', operator: 'custom', field: 'operation', name: 'Operazione', label: 'Operazione', color: "", value: {}, icon: "mdi-book-edit-outline", blank: {} },
        { type: 'string', operator: 'like', field: 'detail', name: 'Dettaglio', label: 'Dettaglio', color: "", value: "" }
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'operator', name: 'Operatore', label: 'Operatore', color: "", value: "" },
      filtersValue: [],
      contexts: {}
    };
  },
  mounted: function () {
    this.loadContext()
  },
  methods: {
    exportToExcel() {
      this.loading = true
      let itemsToExport = _.cloneDeep(this.items)
      let workbook = XLSX.utils.book_new();
      //Creazione e popolamento tabella
      let ws = XLSX.utils.aoa_to_sheet([[]]);

      XLSX.utils.sheet_add_aoa(ws, [
        [
          'Operazione',
          'Contesto',
          'Operatore',
          'Dettagli',
          'Data'
        ],
        ...itemsToExport.map((el) => {
          let details = ''
          switch (el.originalContext) {
            case 'permission':
              details = el.details ? "Accesso Consentito" : "Accesso Negato"
              break;
            case 'bills' || 'payment_transactions':
              details = "Cliente: " + this.printCustomerName(el) + " \n " + "Ammontare: " + this.printAmount(el.details) + " €\n " + "Numero Documento: " + (!!el['details']['info']['documentNumber'] ? el['details']['info']['documentNumber'] : 'Non Presente')
              break;
            case 'payment_debits':
              details = "Operatore/Azienda: " + (!!el['details'] ? el['details']['subject'] : 'Non Definito') + " \n " + "Ammontare: " + (!!el['details'] ? el['details']['amount'] + ' €' : 'Non Definito')
              break;
            case 'promos':
              details = "Nome: " + (!!el['details'] ? el['details']['name'] : 'Non Definito') + " \n " + "Tipologia: " + this.printDetails(el['details'], 'gainType') + " \n " + "Guadagno: " + this.printDetails(el['details'], 'itemGained')
              break;
            case 'promos_customers':
              details = "Cliente: " + (!!el['details'] ? el['details']['customer'] : 'Non Definito') + " \n " + "Nome: " + (!!el['details'] ? el['details']['promo']['name'] : 'Non Definito') + " \n " + "Tipologia: " + this.printDetails(el['details']['promo'], 'gainType') + " \n " + "Guadagno: " + this.printDetails(el['details']['promo'], 'itemGained')
              break;
            case 'invoices':
              details = "Fornitore: " + (!!el['details'] ? el['details']['lastname'] : 'Non Definito') + " \n " + "Descrizione: " + (!!el['details'] ? el['details']['description'] : 'Non Definito') + " \n " + "Ammontare: " + (!!el['details'] ? !!el['details']['amount'] ? el['details']['amount'] + ' €' : 'Non Definito' : 'Non Definito') + " \n " + "Tipologia: " + this.printDetails(el['details'], 'type') + " \n " + "Stato: " + this.printDetails(el['details'], 'status')
              break;
            case 'customers' || 'operators':
              details = "Nome: " + this.printDetails(el['details'], 'user')
              break;
            case 'suppliers':
              details = "Nome: " + (!!el['details'] ? !!el['details']['businessName'] ? el['details']['businessName'] : 'Non Definito' : 'Non Definito')
              break;
            case 'shifts':
              details = "Operatore: " + (!!el['details'] ? el['details']['operator'] : 'Non Definito') + " \n " + "Turno: " + (!!el['details'] ? el['details']['timeTable'] : 'Non Definito') + " \n " + "Data Turno: " + (!!el['details'] ? new Date(el['details']['date']).toLocaleDateString('it-IT') : 'Non Definito')
              break;
            case 'absences':
              details = "Operatore: " + (!!el['details'] ? el['details']['operator'] : 'Non Definito') + " \n " + "Tipo Assenza: " + (!!el['details'] ? el['details']['absenceType'] : 'Non Definito') + " \n " + "Inizio: " + (!!el['details'] ? new Date(el['details']['start']).toLocaleString('it-IT') : 'Non Definito') + " \n " + "Fine: " + (!!el['details'] ? new Date(el['details']['end']).toLocaleString('it-IT') : 'Non Definito')
              break;
            case 'absence_types':
              details = "Nome: " + (!!el['details'] ? el['details']['name'] : 'Non Definito')
              break;
            case 'time_tables':
              details = "Nome: " + (!!el['details'] ? el['details']['name'] : 'Non Definito')
              break;
            case 'items':
              details = "Codice Interno: " + (!!el['details'] ? el['details']['internalCode'] : 'Non Definito') + " \n " + "Descrizione: " + (!!el['details'] ? el['details']['description'] : 'Non Definito') + " \n " + "Fornitore: " + (!!el['details'] ? el['details']['supplier'] : 'Non Definito')
              break;
            case 'movements':
              details = "Codice Movimento: " + (!!el['details'] ? el['details']['code'] : 'Non Definito') + " \n " + "Descrizione: " + (!!el['details'] ? el['details']['description'] : 'Non Definito') + " \n " + "Tipo: " + this.printDetails(el['details'], 'type') + " \n " + "Num Prodotti Movimentati: " + (!!el['details'] ? !!el['details']['items'] ? el['details']['items'].length : 'Non Definito' : 'Non Definito')
              break;
            default:
              details = 'Non Presente'
              break;
          }

          return [
            el.operation,
            el.context,
            el.operatorName,
            details,
            el.timestamp
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws, "Storico Operazioni");
      
      XLSX.writeFile(workbook, "Storico_Operazioni.xls", { type: 'file' });
      this.loading = false
    },
    loadContext() {
      let localContextsObject = analyticNamesMappings.historyOperationsContextMappings

      for (const [key, value] of Object.entries(localContextsObject)) {
        if (Object.values(this.contexts).indexOf(value) == -1)
          this.contexts[key] = value
      }
    },
    fetchHistory() {
      this.loading = true
      historyOperationsService.list(this.page, this.rowPerPage, this.filtersValue).then(async (result) => {
        if (this.rowPerPage != result.rowPerPage) {
          this.rowPerPage = result.rowPerPage;
        }

        if (this.page != result.page) {
          this.page = result.page;
        }

        this.totalPages = result.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }

        this.items = []
        for (let i = 0; i < result["rows"].length; i++) {
          let operatorName = !!result["rows"][i]["operator"] ? result["rows"][i]["operator"]["firstname"] + " " + result["rows"][i]["operator"]["lastname"] : undefined
          
          if (!!result["rows"][i]["user"] && !!result["rows"][i]["user"]["system"]) {
            operatorName = "Sviluppatori"
          }

          this.items.push({
            id: result["rows"][i]['_id'],
            originalOperation: result["rows"][i]["operation"],
            operation: analyticNamesMappings.getHistoryOperationsOp(result["rows"][i]["operation"].toString().toLowerCase()),
            originalContext: result["rows"][i]["resource"][0],
            context: analyticNamesMappings.getHistoryOperationsContext(result["rows"][i]["resource"][0].toString().toLowerCase()),
            operatorName: operatorName,
            timestamp: new Date(result["rows"][i]["timestamp"]).toLocaleString('it-IT'),
            details: result["rows"][i]["operation"].toString().toLowerCase() == 'delete' ? result["rows"][i]["previousRowVersion"] : result["rows"][i]["updatesToRow"]
          })
        }
        this.loading = false
      });
    },

    printCustomerName(item) {
      if (!item['details']) 
        return "Non Definito"

      if (item['details']['isBar'] != undefined && !!item['details']['isBar']
       && (!item['details']['info']['customers'] || item['details']['info']['customers'].length == 0))
        return "Cliente Bar"
        
      if (!item['details']['info']) 
        return "Non Definito"
      
      if (!item['details']['info']['customers']) 
        return "Non Definito"
      
      if (item['details']['info']['customers'].length == 0) 
        return "Non Definito"

      return item['details']['info']['customers'][0]['firstname'] + ' ' + item['details']['info']['customers'][0]['lastname']
    },

    printAmount(item) {
      if (!item)
        return "Non Definito"
      
      let amount = 0
      if (!!item["customerCardPaid"])
        amount = item["customerCardPaid"]
      
      else {
        amount = Number(Number(item['amount']) - Number(item['discounts'])).toFixed(2)
      }

      return amount
    },

    printDetails(item, type) {
      if (!item)
        return "Non Definito"
      
      if (type == 'user')
        return item['firstname'] + ' ' + item['lastname']

      return analyticNamesMappings.getHistoryOperationsDetails(item[type])
    },

    printContext(context) {
      return analyticNamesMappings.getHistoryOperationsContext(context)
    },

    applyFilters() {
      this.fetchHistory()
    },

    openHistoryOperationsPermission() {
      operatorService.canPerformOperation(this.openHistoryOperationsBarcode, "HistoryOperations").then((result) => {
        if (result == true) {
          this.hasPermission = true
        } else if (result == false) {
          this.$router.push({path: '/analytics'})
          alert("Non hai i permessi per eseguire questa azione!")    
        } else {
          this.$router.push({path: '/analytics'})
          alert("Badge non riconosciuto")    
        }
      }).catch(() => {
        this.$router.push({path: '/analytics'})
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false;
      this.openHistoryOperationsBarcode = undefined;
    }
  },
  watch: {
    page() {
      this.fetchHistory()
    },
    rowPerPage() {
      this.fetchHistory()
    },
    hasPermission(newVal) {
      if (!!newVal)
        this.fetchHistory()
    }
  }
};
</script>
<style>
  .export-container {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }
</style>