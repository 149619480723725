<template>
  <div id="ageGroupCustomers">
    <TitleWithBackButton
      title="Clienti per Fasce d'età"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-8 mt-3 flex-wrap">
      <RangeDateSelector
        :start-date.sync="startDate"
        :end-date.sync="endDate"
        :loading="loading"
        :disabled="loading"
        width="50vw"
      ></RangeDateSelector>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="calculate"
        :loading="loading"
        :disabled="!startDate || !endDate"
        >Calcola</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="print"
        :loading="loading"
        :disabled="!startDate || !endDate"
        ><v-icon> mdi-printer </v-icon></v-btn>
        <v-btn 
          icon 
          large 
          :disabled="loading" 
          style="float: right; margin-right: 2.5%"
          @click="() => { dialogBarcode = !dialogBarcode }"
          :color="!!ghost ? 'red' : ''"
        >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>

    <v-container fluid class="mt-5">
      <div             
        v-if="!loading"
        class="mb-2 ml-10"
      >
        <span class="card-title">Clienti per Fasce d'età di tutto il salone</span>
      </div>
      <v-row no-gutters>
        <v-col 
          cols="12"
          sm="12"
          md="9"
          lg="9"
          xl="9"
          class="d-flex justify-center"
          :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
           ? '2' : '1' "
        >
          <div
            v-if="loading"
            class="text-caption font-weight-light d-flex align-center justify-center"
            :style="{
              height: '100%'
            }"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <TypeDataTable
            v-else-if="groupedAgeRowsAllCustomers.length > 0"
            id="table"
            class="pb-5"
            fixed-header
            headersColor="rgb(224, 224, 230)"
            style="background-color: transparent;"
            :headers="headersAllCustomers"
            :items="groupedAgeRowsAllCustomers"
            :results-for-loading="!graphResults"
            :loading="loading"
            :show-select="false"
            :disablePagination="true"
            :select-on-row-click="false" 
          >
            <template v-slot:actions="{ item }">
              <v-row @mouseover="preFetch(item, 'allCustomers')">
                <v-btn icon @click.stop="customersSendMessage = true">
                  <v-icon dense color="blue darken-2">mdi-message-text-fast</v-icon>
                </v-btn>
              </v-row>
            </template>
          </TypeDataTable>
        </v-col>
        
        <v-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
          xl="3"
          :order="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
           ? '1' : '2' "
        >
          <div
            v-if="loading"
            class="text-caption font-weight-light d-flex align-center justify-center"
            :style="{
              height: '100%'
            }"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <div 
            v-else-if="groupedAgeRowsAllCustomers.length > 0"
            class="d-flex justify-center align-center flex-column"
          >
            <div class="d-flex justify-center" style="margin-bottom: 10px; margin-top: -5px; font-size: larger; font-weight: 500">
              <span
                style="font-size: 1rem;"
                class="d-flex title-section"
              >
                Numero Clienti : {{totalAllCustomers}}
              </span>
            </div>
            <div class="d-flex justify-center">
              <DoughnutChart
                v-if="!loading || !!graphResults"
                :chart-data="allCustomersChartData"
                :styles="{
                  height: this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                    ? '30vw' : '20vw',
                  width: this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                    ? '30vw' : '20vw'
                }"
                :options="chartOptions"
              ></DoughnutChart>
            </div>
          </div>
        </v-col>
      </v-row>
      <div v-if="!loading && groupedAgeRowsAllCustomers.length == 0" class="text-center font-weight-light mt-2">
        Nessun risultato
      </div>
      
      <v-divider style="margin-top: 30px; margin-bottom: 30px"></v-divider>
      
      <div 
        v-if="!loading" 
        class="mb-2 ml-10"
      >
        <span class="card-title">Clienti per Fasce d'età del periodo selezionato</span>
      </div>
      <v-row no-gutters>
        <v-col 
          lg="9"
          xl="9"
          md="12" 
          cols="12"
          sm="12"
          class="d-flex justify-center"
          :order="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
           ? '2' : '1' "
        >
          <div
            v-if="loading"
            class="text-caption font-weight-light d-flex align-center justify-center"
            :style="{
              height: '100%'
            }"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <TypeDataTable
            v-else-if="groupedAgeRows.length > 0"
            id="table_paying"
            class="pb-5"
            fixed-header
            headersColor="rgb(224, 224, 230)" 
            style="background-color: transparent"
            :headers="headers"
            :items="groupedAgeRows"
            :results-for-loading="!results"
            :loading="loading"
            :show-load="false"
            :show-select="false"
            :select-on-row-click="false"
            @rowClicked="handleClickPayingCustomers"
            :disablePagination="true"

          >
            <template v-slot:actions="{ item }">
              <v-row
                no-gutters
                style="width: fit-content; flex-wrap: nowrap;"
                @mouseover="preFetch(item, 'payingCustomers')"
              >
                <v-col>
                  <v-btn icon @click.stop="customersSendMessage = true">
                    <v-icon dense color="blue darken-2">mdi-message-text-fast</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <ExportExcelTable
                    :worksheetName="worksheetName"
                    :fileName="generateExcelName(item)"
                    :fieldsToExport="fieldsToExport"
                    :fetch="fetchExcel"
                  >
                    <template v-slot:button>
                      <v-btn icon>
                        <v-icon dense color="green darken-2">mdi-microsoft-excel</v-icon>
                      </v-btn>
                    </template>
                  </ExportExcelTable>
                </v-col>
              </v-row>
            </template>
          </TypeDataTable>
        </v-col>
        <v-col 
          lg="3"
          xl="3"
          md="12" 
          cols="12"
          sm="12"
          :order="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
           ? '1' : '2' "
        >
          <div
            v-if="loading"
            class="text-caption font-weight-light d-flex align-center justify-center"
            :style="{
              height: '100%'
            }"
          >
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <div 
            v-else-if="groupedAgeRows.length > 0"
            class="d-flex justify-center align-center flex-column"
            :style="{
              'margin-bottom': this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
                ? '10px' : 0
            }"
          >
            <div class="d-flex justify-center" style="margin-bottom: 10px; margin-top: -5px; font-size: larger; font-weight: 500">
              <span
                style="font-size: 1rem;"
                class="d-flex title-section"
              >
                Numero Clienti : {{totalCustomers}}
            </span>
            </div>
            <div class="d-flex justify-center">
              <DoughnutChart
                v-if="!loading || !!results"
                :chart-data="payingCustomersChartData"
                :styles="{
                  height: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                    ? '30vw' : '20vw',
                  width: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                    ? '30vw' : '20vw'
                }"
                :options="chartOptions"
              ></DoughnutChart>
            </div>
          </div>
        </v-col>
      </v-row>
      
      <div v-if="!loading && groupedAgeRows.length == 0" class="text-center font-weight-light mt-2">
        Nessun risultato
      </div>
      
      <div> 
        
        <v-divider v-if="!!loadingDetail || groupedServices.length > 0" style="margin-top: 30px; margin-bottom: 30px"></v-divider>
        
        <v-row style="margin-top: 25px"  no-gutters>
          <v-col
            lg="9"
            xl="9"
            md="12" 
            cols="12"
            sm="12"
            :order="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '2' : '1' "
          >
            <div
              v-if="loadingDetail"
              class="text-caption font-weight-light d-flex align-center justify-center"
              :style="{
                height: '100%'
              }"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <div v-else>            
              <div v-if="groupedServices.length > 0" class="d-flex align-content justify-center" style="font-size: larger; font-weight: 500;">
                <div class="title-section">{{ rowSelected }}</div>
              </div>
              <v-row class="d-flex justify-center" style="margin-top: 25px; margin-bottom: 20px">
                <TypeDataTable
                  v-if="!loading && groupedServices.length > 0"
                  fixed-header
                  headersColor="rgb(224, 224, 230)"
                  style="background-color: transparent"                    
                  :headers="headersDetail"
                  :items="groupedServices"
                  :results-for-loading="!detailAgeGroupServices"
                  :loading="loadingDetail"
                  :show-load="false"
                  :show-select="false"
                  :show-actions="false"
                  :select-on-row-click="false" 
                  :disablePagination="true"
                >
                </TypeDataTable>
              </v-row>
            </div>
          </v-col>

          <v-col 
            v-if="!!results" 
            lg="3"
            xl="3"
            md="12" 
            cols="12"
            sm="12"
            :order="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
              ? '1' : '2' "
          >
            <div
              v-if="loadingDetail"
              class="text-caption font-weight-light d-flex align-center justify-center"
              :style="{
                height: '100%'
              }"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
            <div v-else-if="groupedServices.length > 0">            
              <div class="d-flex justify-center" style="margin-bottom: 10px; font-size: larger; font-weight: 500">
                <span 
                  style="font-size: 1rem;"
                  class="title-section"
                >
                  Numero Servizi : {{totalServicesDetails}}
                </span>
              </div>
              <div class="d-flex justify-center">
                <DoughnutChart
                  v-if="!loadingDetail || !!detailAgeGroupServices"
                  :chart-data="servicesDetailsChartData"
                  :styles="{
                    height: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                      ? '30vw' : '20vw',
                    width: this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs 
                      ? '30vw' : '20vw'
                  }"
                  :options="chartOptions"
                ></DoughnutChart>
                </div>
            </div>
          </v-col>
        </v-row>

      </div>
    </v-container>
    
    <SendCustomersSms
      v-model="customersSendMessage"
      :dialog-height=null
      :dialog-max-width="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 
        '95vw' : '60vw'"
      :customers="messageCustomers"
      @fetch-customers="sendMassiveMsg"
    ></SendCustomersSms>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>

import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

import StandardDialog from '@/components/common/StandardDialog.vue'
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import DynamicContentTable from '@/components/common/DynamicContentTable.vue';
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import SendSmsModel from '@/components/calendar/SendSmsModel.vue';
import ServiceTypeAutocomplete from '@/components/common/ServiceTypeAutocomplete.vue'
import ServiceTypeCategoriesAutocomplete from '@/components/common/ServiceTypeCategoriesAutocomplete.vue'
import operatorService from '@/services/operators/operators.service.js'
import balanceService from '@/services/balance/balance.service.js'
import html2canvas from '@/assets/js/html2canvas.min.js'
import chartMixin from '@/components/charts/chart.mixin'
import DoughnutChart from "@/components/charts/DoughnutChart";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import SendCustomersSms from "@/components/areas/marketing/SendCustomersSms.vue"
import dateUtils from "@/mixins/common/dateUtils"
import * as XLSX from "xlsx";

import _ from 'lodash'

export default {
  name: "CustomersByAgeGroup",
  mixins: [chartMixin, dateUtils],
  components: {
    TitleWithBackButton,
    RangeDateSelector,
    DynamicContentTable,
    GroupBasedChart,
    StandardDialog,
    TypeDataTable,
    SendSmsModel,
    SendCustomersSms,
    ServiceTypeAutocomplete,
    ServiceTypeCategoriesAutocomplete,
    ExportExcelTable,
    DoughnutChart,
    ManualBarcodeInput
  },
  data: function() {
    let startDate = new Date();
    startDate.setDate(1)
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    return {
      rowSelected: undefined,
      results: undefined,
      graphResults: undefined,
      startDate: startDate,
      endDate: endDate,
      loading: false,
      loadingDetail: false,
      detailAgeGroupServices: undefined,
      serviceType: [],
      tableOrSummaryIndex: 0,
      data: {},
      graphData: {},
      frequentServices: undefined,
      messageCustomers: [],
      ghost: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      worksheetName: "",
      fileName: "",
      customersSendMessage: false,
      fieldsToExport: {},
      fetchItem: {},
      fetchType: "",
      chartOptions: { 
        mainAspectRatio: true,
        responsive: true,
        legend: {
          display: false
        },
        showTooltips: false,
      },
      colorSet: [
        '#264653','#E9C46A', '#2A9D8F', '#E76F51', '#F2E863',
        '#5eaee1', '#7196e2', '#877be2',
        '#61E8E1','#6A0136', '#0D2C54', '#0000FF', '#FF00FF',
        '#0D2C54','#F6511D', '#00FFFF', '#FCD3DE', '#72A1E5',
        '#8C6057','#390040', '#FFFF00', '#734B5E', '#FFCF99',
        '#542344','#5762D5', '#093A3E', '#5C8001', '#52AD9C',
        '#443850', '#BCAA99', '#BBBE64', '#8E5572', '#8EF9F3', 
        '#F87666', '#226CE0', '#465362', '#F4B266', '#832161', 
        '#BBD5ED', '#967AA1', '#192A51', '#F0B7B3', '#A833B9', 
      ],
      fileName: ""
    }
  },
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  mounted: function() {
    this.fieldsToExport = balanceService._fieldsToExportCustomersByFidelization();
    this.worksheetName = "Export Clienti Fasce d'età";
    this.calculate()
  },
  methods: {
    async exportToExcel() {
      let workbook = XLSX.utils.book_new();

      this.fileName = "Clienti_Per_Fasce_Età_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls";
      let ws = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws, [
        [
          'Numero Clienti Totali: ' + this.totalAllCustomers
        ],
        [],
        [
          'Fascia Anagrafica',
          'N° Clienti'
        ],
        ...this.groupedAgeRowsAllCustomers.map((el) => {
          return [
            el.ageGroup,
            el.customerNumber
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws, "Clienti per Fasce di età");

      let servicesCompleteArrayToFix = []
      let servicesCompleteArray = []
      for (let i = 0; i < this.groupedAgeRows.length; i++) {
        this.detailAgeGroupServices = await this.handleClickPayingCustomers(this.groupedAgeRows[i])
        servicesCompleteArrayToFix.push(this.groupedServices)
      }
      for (let i = 0; i < servicesCompleteArrayToFix.length; i++) {
        for (let j = 0; j < servicesCompleteArrayToFix[i].length; j++) {
          servicesCompleteArray.push(servicesCompleteArrayToFix[i][j])
        }
        servicesCompleteArray.push([])
      }

      let ws1 = XLSX.utils.aoa_to_sheet([[]]);
      XLSX.utils.sheet_add_aoa(ws1, [
        [
          "Numero Clienti: " + this.totalCustomers
        ],
        [],
        [
          'Fascia Età',
          'N° Clienti',
          '% Clienti',
          'Tot. Visite',
          'F. Media',
          'V. Uomini',
          'V. Donne'
        ],
        ...this.groupedAgeRows.map((el) => {
          return [
            el.ageGroup,
            el.customerNumber,
            el.percentageCustomers,
            el.totalVisits,
            el.averageExpense,
            el.maleBillNumber,
            el.femaleBillNumber
          ]
        }),
        [],
        [
          'Servizio',
          'N°',
          '%'
        ],
        ...servicesCompleteArray.map((el) => {
          return [
            el.productName,
            el.productQuantity,
            el.percentageServices
          ]
        })
      ])
      XLSX.utils.book_append_sheet(workbook, ws1, "Periodo selezionato");

      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    generateExcelName(item) {
      let name = item["ageGroup"].replaceAll(' ', '_')
      let startDate = this._buildDateToExport(this.startDate) 
      let endDate = this._buildDateToExport(this.endDate) 
      return "Clienti_Fascia_" + name + '_' + startDate + '_' + endDate + ".xls";
    },
    async fetchExcel(){
      let item = this.fetchItem
      let customersToExport = undefined

      if(!!item) {
        customersToExport = await balanceService.customerTransactionsByAgeGroupDetails({
          from: this.startDate,
          to: this.endDate,
          ageGroup: item["ageGroup"],
          ghost: this.ghost
        })

        customersToExport = customersToExport.map((el) => {
          return {
            'lastname': el.customerLastName,
            'firstname': el.customerFirstName,
            'gender': el.customerGender,
            'cap': el.customerCap,
            'province': el.customerProvince,
            'city': el.customerCity,
            'fiscalCode': el.customerFiscalCode,
            'birthday': el.customerBirthday,
            'telephone': el.customerTelephone,
            'cellphone': el.customerCellphone,
            'email': el.customerEmail,
          }
        })
      }

      return customersToExport
    },
    preFetch(row, type){
      this.fetchItem = row
      this.fetchType = type
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost
          this.calculate()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    getBackgroundColor() {
      const isDark = this.$vuetify.theme.isDark
      if(isDark) return this.$vuetify.theme.themes.dark.background
      else return this.$vuetify.theme.themes.light.background
    },
    async handleClickPayingCustomers(row) {
      this.rowSelected = "Fascia Anagrafica: " + row['ageGroup']
      this.loadingDetail = true
      await this.ageGroupFrequentServices(row['ageGroup']).then((results) => {
        this.detailAgeGroupServices = results
        this.loadingDetail = false
      })
      return this.detailAgeGroupServices
    },
    async handleClickAllCustomers(row) {

    },
    async sendMassiveMsg(filters) {
      let row = this.fetchItem
      let type = this.fetchType

      if (type == 'payingCustomers') {
        const customers = await balanceService.customerTransactionsByAgeGroupDetails({
          from: this.startDate,
          to: this.endDate,
          ageGroup: row["ageGroup"],
          ghost: this.ghost
        }, filters)
        
        this.messageCustomers = customers.map((el) => {
          return {
            'id': el.customerId,
            'firstname': el.customerFirstName,
            'lastname': el.customerLastName,
            'cellphone': el.customerCellphone,
            'sendByWhatsapp': el.customerSendByWhatsapp
          }
        })
      }
      else if (type == 'allCustomers') {
        let mapped = []
        await balanceService.allCustomersByAgeGroupDetails({ageGroup: row['ageGroup']}, filters).then((results) => {
            mapped = results
        })
        this.messageCustomers = mapped
      }
    },
    customerList(ageGroup) {
      const startDate = this.startDate,
      endDate = this.endDate
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      const results = balanceService.customersFrequency({
        from: startDate,
        to: endDate,
        ageGroup: ageGroup,
        ghost: this.ghost
      })

      return results
    },
    async customerAgeGroupSummary() {
      const startDate = this.startDate,
      endDate = this.endDate
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      const results = await balanceService.countTransactionsCustomersByAgeGroup({
        from: startDate,
        to: endDate,
        ghost: this.ghost
      })
      return results
    },
    async ageGroupFrequentServices(ageGroup) {
      const startDate = this.startDate,
      endDate = this.endDate
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)

      const results = await balanceService.servicesTransactionsByAgeGroupDetails({
        from: startDate,
        to: endDate,
        ageGroup: ageGroup,
        ghost: this.ghost
      })
      return results
    },
    async calculate() {
      this.loading = true
      this.detailAgeGroupServices = []

      await Promise.all([
        this.customerAgeGroupSummary(),
        balanceService.countAllCustomersByAgeGroup()
      ]).then((results) => {
        this.results = results[0]
        this.graphResults = results[1]
      })

      this.data =  {
        name: 'Percentuale Visite',
        label: 'Percentuale Visite',
        data: this.groupedAgeRows
      }

      this.graphData = {
        label: 'Numero Clienti',
        data: this.groupedAgeRowsAllCustomers
      }
      this.loading = false;
    },
    print() {
      let id = 'ageGroupCustomers'
      let startDate = this._buildDateToExport(this.startDate) 
      let endDate = this._buildDateToExport(this.endDate)
      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Clienti_Fasce_Eta_" + startDate + "_" + endDate + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
    totalAllCustomers() {
      if(!this.graphResults || this.graphResults.length == 0) {
        return 0
      }

      return this.graphResults.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.countCustomers)
      }, 0)   
    },
    allCustomersChartData() {
      if (!this.graphResults || this.graphResults.length == 0) {
        return {
          labels: [],
          datasets: []
        }
      }

      return {
        labels: this.graphResults.map(el => el.ageGroup),
        datasets: [
          {
            backgroundColor: (() => {let arrayColor = []; for(let i = 0; i < this.graphResults.length; i++) { arrayColor.push(this.colorSet[i % this.graphResults.length])} return arrayColor})(),
            data: this.graphResults.map(el => el.countCustomers)
          }
        ]
      }
    },
    groupedAgeRowsAllCustomers() {
      if (!this.graphResults || this.graphResults.length == 0) {
        return [];
      }

      let results = [];

      for (let i = 0; i < this.graphResults.length; i += 1) {
        results.push({
          color: this.colorSet[i % this.graphResults.length],
          ageGroup: this.graphResults[i].ageGroup,
          customerNumber: this.graphResults[i].countCustomers,
        });
      }

      return results;
    },
    headersAllCustomers() {
      if (!this.graphResults || this.graphResults.length == 0) return [];
      else
        return [
          {
            value: "color",
            text: "",
            type: 'color',
            minWidth: "100px",
          },
          {
            value: "ageGroup",
            text: "Fascia Anagrafica",
            minWidth: "200px",
          },
          {
            value: "customerNumber",
            text: "N° Clienti",
            minWidth: "100px",
          },
        ];
    },
    payingCustomersChartData() {
      if (!this.results || this.results.length == 0) {
        return {
          labels: [],
          datasets: []
        }
      }

      return {
        labels: this.results.map(el => el.ageGroup),
        datasets: [
          {
            backgroundColor: (() => {let arrayColor = []; for(let i = 0; i < this.results.length; i++) { arrayColor.push(this.colorSet[i % this.results.length])} return arrayColor})(),
            data: this.results.map(el => el.countCustomers)
          }
        ]
      }
    },
    servicesDetailsChartData() {
      if (!this.groupedServices || this.groupedServices.length == 0) {
        return {
          labels: [],
          datasets: []
        }
      }

      return {
        labels: this.groupedServices.map(el => el["productName"]),
        datasets: [
          {
            backgroundColor: (() => {let arrayColor = []; for(let i = 0; i < this.groupedServices.length; i++) { arrayColor.push(this.colorSet[i % this.groupedServices.length])} return arrayColor})(),
            data: this.groupedServices.map(el => el["productQuantity"])
          }
        ]
      }
    },
    groupedAgeRows() {
      if(!this.results || this.results.length == 0) {
        return []
      }

      let results = []

      for (let i = 0; i < this.results.length; i += 1) {
        results.push({
          color: this.colorSet[i % this.results.length],
          ageGroup: this.results[i].ageGroup,
          customerNumber: this.results[i].countCustomers,
          totalVisits: this.results[i].countTransactions,
          maleBillNumber: this.results[i].countMaleTransactions,
          femaleBillNumber: this.results[i].countFemaleTransactions,
          averageExpense: Number(Number(this.results[i].averagePrice).toFixed(2)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }),
          percentageCustomers: ((Number(this.results[i].countCustomers) / this.totalCustomers) * 100).toFixed(2) + ' %'
        })
      }

      return results
    },
    groupedServices() {
      if (!this.detailAgeGroupServices || this.detailAgeGroupServices.length == 0)
        return []

      let results = []


      for (let i = 0; i < this.detailAgeGroupServices.length; i += 1) {
        let percentage = Number(((Number(this.detailAgeGroupServices[i].totalQuantity) / this.totalServicesDetails) * 100).toFixed(2))
        if (percentage >= 1) {
          results.push({
            key: i,
            color: this.colorSet[i % this.detailAgeGroupServices.length],
            productName: this.detailAgeGroupServices[i].productName,
            productQuantity: this.detailAgeGroupServices[i].totalQuantity,
            percentageServices: percentage + ' %'
          })
        }
      }

      return results
    },
    totalCustomers() {
      if(!this.results || this.results.length == 0) {
        return 0
      }

      return this.results.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.countCustomers)
      }, 0)   
    },
    totalServicesDetails() {
      if(!this.detailAgeGroupServices || this.detailAgeGroupServices.length == 0) {
        return 0
      }

      return this.detailAgeGroupServices.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.totalQuantity)
      }, 0)   
    },    
    headersDetail() {
      if(!this.detailAgeGroupServices || this.detailAgeGroupServices.length == 0) return []
      else return [
        {
          value: "color",
          text: "",
          type: 'color',
        },
        {
          value: 'productName',
          text: 'Servizio',
        },
        {
          value: 'productQuantity',
          text: 'N°',
        },
        {
          value: 'percentageServices',
          text: '%',
        },
      ]
    },
    headers() {
      if(!this.results || this.results.length == 0) return []
      else return [
        {
          value: "color",
          text: "",
          type: 'color',
        },
        {
          value: 'ageGroup',
          text: 'Fascia Età',
        },
        {
          value: 'customerNumber',
          text: 'N° Clienti',
        },
        {
          value: 'percentageCustomers',
          text: '% Clienti',
        },
        {
          value: 'totalVisits',
          text: 'Tot. Visite',
        },
        {
          value: 'averageExpense',
          text: 'F. Media',
        },
        {
          value: 'maleBillNumber',
          text: 'V. Uomini',
        },
        {
          value: 'femaleBillNumber',
          text: 'V. Donne',
        },
      ]
    }
  },
  watch: {
  },
}

</script>

<style>

</style>
