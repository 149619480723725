import axios from '@/services/axios'
import UrlKeeper from '@/services/UrlKeeper.js';
import eventsHandler from '@/services/common/eventsHandler'
import socketService from '@/services/socket/socket.service'
import settingsList from './settings.json'
import SettingsNameMappings from './settingsNameMappings.js'

class settingsService extends eventsHandler {
  constructor() {
    super()
    this.settings = {}

    this.settingsList = settingsList
    this._calculateSettingsText()

    socketService.on('settings:set', (data) => {
      if (!!data.key) {
        this.get(data.key).then((setting) => {
          this._executeAsyncCallbacksOf('update', { key: data.key, value: this.settings[data.key].value })
          this._executeAsyncCallbacksOf('update-' + data.key, this.settings[data.key].value)
        })
      }
    })
  }

  get(key) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/settings/get', { key: key }).then((response) => {
        if (response.success) {
          if (!this.settings[key]) {
            this.settings[key] = {}
          }
          this.settings[key] = response.results
          resolve(this.settings[key])
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getFromCache(key) {
    return this.settings[key] !== null && this.settings[key] !== undefined ? this.settings[key].value : undefined
  }

  set(key, value) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/settings/set', { key: key, value: value }).then((response) => {
        if (response.success) {
          if (!this.settings[key]) this.settings[key] = {}
          this.settings[key]['value'] = value
          Promise.all([
            this._executeAsyncCallbacksOf('update', { key: key, value: value }),
            this._executeAsyncCallbacksOf('update-' + key, value)
          ]).then(() => {
            resolve()
          })
        } else {
          reject(response.results)
        }
      })
    })
  }

  list(category) {
    return new Promise((resolve, reject) => {
      if (!category) {
        resolve(this.settings)
      } else {
        let results = {};
        for (let key in this.settings) {
          if (this.settings.hasOwnProperty(key) && this.settings[key]['category'] === category) {
            results[key] = this.settings[key];
          }
        }

        resolve(results);
      }
    })
  }

  load() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!apiUrl) {
        reject(new Error('not-found'))
        return
      }

      axios.get(apiUrl + '/settings/list').then((response) => {
        for (let i = 0; i < response.results.length; i++) {
          if (this._isArray(response.results[i].value) || this._isObject(response.results[i].value) || response.results[i].value !== this.settings[response.results[i].key]) {
            this._executeAsyncCallbacksOf('update', { key: response.results[i].key, value: response.results[i].value })
            this._executeAsyncCallbacksOf('update-' + response.results[i].key, response.results[i].value)
          }
          this.settings[response.results[i].key] = response.results[i]
        }
        resolve(this.settings)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  _isArray(value) {
    return Array.isArray(value)
  }

  _isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
  }

  _calculateSettingsText() {
    for (let i = 0; i < this.settingsList.length; i++) {
      this.settingsList[i].text = SettingsNameMappings.getHumanName(this.settingsList[i].name)
      this.settingsList[i].icon = SettingsNameMappings.getAnalyticIcon(this.settingsList[i].name)
    }
  }

  cardList() {
    return new Promise((resolve, reject) => {
      resolve(this.settingsList)
    })
  }
}

export default new settingsService()