class NameMapper {
  constructor() {
    this.optionsArray = []
    this.nameMappings = {}
  }

  _optionsGetter() {
    return new Promise.resolve([])
  }

  options() {
    return new Promise((resolve, reject) => {
      this._optionsGetter().then((options) => {
        this.optionsArray = options
        this._recalculateMappings()
        resolve(this.optionsArray)
      })
    })
  }

  _recalculateMappings() {
    this.nameMappings = {}
    for (let i = 0; i < this.optionsArray.length; i++) {
      const option = this.optionsArray[i]
      this.nameMappings[option.value] = option.text
    }
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName]
  }

  getCodedName(humanName) {
    return Object.keys(this.nameMappings).find(key => this.nameMappings[key] === humanName);
  }
}

export default NameMapper