<template>
  <v-form v-model="formValid" :disabled="disabled">
    <v-text-field
      filled
      dense
      hide-details="auto"
      label="Nome"
      :rules="[max255CharRule, presenceRule]"
      v-model="name"
      @input="$emit('input', localValue)"
    ></v-text-field>

    <div class="mt-5">Contenuto del messaggio</div>
    <div 
      contenteditable="true"
      :contentdisabled="disabled"
      class="editable-message mt-3" 
      ref="editableMessage"
      @input="calculateTextFromHtml"
    ></div>
    <div class="hidden-editable-message" ref="hiddenEditableMessage">
    </div>
    <div>
      <div 
        :style="{
        'color': countCharStringColor
        }"
      >
        {{ countCharString }}
      </div>
    </div>
    <div class="mt-2">
      <v-chip 
        v-for="placeholder in possiblePlaceholders"
        :key="placeholder.name"
        color="primary" 
        class="ma-1" 
        :disabled="disabled"
        @click="addPlaceholder(placeholder.name, placeholder.text)"
      >{{placeholder.text}}</v-chip>
    </div>

    <div 
      class="images-list"
      v-if="!!value && Object.keys(value).length > 0 && !!images && images.length > 0"
    >
      <div 
        v-for="image in images" 
        :key="image.id"
        :contentdisabled="disabled"
        @click="setSelectedImage(image)" 
        class="image-item" 
        :class="{'selected': selectedImage && image.id === selectedImage.id
          && selectedImage.url === image.url
        }">
        <img :src="image.url" :alt="image.name">
      </div>
    </div>

    <a
      v-if="!!value && Object.keys(value).length > 0 && 
        !!images && images.length > 0 && !!selectedImage"
      href="#" 
      @click="setSelectedImage(undefined)"
      :contentdisabled="disabled"
    >
      Deseleziona immagine
    </a>

    <div
      class="mt-2"
      v-if="!hideUploadImage"
    >
      <v-btn
        text
        outlined
        class="mt-2 mb-2"
        :x-small="$vuetify.breakpoint.xs"
        :small="($vuetify.breakpoint.sm || $vuetify.breakpoint.md)"
        :large="($vuetify.breakpoint.lg || $vuetify.breakpoint.xl)"
        :disabled="disabled"
        @click="uploadImageFromLocal"
      >
        Carica immagine da pc
      </v-btn>
    </div>

    <SimplePopUp
      v-model="alertImageSize"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '26vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
      alert-text="Il file non può superare i 50MB"
    ></SimplePopUp>
  </v-form>
</template>

<script>
import SimplePopUp from '@/components/common/SimplePopUp.vue'

import galleryService from "@/services/gallery/gallery.service";

export default {
  Name: "SmsModelForm",
  components: {
    SimplePopUp
  },
  data: function() {
    return {
      images: [],
      formValid: false,
      name: this.value.name,
      text: this.value.text,
      selectedImage: undefined,
      selectedPlaceholders: [],
      possiblePlaceholders: [
        { name: 'customer.firstname', text: 'Nome Cliente' },
        { name: 'customer.lastname', text: 'Cognome Cliente' },
        { name: 'customer.fullname', text: 'Nome Completo Cliente' },
        { name: 'customer.appointment', text: 'Orario appuntamento' },
        { name: 'customer.operator', text: 'Servizi con Operatore' },
        { name: 'customer.service', text: 'Servizi' },
        //{ name: 'customer.namePromo', text: 'Nome Promo' },
        //{ name: 'customer.descriptionPromo', text: 'Descrizione Promo' },
        { name: 'customer.date', text: 'Data appuntamento'}
      ],
      alertImageSize: false,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    }
  },
  props: {
    value: {
      type: Object,
    },
    selectedImages: {
      type: Array,
      default: () => []
    },
    imageTags: {
      type: Array,
      default: () => []
    },
    hideUploadImage: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    passedPlaceholders: {
      type: Array,
      default: undefined
    },

  },
  mounted: function() {
    this.$emit('update:valid', this.localValid)
    this.$refs.editableMessage.innerHTML = this.convertTextToHtml(this.text)
    this.fetchImages(); // Chiama anche all'inizio per avere le immagini iniziali

    if (!!this.passedPlaceholders) {
      this.possiblePlaceholders = this.passedPlaceholders
    }

    if (!!this.selectedImages && this.selectedImages.length > 0) {
      this.selectedImage = this.selectedImages[0]
    }
  },
  methods: {
    async setSelectedImage(image) {
      this.$emit('input', this.localValue);
      this.$emit('update:selectedImage', image);
      this.selectedImage = image;
    },
    fetchImages() {
      if (!this.imageTags || this.imageTags.length === 0) return;
      const params = {
        filter: "",
        limit: 999,
        page: 0,
        tagIds: this.imageTags,
      };

      galleryService.list(params).then((response) => {
        this.images = response.images;
      });
    },
    createPlaceholderDomElement(name, text) {
      let placeholderToInsert = document.createElement('span')
      placeholderToInsert.style.borderRadius = "16px"
      placeholderToInsert.style.fontSize = "14px"
      placeholderToInsert.style.height = "32px"
      placeholderToInsert.style.backgroundColor = "#8a77e2"
      placeholderToInsert.style.borderColor = "#8a77e2"
      placeholderToInsert.style.color = "white"
      placeholderToInsert.style.padding = "5px"
      placeholderToInsert.setAttribute("contenteditable", "false")
      placeholderToInsert.setAttribute("contentdisabled", "false")
      placeholderToInsert.setAttribute("data-name", name)
      placeholderToInsert.innerHTML = text
      return placeholderToInsert
    },
    addPlaceholder(name, text) {
      let placeholderToInsert = this.createPlaceholderDomElement(name, text)

      let range, html
      if(window.getSelection) {
        if(!this.isInEditableSelection()) return
        let selection = window.getSelection()

        if(selection.getRangeAt && selection.rangeCount) {
          range = selection.getRangeAt(0);
          range.collapse(false)
          range.insertNode(placeholderToInsert)
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.collapse(false);
        html = (placeholderToInsert.nodeType == 3) ? placeholderToInsert.data : placeholderToInsert.outerHTML;
        range.pasteHTML(html);
      }

      this.calculateTextFromHtml()
      this.focusAndEdit()
    },
    isInEditableSelection() {
      const element = this.getSelectionBoundaryElement(false)
      return !!element && (element.classList.contains('editable-message') || this.getIfChildOf(element, this.$refs.editableMessage))
    },
    getIfChildOf(child, parent) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    focusAndEdit() {
      let el = this.$refs.editableMessage

      el.focus()
      if(typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
        let range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        let sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      } else if(typeof document.body.createTexRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    calculateTextFromHtml() {
      this.text = this.convertHtmlToText(this.$refs.editableMessage.innerHTML)
      this.$emit('input', this.localValue)
    },
    convertHtmlToText(html) {
      let template = this.$refs.hiddenEditableMessage
      html = html.trim()
      template.innerHTML = html

      this.selectedPlaceholders = []
      
      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const spanElements = template.querySelectorAll('[data-name="' + name + '"]')

        if(spanElements.length > 0) {
          this.selectedPlaceholders.push(this.possiblePlaceholders[i])
        }

        for(let k = 0; k < spanElements.length; k += 1) {
          spanElements[k].parentNode.replaceChild(document.createTextNode('<' + name + '>'), spanElements[k])
        }
      }

      return this.parseChildNodesForValueAndLines(template)
    },
    parseChildNodesForValueAndLines(HtmlNode) {
      let newValue = '';
      let isOnFreshLine = true;

      for (let i = 0; i < HtmlNode.childNodes.length; i++) {
        const childNode = HtmlNode.childNodes[i];

        if (childNode.nodeName === 'BR') {
          newValue += '\n';
          isOnFreshLine = true;
          continue;
        }

        if (childNode.nodeName === 'DIV' && isOnFreshLine === false) {
          newValue += '\n';
        }

        isOnFreshLine = false;

        if (childNode.nodeType === 3 && childNode.textContent) {
          newValue += childNode.textContent;
        }

        newValue += this.parseChildNodesForValueAndLines(childNode);
      }

      return newValue
    },
    calculateHtmlFromText() {
      this.$refs.editableMessage.innerHTML = this.convertTextToHtml(this.text)
    },
    convertTextToHtml(text) {
      let finalText = text

      if(!finalText) finalText = ''

      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const text = this.possiblePlaceholders[i].text
        const spanElement = this.createPlaceholderDomElement(name, text).outerHTML
        finalText = finalText.replaceAll('<' + name + '>', spanElement)
      }

      return finalText
    },
    getSelectionBoundaryElement(isStart) {
      let range, sel, container;
      if (document.selection) {
        range = document.selection.createRange();
        range.collapse(isStart);
        return range.parentElement();
      } else {
        sel = window.getSelection();
        if (sel.getRangeAt) {
          if (sel.rangeCount > 0) {
            range = sel.getRangeAt(0);
          }
        } else {
          // Old WebKit
          range = document.createRange();
          range.setStart(sel.anchorNode, sel.anchorOffset);
          range.setEnd(sel.focusNode, sel.focusOffset);

          // Handle the case when the selection was selected backwards (from the end to the start in the document)
          if (range.collapsed !== sel.isCollapsed) {
            range.setStart(sel.focusNode, sel.focusOffset);
            range.setEnd(sel.anchorNode, sel.anchorOffset);
          }
        }

        if (range) {
          container = range[isStart ? "startContainer" : "endContainer"];

          // Check if the container is a text node and return its parent if so
          return container.nodeType === 3 ? container.parentNode : container;
        }   
      }
    },
    uploadImageFromLocal() {
      let input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = _ => {
        let files = Array.from(input.files);
        let url = URL.createObjectURL(files[0])
        this.handleUploadImageFromLocal(files[0])
      };
      input.click();
    },
    async handleUploadImageFromLocal(file) {
      if (!file) return

      if (!!file && file.size > 50000000) {
        this.alertImageSize = true
        return
      }

      const imageUploaded = await galleryService.addImage(file)
      this.images.push(imageUploaded)
      await this.setSelectedImage(imageUploaded)
    }
  },
  watch: {
    imageTags: {
      deep: true,
      handler() {
        this.fetchImages(); 
      }
    },
    localValid(newVal) {
      this.$emit('update:valid', newVal)
    },
    value(newVal) {
      let newTextFromOutside = this.convertTextToHtml(newVal.text)
      let oldText = this.convertTextToHtml(this.text)

      if(oldText != newTextFromOutside) {
        this.text = newVal.text
        this.$refs.editableMessage.innerHTML = newTextFromOutside
        this.focusAndEdit()
      }
    }
  },
  computed: {
    localValid() {
      return this.formValid && !!this.text
    },
    localValue() {
      return {
        id: !!this.value ? this.value.id : undefined,
        name: this.name,
        text: this.text,
        values: this.selectedPlaceholders,
        imageTags: this.imageTags,
        selectedImage: this.selectedImage
      }
    },
    countCharString(){
      if(!this.text) return '0/160 - 0 Messaggi'
      let string = this.text.length + '/160 - ' + Math.ceil(this.text.length / 160) + ' Messaggi'

      if(this.selectedPlaceholders.length > 0) string = 'Hai selezionato dei placeholder, il numero di caratteri è variabile, quindi potresti avere un numero di messaggi maggiore! \n' + string

      return string
    },
    countCharStringColor(){
      if(!!this.text && this.text.length > 160) return 'red'
      if(this.selectedPlaceholders.length > 0) return 'orange'
      return 'black'
    },
  }
}
</script>

<style scoped>
[contenteditable] {
  outline: 0px solid transparent;
}

[contentdisabled] {
  pointer-events: none;
}

.editable-message {
  border-radius: 5px;
  white-space: normal;
  background: rgba(193, 187, 187, 0.25);
  padding: 20px 20px 20px 15px;
  overflow: auto;
  word-wrap: break-word;
}

.hidden-editable-message {
  visibility: hidden;
  height: 0px;
}

.chip {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  background-color: #8a77e2 !important;
  border-color: #8a77e2 !important;
  color: white;
}

.images-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  flex-direction: row;
  gap: 8px;
}

.image-item > img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #777
}

.image-item > img:hover {
  cursor: pointer;
}

.selected > img {
  border: 3px solid #8a77e2;
}
</style>