import Database from '@/services/offline/database.js'

class CountryPrefixCache extends Database {

  constructor() {
    super()
  }

  _storeName() {
    return 'country-prefixes'
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object[]} param.countryPrefixes - Jobs to cache
   */
  async cache({ countryPrefixes }) {
    let storageKey = this._createStorageKey()

    const db = await this.dbPromise
    await db.put(this._storeName(), countryPrefixes, storageKey)
  }

  async get() {
    let storageKey = this._createStorageKey()

    const db = await this.dbPromise
    return await db.get(this._storeName(), storageKey)
  }

  async cachePresent() {
    return !!(await this.get())
  }

  async deleteAllCache() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }

  _createStorageKey() {
    return 'country-prefixes'
  }
}

export default new CountryPrefixCache()