<template>
  <div class="gd-tp-dropdown">
    <slot></slot>
    <v-expansion-panels :disabled="disabled">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <input type="text" maxlength="8" v-model="localTime" disabled>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <thead>
              <th colspan="6">Hour</th>
              <th>Min</th>
            </thead>
            <tbody>
              <tr>
                <td @click="setIt('0')">
                  <div><span :class="aCH('0')">00</span></div>
                </td>
                <td @click="setIt('1')">
                  <div><span :class="aCH('1')">01</span></div>
                </td>
                <td @click="setIt('2')">
                  <div><span :class="aCH('2')">02</span></div>
                </td>
                <td @click="setIt('3')">
                  <div><span :class="aCH('3')">03</span></div>
                </td>
                <td @click="setIt('4')">
                  <div><span :class="aCH('4')">04</span></div>
                </td>
                <td @click="setIt('5')">
                  <div><span :class="aCH('5')">05</span></div>
                </td>
                &nbsp;
                <td @click="setMn('00')">
                  <div><span :class="aCM('00')">00</span></div>
                </td>
              </tr>
              <tr>
                <td @click="setIt('6')">
                  <div><span :class="aCH('6')">06</span></div>
                </td>
                <td @click="setIt('7')">
                  <div><span :class="aCH('7')">07</span></div>
                </td>
                <td @click="setIt('8')">
                  <div><span :class="aCH('8')">08</span></div>
                </td>
                <td @click="setIt('9')">
                  <div><span :class="aCH('9')">09</span></div>
                </td>
                <td @click="setIt('10')">
                  <div><span :class="aCH('10')">10</span></div>
                </td>
                <td @click="setIt('11')">
                  <div><span :class="aCH('11')">11</span></div>
                </td>
                &nbsp;
                <td @click="setMn('15')">
                  <div><span :class="aCM('15')">15</span></div>
                </td>
              </tr>
              <tr>
                <td @click="setIt('12')">
                  <div><span :class="aCH('12')">12</span></div>
                </td>
                <td @click="setIt('13')">
                  <div><span :class="aCH('13')">13</span></div>
                </td>
                <td @click="setIt('14')">
                  <div><span :class="aCH('14')">14</span></div>
                </td>
                <td @click="setIt('15')">
                  <div><span :class="aCH('15')">15</span></div>
                </td>
                <td @click="setIt('16')">
                  <div><span :class="aCH('16')">16</span></div>
                </td>
                <td @click="setIt('17')">
                  <div><span :class="aCH('17')">17</span></div>
                </td>
                &nbsp;
                <td @click="setMn('30')">
                  <div><span :class="aCM('30')">30</span></div>
                </td>
              </tr>
              <tr>
                <td @click="setIt('18')">
                  <div><span :class="aCH('18')">18</span></div>
                </td>
                <td @click="setIt('19')">
                  <div><span :class="aCH('19')">19</span></div>
                </td>
                <td @click="setIt('20')">
                  <div><span :class="aCH('20')">20</span></div>
                </td>
                <td @click="setIt('21')">
                  <div><span :class="aCH('21')">21</span></div>
                </td>
                <td @click="setIt('22')">
                  <div><span :class="aCH('22')">22</span></div>
                </td>
                <td @click="setIt('23')">
                  <div><span :class="aCH('23')">23</span></div>
                </td>
                &nbsp;
                <td @click="setMn('45')">
                  <div><span :class="aCM('45')">45</span></div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <!--       <div :class="slContClass">
        <input :class="sliderClass" type="range" min="0" max="14" v-model="addedMins" @input="setAddedMins">
      </div> -->
    </v-expansion-panels>
  </div>
</template>

<style scoped>
.ga-tp-sel {
  color: red;
  font-weight: bold;
}
</style>

<script>

export default {
  name: 'BetterTimePicker',
  data: function () {
    return {
      localTime: this.value
    }
  },
  props: {
    value: {
      type: String,
      default: '8:00'
    },
    handleTimeChange: {
      type: Function,
      default: () => { }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    let time = this.value.split(':');
    this.localTime = time[0] + ':' + time[1];
    this.handleTimeChange(this.localTime)
  },
  methods: {
    setIt: function (hh) {
      this.localTime = hh + ':' + this.localTime.split(':')[1];
      this.handleTimeChange(this.localTime)
    },
    setMn: function (mm) {
      this.localTime = this.localTime.split(':')[0] + ':' + mm;
      this.handleTimeChange(this.localTime)
    },
    aCM(min) {
      return {
        'ga-tp-sel': min == this.localTime.split(':')[1]
      }
    },
    aCH(hour) {
      return {
        'ga-tp-sel': hour == this.localTime.split(':')[0]
      }
    },
  },
  computed: {
  },
  watch: { 
    value(newVal) {
      let time = newVal.split(':');
      this.localTime = time[0] + ':' + time[1];
      this.handleTimeChange(this.localTime)
    }
},
}
</script>