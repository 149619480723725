import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class marketingActionsService {

  list(page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingActions/list', {
        page: page,
        rowPerPage: rowPerPage
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  importedList(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingActions/importedList', {
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
    * @param {Object} marketingAction - The action to create
    * @param {Object} marketingAction.name
    * @param {string=} marketingAction.description
    * @param {string} marketingAction.goal
    * @param {string} marketingAction.category
    * @param {number=} marketingAction.landingPageId
    * @param {Object=} marketingAction.landingPage
    * @param {string} marketingAction.landingPage.name 
    * @param {string} marketingAction.landingPage.description
    * @param {'headerImageAndSidebar' | 'sidebar' | 'fullHeaderImageAndSidebar'} marketingAction.landingPage.layout
    * @param {Array<{ 
    *   position: 'header' | 'body' | 'rightSidebar' | 'leftSidebar',
    *   type: 'image' | 'paragraph' | 'imageAndParagraph',
    *   order: number,
    *   title: string,
    *   subtitle: string,
    *   body: string,
    *   image: File
    * }>} marketingAction.landingPage.blocks
    */
  create(marketingAction) {
    const apiUrl = UrlKeeper.getUrl()

    var formData = new FormData();
    formData.append('marketingAction.name', marketingAction.name)
    formData.append('marketingAction.goal', marketingAction.goal)
    formData.append('marketingAction.category', marketingAction.category)

    if (marketingAction.description) {
      formData.append('marketingAction.description', marketingAction.description)
    }

    if (marketingAction.landingPageId) {
      formData.append('marketingAction.landingPageId', marketingAction.landingPageId)
    }

    if (marketingAction.landingPage) {
      formData.append('marketingAction.landingPage.name', marketingAction.landingPage.name)
      formData.append('marketingAction.landingPage.description', marketingAction.landingPage.description)
      formData.append('marketingAction.landingPage.layout', marketingAction.landingPage.layout)

      // Append each block inside landingPage.blocks
      marketingAction.landingPage.blocks.forEach((block, index) => {
        formData.append(`marketingAction.landingPage.blocks[${index}].position`, block.position)
        formData.append(`marketingAction.landingPage.blocks[${index}].type`, block.type)
        if (block.order !== undefined && block.order !== null) {
          formData.append(`marketingAction.landingPage.blocks[${index}].order`, block.order)
        }
        if(block.title !== undefined && block.title !== null) {
          formData.append(`marketingAction.landingPage.blocks[${index}].title`, block.title)
        }
        if(block.subtitle !== undefined && block.subtitle !== null) {
          formData.append(`marketingAction.landingPage.blocks[${index}].subtitle`, block.subtitle)
        }
        if(block.body !== undefined && block.body !== null) {
          formData.append(`marketingAction.landingPage.blocks[${index}].body`, block.body)
        }
        
        if (!!block.image) {
          formData.append(`marketingAction.landingPage.blocks[${index}].image`, block.image, block.image.name)
        }
      })
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingActions/create', formData).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }

      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
   * @param {Object} marketinAction - Action to update
   * @property {number} marketinAction.id - The ID of the object.
   * @property {string=} marketinAction.name - The name of the object (optional).
   * @property {string=} marketinAction.description - The description of the object (optional).
   * @property {string=} marketinAction.goal - The goal of the object (optional).
   * @property {string=} marketinAction.category - The category of the object (optional).
   */
  update(marketinAction) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingActions/update', marketinAction).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(marketinAction) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!marketinAction || !marketinAction.id) {
        reject(new Error('marketing action is not specify'))
        return
      }

      axios.post(apiUrl + '/marketing/marketingActions/delete', {
        id: marketinAction.id
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  get(marketinActionId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/marketing/marketingActions/get', { id: marketinActionId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }


}

export default new marketingActionsService();
