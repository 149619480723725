import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class smsService {
  send(number, body) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/send', {
        number: number,
        body: body
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getEsendexBalance() {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/getEsendexBalance', {}).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendSmsEsendex(message, numbers, customerId) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/sendEsendex', {
        message: message,
        numbers: numbers,
        customerId: customerId
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendWhatsappMessage(message, fromW, toW, customerId) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/sendWhatsappMessage', {
        message: message,
        fromW: fromW,
        toW: toW,
        customerId: customerId
      }).then((response) => {
        if (response.success) {
          resolve(response.success)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendAppointmentMessage(shopNumber, dayAppointment, calendar, appointmentModelSelect, smsCustomerList) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/sendAppointmentMessage', {
        shopNumber: shopNumber,
        dayAppointment: new Date(dayAppointment),
        calendar: calendar,
        appointmentModelSelect: appointmentModelSelect,
        smsCustomerList: smsCustomerList
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendAppointmentMessageDay(shopNumber, dayAppointment, appointmentModelSelect) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/sendAppointmentMessageDay', {
        shopNumber: shopNumber,
        dayAppointment: new Date(dayAppointment),
        appointmentModelSelect: appointmentModelSelect,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  confirmSmsList(dayAppointment, appointmentModelSelect, filters) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/confirmSmsList', {
        dayAppointment: new Date(dayAppointment),
        appointmentModelSelect: appointmentModelSelect,
        filters: filters
      }).then((response) => {
        resolve(response.result)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendBirthdayMessage(shopNumber, birthday, birthdayModelSelect) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/sendBirthdayMessage', {
        shopNumber: shopNumber,
        birthday: new Date(birthday),
        birthdayModelSelect: birthdayModelSelect
      }).then((response) => {
        if (response.success) {
          resolve(response.success)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getCustomersByDate(date, filters) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/getCustomersByDate', {
        date: date,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.result)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  sendMassivePromo(shopNumber, model, isCustom = false, customMessage, withList, sendAll, customers, filters) {
    const apiUrl = UrlKeeper.getUrl()

    let params
    if (!isCustom)
      params = {
        model: model,
        isCustom: isCustom,
        whatsappNumber: shopNumber,
        withList: withList,
        sendAll: sendAll,
        customers: customers,
        filters: filters
      }
    else
      params = {
        customMessage: customMessage,
        isCustom: isCustom,
        whatsappNumber: shopNumber,
        withList: withList,
        sendAll: sendAll,
        customers: customers,
        filters: filters
      }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/sendMassivePromo', {
        ...params
      }).then((response) => {
        if (response.success) {
          resolve(response.success)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/sms/list', { page, rowPerPage, filters }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  _fieldsToExport() {
    return {
      'Cliente': {
        callback: (row) => {
          if (!!row.firstname || !!row.lastname)
            return row.firstname + " " + row.lastname
          else
            return 'Nessun Cliente'
        }
      },
      'Messaggio': 'message',
      'Provider': {
        callback: (row) => {
          return Number(row.smsProviderId) == 1 ? 'Esendex' : 'Whatsapp'
        }
      },
      'Data Invio': {
        callback: (row) => {
          return new Date(row.createdAt).toLocaleDateString()
        }
      },
      'Stato': 'status'
    }
  }
}

export default new smsService();
