import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import servicePhasesService from '@/services/servicePhases/servicePhases.service.js'
import socketService from '../socket/socket.service'

class serviceManagment {
  constructor() {
    this._alreadyLoaded = false

    socketService.on('services:update', () => {
      localStorage.removeItem('hierarchy_services')
    })

    socketService.on('services:create', () => {
      localStorage.removeItem('hierarchy_services')
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/services/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      let servicePhases = service.servicePhases
      service['servicePhases'] = undefined

      axios.post(apiUrl + '/registries/services/create', service).then(async (response) => {
        if (response.success) {
          if (!!servicePhases) {
            for (let i = 0; i < servicePhases.length; i++) {
              servicePhases[i].serviceId = response.results.id
              await servicePhasesService.create(servicePhases[i])
            }
          }
          resolve()
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/services/update', service).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  listServicesFromDepartment(departmentId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/services/listServicesFromDepartment', { departmentId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(service) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!service.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/services/delete', {
        id: service.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(serviceId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/services/get', { id: serviceId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  getServiceTypeCategories() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/serviceTypesCategories/simpleList').then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  fields() {
    return Promise.resolve([
      { text: 'Nome del servizio', value: 'name' },
      //KEEP COMMENT
      /*       { text: 'Descrizione', value: 'description' }, */
      { text: 'Tipo Famiglia', value: 'serviceType', type: 'object' },
      { text: 'Prezzo', value: 'price', type: 'price', symbol: '€' },
      { text: 'Colore', value: 'color', type: 'color' },
      { text: "Disabilitato", value: "disabled", type: "custom" },
    ])
  }

  _fieldsToExport() {
    return {
      'Nome del servizio': 'name',
      'Descrizione': 'description',
      'Reparto': {
        field: 'serviceType',
        callback: (value) => {
          return !!value && !!value.serviceTypesCategory ? 
            value.serviceTypesCategory.name : ''
        }
      },
      'Famiglia': {
        field: 'serviceType',
        callback: (value) => {
          return !!value ? value.name : ''
        }
      },
      'Prezzo': 'price',
      'Minuti': 'minutes',
    }
  }
  _fieldsToExcelExport() {
    return {
      ' ': 'name',
      '€': {
        field: 'total',
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        }
      },
      'N°': 'count',
      '% su N° Servizi': 'onServices',
      '% su N° Clienti': 'onCustomers',
      '% su N° Transazioni': 'onTransactions',
      '% su €': 'onTotal'
    }
  }
}

export default new serviceManagment();
