<template>
  <div>
    <div 
      class="item-container"
      :class="{
        selected: selectable && !!localValue && item[itemKey] == localValue[itemKey],
        disabled: isDisabled(item)
      }"
      v-for="item in items"
      :key="item[itemKey]"
      @click.stop.prevent="handleItemContainerClick(item)"
    >
      <div class="d-flex align-center item-title-container justify-start py-2">
        <div class="item-icon" v-if="iconVisible">
          <v-icon>{{item[itemIcon]}}</v-icon>
        </div>
        <div class="pl-2">
          <slot name="item-title" v-bind="{item}">
            <div class="item-title">
              {{item[itemTitle]}}
            </div>
            <div class="item-subtitle text-caption">
              {{item[itemSubtitle]}}
            </div>
          </slot>
        </div>
        <div v-if="expandible" class="item-collapser d-flex">
          <v-icon
            class="collapser-icon"
            :class="{
              expanded: !!localExpanded && item[itemKey] == localExpanded[itemKey],
              collapsed: !localExpanded || item[itemKey] != localExpanded[itemKey],
            }"
          >{{collapserIcon}}</v-icon>
        </div>
        <slot name="extra-icons" v-bind="{item}"></slot>
      </div>
      <v-expand-transition>
        <div 
          class="expansion pl-2" 
          v-show="!!localExpanded && item[itemKey] == localExpanded[itemKey]"
        >
          <slot name="expansion" v-bind="{item}"></slot>
        </div>
      </v-expand-transition>
      <v-divider v-if="divided"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollapsableList",
  data: function() {
    return {
      localValue: this.value,
      localExpanded: this.expanded
    }
  },
  props: {
    value: {},
    expanded: {},
    selectable: {
      type: Boolean,
      default: false
    },
    expandible: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemIcon: {
      type: String,
      default: undefined
    },
    itemTitle: {
      type: String | Function,
      default: 'title'
    },
    itemSubtitle: {
      type: String | Function,
      default: 'subtitle'
    },
    itemDisabled: {
      default: 'disabled'
    },
    iconVisible: {
      type: Boolean,
      default: false
    },
    divided: {
      type: Boolean,
      default: false
    },
    collapserIcon: {
      type: String,
      default: 'mdi-chevron-down'
    },
    clickMode: {
      type: String,
      default: 'expandAndSelect',
      validator(value) {
        return ['expandAndSelect','expandOnly'].indexOf(value) != -1
      }
    }
    
  },
  mounted: function() {
  },
  methods: {
    handleItemContainerClick(item) {
      if(this.isDisabled(item)) return

      if(this.clickMode == 'expandAndSelect') {
        this.toggleSelection(item)
        this.toggleExpansion(item)
        this.$emit('input', this.localValue)
      }
      else if(this.clickMode == 'expandOnly'){
        this.toggleStayExpanded(item)
        this.$emit('input', this.localValue)
      }
    },
    toggleSelection(item) {
      if(!!this.localValue && this.localValue[this.itemKey] == item[this.itemKey]) {
        this.localValue = undefined
      } else {
        this.localValue = item
      }
      this.$emit('input', this.localValue)
    },
    toggleExpansion(item) {
      if(!!this.localExpanded && this.localExpanded[this.itemKey] == item[this.itemKey]) {
        this.localExpanded = undefined
      } else {
        this.localExpanded = item
      }
      this.$emit('update:expanded', this.localExpanded)
    },
    toggleStayExpanded(item){
      this.localExpanded = item
      this.$emit('update:expanded', this.localExpanded)
    },
    isDisabled(item) {
      if(typeof this.itemDisabled == 'function') {
        return this.itemDisabled(item)
      } else {
        return item[this.itemDisabled]
      }
    },
    getItemTitle(item) {
      if(typeof this.itemTitle == 'function') {
        return this.itemTitle(item)
      } else {
        return item[this.itemTitle]
      }
    },
    getItemSubtitle(item) {
      if(typeof this.itemSubtitle == 'function') {
        return this.itemSubtitle(item)
      } else {
        return item[this.itemSubtitle]
      }
    },
  },
  computed: {
  }
}
</script>

<style scoped>
.item-icon {
  width: 40px;
  text-align: center
}

.item-title {
  font-size: 14pt;
}

.item-container, .collapser-icon {
  transition: all 0.2s;
}

.collapser-icon.expanded {
  transform: rotate(180deg);
}

.collapser-icon.collapsed {
  transform: rotate(0deg);
}

.item-collapser {
  margin-left: auto;
  margin-right: 10px;
}

.item-title-container {
  min-height: 50px;
}

.item-container:hover {
  background-color: rgba(196, 196, 196, 0.429);
}

.item-container.selected {
  background-color: rgba(196, 196, 196, 0.429);
}

.item-container.disabled {
  opacity: 50%;
}
</style>