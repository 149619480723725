<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Turni"
    :route-father="routeFather"
  >
    <template v-slot:content>
      <div class="d-flex justify-center align-center" v-if="loadingOperators">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <template v-else>
        <v-list v-if="operators.length > 0" two-line color="transparent">
          <div
            v-ripple
            @click="openTurns(operator)"
            v-for="operator in operators"
            v-bind:key="operator.id"
          >
            <v-list-item v-if="searchMatch(operator)">
              <v-list-item-avatar class="my-0">
                <OperatorAvatar :value="operator"></OperatorAvatar>
              </v-list-item-avatar>
              <v-list-item-content class="text-left">
                <v-row class="ml-2">
                  <v-col class="pa-0 d-flex align-center">
                    <v-list-item-title>{{ operator.firstname }} {{ operator.lastname }} </v-list-item-title>
                    <div class="text--disabled text-body-2">
                      <span
                        v-for="(shift, index) in shifts.filter(el => el.operatorId.toString() == operator.id.toString())"
                        :key="shift.id"
                      >
                        <span v-for="(turn, index) in shift.timeTable.timeTablesTurns" :key="turn.id">
                          dalle {{turn.start.slice(0,5)}}
                          alle {{turn.stop.slice(0,5)}}<span v-if="index < shift.timeTable.timeTablesTurns.length - 1">,</span>
                        </span><span v-if="index < shifts.filter(el => el.operatorId.toString() == operator.id.toString()).length - 1">,</span>
                      </span>
                    </div>
                  </v-col>
                  <v-col>
                    <v-chip label>
                      Ore Contrattuali: {{operator.contractualHours}}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <div
          v-else
          class="font-weight-light font-italic d-flex align-content-center"
          style="width: 100%"
        >
          <span style="margin: auto">Nessun operatore</span>
        </div>
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import operatorsService from "@/services/operators/operators.service.js";
import shiftService from "@/services/presences/shifts/shifts.service.js";

import OperatorAvatar from "@/components/common/OperatorAvatar.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";

export default {
  name: "Turns",
  components: {
    OperatorAvatar,
    FullScreenDialog
  },
  data: function() {
    let props = this.$router.resolve({name: 'Presence'});

    return {
      operators: [],
      shifts: [],
      operatorEditFormDialog: false,
      loadingOperators: false,
      loading: false,
      operatorFormBus: new Vue(),
      dialog: true,
      routeFather: props.href,
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue();
      }
    },
    searchText: {
      type: String
    }
  },
  mounted: function() {
    this.loadOperators();
    this.loadShifts();
  },
  methods: {
    searchMatch: function(operator) {
      if (
        operator == undefined ||
        operator == null ||
        this.searchText == undefined ||
        this.searchText == null ||
        this.searchText == ""
      )
        return true;
      else {
        return (
          operator.firstname
            .toLowerCase()
            .includes(this.searchText.toLowerCase()) ||
          operator.lastname
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        );
      }
    },
    loadOperators() {
      this.loadingOperators = true;
      operatorsService
        .cachedList()
        .then(results => {
          this.operators = results;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loadingOperators = false;
        });
    },
    openTurns(operator) {
      this.$router.push({
        name: "EditOperatorTurn",
        params: {
          operatorId: operator.id,
          operator: operator
        }
      });
    },
    loadShifts() {
      shiftService
        .list(1, 200, { start: new Date(), end: new Date() })
        .then(results => {
          this.shifts = results.rows;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  computed: {},
  watch: {}
};
</script>