import axios from "./axios"

class ImagesLoader {
  constructor() {
    this._blobs = {}
  }

  getImageFile(url) {
    return new Promise((resolve, reject) => {
      axios.getBlob(url).then((response) => {
        resolve(response)
      })
    })
  }

  getImageUrl(url) {
    return new Promise((resolve, reject) => {
      if(!!this._blobs[url]) {
        resolve(this._blobs[url])
      } else {
        this.getImageFile(url).then((response) => {
          const blobUrl = URL.createObjectURL(response)
          this._blobs[url] = blobUrl
          resolve(blobUrl)
        })
      }
    })
  }
}

export default new ImagesLoader()