import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import EventEmitter from "events";

class NewsService extends EventEmitter {
  constructor() {
    super()
  }

  async getNews(page = 1, rowPerPage, filters) {
    //const apiUrl = UrlKeeper.getUrl()
    const apiUrl = UrlKeeper.getUrl('strapi')

    let response = await axios.get(apiUrl + '/api/pubblications', {
      'populate': 'deep, 3',
      'sort[0]': 'publishedAt:desc',
      'pagination[page]': page,
      'pagination[pageSize]': rowPerPage,
      ...filters,
    })

    return response
  }

  async getCategories() {
    const apiUrl = UrlKeeper.getUrl('strapi')

    let response = await axios.get(apiUrl + '/api/categories', {
    })

    return response
  }

  async createReadNews(news) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/news/createReadNews', news).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  async getReadNews(ids) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/news/getReadNews', {
        ids: ids
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }
}

export default new NewsService();