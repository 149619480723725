<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="4"
        xl="4"
      >
        <v-text-field 
          v-model="internalCode" 
          filled 
          rounded
          disabled
          label="Codice Interno Costi"
          prepend-icon="mdi-code-tags" 
          hide-details="auto"
          @input="updateObject('internalCode', $event)"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="4"
        xl="4"
      >
        <v-text-field 
          v-model="progressive" 
          filled
          rounded
          disabled
          label="Progressivo"
          hide-details="auto"
          @input="updateObject('code', $event)"
        ></v-text-field>
      </v-col>
      <v-col 
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <v-text-field 
          v-model="documentNumber" 
          filled 
          rounded
          label="Numero documento"
          hide-details="auto" 
          @input="updateObject('documentNumber', $event)" 
          :rules="[presenceRule]" 
          :error="!documentNumber"
        ></v-text-field>
      </v-col>
      <v-col
        cols="10"
        sm="10"
        md="10"
        lg="10"
        xl="10"
      >
        <SuppliersAutocomplete 
          class="pr-5" 
          v-model="supplierSelected" 
          return-object
          :error="!supplierId" 
          @input="handleSupplierChange" 
          prepend-icon="mdi-domain"
          :auto-detect-new-supplier="true"
        ></SuppliersAutocomplete>
      </v-col>
      <v-col
        cols="2"
        sm="2"
        md="2"
        lg="2"
        xl="2"
      >
        <v-switch 
          color="red" 
          v-model="ghost" 
          :readonly="variant == 'new' ? false : true"
          @change="updateObject('ghost', $event)"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col 
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <v-textarea 
          v-model="description" 
          filled 
          rounded
          hide-details="auto" 
          label="Note / Descrizione Movimento"
          @input="updateObject('description', $event)" 
          :rules="[presenceRule]" 
          :error="!description" 
          rows="2"
        ></v-textarea>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <v-textarea 
          v-model="linkedDocuments" 
          filled 
          rounded
          hide-details="auto" 
          label="Documenti Collegati"
          @input="updateObject('linkedDocuments', $event)" 
          rows="2"
        ></v-textarea>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <DatePickerCommon 
          filled 
          rounded
          label="Data Emissione" 
          v-model="emittedAt" 
          :rules="[presenceRule]"
          :error="!emittedAt" 
          :disabled="variant == 'new' ? false : true"
          @input="updateObject('emittedAt', $event)"
        ></DatePickerCommon>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="3"
        lg="3"
        xl="3"
      >
        <DatePickerCommon 
          filled 
          rounded
          label="Data Scadenza" 
          v-model="expirationDate" 
          :rules="[presenceRule]"
          :error="!emittedAt" 
          :disabled="variant == 'new' ? false : true"
          @input="updateObject('expirationDate', $event)"
        ></DatePickerCommon>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="4"
        xl="4"
      >
        <OperatorsAutocomplete 
          v-model="operatorId" 
          label="Operatore" 
          :multiple="false"
          :rules="[presenceRule]" 
          :error="!operatorId" 
          @input="updateObject('operatorId', $event)"
        ></OperatorsAutocomplete>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        md="4"
        lg="4"
        xl="4"
      >
        <v-select 
          v-model="paymentTermId" 
          :items="paymentTermsItems" 
          item-value="id" 
          item-text="description"
          label="Modalità Di Pagamento" 
          filled 
          rounded
          dense
          :rules="[presenceRule]" 
          :error="!paymentTermId" 
          hide-details="auto" 
          inner-prepend-icon="mdi-contactless-payment"
          @change="updateObject('paymentTermId', $event)"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <v-select 
          v-model="paymentTypeNatureExemptionId" 
          :items="paymentTypeNatureExemptionItems" 
          item-value="id"
          item-text="description" 
          label="Tipo Natura Esenzione IVA"
          rounded
          filled
          dense
          hide-details="auto" 
          prepend-icon="mdi-contactless-payment"
          @change="updateObject('paymentTypeNatureExemptionId', $event)"
        ></v-select>
      </v-col>
    </v-row>
    <v-subheader class="font-italic font-weight-light my-3">Importi</v-subheader>
    <v-row class="mb-3">
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <TaxesListSelector 
          v-model="otherCostAmounts[0].vatPercentage" 
          :rules="[presenceRule]" 
          :input="updateAmounts(0)" 
          dense
          :readonly="variant == 'new' ? false : true"
        ></TaxesListSelector>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <v-text-field 
          v-model="otherCostAmounts[0].taxable" 
          filled 
          rounded
          dense
          label="Imponibile" 
          hide-details="auto"
          :rules="[greaterThan0]" 
          type="number"
          :input="updateAmounts(0)" 
          :readonly="variant == 'new' ? false : true"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <v-text-field 
          :value="otherCostAmounts[0].amount" 
          :rules="[greaterThan0]" 
          filled 
          rounded
          dense
          label="Importo"
          type="number" 
          hide-details="auto" 
          readonly 
        ></v-text-field>
      </v-col>
      <v-col 
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <v-text-field 
          :value="otherCostAmounts[0].vatAmount" 
          filled 
          rounded
          dense
          label="Importo Iva" 
          readonly
          hide-details="auto" 
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="8"
        lg="4"
        xl="4"
      >
        <TagsAutocomplete 
          :multiple="false" 
          v-model="otherCostAmounts[0].tags" 
          return-object
          @input="updateAmounts(0)"  
          :rules="[presenceRule]"  
          :error="!otherCostAmounts[0].tags" 
          :filters="{ typeCost: true }"
        ></TagsAutocomplete>
      </v-col>
    </v-row>

    <!-- Per più aliquote -->
    <v-row v-for="(n) in nAdditionalTaxes" :key="n">
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <TaxesListSelector 
          v-model="otherCostAmounts[n].vatPercentage" 
          :rules="[greaterThan0]" 
          :change="updateAmounts(n)" 
          dense
          :readonly="variant == 'new' ? false : true"
        ></TaxesListSelector>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <v-text-field 
          v-model="otherCostAmounts[n].taxable" 
          filled 
          rounded
          label="Imponibile" 
          hide-details="auto"
          :rules="[greaterThan0]" 
          type="number"
          :change="updateAmounts(n)" 
          :readonly="variant == 'new' ? false : true"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <v-text-field 
          :value="otherCostAmounts[n].amount" 
          :rules="[greaterThan0]" 
          filled
          rounded
          label="Importo"
          type="number" 
          hide-details="auto" 
          readonly
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        xl="2"
      >
        <v-text-field 
          :value="otherCostAmounts[n].vatAmount" 
          filled
          rounded
          label="Importo Iva" 
          readonly
          type="number" 
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="8"
        md="8"
        lg="4"
        xl="4"
      >
        <TagsAutocomplete 
          :multiple="false" 
          v-model="otherCostAmounts[n].tags" 
          return-object
          @input="updateAmounts(n)" 
          :rules="[presenceRule]" 
          :error="!otherCostAmounts[n].tags"
          :filters="{ typeCost: true }"
        ></TagsAutocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center mb-2">
        <v-btn color="primary" outlined class="px-10 mr-2" @click="addRow()" :disabled="variant == 'new' ? false : true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn color="error" outlined class="px-10 ml-2" @click="removeRow()" :disabled="nAdditionalTaxes < 1 || variant != 'new'">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import otherCostForm from "@/services/otherCosts/otherCosts.form.js";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import DatePickerCommon from "@/components/common/DatePickerCommon.vue";
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"

export default {
  name: "GeneralFormTab",
  components: {
    SuppliersAutocomplete,
    DatePickerCommon,
    TagsAutocomplete,
    OperatorsAutocomplete,
    TaxesListSelector
  },
  data: function () {
    let today = new Date();
    return {
      amount: 0,
      internalCode: 'ACN' + today.getFullYear() + '-00001',
      progressive: 1,
      documentNumber: undefined,
      supplierId: undefined,
      description: undefined,
      linkedDocuments: undefined,
      emittedAt: undefined,
      expirationDate: undefined,
      paymentTermId: undefined,
      paymentTypeNatureExemptionId: undefined,
      operatorId: undefined,
      ghost: false,

      otherCostAmounts: [{}],
      nAdditionalTaxes: 0,

      supplierSelected: undefined,
      paymentTermsItems: [],
      paymentTypeNatureExemptionItems: [],
      once: true,
      tagCreateDescription: [],

      formValid: false,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || v === 0 || "Il campo è obbligatorio";
      },
      greaterThan0: (v) => {
        return v > 0 || "Il valore deve essere maggiore di 0";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    variant: {
      type: String,
      default: "new",
    },
  },
  mounted: function () {
    this.handleObjectChanges(otherCostForm.otherCost);
    this.fetchPaymentTerms();

    var self = this;
    otherCostForm.on("update", function (data) {
      self.handleObjectChanges(data.otherCost);
    });

    this.tagCreateDescription.push(true)
  },
  methods: {
    fields() {
      return [
        'amount',
        'internalCode',
        'progressive',
        'documentNumber',
        'supplierId',
        'description',
        'linkedDocuments',
        'emittedAt',
        'expirationDate',
        'paymentTermId',
        'paymentTypeNatureExemptionId',
        'operatorId',
        'ghost',
        'otherCostAmounts'
      ]
    },
    handleObjectChanges(otherCost) {
      const fields = this.fields()
      const newValKeys = Object.keys(otherCost)

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field == 'supplierId' && otherCost[field] != this[field]) {
          this[field] = otherCost[field]
          this.supplierSelected = otherCost.supplier
        }
        else if ((field == 'emittedAt' || field == 'expirationDate') && otherCost[field] != this[field]) {
          this[field] = otherCost[field] ? new Date(otherCost[field]) : undefined
        }
        else if(field == 'otherCostAmounts' && otherCost[field] != this[field]) {
          if (!!otherCost[field]) {
            for (let i = 0; i < otherCost[field].length; i++) {
              if(!!otherCost[field][i].tags){
                for (let j = 0; j < otherCost[field][i].tags.length; j++) {
                  if (this.once) {
                    otherCost[field][i].tags[j].description = otherCost[field][i].tags[j].description + " - " + otherCost[field][i].tags[j].tagCategoryName
                    this.once = false
                  }
                } 
              }           
            }
            this.nAdditionalTaxes = otherCost[field].length - 1
            this[field] = otherCost[field]
          }
          else{
            this.nAdditionalTaxes = 0
            this[field] = [{}]
          }

        }
        else if (newValKeys.includes(field) && otherCost[field] != this[field]) {
          this[field] = otherCost[field]
        }
      }
    },
    updateObject(key, value) {
      if(key == 'emittedAt'){
        let today = new Date();
        let newDate = new Date(value);
        today.setDate(newDate.getDate());
        today.setMonth(newDate.getMonth());
        today.setFullYear(newDate.getFullYear());
        if(this.expirationDate != today)
          this.expirationDate = today;
        value = today;
      }

      otherCostForm.updateField(key, value);
    },
    handleSupplierChange(supplier) {
      this.supplierSelected = supplier;
      this.updateObject("supplierId", supplier.id);
    },
    fetchPaymentTerms() {
      new Promise(async (resolve, reject) => {
        let resultPaymentTermsItems = await paymentTermService.list();
        this.paymentTermsItems = [...resultPaymentTermsItems.rows];

        let resultPaymentTypeNatureExemptionItems = await paymentTypeNatureExemptionService.list();
        this.paymentTypeNatureExemptionItems = [...resultPaymentTypeNatureExemptionItems.rows];

        resolve();
      });
    },
    updateAmounts(index) {
      if(!!this.otherCostAmounts[index].tags && this.tagCreateDescription[index]) {
        this.otherCostAmounts[index].tags[0].description = this.otherCostAmounts[index].tags[0].description + " - " + this.otherCostAmounts[index].tags[0].categoryObject.name
        this.tagCreateDescription[index] = false
      }
      if (!!this.otherCostAmounts[index].taxable && this.otherCostAmounts[index].vatPercentage != undefined && this.otherCostAmounts[index].vatPercentage != null) {
        let percentage = Number(this.otherCostAmounts[index].vatPercentage)
        let taxable = Number(this.otherCostAmounts[index].taxable)

        let amount = (taxable + (taxable * percentage / 100)).toFixed(2)
        let vatAmount = (taxable * percentage / 100).toFixed(2)

        if(this.otherCostAmounts[index].amount == amount && this.otherCostAmounts[index].vatAmount == vatAmount)
          return

        this.otherCostAmounts[index].amount = amount
        this.otherCostAmounts[index].vatAmount = vatAmount

        this.updateObject('otherCostAmounts', this.otherCostAmounts)
      }
    },
    addRow(){
      this.nAdditionalTaxes++;
      this.tagCreateDescription.push(true)

      this.otherCostAmounts.push({})
    },
    removeRow(){
      this.otherCostAmounts.pop()
      this.tagCreateDescription.pop()
      
      this.updateObject('otherCostAmounts', this.otherCostAmounts)
      this.nAdditionalTaxes--;
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      otherCostForm.setValid(newVal);
    },
  },
};
</script>