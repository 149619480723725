<template>
  <v-select
    v-model="localValue"
    label="Aliquote IVA"
    :items="filteredTaxes"
    item-text="description"
    item-value="value"
    hide-details="auto"
    filled
    rounded
    deletable-chips
    :dense="dense"
    :chips="chips"
    :small-chips="smallChips"
    @input="$emit('input',$event); $emit('change',$event);"
    :rules="rules"
    :readonly="readonly"
  >
  </v-select>
</template>

<script>
import taxesService from '@/services/taxes/taxes.service.js'

export default {
  name: "TaxesListSelector",
  data: function() {
    return {
      loadingItems: false,
      localValue: Number(this.value),
      taxes: [],
    }
  },
  props: {
    value: {
      default: undefined,
    },
    rules:{
      type: Array,
      default: () => []
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.fetchTaxes()
  },
  methods: {
    fetchTaxes() {
      taxesService.list().then((results) => {
        this.taxes = results.rows
      })
    },
  },
  computed: {
    filteredTaxes() {
      return this.taxes
    },
  },
  watch:{
    value(newVal){
      if(newVal != undefined)
        this.localValue = Number(newVal)
    },
  }
}
</script>
<style scoped>
.select__selection {
    width: 100%;
    justify-content: center;
}
</style>