<template>
  <div>
    <div class="table pa-4">
      <v-row class="pb-2">
        <v-col cols="1" class="text-center text-subtitle-1 font-weight-bold">
          Tabella Riepilogo
        </v-col> 
        <v-col cols="4" class="text-center text-subtitle-1 font-weight-bold">
          Servizi
        </v-col> 
        <v-col cols="4" class="text-center text-subtitle-1 font-weight-bold">
          Prodotti 
        </v-col>
        <v-col cols="3" class="text-center text-subtitle-1 font-weight-bold">
          Totale 
        </v-col>
      </v-row>  
      <v-divider></v-divider> 
      <v-row class="pt-3">
        <v-col cols="1" class="pt-5 text-center text-subtitle-1 font-weight-bold">
          Ricavato
        </v-col> 
        <v-col cols="4">
          <v-text-field
            type="number"
            class="border-default"
            dense
            filled
            solo
            flat
            hide-details="auto"
            not-specified-text="0"
            disabled
            v-model="totServicesRevenue"
            prefix="€"
          >
          </v-text-field>
        </v-col> 
        <v-col cols="4">
          <v-text-field
            type="number"
            class="border-default"
            dense
            filled
            solo
            flat
            hide-details="auto"
            not-specified-text="0"
            disabled
            v-model="totItemsRevenue"
            prefix="€"
          >
          </v-text-field>
        </v-col> 
        <v-col cols="3" class="pb-0">
          <v-alert
            text
            color="#90BF5B"
            class="text-center text-subtitle-1"
            dense
          >
            <template v-slot:default>
              {{totRevenue + ' €'}}
            </template>
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        :style="{
          'overflow-y': 'auto',
          'max-height': '30dvh'
        }"
      >
        <v-col cols="1" class="pt-5 text-center text-subtitle-1 font-weight-bold">
          Scegli %
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="9">
              <ServiceTypeServicesSelect
                v-model="selectedServices[0]"
                flat
                dense
                label=""
                :multiple="true"
                :clearable="false"
              ></ServiceTypeServicesSelect>
            </v-col>
            <v-col cols="3">
              <v-text-field
                type="number"
                class="border-default"
                dense
                filled
                solo
                flat
                hide-details="auto"
                not-specified-text="0"
                v-model="multipleServicesPercentage[0]"
                prefix="%"
              >
              </v-text-field>
            </v-col> 
          </v-row>

          <v-row v-for="(n) in servicesPercentageRows" :key="n">
            <v-col cols="9">
              <ServiceTypeServicesSelect
                v-model="selectedServices[n]"
                flat
                dense
                label=""
                :multiple="true"
                :clearable="false"
                :elements-to-exclude="selectedServices"
              ></ServiceTypeServicesSelect>
            </v-col>
            <v-col cols="3">
              <v-text-field
                type="number"
                class="border-default"
                dense
                filled
                solo
                flat
                hide-details="auto"
                not-specified-text="0"
                v-model="multipleServicesPercentage[n]"
                prefix="%"
              >
              </v-text-field>
            </v-col> 
          </v-row>
          
          <v-row>
            <v-col class="d-flex justify-center mb-2">
              <v-btn 
                color="primary" 
                outlined 
                class="px-10 mr-2"
                @click="addRow()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn 
                color="error" 
                outlined 
                class="px-10 ml-2"
                :disabled="servicesPercentageRows == 0"
                @click="removeRow()"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <v-text-field
            type="number"
            class="border-default"
            dense
            filled
            solo
            flat
            hide-details="auto"
            not-specified-text="0"
            v-model="itemPercentage"
            @input="handleitemPercentageInput"
            prefix="%"
          >
          </v-text-field>
        </v-col>  
      </v-row>
      <v-row>
        <v-col cols="1" class="pt-5 text-center text-subtitle-1 font-weight-bold">
          TOT. Procacciato
        </v-col> 
        <v-col cols="4">
          <v-text-field
            type="number"
            class="border-default"
            dense
            filled
            solo
            flat
            hide-details="auto"
            not-specified-text="0"
            disabled
            v-model="servicesAmountToPay"
          >
          </v-text-field>
        </v-col> 
        <v-col cols="4">
          <v-text-field
            type="number"
            class="border-default"
            dense
            filled
            solo
            flat
            hide-details="auto"
            not-specified-text="0"
            disabled
            v-model="itemsAmountToPay"
          >
          </v-text-field>
        </v-col> 
        <v-col cols="3">
          <v-alert
            text
            color="#ED6A5A"
            class="text-center text-subtitle-1"
            dense
          >
            {{localTotToPayToOperator + ' €'}}
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <div class="mt-2 px-1 d-flex">
      <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="(localTotToPayToOperator == 0) || isLicenseBlocked"
          @click="pay"
          class="font-weight-medium text-subtitle-1"
        >
          Paga
        </v-btn>
      <v-spacer></v-spacer>
    </div>
  </div>
</template>

<script>
import ServiceTypeServicesSelect from '@/components/common/ServiceTypeServicesSelect.vue'

import customerService from '@/services/customers/customers.service.js'

export default {
  name: "IncentivePlan",
  components: {
    ServiceTypeServicesSelect
  },
  data: function () {
    return {
      totServicesRevenue: 0,
      totItemsRevenue: 0,
      itemPercentage: this.lastProductPercentage,
      servicesPercentageRows: [],
      multipleServicesPercentage: [],
      itemsAmountToPay: 0,
      servicesAmountToPay: 0,
      customersServices: [],
      selectedServices: [],
    };
  },
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    from: {
      type: Date,
    },
    to: {
      type: Date,
    },
    totToPayToOperator: {
      type: Number,
      default: 0
    },
    lastProductPercentage: {
      type: Number,
      default: 0
    },
    lastServicesWithPercentages: {
      type: Array,
      default: () => []
    },
    ignoredServices: {
      type: Array,
      default: () => []
    },
    ignoredItems: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    if(!!this.companyId && !!this.from && !!this.to)
      this.fetchValues()

    if(!!this.lastServicesWithPercentages && this.lastServicesWithPercentages.length > 0) {
      const groupedServicesByRowNumber = _.groupBy(this.lastServicesWithPercentages, 'rowNumber')
      Object.values(groupedServicesByRowNumber).forEach((el) => {
        this.selectedServices.push(el.map((el) => el.service))
        this.multipleServicesPercentage.push(el[0].percentage)
      })
      this.servicesPercentageRows = this.selectedServices.length - 1
    }
  },
  methods: {
    async fetchValues(){
      try {
        let results = await customerService.listPaymentsByIntermediaryCompanyId(this.companyId, this.from, this.to, true, {
          ignoredServices: this.ignoredServices,
          ignoredItems: this.ignoredItems
        })
        if(!!results) {
          this.totItemsRevenue = Number(results.totItems)
          this.totServicesRevenue = Number(results.totServices)
          const groupedCustomerServices = results.customersServices.reduce((result, item) => {
            const { serviceId, serviceTaxablePrice } = item;

            if (!result[serviceId]) {
              result[serviceId] = {
                serviceId,
                serviceTaxableTotal: 0,
              };
            }

            result[serviceId].serviceTaxableTotal += Number(serviceTaxablePrice);
            result[serviceId].serviceTaxableTotal = Number(result[serviceId].serviceTaxableTotal.toFixed(2));
            return result
          }, {});
          this.customersServices = Object.values(groupedCustomerServices)

          if (this.itemPercentage != undefined && this.totItemsRevenue != undefined)
            this.itemsAmountToPay = ((Number(this.itemPercentage)/100) * this.totItemsRevenue).toFixed(2)

          if (this.multipleServicesPercentage.length > 0 && this.selectedServices.length > 0) {
            this.calculateTotalServicesRevenue(this.selectedServices, this.multipleServicesPercentage)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchServiceTypes() {
      await serviceTypeService.list().then((response) => {
        this.serviceTypes = response.rows
      })
    },
    pay(){
      const servicesWithPercentages = this.selectedServices.map((services, index) => {
        if (services.length > 0 && this.multipleServicesPercentage[index] != undefined) {
          const serviceObjects = []
          services.forEach((el) => {
            serviceObjects.push({
              serviceId: el.id,
              percentage: Number(this.multipleServicesPercentage[index]),
              rowNumber: index
            })
          })
          return serviceObjects
        }
      }).filter((el) => el != undefined).flat()
      this.$emit('newPayment',{
        amount: Number(this.localTotToPayToOperator),
        companyId: Number(this.companyId),
        intermediaryProductPercentage: Number(this.itemPercentage),
        servicesWithPercentages: servicesWithPercentages,
      })
    },
    handleitemPercentageInput(value) {
      if(Number(value) > 100) {
        this.$nextTick(() => {
          this.itemPercentage = 100
        })
      }
    },
    addRow() {
      this.servicesPercentageRows++;

      this.multipleServicesPercentage.push(undefined),
      this.selectedServices.push([])
    },
    removeRow() {
      if(this.servicesPercentageRows > 0) {
        this.servicesPercentageRows--;

        this.multipleServicesPercentage.pop(),
        this.selectedServices.pop()
      }
    },
    calculateTotalServicesRevenue(selectedServices, multipleServicesPercentage) {
      this.servicesAmountToPay = 0
      selectedServices.forEach((el, index) => {
        if (el.length > 0 && multipleServicesPercentage[index] != undefined) {
          this.customersServices.forEach((service) => {
            if (el.find((el) => el.id == service.serviceId)) {
              this.servicesAmountToPay += (Number(multipleServicesPercentage[index])/100) * service.serviceTaxableTotal
            }
          })
        }
      })
      this.servicesAmountToPay = this.servicesAmountToPay.toFixed(2)
    }
  },
  watch: {
    itemPercentage(newVal){
      if(newVal != undefined && Number(newVal) >= 0)
        this.itemsAmountToPay = ((Number(newVal)/100) * this.totItemsRevenue).toFixed(2)
    },
    selectedServices(newVal) {
      if (this.multipleServicesPercentage.length == 0) {
        this.servicesAmountToPay = 0
        return
      }

      this.calculateTotalServicesRevenue(newVal, this.multipleServicesPercentage)
    },
    multipleServicesPercentage(newVal) {
      if (this.selectedServices.length == 0) {
        this.servicesAmountToPay = 0
        return
      }

      this.calculateTotalServicesRevenue(this.selectedServices, newVal)
    },
    companyId(newVal){
      if(!!newVal && !!this.from && !!this.to)
        this.fetchValues()
    },
    from(newVal){
      if(!!newVal && !!this.to && !!this.companyId) 
        this.fetchValues()
    },
    to(newVal){
      if(!!newVal && !!this.from && !!this.companyId)
        this.fetchValues()
    },
    lastProductPercentage(newVal){
      if(newVal != undefined && !!this.companyId)
        this.itemPercentage = this.lastProductPercentage
    },
    lastServicesWithPercentages(newVal) {
      if(newVal != undefined && !!this.companyId) {
        this.multipleServicesPercentage = []
        this.selectedServices = []
        this.servicesPercentageRows = 0
        
        if (newVal.length > 0) {
          const groupedServicesByRowNumber = _.groupBy(newVal, 'rowNumber')
          Object.values(groupedServicesByRowNumber).forEach((el) => {
            this.selectedServices.push(el.map((el) => el.service))
            this.multipleServicesPercentage.push(el[0].percentage)
          })
          this.servicesPercentageRows = this.selectedServices.length - 1
        }
      }
    }
  },
  computed: {
    totRevenue(){
      return (Number(this.totItemsRevenue) + Number(this.totServicesRevenue)).toFixed(2)
    },
    localTotToPayToOperator(){
      let value = (Number(this.itemsAmountToPay) + Number(this.servicesAmountToPay)).toFixed(2)
      this.$emit('totToPayToOperator:update', value)
      return value
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>

</style>



