import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import DateHandler from "@/services/common/dateHandler";

class PaymentCreditsService {

  create(paymentCredit) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!paymentCredit || !paymentCredit.amount) {
        reject(new Error("payment credit not specified"))
        return
      } else if (!paymentCredit.type) {
        reject(new Error("payment credit type not specified"))
        return
      }

      axios.post(apiUrl + '/payments/paymentCredits/create', paymentCredit).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/paymentCredits/list', {
        page, rowPerPage, filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(paymentCredit) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!paymentCredit || !paymentCredit.id) {
        reject(new Error('payment credit is not specified'))
        return
      }

      axios.get(apiUrl + '/payments/paymentCredits/get', paymentCredit).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  /* 
    paymentCredit: Object (id required),
    params: {
      amount: number (required),
      cashDesk: Object (id and name required),
      cashPaid: number,
      cardPaid: number,
      checkPaid: number,
      otherPaid: number,
      documentType: 'invoice' | 'receipt' | 'payLater',
      dataInvoice: Object (required invoice data)
    }
  */
  pay(paymentCredit, params) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      if (!paymentCredit || !paymentCredit.id) {
        reject(new Error('payment credit is not specified'))
        return
      } else if (!params || !params.amount) {
        reject(new Error('payment credit amount is not specified'))
        return
      }

      axios.post(apiUrl + '/payments/paymentCredits/pay', {
        paymentCredit, params
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  paySingleOrMultiCredit(paymentCredit, params) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      if (!paymentCredit) {
        reject(new Error('payment credit is not specified'))
        return
      } else if (!params || !params.amount) {
        reject(new Error('payment credit amount is not specified'))
        return
      } else if (!params || !params.cashDesk || !params.cashDesk.id || !params.cashDesk.name) {
        reject(new Error('cash desk is not specified'))
        return
      }

      axios.post(apiUrl + '/payments/paymentCredits/paySingleOrMultiCredit', {
        paymentCredit, params
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  fields() {
    return Promise.resolve([
      { text: 'Cliente', value: 'customer', type: 'custom' },
      { text: 'Ammontare Iniziale', value: 'amount', type: 'custom' },
      { text: 'Stato', value: 'status', type: 'custom' },
      { text: 'Data Inizio Debito', value: 'openedAt', type: 'date' },
      { text: 'Data Saldo Debito', value: 'closedAt', type: 'date' },
      { text: 'Operatore Cassa', value: 'operator', type: 'custom' },
      { text: 'Reparto', value: 'serviceTypesCategory', type: 'custom' },
    ])
  }

  _fieldsToExport() {
    return {
      "Cliente": "fullname",
      "Ammontare Iniziale": {
        field: "amount",
        callback: (value) => {
          return `${Number(value).toFixed(2).replace('.', ',')}`
        } 
      },
      "Stato": {
        field: "status",
        callback: (status) => {
          let statuses = [
            { key: "beginning", text: "Non pagato" },
            { key: "inProgress", text: "Pagato parzialmente" },
            { key: "finished", text: "Pagato totalmente" },
          ]

          return statuses.find(z => z.key == status).text
        },
      },
      "Data Inizio Debito": {
        field: "openedAt",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      "Data Saldo Debito": {
        field: "closedAt",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
    };
  }

}

export default new PaymentCreditsService()