<template>
  <div style="width: 100%">
    <div class="d-flex pa-5" style="width: 100%">
      <AdvancedFilter
        style="width: 99%"
        v-model="filtersValue"
        :filters="filterTypes"
        :advance-filters="advanceFilters"
        :external-filter="externalFilter"
        @apply-filters="applyFilters"
      >
        <template v-slot:chip-custom-name="{ filter }">
          Descrizione: {{ filter.value }}
        </template>

        <template v-slot:custom-supplierId="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <SuppliersAutocomplete
                v-model="filter.value"
                dense
              ></SuppliersAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-departmentId="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <DepartmentsAutocomplete
                v-model="filter.value"
                dense
                multiple
              ></DepartmentsAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-tag="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <TagsAutocomplete
                v-model="filter.value"
                dense
                :filled="false"
                multiple
              ></TagsAutocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-unitOfMeasureId="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <v-autocomplete
                v-model="filter.value"
                itemText="name"
                itemValue="id"
                :chips="true"
                :deletableChips="true"
                :smallChips="true"
                label="Unità Di Misura"
                :items="unitOfMeasureItems"
                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-packageTypeId="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <v-autocomplete
                v-model="filter.value"
                itemText="name"
                itemValue="id"
                :chips="true"
                :deletableChips="true"
                :smallChips="true"
                label="Confezione"
                :items="packageTypeItems"
                dense
                filled
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>

        <template v-slot:custom-barcode="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <v-text-field
                :outlined="false"
                v-model="filter.value"
                :label="filter.label"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:chip-custom-barcode="{ filter }">
          Barcode: {{ filter.value }}
        </template>

        <template v-slot:custom-tagCategory="{ filter }">
          <v-row class="pa-6">
            <v-col>
              <v-autocomplete
                v-model="filter.value"
                itemText="name"
                itemValue="id"
                :chips="true"
                :deletableChips="true"
                :smallChips="true"
                label="Categoria Tag"
                :items="tagsCategoriesItems"
                dense
                filled
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </template>
      </AdvancedFilter>
    </div>
    <div :style="{height: tableHeight}" ref="dataTableWrapper">
      <TypeDataTable
        style="height: 100%"
        item-key="id"
        fixed-header
        loading-text="Caricamento prodotti"
        v-model="localSelection"
        :headers="headers"
        :items="filteredItems"
        :loading="loading"
        :show-select="selectable"
        :show-actions="showActions"
        :single-select="false"
        :translator="translator"
        :height="dataTableHeight"
        :page.sync="page"
        :row-per-page.sync="rowPerPage"
        :total-pages.sync="totalPages"
        @dblclick:row="detailItemDoubleClick"
        @input="handleSelection"
      >
        <template v-slot:custom-qtyInStock="{ item }">
          <v-chip v-if="item['qtyInStock'] == 0" color="error">
            {{
              item["qtyInStock"]
                ? item["qtyInStock"].toLocaleString("it-IT")
                : 0
            }}</v-chip
          >

          <v-chip
            v-else-if="Number(item['qtyInStock']) < Number(item['qtyMinStock'])"
            color="orange"
          >
            {{
              item["qtyInStock"]
                ? Number(item["qtyInStock"]).toLocaleString("it-IT")
                : 0
            }}</v-chip
          >
          <v-chip v-else-if="item['qtyInStock'] > 0" color="success">
            {{ Number(item["qtyInStock"]).toLocaleString("it-IT") }}</v-chip
          >
        </template>
        <template v-slot:custom-tags="{ item }">
          <v-chip>
            {{ !!item.tags && !!item.tags[0] ? item.tags[0].description : "" }}
          </v-chip>
        </template>
        <template v-slot:actions="{ item }">
          <div style="min-width: 100px">
            <v-btn icon class="ml-1" @click="detailItem(item)">
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon class="ml-1" @click="deleteItem(item)">
              <v-icon> mdi-trash-can </v-icon>
            </v-btn>
          </div>
        </template>
      </TypeDataTable>
    </div>

    <StandardDialog
      v-if="openConfirmDialog"
      :value="openConfirmDialog"
      @close="openConfirmDialog = false"
      :dialog-width="showOrdersByItem ? '560' : '560'"
      :dialog-height="showOrdersByItem ? '560' : '250'"
      persistent
      title="Elimina"
    >
      <v-alert class="mt-5" color="red" dense outlined type="error">
        <div class="font-italic font-weight-light">
          Il prodotto {{ itemToDelete.code }} {{ errMessageDelete }}
        </div>
      </v-alert>
      <div v-if="showOrdersByItem">
        <TypeDataTable
          :single-select="false"
          :height="200"
          :show-select="false"
          item-key="id"
          fixed-header
          class="mt-10"
          :items="ordersbyItem"
          :headers="headersOrder"
          :show-actions="false"
          @input="handleOrderSelection"
          v-model="ordersToRemoveItem"
        >
          <template v-slot:custom-status="{ item }">
            <v-icon v-if="item.status == 'created'" color="red darken-3">
              mdi-package-up
            </v-icon>
            <v-icon
              v-else-if="item.status == 'waiting'"
              color="yellow darken-3"
            >
              mdi-package-up
            </v-icon>
            <v-icon
              color="green darken-3"
              v-else-if="item.status == 'fulfilled'"
            >
              mdi-package-up
            </v-icon>
          </template>
        </TypeDataTable>
      </div>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="openConfirmDialog = false"
          >Annulla</v-btn
        >
        <v-btn
          v-if="showOrdersByItem"
          text
          :disabled="showDeleteItem"
          @click="confirmedDelete"
          >Elimina</v-btn
        >
      </template>
      <StandardDialog
        v-model="showBarcode"
        :title="titleDelete"
        :dialog-height="null"
        dialog-max-width="500px"
        persistent
      >
        <ManualBarcodeInput
          v-model="barcodePermission"
          @confirm="deletePermission"
        ></ManualBarcodeInput>
      </StandardDialog>
    </StandardDialog>
  </div>
</template>

<script>
import itemService from "@/services/warehouse/items.service.js";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import DepartmentsAutocomplete from "@/components/common/DepartmentsAutocomplete.vue";
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js";
import unitOfMeasuresService from "@/services/unitOfMeasures/unitOfMeasures.service.js";
import packageTypesService from "@/services/packageTypes/packageTypes.service.js";
import orderService from "@/services/warehouse/orders.service.js";
import operatorService from "@/services/operators/operators.service.js";
import dateUtils from "@/mixins/common/dateUtils";
import Vue from 'vue'

export default {
  name: "ItemsTable",
  components: {
    AdvancedFilter,
    SuppliersAutocomplete,
    TypeDataTable,
    DepartmentsAutocomplete,
    TagsAutocomplete,
    StandardDialog,
    ManualBarcodeInput,
  },
  data: function () {
    return {
      showOrdersByItem: false,
      openConfirmDialog: false,
      dialog: true,
      loading: false,
      items: [],
      localSelection: [],
      itemToDelete: undefined,
      showDeleteItem: false,
      ordersbyItem: [],
      ordersToRemoveItem: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      pageOrder: 1,
      rowPerPageOrder: 50,
      totalPagesOrder: 50,
      errMessageDelete: "",
      orderFilters: {},
      headersOrder: [
        {
          text: "Barcode",
          value: "code",
        },
        {
          text: "Descrizione",
          value: "description",
        },
        {
          text: "Stato Ordine",
          value: "status",
          type: "custom",
        },
      ],
      dataTableHeightValue: 400,

      resizeListener: undefined,

      tagsCategoriesItems: undefined,
      unitOfMeasureItems: undefined,
      packageTypeItems: undefined,

      filterTypes: [
        {
          type: "date",
          operator: "between",
          field: "createdAt",
          name: "Data Creazione",
          label: ["Da", "A"],
          color: "",
          value: [undefined, undefined],
        },
        {
          type: "date",
          operator: "between",
          field: "lastPurchaseDate",
          name: "Data Ultimo Acquisto",
          label: ["Da", "A"],
          color: "",
          value: [undefined, undefined],
        },
        {
          type: "custom",
          operator: "equal",
          field: "supplierId",
          name: "Fornitore",
          label: "Fornitore",
          color: "",
          value: [],
          icon: "mdi-dolly",
        },
        {
          type: "custom",
          operator: "custom",
          field: "barcode",
          name: "Barcode",
          label: "Barcode",
          color: "",
          value: undefined,
          icon: "mdi-barcode",
        },
        {
          type: "cash",
          operator: "equal",
          field: "priceSelling",
          name: "Prezzo di vendita",
          label: "Prezzo di vendita",
          color: "",
          value: undefined,
        },
        {
          type: "cash",
          operator: "equal",
          field: "pricePurchase",
          name: "Prezzo d'acquisto",
          label: "Prezzo d'acquisto",
          color: "",
          value: undefined,
        },
        {
          type: "cash",
          operator: "equal",
          field: "priceNetPurchase",
          name: "Prezzo Netto d'acquisto",
          label: "Prezzo Netto d'acquisto",
          color: "",
          value: undefined,
        },
        {
          type: "custom",
          operator: "custom",
          field: "tag",
          name: "Tag",
          label: "Tag",
          color: "",
          value: undefined,
          icon: "mdi-tag",
        },
        {
          type: "custom",
          operator: "custom",
          field: "tagCategory",
          name: "Categoria Tag",
          label: "Categoria Tag",
          color: "",
          value: undefined,
          icon: "mdi-tag-multiple",
        },
      ],
      advanceFilters: [
        {
          type: "custom",
          operator: "custom",
          field: "departmentId",
          name: "Reparto",
          label: "Reparto",
          color: "",
          value: [],
          icon: "mdi-home-city",
        },
        {
          type: "number",
          operator: "equal",
          field: "discount",
          name: "Sconto",
          label: "Sconto",
          color: "",
          value: undefined,
          icon: "mdi-sale",
        },
        {
          type: "boolean",
          operator: "equal",
          field: "isInternal",
          name: "Uso Interno",
          label: "Uso Interno",
          color: "",
          value: false,
        },
        {
          type: "custom",
          operator: "equal",
          field: "packageTypeId",
          name: "Confezione",
          label: "Confezione",
          color: "",
          value: undefined,
          icon: "mdi-package-variant",
        },
        {
          type: "custom",
          operator: "equal",
          field: "unitOfMeasureId",
          name: "Unità di misura",
          label: "Unità di misura",
          color: "",
          value: undefined,
          icon: "mdi-ruler-square-compass",
        },
        {
          type: "number",
          operator: "equal",
          field: "qtyInStock",
          name: "Quantità Disponibile",
          label: "Quantità Disponibile",
          color: "",
          value: undefined,
        },
      ],
      externalFilter: {
        type: "custom",
        operator: "custom",
        field: "name",
        name: "Nome",
        label: "Prodotto",
        color: "",
        value: "",
      },
      filtersValue: [],
      barcodePermission: undefined,
      showBarcode: false,
    };
  },
  mixins: [dateUtils],
  props: {
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selection: {
      type: Array,
      default: function() {
        return []
      }
    },
    tableHeight: {
      type: String,
      default: undefined
    },
    headers: {
      type: Array,
      default: function() {
        return [
          {
            text: "Barcode",
            value: "code",
          },
          {
            text: "Codice Interno",
            value: "internalCode",
          },
          {
            text: "Descrizione",
            value: "description",
          },
          {
            text: "Quantità Disponibile",
            value: "qtyInStock",
            type: "custom",
          },
          {
            text: "Tag",
            value: "tags",
            type: "custom",
          },
          {
            text: "Fornitore",
            value: "supplier",
            type: "object",
            style: "chip",
          },
          {
            text: "Prezzo di Vendita",
            value: "priceSelling",
            type: "price"
          },
          {
            text: "Prezzo d'acquisto",
            value: "pricePurchase",
            type: "price"
          },
          {
            text: "Prezzo Netto d'acquisto",
            value: "priceNetPurchase",
            type: "price"
          },
          {
            text: "Data Ultimo Acquisto",
            value: "lastPurchaseDate",
            type: "date",
          },
          {
            text: "Data Creazione",
            value: "createdAt",
            type: "date",
          },
        ]
      }
    },
    selectable: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    showActions: {
      type: Boolean,
      default: true
    }
  },
  mounted: function () {
    this.filtersValue = this.filters
    this.fetchItems();

    setTimeout(() => {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
      this.resizeListener = function () {
        this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
      };
      this.resizeListener = this.resizeListener.bind(this);
      window.addEventListener("resize", this.resizeListener);
    }, 200)

    this.fetchTagCategories();
    this.fetchUnitOfMeasures();
    this.fetchPackageTypes();

    this.bus.$on('reload', this.reloadItems)
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    handleSelection(itemsSelected) {
      this.$emit("update:selection", itemsSelected);
    },
    fetchTagCategories() {
      return new Promise((resolve, reject) => {
        tagsCategoriesService
          .list(undefined, 2000, { typeCost: true })
          .then((results) => {
            this.tagsCategoriesItems = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchUnitOfMeasures() {
      return new Promise((resolve, reject) => {
        unitOfMeasuresService
          .list()
          .then((results) => {
            this.unitOfMeasureItems = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    fetchPackageTypes() {
      return new Promise((resolve, reject) => {
        packageTypesService
          .list()
          .then((results) => {
            this.packageTypeItems = results.rows;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    reloadItems() {
      this.loading = true;

      setTimeout(() => {
        this.fetchItems();
        this.loading = false;
      }, 200);
    },
    fetchItems() {
      this.loading = true;
      this.items = [];
      let filters = [...this.filtersValue];

      itemService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.items = results.rows;
        this.loading = false;
      });
    },
    detailItem(item) {
      this.$emit('item-detail', item)
    },
    detailItemDoubleClick(row, { item: item }) {
      this.$emit('item-double-clicked', item)
    },
    async fetchOrders(filters) {
      this.ordersbyItem = [];
      let results = await orderService.list(this.pageOrder, this.rowPerPageOrder, filters)

      if (this.rowPerPageOrder != results.rowPerPage) {
        this.rowPerPageOrder = results.rowPerPage;
      }

      if (this.pageOrder != results.page) {
        this.pageOrder = results.page;
      }

      this.totalPagesOrder = results.totalPages;
      if (this.totalPagesOrder < this.pageOrder) {
        this.pageOrder = this.totalPagesOrder;
      }
      this.ordersbyItem = results.rows;
      return results;
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.orderFilters = {};
      this.orderFilters.itemId = item.id;
      this.fetchOrders(this.orderFilters).then((orders) => {
        if (orders.rows.length == 0) {
          if (item.qtyInStock > 0) {
            this.openConfirmDialog = true;
            this.showOrdersByItem = false;
            this.showDeleteItem = true;
            this.errMessageDelete =
              "Non è presente in alcun ordine, prima portare la quantità a 0 in magazzino per eliminarlo";
            return;
          }
          this.openConfirmDialog = true;
          this.showOrdersByItem = true;
          this.showDeleteItem = false;
          this.errMessageDelete =
            "non presente in alcun ordine, vuoi eliminarlo?";
          return;
        }
        this.ordersbyItem = orders.rows;
        let orderCanBeDeleted = true;
        for (const order of this.ordersbyItem) {
          if (order.status == "waiting" || order.status == "created") {
            orderCanBeDeleted = false;
            break;
          }
        }
        if (orderCanBeDeleted) {
          if (this.ordersbyItem[0].items[0].qtyInStock > 0) {
            this.errMessageDelete =
              "E' presente solo in ordini evasi, prima portare la quantità a 0 in magazzino per eliminarlo";
            this.showDeleteItem = true;
          } else {
            this.showDeleteItem = false;
            this.errMessageDelete =
              "E' presente solo in ordini evasi, e ha quantità in magazino a 0, il prodotto può essere eliminato";
          }
          this.showOrdersByItem = true;
        } else {
          this.ordersbyItem = orders.rows.filter((order) => {
            return order.status != "fulfilled";
          });
          this.errMessageDelete =
            "E' presente in ordini in attesa o creati, attendere il completamento di tali ordini prima di eliminarlo";
          this.showDeleteItem = true;
          this.showOrdersByItem = true;
        }
        this.openConfirmDialog = true;
      });
    },
    handleOrderSelection(ordersSelected) {
      this.ordersToRemoveItem = ordersSelected;
    },
    deletePermission() {
      operatorService
        .canPerformOperation(this.barcodePermission, "Warehouse", "delete")
        .then((result) => {
          if (result) {
            itemService.deactive(this.itemToDelete).then((result) => {
              this.openConfirmDialog = false;
              this.reloadItems();
            });
          } else {
            alert("Non hai i permessi per eseguire questa azione!");
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Badge non riconosciuto");
        });
      this.showBarcode = false;
    },
    confirmedDelete() {
      this.barcodePermission = undefined;
      this.showBarcode = true;
    },
    translator(field, value) {
      if (field == "itemType" && value) {
        return value.name;
      } else if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    applyFilters() {
      this.$emit('update:filters', this.filtersValue)
      this.fetchItems();
    },
  },
  computed: {
    filteredItems() {
      return this.items;
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
    titleDelete() {
      if (this.itemToDelete) return "Elimina " + this.itemToDelete.code;
      else return "Elimina Prodotto";
    },
  },
  watch: {
    page() {
      this.fetchItems();
    },
    rowPerPage() {
      this.fetchItems();
    },
    pageOrder() {
      this.fetchOrders(this.orderFilters);
    },
    rowPerPageOrder() {
      this.fetchOrders(this.orderFilters);
    },
    filters() {
      this.filtersValue = this.filters
      this.fetchItems()
    },
  },
};
</script>

<style>
</style>