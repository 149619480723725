import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import translationService from '@/services/translations/translations.service'

class DataSheetsService {
  constructor() {

  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/dataSheets/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(customer, dataSheet) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!dataSheet || !customer) {
        reject(new Error('data sheet or customer is not defined'))
        return
      }

      axios.post(apiUrl + '/registries/customers/dataSheets/create', {
        customer: customer,
        dataSheet: dataSheet
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  update(dataSheet) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if(!dataSheet || !dataSheet.id) {
        reject(new Error('data sheet is not defined'))
        return
      }

      axios.post(apiUrl + '/registries/customers/dataSheets/update', dataSheet).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(dataSheet) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!dataSheet || !dataSheet.id) {
        reject(new Error('data sheet is not defined'))
        return
      }

      axios.get(apiUrl + '/registries/customers/dataSheets/delete', dataSheet).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  _typeValid(type) {
    return ['beutician', 'pmu', 'barber'].includes(type)
  }

  _translationPrefix() {
    return 'models.DataSheet.fields.'
  }

  _translationKey(fieldName) {
    return this._translationPrefix() + fieldName
  }

  _translateField(fieldName) {
    return translationService.translate(this._translationKey(fieldName))
  }

  _headersForType() {
    return {
      'common': [
        { text: this._translateField('operatorId'), value: 'operator', type: 'object' },
        { text: this._translateField('createdAt'), value: 'createdAt', type: 'datetime' },
      ],
      'beutician': [
        { text: this._translateField('skinTest'), value: 'skinTest' },
        { text: this._translateField('recommendedProducts'), value: 'recommendedProducts' },
        { text: this._translateField('faceTreatments'), value: 'faceTreatments' },
        { text: this._translateField('bodyTreatments'), value: 'bodyTreatments' },
        { text: this._translateField('epilation'), value: 'epilation' },
        { text: this._translateField('packages'), value: 'packages' },
        { text: this._translateField('other'), value: 'other' },
      ],
      'barber': [
        { text: this._translateField('saloonShampoo'), value: 'saloonShampoo' },
        { text: this._translateField('saloonTreatment'), value: 'saloonTreatment'},
        { text: this._translateField('homeAdvices'), value: 'homeAdvices'},
        { text: this._translateField('color'), value: 'color'},
        { text: this._translateField('balayage'), value: 'balayage'},
        { text: this._translateField('hairStyle'), value: 'hairStyle'},
        { text: this._translateField('hairCut'), value: 'hairCut'},
        { text: this._translateField('styling'), value: 'styling'},
        { text: this._translateField('permanent'), value: 'permanent'},
        { text: this._translateField('various'), value: 'various'},
        { text: this._translateField('notes'), value: 'notes' },
      ],
      'pmu': [
        { text: this._translateField('treatmentTypeId'), value: 'treatmentType', type: 'object' },
        { text: this._translateField('needleChoiceId'), value: 'needleChoice', type: 'object' },
        { text: this._translateField('number'), value: 'number' },
        { text: this._translateField('colorChoiceId'), value: 'colorChoice', type: 'object' },
        { text: this._translateField('pmuMethodId'), value: 'pmuMethod', type: 'object' },
        { text: this._translateField('additionalDescription'), value: 'additionalDescription' }
      ]
    }
  }

  headers(type) {
    const headersForType = this._headersForType()
    return Promise.resolve([
      ...headersForType['common'].map(el => ({...el, divider: true})),
      ...headersForType[type].map(el => ({ ...el, divider: true }))
    ])
  }
}

export default new DataSheetsService()