import authService from "@/services/auth/auth.service";
const unitOfMeasuresRoutes = [
  {
    path: "/registries/unitOfMeasures",
    name: "UnitOfMeasuresRegistryNewForm",
    component: () => import("@/components/areas/registries/unitOfMeasures/UnitOfMeasuresRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/unitOfMeasures/:id/edit",
    name: "UnitOfMeasuresRegistryEditForm",
    component: () => import("@/components/areas/registries/unitOfMeasures/UnitOfMeasuresRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/registries/unitOfMeasures/list",
    name: "UnitOfMeasuresRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/unitOfMeasures/UnitOfMeasuresRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default unitOfMeasuresRoutes;