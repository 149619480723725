import Presence from "@/views/PrivateSection/Presences.vue"
import Turns from "@/components/areas/presences/turns/Turns.vue"
import OperatorsTimeLogs from "@/components/areas/presences/presence/OperatorsTimeLogs.vue"
import Absences from "@/components/areas/presences/absences/Absences.vue"
import AbsenceTypeList from "@/components/areas/presences/absenceTypes/AbsenceTypeList.vue"
import minimumMinutesSettings from "@/components/setting/Presences.vue"
import AbsenceTypeEditForm from "@/components/areas/presences/absenceTypes/AbsenceTypeEditForm.vue"
import AbsenceTypeNewForm from "@/components/areas/presences/absenceTypes/AbsenceTypeNewForm.vue"
import EditOperatorTurn from "@/components/areas/presences/turns/EditOperatorTurn.vue"
import OperatorAbsences from "@/components/areas/presences/absences/OperatorAbsences.vue"
import TimeLogsList from "@/components/areas/presences/presence/TimeLogsList.vue"

import authService from "@/services/auth/auth.service.js"

const presencesRoutes = [
  {
    path: "/presence",
    name: "Presence",
    component: Presence,
    props: (route) => {
      return {
      }
    },
    beforeEnter: (to, from, next) => {
      if (from.path.includes("/presence")) {
        to.params.showBarcode = false
      } else {
        to.params.showBarcode = true
      }
      next()
    }
  },
  {
    path: "/presence/turns",
    name: "Turns",
    component: Turns,
    props: (route) => {
      return {
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/operatorsTimeLogs",
    name: "OperatorsTimeLogs",
    component: OperatorsTimeLogs,
    props: (route) => {
      return {
        bus: route.params.bus
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence") && !from.name === 'Dashboard') {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/absences",
    name: "Absences",
    component: Absences,
    props: (route) => {
      return {
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/absenceTypes/list",
    name: "AbsenceTypeList",
    component: AbsenceTypeList,
    props: (route) => {
      return {
        filters: route.params.filters
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/minimumMinutesSettings",
    name: "minimumMinutesSettings",
    component: minimumMinutesSettings,
    props: (route) => {
      return {
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/absenceTypes/:id/edit",
    name: "AbsenceTypeEditForm",
    component: AbsenceTypeEditForm,
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        filters: route.params.filters
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/absenceTypes",
    name: "AbsenceTypeNewForm",
    component: AbsenceTypeNewForm,
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()

      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/:operatorId/turns",
    name: "EditOperatorTurn",
    component: EditOperatorTurn,
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
        operator: !!route.params.operator ? route.params.operator : { id: route.params.operatorId }
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/:operatorId/absences",
    name: "OperatorAbsences",
    component: OperatorAbsences,
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
        operator: !!route.params.operator ? route.params.operator : { id: route.params.operatorId }
      }
    },
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
  {
    path: "/presence/:operatorId/timeLogs",
    name: "TimeLogsList",
    component: TimeLogsList,
    beforeEnter: (to, from, next) => {
      if (!from.path.includes("/presence")) {
        next("/presence")
      } else {
        next()
      }
    }
  },
];
export default presencesRoutes;