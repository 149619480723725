<template>
  <div>
    <v-autocomplete
      v-model="selectedInterests"
      :items="interests"
      item-text="name"
      :item-value="itemValue"
      :filled="filled"
      :rounded="rounded"
      :label="label"
      :hide-details="hideDetails"
      :placeholder="placeholder"
      :dense="dense"
      :multiple="multiple"
      :clearable="clearable"
      :loading="loading"
      :return-object="returnObject"
      :prepend-icon="prependIcon"
      :rules="rules"
      :disabled="disabled"
      :solo-inverted="soloInverted"
    >
      <template slot="append-outer">
        <slot name="append-outer"></slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import interestService from "@/services/interests/interests.service.js";
import Vue from 'vue'

export default {
  name: "InterestsAutocomplete",
  data: function() {
    let selection;
    if(!!this.value) {
      if(this.multiple && !this.returnObject) {
        selection = []
        for(let i = 0; i < this.value.length; i++) {
          selection.push(this.value[i])
        }
      } else if(!this.returnObject) {
        selection = this.value
      } else {
        selection = this.value
      }
    }

    return {
      interests: [],
      selectedInterests: selection,
      loading: false
    }
  },
  props: {
    value: {
      default: function() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: 'Tutti gli Interessi'
    },
    dense: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: true
    },
    filled: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    label: {
      type: String,
      default: 'Interessi'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: true
    },
    hideDetails: {
      default: 'auto'
    },
    prependIcon: {
      type: String,
      default: 'mdi-heart-outline'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    soloInverted: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  mounted: function() {
    this.loadInterests()
  },
  methods: {
    loadInterests() {
      this.loading = true
      interestService.cachedList(this.filters).then((interests) => {
        this.interests = interests
        this.loading = false
      })
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  },
  watch: {
    value(newVal) {
      this.selectedInterests = newVal
    },
    selectedInterests(newVal) {
      if(this.multiple && !this.arraysEqual(newVal, this.value)) {
        this.$emit('input', newVal)
      } else if(!this.multiple && newVal != this.value) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style>

</style>