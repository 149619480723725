import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class TagsService {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/tags/tagCategories/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(tagsCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/tags/tagCategories/create', tagsCategory).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(tagsCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    if (!tagsCategory || !tagsCategory.id) {
      reject(new Error('tagsCategory must be specified'))
      return
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/tags/tagCategories/update', tagsCategory).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(tagsCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!tagsCategory || !tagsCategory.id) {
        reject(new Error('tagsCategory must be specified'))
        return
      }

      axios.get(apiUrl + '/tags/tagCategories/get', { id: tagsCategory.id }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  delete(tagsCategory) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!tagsCategory || !tagsCategory.id) {
        reject(new Error('tagsCategory must be specified'))
        return
      }

      axios.get(apiUrl + '/tags/tagCategories/delete', { id: tagsCategory.id }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
}

export default new TagsService()