<template>
  <div>
    <TitleWithBackButton
      title="Minuti Minimi"
      back-route="Presence"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <div class="pt-5 px-4">
      <v-row align-content="center">
        <v-col class="align-center justify-center">
          <v-select
            filled
            :items="[0, 5, 10, 15, 30]"
            v-model="delayThresholdSetting"
            hide-details="auto"
            :menu-props="{offsetY: true}"
            :label="$translations.t('settings.delayThreshold.title')"
            @change="$settings.set('delayThreshold', $event)"
            rounded
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            filled
            :items="[0, 5, 10, 15, 30]"
            v-model="overtimeThresholdSetting"
            hide-details="auto"
            :menu-props="{offsetY: true}"
            :label="$translations.t('settings.overtimeThreshold.title')"
            @change="$settings.set('overtimeThreshold', $event)"
            rounded
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            filled
            :items="[0, 5, 10, 15, 30]"
            v-model="earlyExitThresholdSetting"
            hide-details="auto"
            :menu-props="{offsetY: true}"
            :label="$translations.t('settings.earlyExitThreshold.title')"
            @change="$settings.set('earlyExitThreshold', $event)"
            rounded
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  name: "Presences",
  components: {
    TitleWithBackButton
  },
  data: function() {
    return {
      delayThresholdSetting: undefined,
      overtimeThresholdSetting: undefined,
      earlyExitThresholdSetting: undefined
    }
  },
  settings: {
    delayThreshold: {
      bind: 'delayThresholdSetting'
    },
    overtimeThreshold: {
      bind: 'overtimeThresholdSetting'
    },
    earlyExitThreshold: {
      bind: 'earlyExitThresholdSetting'
    },
  }
}
</script>

<style>

</style>