import sjcl from "sjcl";

class Cookies {
  constructor() {}

  get(name) {
    let cookieKey = name + "=";
    let allCookies = document.cookie.split(";");
    for (let index = 0; index < allCookies.length; index++) {
      let element = allCookies[index];

      let initialSpaces = true;
      let i = 0;
      while (initialSpaces) {
        if (element[i] == " ") {
          element = element.substring(1, element.length);
        } else {
          initialSpaces = false;
        }
        i++;
      }

      if (element.indexOf(cookieKey) == 0) {
        let value = element.substring(cookieKey.length, element.length);
        value = sjcl.decrypt(process.env.VUE_APP_COOCKIES_SECRET, value);
        return value;
      }
    }
    return undefined;
  }

  set(name, value, daysUntilExpired) {
    let date = new Date();
    date.setTime(date.getTime() + daysUntilExpired * 24 * 60 * 60 * 1000);
    let expires = "expires=" + date.toUTCString();
    value = sjcl.encrypt(process.env.VUE_APP_COOCKIES_SECRET, String(value));

    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }

  deleteCookie(name) {
    this.set(name, null, 0);
  }
}
export default new Cookies();
