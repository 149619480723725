import snackBarerDelegate from './snackBarer.delegate'
import privilegesDelegate from './privileges.delegate'

export default {
  install: (Vue, options) => {
    Vue.prototype.$delegates = {
      snackbar: function (message, timeout) {
        snackBarerDelegate.call({ message, timeout })
      },
      /* 
        params: {
          timeout: Number
        }
      */
      privileges: async function(params) {
        if(!params) params = {}
        
        return (await privilegesDelegate.call({
          timeout: params.timeout
        }))[0]
      }
    }
  }
}