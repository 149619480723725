const invoicesRoutes = [
  {
    path: "/payments/closures",
    name: "Closures",
    component: () => import("@/components/areas/payments/closures/ClosureHome.vue"),
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
      }
    },
  },
];

export default invoicesRoutes;