import authService from "@/services/auth/auth.service";

const colorLinesRoutes = [
  {
    path: "/registries/colorLines",
    name: "ColorLinesRegistryNewForm",
    component: () => import("@/components/areas/registries/colorLines/ColorLinesRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/colorLines/:id/edit",
    name: "ColorLinesRegistryEditForm",
    component: () => import("@/components/areas/registries/colorLines/ColorLinesRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/registries/colorLines/list",
    name: "ColorLinesRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/colorLines/ColorLinesRegistryList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];

export default colorLinesRoutes;