<template>
  <div
    style="height: 100dvh;"
    id="customers-turnover"
  >
    <TitleWithBackButton
      title="Turnover Clienti"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 flex-wrap">
      <div
        style="width: 180px;"
      >
        <DatePicker
          v-model="startDate"
          filled
          dense
          hide-details="auto"
          label="Inizio"
          :show-time="false"
          :disabled="loading"
          date-type="month"
        ></DatePicker>
      </div>
      <div 
        class="ml-3"
        style="width: 180px;"
      >
        <DatePicker
          v-model="endDate"
          filled
          dense
          hide-details="auto"
          label="Fine"
          :show-time="false"
          :disabled="loading"
          :min="minEndDate"
          date-type="month"
          @input="($event) => {
            let localDate = new Date($event)
            this.endDate = new Date(localDate.getFullYear(), localDate.getMonth() + 1, 0)
          }"
        ></DatePicker>
      </div>
      <div 
        class="ml-7"
        :style="{
          'max-width': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
            ? '60vw' : '15vw',
          'margin-top': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm ?
            '10px' : '0px'
        }"
      >
        <TagsCategoriesAutocomplete 
          label="Scelta Tag" 
          v-model="selectedTagCategories" 
          :prepend-icon="null" 
          :filters="{ typeCost: false, excludeInBalance: false, 
            notGeneral: true, notBar: true }"
        >
        </TagsCategoriesAutocomplete>
      </div>
      <div
        class="d-flex align-center justify-center ml-3"
        style="gap: 10px"
      >
        <v-checkbox
          v-model="showOnlyNewCustomers"
          :disabled="loading"
          label="Mostra Solo Nuovi Clienti"
        ></v-checkbox>
      </div>
      <v-btn
        class="mr-2 ml-2"
        text
        :loading="loading"
        :disabled="!startDate || !endDate"
        @click="calculateTurnover"
      >Calcola</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-1 ml-2"
        text
        :loading="loading"
        @click="() => { showChart = !showChart }"
      ><v-icon> mdi-chart-bar </v-icon></v-btn>
      <v-btn
        class="mr-1 ml-2"
        text
        :loading="loading"
        :disabled="!startDate || !endDate"
        @click="() => { sendSmsDialog = true }"
      ><v-icon> mdi-message-text-fast-outline </v-icon></v-btn>
      <v-btn
        class="btn-export"
        :loading="loading"
        @click="exportToExcel"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        :loading="loading"
        :disabled="!startDate || !endDate"
        @click="print"
      ><v-icon> mdi-printer </v-icon></v-btn>
      <v-btn 
        icon 
        large 
        :disabled="loading" 
        style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!ghost ? 'red' : ''"
        >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>

    <div
      class="d-flex justify-center w-100 mt-5"
      style="height: calc(100% - 160px);"
    >
      <TypeDataTable
        v-model="customersSms"
        class="pb-5"
        fixed-headers
        style="width: 98%; height: calc(100% - 130px); background-color: transparent;"
        headersColor="rgb(224, 224, 230)"
        :headers="monthHeaders"
        :items="customerTurnoverResult"
        item-key="customerId"
        :show-select="true"
        :single-select="false"
        :show-actions="false"
        :select-on-row-click="false" 
        :loading="loading"
        :disable-pagination="true"
        row-height="35px"
      >
        <template v-for="header in monthHeaders" v-slot:[`custom-${header.value}`]="{ item }">
          <div
            :key="header.value"
            :style="{
              'height': '100%',
              'backgroundColor': header.value == 'customerFullname' ? 'transparent' :
                item[header.value] > 0 ? 'rgb(8, 165, 8)' : 'rgb(237, 35, 35)',
              'color': header.value == 'customerFullname' ? 'black': 'white',
              'display': 'flex',
              'justifyContent': header.value !== 'customerFullname' ? 'center': 'start',
              'alignItems': 'center',
              'width': header.value !== 'customerFullname' ? '120px' : '180px'
            }"
            @click="() => {
              if (header.value == 'customerFullname' || header.value == 'percentage' ||
                header.value == 'countTransactionsTotal') {
                  return
                }
              openDetailCustomer({ customerId: item.customerId, customerFullname: item.customerFullname, date: header.value, count: item[header.value] })
            }"
          >
            {{ item[header.value] }}
          </div>
        </template>

        <template v-slot:externalFooter>
          <TypeDataTable
            class="pb-5 mt-2"
            fixed-headers
            style="width: 100%; background-color: transparent;"
            headersColor="rgb(224, 224, 230)"
            :headers="totalMonthHeaders"
            :items="[totalCustomerTurnoverResult]"
            :show-select="true"
            :show-actions="false"
            :select-on-row-click="false"
            :loading="loading"
            disable-pagination
            row-height="35px"
          >
            <template v-for="header in totalMonthHeaders" v-slot:[`custom-${header.value}`]="{ item }">
              <div
                :key="header.value"
                :style="{
                  'height': '100%',
                  'backgroundColor': header.value == 'totalCustomers' || header.value == 'fillEnd' ?
                    'transparent' : item[header.value] > 0 ? 'rgb(8, 165, 8)' : 'rgb(237, 35, 35)',
                  'color': header.value == 'totalCustomers' || header.value == 'fillEnd' ?
                    'black': 'white',
                  'display': 'flex',
                  'justifyContent': header.value !== 'totalCustomers' ? 'center': 'start',
                  'alignItems': 'center',
                  'width': header.value !== 'totalCustomers' ? '120px' : '179px'
                }"
              >
                {{ item[header.value] }}
              </div>
            </template>
          </TypeDataTable>
        </template>
      </TypeDataTable>
    </div>

    <StandardDialog
      v-model="showDetailTurnover"
      :title="!!customerTurnoverResult ? 'Dettaglio Turnover ' + selectedCustomerFullname : 'Dettaglio Turnover'"
      dialog-max-width="50dvw"
      :dialog-height="null"
    >
      <TypeDataTable
        :headers="detailTurnoverHeaders"
        :items="customerTurnoverServices"
        :show-select="false"
        :single-select="false"
        :show-actions="false"
        :select-on-row-click="false"
        :loading="loadingDetail"
        disable-pagination
      ></TypeDataTable>
    </StandardDialog>

    <StandardDialog
      v-model="showChart"
      title="Turnover Clienti"
      dialog-max-width="50dvw"
      :dialog-height="null"
    >
      <v-row>
        <v-col cols="12">
          <div
            class="d-flex justify-end align-center mt-2"
          >
            <v-btn
              class="mx-2"
              text
              @click="printTurnoverChart"
              :loading="printLoading"
              :disabled="!customerTurnoverResult || customerTurnoverResult.length === 0"
            >
              <v-icon> mdi-printer </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div
            id="turnover-chart"
          >
            <GroupBasedChart
              no-data-text="Nessun dato da visualizzare"
              height="300px"
              width="100%"
              type="bar"
              transparent
              :point-colors="[]"
              :data="turnoverDatasetChart"
              :getYValue="(object) => {
                return object.y
              }"
              :getXValue="(object) => {
                return object.x
              }"
            ></GroupBasedChart>
          </div>
        </v-col>
      </v-row>
    </StandardDialog>

    <StandardDialog
      v-model="sendSmsDialog"
      :dialog-height="null"
      :title="customersSms ? 'Invia Messaggio a ' + customersSms.length : 'Invia Messaggio a circa ' + rowPerPage * totalPages  + ' clienti'"
      dialog-max-width="1000px"
    >
      <SendPromoSmsModel
        :with-list="true"
        :send-all="false"
        :customers="customersSms.map(c => c.customerId)"
        alert-text="Stai per fare un invio massivo di messaggi. Sei sicuro di volero fare?"
        @close="handleSmsClose"
      >
      </SendPromoSmsModel>
      <template v-slot:footer>
        <v-spacer></v-spacer>
          <v-btn 
            text 
            color="error" 
            @click="sendSmsDialog = false" 
          >Annulla</v-btn>
      </template>
    </StandardDialog>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import DatePicker from "@/components/common/DatePickerCommon.vue";
import DateTimePicker from "@/components/common/DateTimePicker.vue";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import TagsCategoriesAutocomplete from "@/components/common/TagsCategoriesAutocomplete.vue";
import SendPromoSmsModel from "@/components/areas/marketing/SendPromoSmsModel.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue';
import balanceService from '@/services/balance/balance.service.js';
import operatorService from '@/services/operators/operators.service.js';
import tagsCategoriesService from "@/services/tagsCategories/tagsCategories.service.js";
import html2canvas from '@/assets/js/html2canvas.min.js';
import * as XLSX from 'xlsx';


export default {
  name: "CustomersTurnover",
  components: {
    TitleWithBackButton,
    StandardDialog,
    ManualBarcodeInput,
    TagsCategoriesAutocomplete,
    DatePicker,
    DateTimePicker,
    GroupBasedChart,
    SendPromoSmsModel,
    TypeDataTable
  },
  data: function () {
    let startDate = new Date()
    startDate.setDate(1)
    let endDate = new Date()
    endDate.setMonth(endDate.getMonth() + 1)
    endDate.setDate(0)
    
    return {
      startDate: startDate,
      endDate: endDate,
      loading: false,
      loadingDetail: false,
      printLoading: false,
      showDetailTurnover: false,
      showChart: false,
      customerTurnoverServices: [],
      selectedCustomerFullname: undefined,
      turnoverDatasetChart: [],
      dialogBarcode: false,
      ghost: false,
      sendSmsDialog: false,
      customersSms: [],
      selectedTagCategories: [],
      showOnlyNewCustomers: false,
      operatorBarcode: undefined,
      customerTurnoverResult: undefined,
      totalCustomerTurnoverResult: {},
      monthHeaders: [
      ],
      totalMonthHeaders: [
      ],
      monthMappings: {
        1: 'Gen',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'Mag',
        6: 'Giu',
        7: 'Lug',
        8: 'Ago',
        9: 'Set',
        10: 'Ott',
        11: 'Nov',
        12: 'Dic'
      },
      detailTurnoverHeaders: [
        { text: 'Data', value: 'paymentTransactionDate' },
        { text: 'Servizio', value: 'serviceName' },
        { text: 'Operatore', value: 'operatorFullname' },
      ]
    }
  },
  async mounted() {
    let response = await tagsCategoriesService.list(1, 4000, { 'typeCost': false, excludeInBalance: false, 
      notGeneral: true, notBar: true})
    this.selectedTagCategories = response.rows.splice(0, 2).map(el => el.id)
    await this.calculateTurnover()
  },
  methods: {
    
    async openDetailCustomer({ customerId, customerFullname, date, count}) {
      this.selectedCustomerFullname = customerFullname
      this.showDetailTurnover = true
      this.customerTurnoverServices = []

      if (Number(count || 0) === 0 || !date) {
        return
      }

      let [month, year] = date.split('_')

      this.loadingDetail = true
      const resultDetailTurnover = await balanceService.detailTurnoverBalance({
        customerId: customerId,
        from: this.startDate,
        to: this.endDate,
        tagCategories: this.selectedTagCategories,
        ghost: this.ghost,
        month: month,
        year: year
      })

      if (resultDetailTurnover) {
        this.customerTurnoverServices = resultDetailTurnover.map((item) => {
          return {
            ...item,
            paymentTransactionDate: new Date(item.paymentTransactionDate).toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
          }
        })
      }
      this.loadingDetail = false
    },
    async calculateTurnover() {
      this.loading = true
      this.monthHeaders = []
      this.totalMonthHeaders = []
      const resultTurnover = await balanceService.turnoverBalance({
        from: this.startDate,
        to: this.endDate,
        tagCategories: this.selectedTagCategories,
        ghost: this.ghost,
        showInactiveCustomers: false,
        showOnlyNewCustomers: this.showOnlyNewCustomers
      })

      if (resultTurnover) {
        this.customerTurnoverResult = resultTurnover['customersData'].map((customerData) => {
          
          const numberOfMonthsWithTransaction = Object.values(customerData['months']).reduce((total, item) => {
            return total + (Number(item) > 0 ? 1 : 0)
          }, 0)
          const totalMonth = this.endDate.getMonth() - this.startDate.getMonth() +
            (12 * (this.endDate.getFullYear() - this.startDate.getFullYear()) + 1)

          const countTransactionTotal = Object.values(customerData['months']).reduce((total, item) => {
            return total + Number(item)
          }, 0)

          let objectToReturn = {
            ...customerData,
            ...customerData['months'],
            countTransactionsTotal: countTransactionTotal,
            percentage: Number(((numberOfMonthsWithTransaction / totalMonth) * 100).toFixed(2))
          }

          delete objectToReturn['months']

          return objectToReturn
        })


        this.monthHeaders.push({ text: 'Cliente', value: 'customerFullname', type: 'custom', width: 120 })

        this.totalMonthHeaders.push({ text: 'Totale Clienti', value: 'totalCustomers', type: 'custom', width: 110 })
        
        resultTurnover['months'].map((date) => {
          const [month, year] = date.split('_')
          
          this.monthHeaders.push({
            text: this.monthMappings[month] + ' ' + year,
            value: date,
            type: 'custom',
            width: 120
          })

          this.totalMonthHeaders.push({
            text: this.monthMappings[month] + ' ' + year,
            value: date,
            type: 'custom',
            width: 120
          })
        })
        this.monthHeaders.push({ text: 'Tot', value: 'countTransactionsTotal', type: 'custom', width: 120 })
        this.monthHeaders.push({ text: '%', value: 'percentage', type: 'custom', width: 120 })
        this.totalMonthHeaders.push({ text: 'Tot', value: 'countTransactionsTotal', type: 'custom', width: 120 })
        this.totalMonthHeaders.push({ text: 'Media %', value: 'avgPercentage', type: 'custom', width: 120 })

        for (let i = 0; i < resultTurnover['months'].length; i++) {
          this.totalCustomerTurnoverResult[resultTurnover['months'][i]] = this.customerTurnoverResult.reduce((total, item) => {
            return total + Number(item[resultTurnover['months'][i]] || 0)
          }, 0)
        }

        this.totalCustomerTurnoverResult.countTransactionsTotal = this.customerTurnoverResult.reduce((total, item) => {
          return total + Number(item.countTransactionsTotal || 0)
        }, 0)

        this.totalCustomerTurnoverResult.totalCustomers = this.customerTurnoverResult.length

        if (this.customerTurnoverResult && this.customerTurnoverResult.length > 0) {
          this.totalCustomerTurnoverResult.avgPercentage = Number((this.customerTurnoverResult.reduce((total, item) => {
            return total + Number(item.percentage || 0)
          }, 0) / Number(this.customerTurnoverResult.length || 1)).toFixed(2))
        } else {
          this.totalCustomerTurnoverResult.avgPercentage = 0
        }
      }

      let dataChart = []
      for (let i = 0; i < resultTurnover['months'].length; i++) {
        const [month, year] = resultTurnover['months'][i].split('_')
        dataChart.push({
          x: this.monthMappings[month] + ' ' + year,
          y: this.totalCustomerTurnoverResult[resultTurnover['months'][i]]
        })
      }

      this.turnoverDatasetChart = [{
        label: 'N° Transazioni',
        backgroundColor: '#8a77e2',
        data: dataChart
      }]
      this.loading = false
    },
    handleSmsClose() {
      this.sendSmsDialog = false
      this.customersSms = []
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.ghost = !this.ghost
          this.calculateTurnover()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    print() {
      let id = 'customers-turnover'

      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Turnover_Clienti_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) +
          '-' + this.startDate.getFullYear() + '_' + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) + 
          '-' + this.endDate.getFullYear() + ".png";
        a.href = canvas.toDataURL("image/jpeg");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    },
    printTurnoverChart() {
      let id = 'turnover-chart'

      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Grafico_Turnover_Clienti_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) +
          '-' + this.startDate.getFullYear() + '_' + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) + 
          '-' + this.endDate.getFullYear() + ".png";
        a.href = canvas.toDataURL("image/jpeg");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    },
    exportToExcel() {
      this.loading = true
      let workbook = XLSX.utils.book_new()
      let fileName = "Turnover_Clienti_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) +
        '-' + this.startDate.getFullYear() + '_' + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) + 
        '-' + this.endDate.getFullYear() + ".xlsx";

        let ws = XLSX.utils.aoa_to_sheet([[]])
        XLSX.utils.sheet_add_aoa(ws, [
          [
            ...this.monthHeaders.map((header) => header.text)
          ],
          ...this.customerTurnoverResult.map((item) => {
            return [
              item.customerFullname, ...this.monthHeaders.slice(1).map((header) => Number(item[header.value]))
            ]
          }),
          [],
          [
            ...this.totalMonthHeaders.map((header) => header.text)
          ],
          [
            this.totalCustomerTurnoverResult.totalCustomers,
            ...this.totalMonthHeaders.slice(1).map((header) => this.totalCustomerTurnoverResult[header.value])
          ]
        ])
        XLSX.utils.book_append_sheet(workbook, ws, "Turnover_Clienti")
        
        XLSX.writeFile(workbook, fileName, { type: 'file' })
        this.loading = false
    }
  },
  computed: {
    minEndDate() {
      let minEndDate = new Date(this.startDate)
      minEndDate.setMonth(minEndDate.getMonth())
      return minEndDate.toISOString()
    },
  },
}
</script>

<style>

</style>