<template>
  <div>
    <v-autocomplete
      v-model="selectedServiceTypes"
      :items="serviceTypes"
      item-text="name"
      item-value="id"
      filled
      :rules="rules"
      :hide-details="hideDetails"
      :label="label"
      :placeholder="placeholder"
      :dense="dense"
      :multiple="multiple"
      :clearable="clearable"
      :loading="loading"
      :return-object="returnObject"
      :color="color"
      :item-color="color"
      :height="height"
      :readonly="readonly"
    >
      <template slot="append-outer">
        <slot name="append-outer"></slot>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import serviceTypesCategoriesService from "@/services/serviceTypesCategories/serviceTypesCategories.service.js";

export default {
  name: "ServiceTypesAutocomplete",
  data: function() {
    let selection;
    if(this.multiple && !this.returnObject) {
      selection = []
      for(let i = 0; i < this.value.length; i++) {
        selection.push(this.value[i].toString())
      }
    } else if(!this.returnObject) {
      selection = this.value.toString()
    } else {
      selection = this.value
    }

    return {
      serviceTypes: [],
      selectedServiceTypes: selection,
      loading: false
    }
  },
  props: {
    value: {
      default: function() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: 'Tutti i tipi di servizio'
    },
    dense: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    rules: {
      type: Array,
      default: function() {
        return []
      }
    },
    hideDetails: {},
    height:{},
    isBar: {
      type: Boolean,
      default: undefined
    },
    label:{
      type: String,
      default: 'Categoria'
    },
    readonly:{
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    this.loadServiceTypes()
  },
  methods: {
    loadServiceTypes() {
      this.loading = true
      serviceTypesCategoriesService.list(undefined, undefined, {isBar: this.isBar}).then((serviceTypes) => {
        for(let i = 0; i < serviceTypes.rows.length; i++) {
          serviceTypes.rows[i]['id'] = serviceTypes.rows[i]['id'].toString()
        }
        this.serviceTypes = serviceTypes.rows
        this.loading = false
      })
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  },
  watch: {
    value(newVal) {
      if(this.multiple && !this.returnObject) {
        for(let i = 0; i < newVal.length; i++) {
          newVal[i] = newVal.toString()
        }
      } else if(!this.returnObject) {
        newVal = newVal.toString()
      }
      this.selectedServiceTypes = newVal
    },
    selectedServiceTypes(newVal) {
      if(this.multiple && !this.arraysEqual(newVal, this.value)) {
        this.$emit('input', newVal)
      } else if(!this.multiple && newVal != this.value) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style>

</style>