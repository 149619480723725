import Database from '@/services/offline/database.js'

class BillsCache extends Database {

  constructor() {
    super()
  }

  _storeName() {
    return 'bills'
  }

  _maxRows() {
    return 1000
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.bill - Bill to cache
   * @param {String} param.bill.id - Bill id
   */
  async cache({ bill }) {
    let storageKey = this._createStorageKey({
      bill
    })

    const db = await this.dbPromise

    const keys = await db.getAllKeys(this._storeName())
    if (keys.length > this._maxRows()) {
      db.delete(this._storeName(), keys.reverse()[0])
    }

    await db.put(this._storeName(), bill, storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.bill - Bill to get cache
   * @param {String} param.bill.id - Bill id
   * @param {Object[]} param.bills - Find multiple bills
   * @param {String} param.bills[].id - Bill id
   */
  async get({ bill, bills }) {
    if(!!bill) {
      let storageKey = this._createStorageKey({
        bill
      })

      const db = await this.dbPromise
      return await db.get(this._storeName(), storageKey)
    } else {
      let promises = []
      const db = await this.dbPromise

      for(let i = 0; i < bills.length; i += 1) {
        let storageKey = this._createStorageKey({
          bill: bills[i]
        })

        
        promises.push(db.get(this._storeName(), storageKey))
      }

      return await Promise.all(promises)
    }
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.bill - Bill to get cache
   * @param {String} param.bill.id - Bill id
   */
  async cachePresent({ bill }) {
    return !!(await this.get({ bill }))
  }

  async deleteAllCache() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.bill - Bill storage key 
   */
  _createStorageKey({ bill }) {
    return bill.id
  }
}

export default new BillsCache()