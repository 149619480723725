import authService from "@/services/auth/auth.service";
const invoicesToPayRoutes = [
  {
    path: "/payments/invoicesToPay/list",
    name: "InvoicesToPayList",
    component: () =>
      import(
        "@/components/areas/payments/invoicesToPay/InvoicesToPayList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  },
  {
    path: "/payments/invoicesToPay/:id/edit",
    name: "InvoicesToPayEditForm",
    component: () => import("@/components/areas/payments/invoicesToPay/InvoicesToPayEditForm.vue"),
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        openDialog: true,
        variant: route.params.variant,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/invoicesToPay/:id/editCreditNote",
    name: "InvoicesCreditNoteToPayEditForm",
    component: () => import("@/components/areas/payments/invoicesToPay/InvoicesCreditNoteToPayEditForm.vue"),
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        openDialog: true,
        variant: route.params.variant,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/invoicesToPay",
    name: "InvoicesToPayNewForm",
    component: () => import("@/components/areas/payments/invoicesToPay/InvoicesToPayNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/invoicesToPay/CreditNote",
    name: "InvoicesCreditNoteToPayNewForm",
    component: () => import("@/components/areas/payments/invoicesToPay/InvoicesCreditNoteToPayNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
];

export default invoicesToPayRoutes;