<template>
  <v-data-table
    v-model="localValue"
    :caption="caption"
    :headers="localHeaders"
    :items="items"
    :loading="loading"
    :item-key="itemKey"
    :loading-text="loadingText"
    :fixed-header="fixedHeader"
    :single-select="singleSelect"
    :disable-pagination="true"
    hide-default-footer
    :hide-default-header="hideDefaultHeader"
    :height="height"
    :width="width"
    :show-select="showSelect"
    :no-data-text="noDataText"
    @click:row="selectRow"
    @dblclick:row="handleDoubleClickRow"
    :item-class="handleCustomRowClass"
    :style="cssProps"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    :dense="dense"
    :disable-sort="disableSort"
    :show-order="showOrder"
    :expanded="localExpanded"
    :show-expand="showExpand"
    :single-expand="singleExpand"
    :search="search"
    :custom-filter="customFilter"
    @item-expanded="expandItem"
  >
    <template v-slot:top>
      <slot name="top"></slot>
    </template>
    <template
      v-for="(field, index) in typedFields"
      v-slot:[field.slotName]="{ item }"
    >
      <DateTimeFormattor
        v-if="field.type == 'date'"
        :format="dateFormat"
        v-bind:key="field.value"
        v-model="item[field.value]"
        not-specified-text="Non specificato"
      ></DateTimeFormattor>
      <DateTimeFormattor
        v-else-if="field.type == 'time'"
        :with-time="false"
        :only-time="true"
        :with-date="false"
        v-bind:key="field.value"
        v-model="item[field.value]"
        not-specified-text="Non specificato"
      ></DateTimeFormattor>
      <DateTimeFormattor
        v-else-if="field.type == 'datetime'"
        with-time
        with-date
        v-bind:key="field.value"
        v-model="item[field.value]"
        not-specified-text="Non specificato"
      ></DateTimeFormattor>
      <v-chip
        v-else-if="field.type == 'string[]'"
        v-for="element in item[field.value]"
        class="mr-1 mt-1"
        v-bind:key="element"
      >
        {{ translator(field.value, element, field.params) }}
      </v-chip>
      <v-chip
        v-else-if="field.type == 'object[]'"
        v-for="element in item[field.value]"
        class="mr-1"
        v-bind:key="element[Object.keys(element)[0]]"
      >
        {{ translator(field.value, element, field.params) }}
      </v-chip>
      <v-checkbox
        v-else-if="field.type == 'bool'"
        v-bind:key="field.value"
        hide-details="auto"
        class="my-0"
        readonly
        :input-value="item[field.value]"
        :ripple="false"
      ></v-checkbox>
      <span
        v-else-if="field.type == 'enum' || field.type == 'object'"
        v-bind:key="field.value"
      >
        <v-chip v-if="field.style == 'chip' && item[field.value]">
          {{ translator(field.value, item[field.value], field.params) }}
        </v-chip>
        <span v-else>
          {{ translator(field.value, item[field.value], field.params) }}
        </span>
      </span>
      <v-avatar
        v-else-if="field.type == 'color'"
        :key="field.value"
        :color="item[field.value]"
        :size="20"
      ></v-avatar>
      <v-icon v-else-if="field.type == 'icon'" :key="field.value" :color="field.color">
        {{ item[field.value] }}
      </v-icon>
      <span v-else-if="field.type == 'price' || field.type == 'cash'" :key="field.value">
        {{ !!item[field.value] ? Number(item[field.value]).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }} 
      </span>
      <span v-else-if="field.type == 'number'" :key="field.value">
        {{ !!item[field.value] ? Number(item[field.value]).toLocaleString('it-IT') : (0).toLocaleString('it-IT') }}
      </span>
      <span v-else-if="field.type == 'object.dotted'" :key="field.value">
        {{ extractValueWithDottedKey(field.value, item) }}
      </span>
      <span :style="{'color': item['rowColor']}" v-else-if="field.type == 'rowColor'" :key="field.value">
        {{ item[field.value]}}
      </span>
      <template v-else-if="field.type == 'custom'">
        <slot :name="'custom-' + field.value" v-bind="{ item, value: item[field.value]}"></slot>
      </template>
      <template v-else-if="field.type == 'custom-type'">
        <slot :name="'custom-' + field.customType" v-bind="{ item, value: item[field.value]}"></slot>
      </template>
      <template v-else-if="field.type == 'tooltip'">
        <v-tooltip bottom :key="field.value" :open-delay="tooltipDelay">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >{{ item[field.value].text }}</span>
          </template>
          <span>{{ item[field.value].tooltip }}</span>
        </v-tooltip>
      </template>
    </template>



    <template v-slot:no-data>
      <slot name="no-data">
        
      </slot>
    </template>

    <template v-slot:header.data-table-select v-if="!defaultDataTableHeaderSelect">
      <slot name="header.data-table-select">
      </slot>
    </template>

    <template v-slot:expanded-item="{ headers, item }" v-if="showExpand">
      <slot name="expanded-item" v-bind="{headers, item}">
      </slot>
    </template>

    <template v-slot:item.actions="{ item }" v-if="showActions">
      <slot name="actions" v-bind="{ item }">
        <div style="min-width: fit-content; display: flex;">
          <v-container v-if="showOrder" class="buttonOrderContainer" style="padding: 0; position: relative; width: fit-content; margin: 0;">
            <v-btn icon @click.stop="$emit('editRow', item)">
              <v-icon style="color: rgba(0, 0, 0, 0.8)" small> mdi-format-list-numbered </v-icon>
            </v-btn>
            <span class="showOnHover">Modifica ordine</span>
          </v-container>
          <div style="display: flex; justify-content: center;">
            <v-btn icon @click.stop="$emit('edit', item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon @click.stop="$emit('delete', item)" v-if="deletable">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </div>
          <slot name="extra-actions" v-bind="{ item }"></slot>
        </div>
      </slot>
    </template>
    <template v-slot:footer>
      <slot name="externalFooter">

      </slot>
      <v-divider v-if="!disablePagination"></v-divider>
      <div class="d-flex justify-end align-center my-1" v-if="!disablePagination">
        <div>Righe per pagina</div>
        <v-select
          style="max-width: 60px"
          class="mx-2"
          filled
          dense
          :disabled="disabledPagination || (disbalePaginationOnLoading && loading)"
          hide-details="auto"
          :items="rowPerPageOptions"
          :append-icon="null"
          :menu-props="{ offsetY: true }"
          v-model="localRowPerPage"
        ></v-select>
        <div class="mx-2">{{ localPage }} di {{ localTotalPages }}</div>
        <v-btn
          icon
          class="mx-1"
          :disabled="!canDecrementPage || disabledPagination || (disbalePaginationOnLoading && loading)"
          @click="decrementPage"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          icon
          class="mx-1"
          :disabled="!canIncrementPage || disabledPagination || (disbalePaginationOnLoading && loading)"
          @click="incrementPage"
        >
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import _ from "lodash";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";

export default {
  name: "TypeDataTable",
  components: {
    DateTimeFormattor,
  },
  data: function () {
    return {
      localValue: [],
      localRowPerPage: this.rowPerPage,
      localPage: this.page,
      localTotalPages: this.totalPages,
    };
  },
  props: {
    value: {},
    height: {
      default: "100%",
    },
    width: {
      default: "100%",
    },
    overflowY: {
      type: String,
      default: "auto",
    },
    tableWrapperMaxHeight: {
      type: String,
      default: "600px",
    },
    rowHeight: {
      type: String,
      default: "48px",
    },
    fontSizeRow: {
      type: String,
      default: "0.9rem",
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: true,
    },
    selectOnRowClick: {
      type: Boolean,
      default: true,
    },
    fixedHeader: {
      type: Boolean,
      default: true,
    },
    loadingText: {
      type: String,
      default: "Caricamento",
    },
    itemKey: {
      type: String,
      default: "id",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    showOrder: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    rowPerPage: {
      type: Number,
      default: 20,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    noDataText: {
      type: String,
      default: "Nessun dato disponibile",
    },
    disabledPagination: {
      type: Boolean,
      default: false
    },
    disbalePaginationOnLoading: {
      type: Boolean,
      default: true
    },
    defaultDataTableHeaderSelect: {
      type: Boolean,
      default: true
    },
    rowPerPageOptions: {
      type: Array,
      default:  function () {
        return [20,50,100,200];
      },
    },
    translator: {
      default: (fieldName, elementValue, params) => {
        return elementValue;
      },
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    caption: {
      type: String,
      default: undefined
    },
    dateFormat: {
      type: String,
      default: function () {
        return "dd/mm/yyyy";
      },
    },
    actionsWidth: {
      type: String,
      default: undefined,
    },
    headersColor: {
      type: String,
      default: 'white',
    },
    selectedColor: {
      type: String,
      default: '#8a77e220', 
    },
    hoverColor: {
      type: String,
      default: '#8a77e220',
    },
    sortBy: {
      type: String,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
      default: false,
    },
    tooltipDelay: {
      type: String | Number,
      default: 0,
    },
    hideDefaultHeader : {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Array,
      default: function() {
        return []
      }
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    singleExpand: {
      type: Boolean,
      default: false
    },
    customFilter: {
      type: Function,
      default: undefined
    },
    search: {
      type: String,
      default: undefined
    },
  },
  methods: {
    selectRow(item, { select, isSelected }) {
      this.$emit('rowClicked',item)
      if (this.selectOnRowClick) {
        if (!isSelected) {
          select(item);
        } else {
          this.localValue = this.localValue.filter(
            (el) => el[this.itemKey] != item[this.itemKey]
          );
        }
      }
    },
    expandItem(item) {
      this.$emit('expandItem', item)
    },
    incrementPage() {
      if (this.localPage >= this.totalPages) {
        return;
      }
      this.localPage += 1;
      this.$emit("update:page", this.localPage);
    },
    decrementPage() {
      if (this.localPage <= 1) {
        return;
      }
      this.localPage -= 1;
      this.$emit("update:page", this.localPage);
    },
    extractValueWithDottedKey(key, value) {
      return _.get(value, key);
    },
    handleDoubleClickRow(event, params) {
      this.$emit('dblclick:row', event, params)
    },
    handleCustomRowClass(event, params) {
      this.$emit('customRowClass', event, params)
    }
  },
  watch: {
    localValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      if (this.singleSelect && this.localValue !== newVal) {
        this.localValue = newVal;
      } else if (!_.isEqual(newVal, this.localValue)) {
        this.localValue = newVal;
      }
    },
    localRowPerPage(newVal) {
      this.$emit("update:rowPerPage", newVal);
    },
    page(newVal) {
      if (newVal != this.localPage) {
        this.localPage = newVal;
      }
    },
    rowPerPage(newVal) {
      if (newVal != this.localRowPerPage) {
        this.localRowPerPage = newVal;
      }
    },
    totalPages(newVal) {
      if (newVal != this.localTotalPages) {
        this.localTotalPages = newVal;
      }
    },
  },
  computed: {
    typedFields() {
      return _.filter(this.headers, function (header) {
        return header.type != undefined;
      }).map((header) => {
        return {
          ...header,
          slotName: "item." + header.value,
        };
      });
    },
    localExpanded() {
      return this.expanded
    },
    localHeaders() {
      let headersColored = this.headers.map((el) => {
        if(!!el.overrideClass){
          return el
        }

        el.class = ['headerColorTable', ...(el.class || [])]
        return el
      })
      if(this.showExpand)
        headersColored = [{ text: "", value: "data-table-expand", class: 'headerColorTable'}, ...headersColored] 
      if(!this.showActions)
        return headersColored
      
        
      return [...headersColored, { text: "", value: "actions", sortable: false ,width: this.actionsWidth, class: 'headerColorTable'}];
    },
    canDecrementPage() {
      return this.localPage > 1;
    },
    canIncrementPage() {
      return this.localPage < this.totalPages;
    },
    cssProps() {
      return {
        '--headers-color': this.headersColor,
        '--hover-color': this.hoverColor,
        '--selected-color': this.selectedColor,
        '--overflow-y': this.overflowY,
        '--table-wrapper-max-height': this.tableWrapperMaxHeight,
        '--row-height': this.rowHeight,
        '--font-size-row': this.fontSizeRow
      }
    }
  },
};
</script>

<style>
.headerColorTable {
  background-color: var(--headers-color, rgba(187, 179, 233, 1)) !important;
}

.v-data-table__wrapper > table > thead > tr > th {
  background-color: var(--headers-color, rgba(187, 179, 233, 1)) !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: var(--row-height, 48px) !important;
  font-size: var(--font-size-row, 0.9rem);
}

.v-data-table__wrapper > table > tbody > tr:hover {
  background: var(--hover-color) !important;
}

.v-data-table__wrapper {
  overflow-y: var(--overflow-y) !important;
  max-height: var(--table-wrapper-max-height);
}

.v-data-table__selected {
  background: var(--selected-color) !important;
}
.content-container {
  overflow: auto;
}

.showOnHover {
  position: absolute; 
  background-color: rgb(119, 119, 119);
  color: white;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  top: 100%; 
  left: calc(-60% - 10px);
  z-index: 100;
  display: none;
  width: max-content;
}

.buttonOrderContainer:hover .showOnHover {
  display: block;
}

</style>