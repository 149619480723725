import Database from '@/services/offline/database.js'
import billsCache from './bills.cache'
import hash from 'object-hash'

class TodayBillsCache extends Database {
  constructor() {
    super()
  }

  _storeName() {
    return 'today-bills'
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object[]} param.bills - Today bills
   * @param {Object} param.filters - filters that has been applied
   */
  async cache({ bills, filters }) {
    let storageKey = this._createStorageKey({ filters })
    const db = await this.dbPromise

    for(let i = 0; i < bills.length; i += 1) {
      await billsCache.cache({ bill: bills[i] })
    }

    const billIds = bills.map((el => el.id ))
    await db.put(this._storeName(), billIds, storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.filters - filters that has been applied
   */
  async get({ filters }) {
    let storageKey = this._createStorageKey({ filters })

    const db = await this.dbPromise
    const cachedBills = await db.get(this._storeName(), storageKey)
    if(!cachedBills) return undefined

    const bills = billsCache.get({
      bills: cachedBills.map((id) => ({ id: id }))
    })
    return bills
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.filters - filters that has been applied
   */
  async cachePresent({ filters }) {
    return !!(await this.get({ filters }))
  }

  async deleteAllCache() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {Object} param.filters - Filters that has cached
   */
  _createStorageKey({ filters }) {
    if(!!filters) {
      return 'bills:' + hash(filters)
    } else {
      return 'bills'
    }
  }
}

export default new TodayBillsCache()