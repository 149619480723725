<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card 
      min-height="200px"
      max-width="90vw"
      width="400px"
      class="flex-container"
    >
      <div class="body pa-2">
        <div class="text-center text-h6 mb-2">Inserisci le credenziali</div>
        <v-form v-model="formValid">
          <v-text-field
            filled
            dense
            label="Email"
            v-model="email"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            clearable
            dense
            filled
            :rules="[presenceRule]"
            :type="passwordVisible ? 'text' : 'password'"
            :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @keydown="handleKeyDown"
            @click:append="passwordVisible = !passwordVisible"
          ></v-text-field>
        </v-form>
      </div>
      <v-divider></v-divider>
      <div class="d-flex pa-1">
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="handleDialogClose"
        >Chiudi</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid"
          @click="handleLoginClick"
        >Login</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import privilegesDelegate from '@/delegates/privileges.delegate'
import authService from '@/services/auth/auth.service.js'
import Vue from 'vue'

export default {
  data: function() {
    return {
      dialog: false,
      bus: new Vue(),
      presenceRule: (v) => {
        return !!v || "Il campo è obbligatorio";
      },
      passwordVisible: false,
      password: undefined,
      email: undefined,
      formValid: false,
      loading: false
    }
  },
  mounted: function() {
    let handler = ({ }) => {
      this.dialog = true
      return this.bus
    }
    handler = handler.bind(this)
    privilegesDelegate.on('call', handler)
  },
  methods: {
    handleDialogClose() {
      this.dialog = false
    },
    handleLoginClick() {
      this.login()
    },
    handleKeyDown(event) {
      if(event.key == 'Enter') {
        this.login()
      }
    },
    login() {
      this.loading = true
      authService.updatePrivileges({
        email: this.email,
        password: this.password
      }).then(() => {
        this.loading = false
        this.dialog = false
        this.bus.$emit('submit')
      })
    }
  }
}
</script>


<style>
.flex-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.body {
  flex: 0 0 auto;
  flex-shrink: 1;
  flex-grow: 1
}
</style>