<template>
  <div>
    <TitleWithBackButton
      title="Analisi Statistica"
      back-route="Homepage"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <AnalyticsHome
      :style="{
        'pointer-events': canManageAnalytics ? 'all' : 'none',
        opacity: canManageAnalytics ? '1' : '0.2',
      }"
    ></AnalyticsHome>
    <ErrorDialogComponent
      v-if="showErrorDialog"
      :open-dialog="showErrorDialog"
      title="Utente non abilitato"
      :error-message="errorMessage"
      @close-error="goBack"
    >
    </ErrorDialogComponent>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import AnalyticsHome from '@/components/areas/analytics/AnalyticsHome.vue'
import ErrorDialogComponent from "@/components/common/errorComponent/ErrorDialogComponent.vue";

export default {
  name: "Analytics",
  components: {
    TitleWithBackButton,
    AnalyticsHome,
    ErrorDialogComponent
  },
  data: function () {
    return {
      showErrorDialog: false,
      errorMessage: "",
    };
  },  
  methods: {
    goBack() {
      this.$router.push({ name: "Homepage" });
    },
  },
  computed: {
    canManageAnalytics() {
      if (
        this.analitycsPermission.includes("view")
      )
        return true;
      else {
        this.showErrorDialog = true;
        this.errorMessage = "Accesso alle analitiche non autorizzato ";
        return false;
      }
    },
  },
  permissions: {
    Analytics: {
      bind: "analitycsPermission",
    },
  },
};
</script>

<style>
</style>