import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import EventsHandler from "@/services/common/eventsHandler.js"

class TranslationService extends EventsHandler {
  constructor() {
    super()

    this._translations = {}
    this.lang = 'it'
  }

  loadTranslations(lang) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!apiUrl) {
        reject(new Error('not-found'))
        return
      }
      
      axios.get(apiUrl + "/translations/all", {lang: lang}).then((response) => {
        if(response.success) {
          this._translations[lang] = response.results
          resolve()
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  languages() {
    return ['en', 'it']
  }

  loadLanguages() {
    return new Promise(async (resolve, reject) => {
      try {
        const langs = this.languages()
        for (let i = 0; i < langs.length; i++) {
          await this.loadTranslations(langs[i])
        }
        resolve()
      } catch(error) {
        reject(error)
      }
    })
  }

  translate(key) {
    return this._extractValueWithDottedKey(key, this._translations[this.lang])
  }

  setLocale(lang) {
    return new Promise((resolve, reject) => {
      if (!lang || !this.languages().includes(lang)) return
      else {
        this._executeAsyncCallbacksOf('locale-changed', lang).then(() => {
          this.lang = lang
          resolve()
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  _extractValueWithDottedKey(key, json) {
    if (!key) return
    else if (!json) return
    let cascadeKeys = key.split('.')
    let value = { ...json }

    for (let i = 0; i < cascadeKeys.length; i++) {
      if (!value) return
      value = value[cascadeKeys[i]]
    }
    return value
  }
}

export default new TranslationService()