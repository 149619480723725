import SmsModelEdit from "@/components/setting/sms/SmsModelEdit.vue"
import AutomationMarketing from "@/components/setting/automationMarketing/AutomationMarketing.vue"
import SmsSettings from "@/components/setting/sms/SmsSetting.vue"
import intermediaryList from "@/components/areas/marketing/intermediary/IntermediayList.vue"
import IntermediarySettings from "@/components/areas/marketing/intermediary/IntermediarySettings.vue"
import newsList from "@/components/areas/marketing/NewsList.vue"
import smsRegister from "@/components/areas/marketing/smsRegister.vue"
import SendMassiveSms from "@/components/areas/marketing/SendMassiveSms.vue"
import BookingVoucher from "@/components/areas/marketing/BookingVoucher.vue"
import CalendarMarketing from "@/components/areas/marketing/CalendarMarketing.vue"
import EventMarketingNewForm from "@/components/areas/marketing/events/EventMarketingNewForm.vue"
import EventMarketingEditForm from "@/components/areas/marketing/events/EventMarketingEditForm.vue"
import EventMarketingImportForm from "@/components/areas/marketing/events/EventMarketingImportForm.vue"
import ReceiptModelPresetList from "@/components/areas/marketing/actions/presets/ReceiptModelPresetList.vue"
import SmsModelPresetList from "@/components/areas/marketing/actions/presets/SmsModelPresetList.vue"

import authService from "@/services/auth/auth.service.js"

const marketingRoutes = [
  {
    path: "/marketing/smsModelEdit",
    name: "SmsModelEdit",
    component: SmsModelEdit,
  },
  {
    path: "/marketing/automationMarketing",
    name: "AutomationMarketing",
    component: AutomationMarketing,
  },
  {
    path: "/marketing/sms",
    name: "SmsSettings",
    component: SmsSettings,
  },
  {
    path: "/marketing/intermediaryList",
    name: "intermediaryList",
    component: intermediaryList,
    props: (route) => {
      return {
        verified: route.params.verified,
      }
    },
  },
  {
    path: "/marketing/intermediarySetting",
    name: "intermediarySetting",
    component: IntermediarySettings,
  },
  {
    path: "/marketing/newsList",
    name: "newsList",
    component: newsList,
  },
  {
    path: "/marketing/smsRegister",
    name: "smsRegister",
    component: smsRegister,
  },
  {
    path: "/marketing/sendMassiveSms",
    name: "sendMassiveSms",
    component: SendMassiveSms,
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/marketing/bookingVoucher",
    name: "bookingVoucher",
    component: BookingVoucher,
  },
  {
    path: "/marketing/calendar",
    name: "calendarMarketing",
    component: CalendarMarketing,
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  },
  {
    path: "/marketing/event/new",
    name: "EventMarketingNewForm",
    component: EventMarketingNewForm,
    props: (route) => {
      return {
        openDialog: true,
        startDate: route.params.startDate,
        endDate: route.params.endDate,
        pathName: route.query.pathName,
        pathToGoBack: route.query.pathToGoBack,
        type: route.params.type || 'new',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/marketing/event/:id/edit",
    name: "EventMarketingEditForm",
    component: EventMarketingEditForm,
    props: (route) => {
      return {
        openDialog: true,
        startDate: route.params.startDate,
        endDate: route.params.endDate,
        pathName: route.query.pathName,
        pathToGoBack: route.query.pathToGoBack,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/marketing/event/:id/import",
    name: "EventMarketingImportForm",
    component: EventMarketingImportForm,
    props: (route) => {
      return {
        openDialog: true,
        startDate: route.params.startDate,
        endDate: route.params.endDate,
        pathName: route.query.pathName,
        pathToGoBack: route.query.pathToGoBack,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/marketing/receiptModelPreset/list",
    name: "receiptModelPreset",
    component: ReceiptModelPresetList,
  },
  {
    path: "/marketing/smsModelPreset/list",
    name: "smsModelPreset",
    component: SmsModelPresetList,
  },
]
export default marketingRoutes;
