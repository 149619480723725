<template>
  <div>
    <div class="my-2">
      <v-text-field
        rounded
        filled
        dense
        placeholder="Cerca ..."
        prepend-inner-icon="mdi-search-web"
        v-model="searchText"
        style="width: 30vw;min-width: 500px;"
      ></v-text-field>
      <v-btn
        icon
        class="mr-2"
        @click="openNewDialog"
        v-if="creatable"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        icon
        class="mr-2"
        v-if="!hideFilter"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
      <v-btn
        icon
        class="mr-2"
        :loading="listLoading"
        v-if="!hideLoading"
        @click="fetchItems"
      >
        <v-icon>mdi-refresh</v-icon>
        <template v-slot:loader>
          <v-progress-circular
            indeterminate
            color="grey"
            size="20"
            width="2"
            class="ml-2"
          ></v-progress-circular>
        </template>
      </v-btn>
    </div>
    <div v-if="!dataPresent" class="text-body-2 font-weight-light">
      {{noDateMessage}}
    </div>
    <ResponsiveCardSelector
      v-else
      :items="filteredItems"
      v-model="selectedItem"
      :item-key="itemKey"
      :card-max-height="cardMaxHeight"
      :card-min-height="cardMinHeight"
      :card-max-width="cardMaxWidth"
      :card-min-width="cardMinWidth"
      @input="handleResponsiveCardSelectorInput"
      @click="openEditDialog"
      :shaped="true"
      :outlined="true"
      :colors="['#6FB5CC', '#97A1C5', '#D3ADC9', '#EDD7E7', '#F0E3C5']"
    >
      <template v-slot:item="binding">
        <slot name="item" v-bind="{...binding, delete: deleteItem, reload: fetchItems, deletingItem: deleteLoadingItem}">
          <v-btn
            v-if="deletable"
            icon
            style="position: absolute; right: 0.5px; top: 0.5px"
            @click.stop.prevent="deleteItem(binding.item)"
            :loading="!!deleteLoadingItem && deleteLoadingItem[itemKey] == binding.item[itemKey]"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div 
            class="pa-2 d-flex flex-column justify-center align-center" 
            :style="{
              height: binding.cardMinHeight + 'px'
            }"
          >
            <div class="text-subtitle-1 font-weight-bold text-center">{{ getNameFromItem(binding.item) }}</div>
            <v-divider inset></v-divider>
            <div class="text-caption font-weight-light text-center">{{ getDescriptionFromItem(binding.item) }}</div>
          </div>
        </slot>
      </template>
    </ResponsiveCardSelector>

    <StandardDialog
      v-model="localNewDialog"
      @input="$emit('update:newDialog', $event)"
      :dialog-height="null"
      :dialog-max-width="newDialogMaxWidth"
      :title="newDialogTitle"
      :persistent="true"
    >
      <slot name="new-form" v-bind="{ create, setField: setNewField, item: localNewItem, valid: localNewValid }">
        <div class="mt-2">
          <v-text-field
            v-model="localNewItem.name"
            label="Nome"
            hide-details="auto"
            filled
            dense
          ></v-text-field>
        </div>
      </slot>
      <template v-slot:footer-actions="{}">
        <slot name="new-footer-actions" v-bind="{ create, close: closeNewDialog }">
          <v-btn
            color="error"
            text
            :disabled="createLoading"
            @click="closeNewDialog"
          >Chiudi</v-btn>
          <v-btn
            color="default"
            text
            :disabled="!localNewValid"
            :loading="createLoading"
            @click="create(localNewItem)"
          >Salva</v-btn>
        </slot>
      </template>
    </StandardDialog>

    <StandardDialog
      v-model="localEditDialog"
      @input="$emit('update:editDialog', $event)"
      :dialog-height="null"
      :dialog-max-width="editDialogMaxWidth"
      :title="editDialogTitle"
      :persistent="true"
    >
      <slot name="update-form" v-bind="{ update, setField: setEditField, item: localEditItem, valid: localEditValid }">
        <div class="mt-2">
          <v-text-field
            v-model="localEditItem.name"
            label="Nome"
            hide-details="auto"
            filled
            dense
          ></v-text-field>
        </div>
      </slot>

      <template v-slot:footer-actions="{}">
        <slot name="new-footer-actions" v-bind="{ update, close: closeEditDialog }">
          <v-btn
            color="error"
            text
            :disabled="updateLoading"
            @click="closeEditDialog"
          >Chiudi</v-btn>
          <v-btn
            color="default"
            text
            :disabled="!localEditValid"
            :loading="updateLoading"
            @click="update(localEditItem)"
          >Salva</v-btn>
        </slot>
      </template>
    </StandardDialog>

    <v-snackbar
      v-model="errorSnackbar"
      vertical
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="secondary"
          text
          v-bind="attrs"
          @click="contactUs"
        >
          Contattaci
        </v-btn>
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          Chiudi
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from 'vue'

import ResponsiveCardSelector from './ResponsiveCardSelector.vue'
import StandardDialog from './StandardDialog.vue'

export default {
  name: "crudCardList",
  components: {
    ResponsiveCardSelector,
    StandardDialog
  },
  data: function() {
    return {
      items: [],
      selectedItem: undefined,
      localEditValid: this.editValid,
      localNewValid: this.newValid,
      localNewDialog: this.newDialog,
      localEditDialog: this.editDialog,
      localNewItem: {...this.newItem},
      localEditItem: {...this.editItem},
      errorSnackbar: false,
      errorMessage: "",
      searchText: undefined,

      updateLoading: false,
      createLoading: false,
      listLoading: false,
      deleteLoadingItem: undefined,
    }
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    name: {
      type: String,
    },
    service: {
      type: Object,
      required: true,
      validator: (value) => {
        if(!value.list) {
          console.error("Service must have a list method");
          return false
        } else if(!value.create) {
          console.error("Service must have a create method");
          return false
        } else if(!value.update) {
          console.error("Service must have an update method");
          return false
        } else return true
      }
    },
    itemKey: {
      type: String,
      default: 'id',
      validator: (value) => {
        return value === undefined || value === null || value === '' ? 'itemKey must be defined' : true
      }
    },
    itemName: {
      type: String | Function,
      default: 'name',
    },
    itemDescription: {
      type: String | Function,
      default: undefined,
    },
    cardMaxHeight: {
      type: String | Number,
      default: undefined
    },
    cardMinHeight: {
      type: String | Number,
      default: "158"
    },
    cardMaxWidth: {
      type: String | Number,
      default: undefined
    },
    cardMinWidth: {
      type: String | Number,
      default: "158"
    },
    newDialog: {
      type: Boolean,
      default: false
    },
    editDialog: {
      type: Boolean,
      default: false
    },
    creatable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      default: true
    },
    confirmBeforeDelete: {
      type: Boolean,
      default: true
    },
    editItem: {
      type: Object,
      default: function() {
        return {}
      }
    },
    newItem: {
      type: Object,
      default: function() {
        return {}
      }
    },
    newDialogMaxWidth: {
      type: String | Number,
      default: "700"
    },
    editDialogMaxWidth: {
      type: String | Number,
      default: "700"
    },
    newValid: {
      type: Boolean,
      default: true
    },
    editValid: {
      type: Boolean,
      default: true
    },
    noDateMessage: {
      type: String,
      default: "Nessun dato da mostrare"
    },
    hideFilter: {
      type: Boolean,
      default: false,
    },
    hideLoading: {
      type: Boolean,
      default: false,
    },
    itemType: {
      type: String,
      default: ""
    }
  },
  mounted: function() {
    if(this.deletable && !this.service.delete) {
      throw new Error('delete funcion must be defined')
    }

    if(!!this.bus) 
      this.bus.$on('reload', this.fetchItems)

    this.fetchItems()
  },
  methods: {
    async fetchItems() {
      this.listLoading = true
      let items, promise = this.service.list()
      if(promise instanceof Promise) {
        items = await promise
      } else {
        items = promise
      }

      this.items = [...items]
      this.listLoading = false
    },
    handleResponsiveCardSelectorInput() {
      this.selectedItem = []
    },
    getNameFromItem(item) {
      if(typeof this.itemName == "function") {
        return this.itemName(item)
      } else {
        return item[this.itemName]
      }
    },
    getDescriptionFromItem(item) {
      if(!this.itemDescription) {
        return ''
      } else if(typeof this.itemDescription == "function") {
        return this.itemDescription(item)
      } else {
        return item[this.itemDescription]
      }
    },
    openNewDialog() {
      this.localNewItem = {}
      this.localNewDialog = true
      this.$emit('update:newDialog', this.localNewDialog)
      this.$emit('update:newItem', this.localNewItem)
    },
    closeNewDialog() {
      this.localNewDialog = false
      this.$emit('update:newDialog', this.localNewDialog)
    },
    openEditDialog(item) {
      if(!this.editable) return
      this.localEditItem = {...item}
      this.localEditDialog = true
      this.$emit('update:editDialog', this.localEditDialog)
      this.$emit('update:editItem', this.localEditItem)
    },
    closeEditDialog() {
      this.localEditItem = {}
      this.localEditDialog = false
      this.$emit('update:editDialog', this.localEditDialog)
      this.$emit('update:editItem', this.localEditItem)
    },
    setNewField(key, value) {
      if(key === null || key === undefined || key === '')
        throw new Error('key must be specified')
      this.localNewItem[key] = value
      this.$emit('update:newItem', {...this.localNewItem})
    },
    setEditField(key, value) {
      if(key === null || key === undefined || key === '')
        throw new Error('key must be specified')
      this.localEditItem[key] = value
      this.$emit('update:editItem', {...this.localEditItem})
    },
    async create(...args) {
      this.createLoading = true
      try {
        let promise = this.service.create(...args)
        if(promise instanceof Promise) {
          await promise
        }
      } catch(error) {
        this.snackbar("Siamo dispiacenti, qualcosa è andato storto")
        return
      }
      this.createLoading = false

      if(!!this.bus)
        this.bus.$emit('reload')
      else
        this.fetchItems()
      this.closeNewDialog()
    },
    async update(...args) {
      this.updateLoading = true
      try {
        let promise = this.service.update(...args)
        if(promise instanceof Promise) {
          await promise
        }
      } catch(error) {
        this.snackbar("Siamo dispiacenti, qualcosa è andato storto")
        return
      }
      this.updateLoading = false

      if(!!this.bus)
        this.bus.$emit('reload')
      else
        this.fetchItems()
      this.closeEditDialog()
    },
    async deleteItem(...args) {
      let confirmed = true
      if(this.confirmBeforeDelete) {
        confirmed = confirm("Eliminare l'elemento?")
      }
      
      if(confirmed) {
        if (this.itemType == 'tags' && args[0].tagEntities.length > 0) {
          this.snackbar("Il tag ha entità associate, impossibile eliminarlo")
        }
        else if (this.itemType == 'tagCategories' && args[0].tags.length > 0) {
          this.snackbar("La categoria ha tag associati, impossibile eliminarla")
        }
        else {
          this.deleteLoadingItem = args[0]
          try {
            let promise = this.service.delete(...args)
            if(promise instanceof Promise) {
              await promise
            }
          } catch(error) {
            this.snackbar("Siamo dispiacenti, qualcosa è andato storto")
            return
          }
    
          if(!!this.bus)
            this.bus.$emit('reload')
          else
            this.fetchItems()
          this.deleteLoadingItem = undefined
        }
      }
    },
    snackbar(message) {
      this.errorMessage = message
      this.errorSnackbar = true
    },
    contactUs(){
      let clickUpLink = "https://forms.clickup.com/f/hffyw-4428/1M1PSFG5AOV8DUQCUG"
      window.open(clickUpLink, '_blank').focus()
      this.errorSnackbar = false
    }
  },
  computed: {
    newDialogTitle() {
      return !!this.name ? "Nuovo " + this.name : "Nuovo"
    },
    editDialogTitle() {
      return 'Modifica'
    },
    dataPresent() {
      return !!this.items && Array.isArray(this.items) && this.items.length > 0
    },
    filteredItems() {
      return this.items.filter((el) => {
        let itemName = this.getNameFromItem(el)
        if(!!itemName) itemName = itemName.toLowerCase()

        let itemDescription = this.getDescriptionFromItem(el)
        if(!!itemDescription) itemDescription = itemDescription.toLowerCase()

        let searchText = this.searchText
        if(!!searchText) searchText = searchText.toLowerCase() 
        return !this.searchText || (!!itemName && itemName.includes(searchText) || (!!itemDescription && itemDescription.includes(searchText)))
      })
    }
  },
  watch: {
    newDialog(newVal) {
      this.localNewDialog = newVal
    },
    newItem(newVal) {
      this.localNewItem = {...newVal}
    },
    editItem(newVal) {
      this.localEditItem = {...newVal}
    },
    newValid(newVal) {
      this.localNewValid = newVal
    },
    editValid(newVal) {
      this.localEditValid = newVal
    }
  }
}
</script>

<style>

</style>