<template>
  <div>
    <v-card
      elevation="2"
      :style="{ 'overflow-y': 'auto', 'background-color': color }"
      class="mx-auto"
      outlined
    >
      <v-card-text>
        <div class="stick-title" :style="{ 'background-color': color }">
          <v-row class="d-flex align-content">
            <v-col>
              <div style="font-size: 19px; font-weight: bold">
                {{ title }}
              </div>
            </v-col>
            <v-col class="d-flex justify-end align-start" v-if="false">
                <v-btn @click="showInfoDialog(node)" icon style="margin-top: -8px">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>
        </div>
        <div v-if="!hideContent">
          <div :key="item.text" v-for="item in description">
            <v-row>
              <v-col cols="9" class="mr-1">
                <div style="font-size: 16px">
                  {{ item.text }}
                </div>
              </v-col>
              <v-col cols="2" class="d-flex justify-end align-content">
                <div>
                  {{ item.value }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-2 mb-2"></v-divider>
          </div>
        </div>
        <div class="d-flex justify-center align-content">
          <v-btn @click="hideContent = !hideContent" icon>
            <v-icon>{{
              !hideContent ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <StandardDialog v-model="infoDialog" :dialog-height="null" dialog-width="50vw" title="Info">
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";

export default {
  name: "CustomFlowyNode",
  components: {
    StandardDialog,
  },
  data: function () {
    return {
      hideContent: false,
      infoDialog: false,
    };
  },
  props: {
    remove: {},
    node: {},
    title: {},
    description: {},
    color: {
      type: String,
      default: "White",
    },
  },
  mounted: function () {},
  methods: {
    showInfoDialog(node) {
      this.infoDialog = true;
      console.log(node);
    },
  },
};
</script>

<style scoped>
.stick-title {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>