import EventsHandler from "@/services/common/eventsHandler.js"
import devicesDatabase from "@/services/devices/devices.database"
import barcodeScannerEmulator from "./barcodeScannerEmulator"

class BarcodeScanner extends EventsHandler {
  constructor() {
    super()

    this._device = undefined
    this._HIDDeviceInstance = undefined

    this._scanHandler = ({ barcode }) => {
      this._executeAsyncCallbacksOf('scan', { barcode }).then(() => { })
    }
    this._scanHandler = this._scanHandler.bind(this)
    barcodeScannerEmulator.on('scan', this._scanHandler)
  }

  loadFavouriteDevice() {
    return new Promise((resolve, reject) => {
      devicesDatabase.getDevice('barcodeScanner').then((device) => {
        this._device = device
        if(navigator.hid) {
          navigator.hid.getDevices().then((devices) => {
            if(!!device) {
              for (let i = 0; i < devices.length; i++) {
                if (devices[i].productId === device.productId && devices[i].productName === device.productName) {
                  this._HIDDeviceInstance = devices[i]
                }
              }
            }
            if (!!this._HIDDeviceInstance) {
              this._openDeviceConnection().then(() => {
                resolve()
              })
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    })
  }

  isDeviceConnected() {
    return !!this._device
  }

  device(serialize=false) {
    if(serialize) {
      return this._serializeDevice(this._device)
    } else {
      return this._HIDDeviceInstance
    }
  }

  getDeviceOptions() {
    return new Promise((resolve, reject) => {
      if(navigator.hid) {
        navigator.hid.requestDevice({ filters: [] }).then((authorizedDevices) => {
          navigator.hid.getDevices().then((devices) => {
            resolve(devices.map(el => this._serializeDevice(el)))
          })
        })
      } else {
        reject(new Error('no navigator hid'))
      }
    })
  }

  selectDevice(device) {
    return new Promise((resolve, reject) => {
      this._device = device
      devicesDatabase.saveDevice('barcodeScanner', this._serializeDevice(device)).then(() => {
        if(navigator.hid) {
          navigator.hid.getDevices().then((devices) => {
            for (let i = 0; i < devices.length; i++) {
              if (devices[i].productId === device.productId && devices[i].serialNumber === device.serialNumber && devices[i].productName === device.productName) {
                this._HIDDeviceInstance = devices[i]
              }
            }
            this._openDeviceConnection().then(() => {
              this._executeAsyncCallbacksOf('connect', { device: device }).then(() => {
                resolve()
              })
            })
          })
        } else {
          resolve()
        }
      })
    })
  }

  _serializeDevice(hidDevice) {
    if(!hidDevice) return undefined

    return {
      productId: hidDevice.productId,
      productName: hidDevice.productName,
      vendorId: hidDevice.vendorId
    }
  }

  async _openDeviceConnection() {
    if(!this._HIDDeviceInstance) throw new Error('no usb device set')
  }

  startListen() {
    barcodeScannerEmulator.startListen()
  }

  stopListen() {
    barcodeScannerEmulator.stopListen()
  }

  enableCharsRemoving() {
    barcodeScannerEmulator.enableCharsRemoving()
  }

  disableCharsRemoving() {
    barcodeScannerEmulator.disableCharsRemoving()
  }
}

export default new BarcodeScanner()