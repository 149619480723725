<template>
  <span>
    <template v-if="!textFieldView">
      <span v-if="withDate">{{ formattedDate }}</span>
      <span v-if="onlyTime && !!value "> {{ formattedTime }} </span>
      <span v-if="withTime && !!value"> alle {{ formattedTime }}</span>
    </template>
    <template v-else>
      <v-text-field
        v-if="withDate"
        prepend-icon="mdi-calendar"
        disabled
        :dense="dense"
        filled
        :label="label"
        :rounded="rounded"
        v-model="textFieldValue">
      </v-text-field>
      <v-text-field
        v-else 
        prepend-icon="mdi-clock"
        disabled
        :dense="dense"
        filled
        :label="label"
        :rounded="rounded"
        v-model="formattedTime">
      </v-text-field>

    </template>
  </span>
</template>

<script>
import dateUtils from "@/mixins/common/dateUtils.js";

export default {
  data: function () {
    return {};
  },
  mixins: [dateUtils],
  props: {
    label:{
      type: String,
      default: '',
    },
    textFieldView:{
      type: Boolean,
      default: false,
    },
    value: {},
    format: {
      type: String,
      default: "dd/mm/yyyy",
    },
    notSpecifiedText: {
      type: String,
      default: "Non specificato",
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    onlyTime: {
      type: Boolean,
      default: false,
    },
    withDate: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    textFieldValue(){
      return this.withTime ? this.formattedDate + ' alle ' + this.formattedTime: this.formattedDate
    },
    formattedDate() {
      let date = this.value;
      if (!date) {
        return this.notSpecifiedText;
      }

      if (!this.isDate(date)) {
        date = new Date(date);
      }
      return this.dateToString(this.format, date);
    },
    formattedTime() {
      let date = this.value;
      if (!this.isDate(date)) {
        date = new Date(date);
      }

      return (
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
  },
};
</script>