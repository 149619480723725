<template>
  <div id="content-to-print">
    <TitleWithBackButton
      title="Efficientamento Prodotti"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <div class="d-flex align-center ml-3 mt-3 pr-5 flex-wrap">
      <RangeDateSelector
        ref="dateSelector"
        :start-date.sync="startDate"
        :end-date.sync="endDate"
        :loading="loading"
        :disabled="loading"
				:max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '60vw' : '50vw'"
      ></RangeDateSelector>
      <v-btn
        class="mr-2 ml-2"
        :class="{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm}"
        text
        @click="calculate"
        :loading="loading"
        :disabled="!startDate || !endDate"
      >Calcola</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="save"
        :loading="loading"
      >Salva</v-btn>
      <v-btn
        class="btn-export"
        @click="exportToExcel"
        :loading="loading"
      ><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      <v-btn
        class="mr-2 ml-2"
        text
        @click="print"
        :loading="loading || printLoading"
        :disabled="!startDate || !endDate"
      ><v-icon> mdi-printer </v-icon></v-btn>
      <v-btn icon large style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!isB ? 'red' : ''"
      >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div class="pa-4 mt-5">
      <div
        style="overflow-x: auto; overflow-y: hidden; margin-bottom: 15px;"
      >
        <div
          style="width: fit-content; gap: 15px;"
          class="d-flex align-center"
        >
          <div
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '6dvw' : $vuetify.breakpoint.md ? '6dvw' : '4dvw'
            }"
          >
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '35dvw' : $vuetify.breakpoint.md ? '25dvw' : '20dvw'
            }"
            class="text-center"
          >
            Tag di Costo
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '35dvw' : $vuetify.breakpoint.md ? '25dvw' : '25dvw'
            }"
            class="text-center"
          >
            Famiglia Servizio
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '20dvw' : $vuetify.breakpoint.md ? '10dvw' : '10dvw'
            }"
            class="text-center"
          >
            Rapporto Efficienza
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '25dvw' : $vuetify.breakpoint.md ? '16dvw' : '16dvw'
            }"
            class="text-center"
          >
            Grado Efficienza
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '15dvw' : $vuetify.breakpoint.md ? '6dvw' : '3dvw'
            }"
          ></div>
        </div>
        <div
          style="width: fit-content; gap: 15px;"
          class="d-flex align-center" 
          v-for="(row, idx) in rows" :key="idx"
        >
          <div
            cols="1"
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '6dvw' : $vuetify.breakpoint.md ? '6dvw' : '4dvw'
            }"
            class="d-flex align-center justify-end"
          >
            <v-icon v-if="row.error" color="error">
              mdi-alert
            </v-icon>
          </div>
          <div
            cols="3"
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '35dvw' : $vuetify.breakpoint.md ? '25dvw' : '25dvw'
            }"
            class="d-flex align-center justify-center"
          >
            <TagsAutocomplete 
              v-model="row.tag"
              :multiple="false" 
              solo-inverted
              flat
              return-object
              :filters="{ typeCost: true }"
              @input="handleInput(row)"
              style="width: 100%"
            >
            </TagsAutocomplete>
          </div>
          <div 
            cols="3"
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '35dvw' : $vuetify.breakpoint.md ? '25dvw' : '20dvw'
            }"
            class="d-flex align-center justify-center"
          >
            <ServiceTypeServicesSelect
              v-model="row.services"
              flat
              dense
              label=""
              :multiple="true"
              :clearable="false"
              height="15px"
              style="width: 100%"
              :filters="{
                categoriesToExclude: ['BISTROT'],
              }"
              @input="handleInput(row)"
            ></ServiceTypeServicesSelect>
          </div>
          <div 
            cols="2"
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '20dvw' : $vuetify.breakpoint.md ? '10dvw' : '10dvw'
            }"
          >
            <v-row class="d-flex align-center justify-center">
              <div>
                <v-autocomplete
                  v-model="row.itemCoefficent"
                  class="mt-6"
                  dense
                  solo-inverted
                  flat
                  rounded
                  :items="listItemCoefficients"
                  @change="handleInput(row)"
                >
                </v-autocomplete>
              </div>
            </v-row>
          </div>
          <div 
            cols="2"
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '25dvw' : $vuetify.breakpoint.md ? '16dvw' : '16dvw'
            }"
            class="d-flex align-center justify-center"
          >
            <v-chip v-if="!!row.efficency" :color="color(row.efficency)">
              {{ Number(row.efficency).toFixed(0) }}%
            </v-chip>
            <v-chip v-else color="grey">
              Non calcolato
            </v-chip>
          </div>
          <div 
            :style="{
              'padding': '5px',
              'width': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? '15dvw' : $vuetify.breakpoint.md ? '6dvw' : '3dvw'
            }"
          >
            <v-btn color="error" fab small outlined elevation="0" class="mb-3" @click="removeRow(idx)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-row>
        <v-col class="d-flex align-center justify-center mb-3">
          <v-btn color="primary" outlined class="px-10 mr-2" @click="addRow()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import ServiceTypeAutocomplete from '@/components/common/ServiceTypeAutocomplete.vue'
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import colorConstrast from "@/mixins/common/colorContrast"
import balanceService from '@/services/balance/balance.service.js'
import serviceConsumes from '@/services/balance/serviceConsumes.service.js'
import operatorService from '@/services/operators/operators.service.js'
import html2canvas from '@/assets/js/html2canvas.min.js'
import * as XLSX from "xlsx";

import ServiceTypeServicesSelect from '@/components/common/ServiceTypeServicesSelect.vue'

export default {
  name: "ServiceConsume",
  components: {
    TitleWithBackButton,
    TagsAutocomplete,
    ServiceTypeAutocomplete,
    RangeDateSelector,
    StandardDialog,
    ManualBarcodeInput,
    ServiceTypeServicesSelect
  },
  mixins: [colorConstrast],
  data: function() {
    let startDate = new Date();
    startDate.setDate(1)
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0);

    return {
      startDate: startDate,
      endDate: endDate,
      loading: false,
      printLoading: false,
      serviceListDialog: false,
      rows: [],
      selectedRow: undefined,
      operatorBarcode: undefined,
      listItemCoefficients: Array.from({ length: 201 }, (_, i) => { 
        if (i > 40 && i % 10 == 0) return '1/' + i
        if (i <= 40) return '1/' + (0.5 * (i + 1))
      }).filter(el => el != undefined),
      dialogBarcode: false,
      isB: false,
      fileName: ""
    }
  },
  async mounted() {
    this.loadServiceConsumes()
  },
  methods: {
    async exportToExcel(){
      let workbook = XLSX.utils.book_new();
      this.fileName = "Efficientamento_Prodotti_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls";
      let row = []
      for (let i = 0; i < this.rows.length; i++) {
        this.rows[i].tag[0].itemCoefficent = this.rows[i].itemCoefficent
        if (!!this.rows[i].efficency)
          this.rows[i].tag[0].efficency = this.rows[i].efficency + "%"
        else
          this.rows[i].tag[0].efficency = "Non Calcolato"
        this.rows[i].tag[0].costTagName = this.rows[i].tag[0].description
        row.push(this.rows[i].tag[0])
        for (let j = 0; j < this.rows[i].services.length; j++) {
          this.rows[i].services[j].familyServiceName = this.rows[i].services[j].name
          row.push(this.rows[i].services[j])          
        }
      }
      let ws = XLSX.utils.json_to_sheet(row.map((element) => {
        return {
          "Tag di costo": element.costTagName,
          "Famiglia Servizio": element.familyServiceName,
          "Rapporto Efficienza": element.itemCoefficent,
          "Grado Efficienza": element.efficency
        }
      }));
      XLSX.utils.book_append_sheet(workbook, ws, "Efficientamento Prodotti");

      XLSX.writeFile(workbook, this.fileName, { type: 'file' });
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.changeWithB()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    changeWithB() {
      this.isB = !this.isB
      this.calculate()
    },
    async loadServiceConsumes() {
      this.loading = true

      let res = await serviceConsumes.list()
      this.rows = res.rows.map(row => {
        return {
          id: row.id,
          tag: row.tag,
          services: row.service,
          itemCoefficent: row.itemCoefficent,
        }
      })
      
      this.loading = false
    },
    async calculate(){
      this.loading = true

      this.startDate.setHours(0, 0, 0, 0)
      this.endDate.setHours(23, 59, 59, 999)
      let res = await balanceService.serviceConsume({
        from: this.startDate,
        to: this.endDate,
        ghost: this.isB
      })

      this.fileName = "Efficientamento_Prodotti_da_" + this.startDate.toLocaleDateString('it-IT') + "_a_" + this.endDate.toLocaleDateString('it-IT') + ".xls";

      let newRows = this.rows.map(row => {
        let resRow = res.find(r => r.id === row.id)
        if(!!resRow) {
          if(Number(resRow.serviceQty) === 0) {
            row.efficency = undefined
            return row
          }
          let actualEfficency = Number(Number(resRow.itemQty) / Number(resRow.serviceQty))
          let wantedEfficency = Number(Number(row.itemCoefficent.split('/')[0]) / Number(row.itemCoefficent.split('/')[1]))

          row.efficency = Number(Number(actualEfficency) / Number(wantedEfficency) * 100).toFixed(0)
        }
        return row
      })

      this.rows = newRows


      this.loading = false
    },
    color(efficency) {
      if(efficency < 70) return 'red'
      if(efficency <= 85) return 'orange'
      if(efficency <= 115) return 'green'
      if(efficency <= 130) return 'orange'
      return 'red'
    },
    addRow() {
      this.rows.push({
        id: undefined,
        tag: undefined,
        services: undefined,
        itemCoefficent: undefined,
        newId: this.rows.length
      })
    },
    async removeRow(idx) {
      if(!this.rows[idx].id)
        this.rows.splice(idx, 1)
      else
        await serviceConsumes.delete({ id: this.rows[idx].id }).then(res => {
          this.rows.splice(idx, 1)
        })
    },
    handleInput(row) {
      if(!!row.id)
        this.rows.find(r => r.id === row.id).edited = true
    },
    handleOpenAddService(row) {
      this.serviceListDialog = true
      this.selectedRow = row
    },
    save(){
      this.saveLoading = true

      let allCompleted = this.rows.every(row => {
        return !!row.tag && !!row.services && !!row.itemCoefficent
      })

      if(!allCompleted) {
        this.$delegates.snackbar('Completa tutti i campi prima di salvare!')

        this.rows = this.rows.map(row => {
          if(!row.tag || !row.services || !row.itemCoefficent)
            row.error = true
          return row
        })

        this.rows = [...this.rows]
        this.saveLoading = false
        return
      }

      let rows = this.rows.map(row => {
        return {
          id: row.id,
          tagId: row.tag[0].id,
          services: row.services.map(el => el.id),
          itemCoefficent: row.itemCoefficent,
          edited: !!row.edited
        }
      })

      let uniqueRows = rows.filter((value,idx,arr) =>{
        return arr.findIndex(t => {
          return JSON.stringify(t.services) === JSON.stringify(value.services) && t.tagId === value.tagId
        }) === idx
      })

      if(uniqueRows.length !== rows.length) {
        this.$delegates.snackbar('Non puoi inserire due volte lo stesso servizio per lo stesso tag!')

        let duplicates = rows.filter((value,idx,arr) =>{
          return arr.findIndex(t => {
            return JSON.stringify(t.services) === JSON.stringify(value.services) && t.tagId === value.tagId
          }) !== idx
        })


        duplicates.map(duplicate => {
          let rowIdx = this.rows.findLastIndex(row => JSON.stringify(row.services) === JSON.stringify(duplicate.services) && row.tag[0].id === duplicate.tagId)
          this.rows[rowIdx].error = true
        })

        this.rows = [...this.rows]

        this.saveLoading = false
        return
      }

      let sendRows = rows.filter(row => !!row.edited || !row.id)

      if(sendRows.length === 0) {
        this.$delegates.snackbar('Nessun dato da salvare!')
        this.saveLoading = false
        return
      }

      serviceConsumes.save(sendRows).then(async res => {
        await this.loadServiceConsumes()
        this.$delegates.snackbar('Salvataggio effettuato con successo!')
        this.saveLoading = false
      }).catch(err => {
        this.saveLoading = false
        console.error(err)
      })
    },
    print() {
      let id = 'content-to-print'
      this.printLoading = true

      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Efficientamento_Prodotti_" 
          + "_" + this.startDate.getDate() + "-" + (this.startDate.getMonth() + 1) 
          + "_" + this.endDate.getDate() + "-" + (this.endDate.getMonth() + 1) +  ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
  }
}
</script>

<style>

</style>