import Database from '@/services/offline/database.js'

class AbsencesCache extends Database {

  constructor() {
    super()
  }

  _storeName() {
    return 'absences'
  }

  _maxRows() {
    return 50
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {String} param.day - Day to cache (format: YYYYMMDD )
   * @param {Object[]} param.absences - Absences to cache
   */
  async cacheDay({ day, absences }) {
    let storageKey = this._createStorageKey({
      timestamp: day,
    })

    const db = await this.dbPromise

    const keys = await db.getAllKeys(this._storeName())
    if (keys.length > this._maxRows()) {
      db.delete(this._storeName(), keys.reverse()[0])
    }

    await db.put(this._storeName(), absences, storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {String} param.day - Day to cache (format: YYYYMMDD )
   */
  async getDay({ day }) {
    let storageKey = this._createStorageKey({
      timestamp: day
    })

    const db = await this.dbPromise
    return await db.get(this._storeName(), storageKey)
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {String} param.day - Day to cache (format: YYYYMMDD )
   */
  async cachePresent({ day }) {
    return !!(await this.getDay({ day }))
  }

  async deleteAllCache() {
    const db = await this.dbPromise
    return await db.clear(this._storeName())
  }

  /**
   * @param {Object} param - Cache parameters
   * @param {String} param.timestamp - Day to cache (format: YYYYMMDD )
   */
  _createStorageKey({ timestamp }) {
    return timestamp
  }
}

export default new AbsencesCache()