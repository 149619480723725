export default {
  directives: {
    debounce: {
      // directive definition
      bind: function (el, binding, vnode) {
        if(!typeof binding.value == 'function') throw new Error('define a function in the debounce directive')

        let event = 'input'
        if (Object.keys(binding.modifiers).length > 0) {
          event = Object.keys(binding.modifiers)[0]
        }

        let timeout;
        let isTyping = false;
        let intervalMillisecond = !!binding.arg ? binding.arg : 1000;

        const checkForDebounce = (input) => {
          timeout = setTimeout(() => {
            isTyping = false
            binding.value(input)
          }, intervalMillisecond)
        }
        
        const remindDebounce = (input) => {
          clearTimeout(timeout)
          checkForDebounce(input)
        }

        vnode.componentInstance.$on(event, (newInput) => {
          if(!isTyping) {
            isTyping = true
            checkForDebounce(newInput)
          } else {
            remindDebounce(newInput)
          }
        })
      }
    }
  }
}