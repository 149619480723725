<template>
  <div style="overflow: auto;">
    <TitleWithBackButton
      title="Confronta Periodo Commerciale"
      back-route="Analytics"
    ></TitleWithBackButton>
    <div class="pa-3 d-flex justify-center align-center">
      <v-select
        label="Tipo comparazione"
        v-model="comparationKind"
        :items="comparationKindOptions"
        filled
        dense
        hide-details="auto"
        :menu-props="{
          offsetY: true
        }"
        style="max-width: 250px"
        @input="loadData"
      ></v-select>
      <v-select
        label="Valori di comparazione"
        v-model="comparationValue"
        :items="comparationValueOptions"
        small-chips
        deletable-chips
        filled
        dense
        multiple
        hide-details="auto"
        :menu-props="{
          offsetY: true
        }"
        style="max-width: 350px"
        class="ml-2"
        @input="loadData"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item["text"] }}</span>
          </v-chip>
          <span 
            v-if="index === 1"
            class="grey--text text-caption"
          >
          (+{{ comparationValue.length - 1}} altri)
          </span>
        </template>
      </v-select>
      <v-autocomplete
        v-if="!!comparationKind"
        label="Anni da comparare"
        v-model="comparationYears"
        :items="comparationYearsOptions"
        small-chips
        deletable-chips
        multiple
        filled
        dense
        hide-details="auto"
        :menu-props="{
          offsetY: true
        }"
        style="max-width: 250px"
        class="ml-2"
        @input="loadData"
      ></v-autocomplete>
      <v-autocomplete
        v-if="comparationKind == 'month'"
        label="Mese da comparare"
        v-model="comparationMonth"
        :items="comparationMonthOptions"
        small-chips
        deletable-chips
        filled
        dense
        hide-details="auto"
        :menu-props="{
          offsetY: true
        }"
        style="max-width: 250px"
        class="ml-2"
        @input="loadData"
      ></v-autocomplete>
      <v-btn-toggle
        v-model="chartOrTableIndex"
        mandatory
        class="ml-2"
        color="default"
      >
        <v-btn>
          <v-icon>mdi-table</v-icon>
        </v-btn>
        <v-btn>
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-btn 
        icon
        @click="printRows"
        :disabled="tableRows.length == 0"
        class="ml-2"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </div>
    <v-window
      v-model="chartOrTableIndex"
    >
      <v-window-item>
        <div class="d-flex justify-center mt-10">
          <v-data-table
            height="60vh"
            style="width: 90vw"
            :headers="tableHeaders"
            :items="tableRows"
            disable-pagination
            hide-default-footer
            fixed-header
            :loading="loading"
            group-by="month"
            v-if="comparationKind == 'year'"
          >
            <template v-slot:group.header="{ groupBy, group }">
              <td :colspan="tableHeaders.length">
                <span v-if="groupBy == 'month'" class="font-weight-bold">{{getMonthName(group)}}</span>
              </td>
            </template>
            <template
              v-for="(header, index) in tableHeaders.filter(header => header.colorize).map(header => ({ ...header, slotName: 'item.' + header.value}))"
              v-slot:[header.slotName]="{ item }"
            >
              <div 
                :key="index"
                :style="{
                  'color': item.delta ? Number(item[header.value]) > 0 ? 'green' : 'red' : undefined,
                  'font-weight': item.delta ? '800' : undefined
                }"
              >
                {{item[header.value]}}
              </div>
            </template>
          </v-data-table>
          <div 
            v-else
          >
            Tabella al momento non disponibile
          </div>
        </div>
      </v-window-item>
      <v-window-item>
        <div 
          v-for="cv in comparationValue"
          :key="cv['value']"
          class="d-flex justify-center mt-10"
        >
          <DateBasedChart
            :getYValue="(object) => {
              return object[`Balance.${cv}`]
            }"
            :getXDate="getXDate"
            height="60vh"
            width="90vw"
            :data="data[cv]"
            :label-modifier="labelModifier"
            :tooltip-label-modifier="(tooltipLabel) => tooltipLabel.yLabel"
            :loading="loading"
            :filters="chartFilters"
            :granularity="granularity"
          ></DateBasedChart>
        </div>
      </v-window-item>
    </v-window>
    <div></div>
  </div>
</template>

<script>
import DateBasedChart from '@/components/charts/DateBasedChart.vue'
import DatePicker from '@/components/common/DatePickerCommon.vue'
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import cubeService from '@/services/cube/cube.service.js'
import balanceService from '@/services/balance/balance.service.js'
import printTableService from '@/services/printer/printTable.service.js'

export default {
  name: "BalanceByTags",
  components: {
    DatePicker,
    TitleWithBackButton,
    DateBasedChart,
  },
  data: function() {
    return {
      comparationKindOptions: [
        {
          value: 'year',
          text: 'Anno'
        }, {
          value: 'month',
          text: 'Mese'
        }
      ],
      comparationValueOptions: [
        {
          value: 'total',
          text: 'Totale'
        }, {
          value: 'billNumber',
          text: 'Passaggi cassa'
        }, {
          value: 'customerNumber',
          text: 'Numero clienti'
        }, {
          value: 'itemsNumber',
          text: 'Prodotti venduti'
        }, {
          value: 'servicesNumber',
          text: 'Servizi venduti'
        }, {
          value: 'maleNumber',
          text: 'Numero uomini'
        }, {
          value: 'femaleNumber',
          text: 'Numero donne'
        }, {
          value: 'maleBillNumber',
          text: 'Passaggi cassa uomini'
        }, {
          value: 'femaleBillNumber',
          text: 'Passaggi cassa donne'
        }, {
          value: 'itemOverTotalRevenue',
          text: 'Percentuale prodotti sul totale'
        }
      ],
      comparationYearsOptions: [...Array(31).keys()].map(el => el + 2020),
      comparationYears: [
        new Date().getFullYear(),
        new Date().getFullYear() - 1,
      ],
      comparationMonthOptions: [
        {
          value: 0,
          text: 'Gennaio'
        }, {
          value: 1,
          text: 'Febbraio'
        }, {
          value: 2,
          text: 'Marzo'
        }, {
          value: 3,
          text: 'Aprile'
        }, {
          value: 4,
          text: 'Maggio'
        }, {
          value: 5,
          text: 'Giugno'
        }, {
          value: 6,
          text: 'Luglio'
        }, {
          value: 7,
          text: 'Agosto'
        }, {
          value: 8,
          text: 'Settembre'
        }, {
          value: 9,
          text: 'Ottobre'
        }, {
          value: 10,
          text: 'Novembre'
        }, {
          value: 11,
          text: 'Dicembre'
        }
      ],
      comparationMonth: new Date().getMonth(),
      comparationKind: undefined,
      comparationValue: [],
      loading: false,
      chartOrTableIndex: 1,
      labelModifier: ({ month, monthNameMapper, granularity, label, date }) => {
        if (granularity === 'month') {
          return monthNameMapper[month]
        } else {
          return `${date.getDate()}/${date.getMonth() + 1}`
        }
      },
      data: {},
      chartFilters: undefined
    }
  },
  mounted: function() {
    this.comparationValue = !!this.comparationValueOptions ? this.comparationValueOptions.map(el => el.value) : undefined
  },
  methods: {
    loadData() {
      if(!this.comparationKind || !this.granularity || !this.comparationValue || this.comparationValue.length == 0 || !this.comparationYears || this.comparationYears.length == 0) return
      if(this.comparationKind == 'month' && this.comparationMonth === undefined || this.comparationMonth === null) return

      let periods = []
      if(this.comparationKind == 'year') {
        periods = this.comparationYears.map(year => {
          return {
            from: new Date(year, 0, 1),
            to: new Date(year, 11, 31)
          }
        })

        this.chartFilters = {
          from: new Date(this.comparationYears[0], 0, 1),
          to: new Date(this.comparationYears[0], 11, 31)
        }

      } else if(this.comparationKind == 'month') {
        periods = this.comparationYears.map(year => {
          return {
            from: new Date(year, this.comparationMonth, 1),
            to: new Date(year, this.comparationMonth + 1, 0)
          }
        })

        this.chartFilters = {
          from: new Date(this.comparationYears[0], this.comparationMonth, 1),
          to: new Date(this.comparationYears[0], this.comparationMonth + 1, 0)
        }
      }

      this.loading = true

      balanceService.compareByTags({
        periods: periods,
        measures: this.comparationValue,
        granularity: this.granularity
      }).then((results) => {
        let data = {}
        for(let k = 0; k < this.comparationValue.length; k += 1) {
          let comparationValueData = []
          for(let i = 0; i < this.comparationYears.length; i += 1) {
            let comparationValueLabel = this.comparationValue.length == 1 ? 
              this.comparationYears[i] : 
              [this.getComparationValueText(this.comparationValue[k]), this.comparationYears[i]].join(' ')

            let total = 0
            results[i].forEach(elem=>{
              total += Number(elem[`Balance.${this.comparationValue[k]}`])
            })
            
            let totalObject = {'isTotal': true}
            totalObject[`Balance.${this.comparationValue[k]}`] = Number.isInteger(total) ? total : total.toFixed(2)

            comparationValueData.push({
              name: comparationValueLabel,
              label: comparationValueLabel,
              year: this.comparationYears[i],
              data: [...results[i], totalObject],
              delta: 14
            })
          }
          data[this.comparationValue[k]] = comparationValueData
        }
        this.data = data
        this.loading = false
      })
    },
    getXDate(object) {
      let date = new Date(object[`Balance.billDate.${this.granularity}`])
      if(this.granularity == 'day') date.setFullYear(this.comparationYears[0])
      return date
    },
    getComparationValueText(key) {
      return this.comparationValueOptions.find(el => el.value == key).text
    },
    getMonthName(monthIndex) {
      let monthMapper = {
        0: 'Gennaio',
        1: 'Febbraio',
        2: 'Marzo',
        3: 'Aprile',
        4: 'Maggio',
        5: 'Giugno',
        6: 'Luglio',
        7: 'Agosto',
        8: 'Settembre',
        9: 'Ottobre',
        10: 'Novembre',
        11: 'Dicembre',
        12: 'Totale'
      }

      return monthMapper[monthIndex]
    },
    printRows() {
      const hLineWidth = ({ i, node, row }) => {
        if (i === 0 || i === node.table.body.length) {
          return 0;
        }

        const previousRow = this.tableRows[i - 2] 
        const currentRow = this.tableRows[i - 1]

        if(!previousRow || !currentRow) return 1
        else if(previousRow.month !== currentRow.month) return 3
        else return 1
      }

      const hLineColor = ({ i, node, row }) => {
        if(i === 1) return 'black'

        const previousRow = this.tableRows[i - 2] 
        const currentRow = this.tableRows[i - 1]

        if(!previousRow || !currentRow) return '#aaa'
        else if(previousRow.month !== currentRow.month) return 'black'
        else return '#aaa'
      }

      printTableService.print({
        headers: this.tableHeaders,
        rows: this.tableRows,
        title: 'Statistiche commerciali periodo',
        orientation: 'landscape',
        tableLayout: {
          hLineWidth: hLineWidth.bind(this),
          hLineColor: hLineColor.bind(this)
        }
      })
    }
  },
  computed: {
    granularity() {
      const comparationKindMapper = {
        'year': 'month',
        'month': 'day',
        'week': 'day'
      }

      return comparationKindMapper[this.comparationKind]
    },
    chartOrTable() {
      const mapper = ['table', 'chart']
      return mapper[this.chartOrTableIndex]
    },
    tableRows() {
      if(!this.data) return []
      if(!this.comparationValue || this.comparationValue.length == 0) return []

      let finalRows = []
      if(this.comparationKind == 'year') {
        let rows = {}
        for(let i = 0; i < 13; i += 1) {
          for(const [comparationValue, datasets] of Object.entries(this.data)) {
            for(let k = 0; k < datasets.length; k += 1) {
              const rowKey = this.getMonthName(i) + ' ' + datasets[k].year
              const rowLabel = rowKey
              
              const dataRow = !!datasets[k].data ? datasets[k].data.find((el) => {
                const date = this.getXDate(el)
                return date.getMonth() == i || el.isTotal && i == 12
              }) : undefined

              if(!rows[rowKey]) rows[rowKey] = {
                id: i.toString() + datasets[k].year.toString(),
                label: rowLabel,
                month: i,
                year: datasets[k].year,
              }
              rows[rowKey][comparationValue] = !!dataRow ? dataRow[`Balance.${comparationValue}`] : undefined
            }
          }
        }

        const orderedValues = [...Object.values(rows)].sort((a, b) => {
          if(a.year < b.year && a.month >= b.month ) return 1
          else if(a.month > b.month) return 1
          else return -1
        })
        
        for(let i = 0; i < orderedValues.length; i += 1) {
          finalRows.push(orderedValues[i])
          if(i + 1 < orderedValues.length && orderedValues[i + 1].month == orderedValues[i].month) {
            let delta = {
              id: orderedValues[i].id + 'Delta',
              label: 'Delta',
              delta: true,
              month: orderedValues[i].month
            }

            for(let k = 0; k < this.comparationValue.length; k += 1) {
              const firstValue = orderedValues[i][this.comparationValue[k]]
              const secondValue = orderedValues[i + 1][this.comparationValue[k]]
              delta[this.comparationValue[k]] = !!firstValue && !!secondValue ? 
                Number(Number(Number(firstValue) - Number(secondValue)).toFixed(2)) :
                undefined
            }
            finalRows.push(delta)
          }
        }
      }
      return finalRows
    },
    tableHeaders() {
      if(!this.comparationValue || this.comparationValue.length == 0) return []

      let headers = [{
        text: '',
        value: 'label',
        sortable: false,
        groupable: true
      }]

      for(let i = 0; i < this.comparationValue.length; i += 1) {
        headers.push({
          text: this.getComparationValueText(this.comparationValue[i]),
          value: this.comparationValue[i],
          colorize: true
        })
      }

      return headers
    }
  }
}
</script>

<style>

</style>