import licenseSettingsService from './licenseSettings.service'

export default {
  install: (Vue, options) => {
    Vue.mixin({
      created: function() {
        if (!!this.$options.licenseSettings) {
          let bindings = []
          for(const [settingKey, value] of Object.entries(this.$options.licenseSettings)) {
            if (!!value.initial) {
              let bindFunction = value.initial.bind(this)
              bindFunction(licenseSettingsService.getFromCache(settingKey))
            }

            if(!!value.change) {
              let bindFunction = value.change.bind(this)
              licenseSettingsService.on('update-' + settingKey, bindFunction)
            }

            if(!!value.bind) {
              if (bindings.includes(value.bind))
                console.warn('Try to bind a variable that has been already bind (' + value.bind + ')')
              else
                bindings.push(value.bind)

              this[value.bind] = licenseSettingsService.getFromCache(settingKey)
              let updateCallback = (newValue) => {
                this[value.bind] = newValue
              }
              updateCallback = updateCallback.bind(this)
              licenseSettingsService.on('update-' + settingKey, updateCallback)
            }

          }
        }
      }
    })

    Vue.prototype.$licenseSettings = {
      get(name) {
        return new Promise((resolve, reject) => {
          licenseSettingsService.get(name).then((result) => {
            resolve(result)
          }).catch((err) => {
            reject(err)
          })
        })
      }
    }
  }
}