<template>
  <StandardDialog v-model="dialog" :dialog-height="null" :title="dialogTitle" dialog-max-width="400px">
    <div v-if="loading" class="d-flex justify-center align-center">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else-if="success">
      <v-row>
        <v-col class="d-flex justify-center align-center flex-column">
          <div class="font-weight-black text-button" v-if="openedAt && closedAt === undefined">Entrata registrata</div>
          <div class="font-weight-black text-button" v-if="openedAt && closedAt">Uscita registrata</div>
          <OperatorAvatar
            v-if="!!operator"
            :value="operator"
            :size="100"
            class="my-2"
          ></OperatorAvatar>
          <div class="font-weight" v-if="!!operator">
            {{operatorFullname}}
          </div>
          <div class="font-weight-light">
            Entrata {{openedAt}}
          </div>
          <div class="font-weight-light" v-if="openedAt && closedAt">
            Uscita {{closedAt}}
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!!startTurn">
        <v-col class="pa-0 text-center">
          <span class="font-weight-bold">Orario inizio programmato: </span><span>{{startTurn.slice(0, 5)}}</span>
        </v-col>
      </v-row>
      <v-row v-if="!!stopTurn">
        <v-col class="pa-0 text-center">
          <span class="font-weight-bold">Orario fine programmato: </span><span>{{stopTurn.slice(0, 5)}}</span>
        </v-col>
      </v-row>
      <v-row v-if="!!finalStatus">
        <v-col class="pa-0 text-center">
          <span class="font-weight-bold">Stato timbratura: </span><span>{{translateFinalStatus(finalStatus)}}</span>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      Codice Non Riconosciuto
    </div>
  </StandardDialog>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import timeLogService from '@/services/presences/timeLogs/timeLogs.service.js'
import OperatorAvatar from '@/components/common/OperatorAvatar.vue'
import dateUtils from '@/mixins/common/dateUtils'

export default {
  name: "OperatorPresenceBadgeDialog",
  components: { 
    StandardDialog,
    OperatorAvatar
  },
  mixins: [
    dateUtils
  ],
  data: function() {
    return {
      dialog: false,
      loading: false,
      barcode: undefined,

      operator: undefined,
      timeLog: undefined,
      success: true,

      startTurn: undefined,
      stopTurn: undefined,
      finalStatus: undefined
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
  },
  mounted: function() {
  },
  methods: {
    translateFinalStatus(finalStatus) {
      const mapper = {
        overtime: 'Straordinario',
        earlyExit: 'Uscita in anticipo',
        delay: 'Ritardo',
        noTurns: 'Nessun turno',
        tooManyTurns: 'Troppi turni per individuare lo stato',
        turnIncomplete: 'Turno incompleto'
      }

      return mapper[finalStatus]
    },

    manageManualScan(barcode, note) {
        this.dialog = true
        this.loading = true
        this.barcode = barcode
        timeLogService.badge({ barcode, note }).then((results) => {
          this.operator = results.operator
          this.timeLog = results.timeLog
          if(!!results.status) {
            this.finalStatus = results.status.message
            this.startTurn = results.status.programmedStart
            this.stopTurn = results.status.programmedStop
          }
          this.loading = false
          this.success = true
        }).catch((error) => {
          this.operator = undefined
          this.loading = false
          this.success = false
        })
      }
  },
  computed: {
    dialogTitle() {
      return this.operator ? this.operator.firstname + ' ' + this.operator.lastname : this.barcode
    },
    operatorFullname() {
      return this.operator ? this.operator.firstname + ' ' + this.operator.lastname : undefined
    },
    openedAt() {
      if(!!this.timeLog && !!this.timeLog.openedAt) {
        return this.dateToString('dd/mm/yyyy', new Date(this.timeLog.openedAt)) + ", " + this.dateToTimeString(new Date(this.timeLog.openedAt))
      } else {
        return undefined
      }
    },
    closedAt() {
      if(!!this.timeLog && !!this.timeLog.closedAt) {
        return this.dateToString('dd/mm/yyyy', new Date(this.timeLog.closedAt)) + ", " + this.dateToTimeString(new Date(this.timeLog.closedAt))
      } else {
        return undefined
      }
    }
  },
  barcode: {
    scan(barcode) {
      if(!this.disabled) {
        this.dialog = true
        this.loading = true
        this.barcode = barcode
        timeLogService.badge({ barcode }).then((results) => {
          this.operator = results.operator
          this.timeLog = results.timeLog

          if(!!results.status) {
            this.finalStatus = results.status.message
            this.startTurn = results.status.programmedStart
            this.stopTurn = results.status.programmedStop
          }
          this.loading = false
          this.success = true
        }).catch((error) => {
          this.loading = false
          this.success = false
        }) 
      }
    }
  }
}
</script>

<style>

</style>
