<template>
  <v-list style="width: 100%">
    <v-list-group
      v-for="category in localItems"
      :key="category[categoriesIdentifierKey]"
      no-action
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title
            v-text="category[categoriesTextKey]"
          ></v-list-item-title>
        </v-list-item-content>
      </template>

      <v-radio-group
        v-model="localSelected"
        :disabled="disabled"
      >
        <v-list-item
          v-for="item in category[itemsKey]"
          :key="item[itemsIdentifierKey]"
          :disabled="disabled"
          :selectable="true"
          @click="itemType(item, item[itemsIdentifierKey])"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-radio
                :key="item[itemsIdentifierKey]"
                :value="item[itemsIdentifierKey]"
              ></v-radio>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item[itemsTextKey] }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon class="ma-0" @click.stop.prevent="">
              <slot
                name="extra-actions"
                v-bind="{ item, active, emitInput }"
              ></slot>
            </v-list-item-icon>
          </template>
        </v-list-item>
      </v-radio-group>
    </v-list-group>
  </v-list>
</template>

<script>
import _ from "lodash";

export default {
  name: "GroupedSingleSelectList",
  data: function () {
    return {
      localItems: _.cloneDeep(this.items),
      localSelected: undefined,
    };
  },
  props: {
    value: {
      type: Object,
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    selectedItem: {
      type: Object,
    },
    badges: {
      type: Boolean,
      default: true,
    },
    badgesColor: {
      type: String,
      default: "primary",
    },
    categoriesIdentifierKey: {
      type: String,
      default: "id",
    },
    itemsIdentifierKey: {
      type: String,
      default: "id",
    },
    categoriesTextKey: {
      type: String,
      default: "code",
    },
    itemsKey: {
      type: String,
      default: "items",
    },
    itemsTextKey: {
      type: String,
      default: "code",
    },
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    this.calculateSelected()
  },
  methods: {
    calculateSelected() {
      if (!!this.value && !!this.value.id)
        this.localSelected = this.value.id
    },
    findCategoryByItemId(itemId) {
      let categories = []
      for (let i = 0; i < this.localItems.length; i++) {
        for (let k = 0; k < this.localItems[i][this.itemsKey].length; k++) {
          if (this.localItems[i][this.itemsKey][k][this.itemsIdentifierKey] === itemId)
            categories.push(this.localItems[i])
        }
      }

      return categories
    },
    emitInput() {
      this.$emit("input",_.cloneDeep(_.uniqBy(this.value, this.itemsIdentifierKey)));
    },
    itemType(item, id) {
      this.localSelected = id;
      
      let inputItem = {
        ...item,
        "category": this.findCategoryByItemId(id)
      }
      this.$emit("input",_.cloneDeep(inputItem));
      this.$nextTick(() => {
        this.$emit('update:selected-items', inputItem)
      })
    },
  },
  watch: {
    value() {
      this.calculateSelected()
    }
  },
};
</script>