<template>
  <StandardDialog v-model="dialog" :dialog-height="null" title="Dispositivi" dialog-max-width="400px" persistent>
    Alcuni dei seguenti dispositivi non sembrano essere collegati. Seleziona i dispositivi da utilizzare, o selezionali dal menu <a>impostazioni > dispositivi</a>
    <v-alert 
      v-if="error" 
      border="left"
      class="py-2 my-3"
      color="error"
    >
      <div class="d-flex justify-center align-center">
        <v-icon class="mr-2">mdi-alert-circle</v-icon>
        <div style="font-size: 10pt">{{errorMessage}}</div>
        <v-btn class="ml-auto" icon @click="error = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-alert>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-camera</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>Fotocamera</v-list-item-content>
        <v-list-item-action>
          <v-btn v-if="!cameraConnected" icon @click="requestCameraOptions" :loading="loadingCamera">
            <v-icon v-if="expanded == 'camera'">mdi-refresh</v-icon>
            <v-icon v-else>mdi-arrow-right</v-icon>
          </v-btn>
          <v-icon v-else>mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-expand-transition>
        <div v-if="expanded == 'camera'">
          <v-list dense class="pa-0 mb-5">
            <v-list-item v-for="(option, index) in cameraOptions" :key="index" @click="selectCameraDevice(option)">
              <v-list-item-content>{{option.label || option.groupId}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-expand-transition>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-barcode-scan</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>Barcode Scanner</v-list-item-content>
        <v-list-item-action>
          <v-btn v-if="!barcodeScannerConnected" icon @click="requestBarcodeScannerOptions" :loading="loadingBarcodeScanner">
            <v-icon v-if="expanded == 'barcodeScanner'">mdi-refresh</v-icon>
            <v-icon v-else>mdi-arrow-right</v-icon>
          </v-btn>
          <v-icon v-else>mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-expand-transition>
        <div v-if="expanded == 'barcodeScanner'">
          <v-list dense class="pa-0 mb-5">
            <v-list-item v-for="(option, index) in barcodeScannerOptions" :key="index" @click="selectBarcodeScannerDevice(option)">
              <v-list-item-content>{{option.manufacturerName}} {{option.productName}}</v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-expand-transition>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon>mdi-printer-pos</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>Stampante RT</v-list-item-content>
        <v-list-item-action>
          <v-btn icon v-if="!rtPrinterAddress" @click="expanded = 'rtPrinter'" :loading="loadingSetRtPrinter">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
          <v-icon v-else>mdi-check</v-icon>
        </v-list-item-action>
      </v-list-item>
      <v-expand-transition>
        <div v-if="expanded == 'rtPrinter'" class="d-flex align-center">
          <v-text-field
            v-model="editingRtPrinterAddress"
            filled
            dense
            hide-details="auto"
            label="Indirizzo stampante RT"
          ></v-text-field>
          <v-btn 
            icon 
            class="mx-2" 
            @click="confirmRtPrinterAddress"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </div>
      </v-expand-transition>
    </v-list>
    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn
        color="error"
        text
        @click="closeDialog"
      >
        Chiudi
      </v-btn>
      <v-btn
        color="default"
        text
        @click="dontAskMeAgain"
      >
        Non chiedermelo più
      </v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import IpInput from '@/components/common/IpInput.vue'
import cameraDevice from '@/services/devices/camera.device'
import barcodeScannerDevice from '@/services/devices/barcodeScanner.device'

export default {
  name: "DevicesDialog",
  components: { 
    StandardDialog,
    IpInput
  },
  data: function() {
    return {
      dialog: false,
      expanded: undefined,

      cameraOptions: [],
      loadingCamera: false,
      cameraConnected: false,

      barcodeScannerOptions: [],
      loadingBarcodeScanner: false,
      barcodeScannerConnected: false,

      loadingSetRtPrinter: false,
      editingRtPrinterAddress: undefined,
      rtPrinterAddress: undefined,
      disableAskDevices: false,
      dontAskMeAgainLoading: false,
      error: false,
      errorMessage: undefined,
    }
  },
  mounted: function() {
    this.cameraConnected = cameraDevice.isDeviceConnected()
    cameraDevice.on('connect', (device) => {
      this.cameraConnected = true
    })

    this.barcodeScannerConnected = barcodeScannerDevice.isDeviceConnected()
    barcodeScannerDevice.on('connect', (device) => {
      this.barcodeScannerConnected = true
    })

    /*setTimeout(() => {
      if((!this.cameraConnected || !this.rtPrinterAddress) && !this.disableAskDevices) {
        this.dialog = true
      }
    }, 1000);*/
  },
  methods: {
    requestCameraOptions() {
      this.loadingCamera = true
      cameraDevice.getDeviceOptions().then((options) => {
        this.cameraOptions = options
        this.expanded = 'camera'
        this.loadingCamera = false
        this.error = false
      }).catch((error) => {
        this.showError('Occorre autorizzare la videocamera')
        this.loadingCamera = false
      })
    },
    selectCameraDevice(device) {
      this.loadingCamera = true
      cameraDevice.selectDevice(device).then(() => {
        this.expanded = undefined
        this.loadingCamera = false
      })
    },

    requestBarcodeScannerOptions() {
      this.loadingBarcodeScanner = true
      barcodeScannerDevice.getDeviceOptions().then((options) => {
        this.barcodeScannerOptions = options
        this.expanded = 'barcodeScanner'
        this.loadingBarcodeScanner = false
        this.error = false
      }).catch((error) => {
        if(error.message == 'no navigator hid') {
          this.showError('Funzione non disponibile sul questo browser')
          this.loadingBarcodeScanner = false
        } else {
          this.showError('Occorre autorizzare il dispositivo')
          this.loadingBarcodeScanner = false
        }
      })
    },
    selectBarcodeScannerDevice(device) {
      this.loadingBarcodeScanner = true
      barcodeScannerDevice.selectDevice(device).then(() => {
        this.expanded = undefined
        this.loadingBarcodeScanner = false
      })
    },

    closeDialog() {
      this.dialog = false
    },
    dontAskMeAgain() {
      this.dontAskMeAgainLoading = true
      this.$settings.set('disableAskDevices', true).then(() => {
        this.dialog = false
        this.dontAskMeAgainLoading = false
      })
    },
    confirmRtPrinterAddress() {
      this.loadingSetRtPrinter = true
      this.$settings.set('rtPrinterAddress', this.editingRtPrinterAddress).then(() => {
        this.loadingSetRtPrinter = false
        this.expanded = undefined
        this.rtPrinterAddress = this.editingRtPrinterAddress
      })
    },
    showError(message) {
      this.errorMessage = message
      this.error = true
    },
  },
  settings: {
    disableAskDevices: {
      bind: 'disableAskDevices'
    },
    rtPrinterAddress: {
      bind: 'rtPrinterAddress'
    }
  }
}
</script>

<style>

</style>