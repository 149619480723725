<template>
  <StandardDialog
    v-model="localDialog"
    @input="$emit('update:dialog', $event)"
    :dialog-height="null"
    :title="!!date ? dateToString('dd/mm/yyyy', new Date(date)) : undefined"
  >
    <div style="height: 70vh">
      <template v-for="timeLog in value">
        <StandardMenu :key="timeLog.id" v-model="menus[timeLog.id]" @input="setUpdatingTimeLog(timeLog)" fit-width absolute>
            <template v-slot:activator="{ attrs, on}">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-content>
                  <v-row>
                    <v-col>
                      <v-icon class="mx-2">mdi-location-enter</v-icon>
                      {{dateToString('dd/mm/yyyy', new Date(timeLog.openedAt))}}
                      {{dateToTimeString(new Date(timeLog.openedAt))}}
                    </v-col>
                    <v-col>
                      <template v-if="!!timeLog.closedAt">
                        <v-icon class="mx-2">mdi-location-exit</v-icon>
                        {{dateToString('dd/mm/yyyy', new Date(timeLog.closedAt))}}
                        {{dateToTimeString(new Date(timeLog.closedAt))}}
                      </template>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card width="300px" @input="setUpdatingTimeLog(timeLog)">
            <v-card-title>
              {{eventName(updatingTimeLog)}}
<!--               {{dateToTimeString(new Date(timeLog.openedAt))}} 
              {{!!timeLog.closedAt ? ' - ' + dateToTimeString(new Date(timeLog.closedAt)) : undefined}} -->
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click="closeMenu(timeLog)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <DateTimePicker
                      filled
                      v-model="timeLog.openedAt"
                    ></DateTimePicker>
                  </v-col>
                </v-row>
                <v-row v-if="!!timeLog.closedAt">
                  <v-col>
                    <DateTimePicker
                      filled
                      v-model="timeLog.closedAt"
                    ></DateTimePicker>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider></v-divider>
                <div class="d-flex justify-end pa-2">
                  <v-btn 
                    color="default" 
                    text
                    @loading="loadingSave"
                    @click="handleSave"
                  >Salva</v-btn>
                </div>
            </div>
          </v-card>
        </StandardMenu>
        <v-divider :key="timeLog.id + '-divider'"></v-divider>
      </template>
    </div>
  </StandardDialog>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import dateUtils from '@/mixins/common/dateUtils'
import { DateTimePicker } from 'likablehair-ui-components'
import timeLogService from '@/services/presences/timeLogs/timeLogs.service'


export default {
  name: "DayTimeLogs",
  components: { 
    StandardDialog,
    StandardMenu,
    DateTimePicker
  },
  mixins: [dateUtils],
  data: function() {
    return {
      localDialog: this.dialog,
      loadingSave: false,
      menus: {},
      updatingTimeLog: undefined
    }
  },
  props: {
    value: {
      type: Array
    },
    dialog: {
      type: Boolean,
      default: false
    },
    date: { }
  },
  methods: {
    eventName(event) {
      if (!!event) {
        let name = this.dateToTimeString(event.openedAt)
        if(!!event.closedAt) name += " - " + this.dateToTimeString(event.closedAt)
        return name
      }
    },
    setUpdatingTimeLog(timeLog) {
      this.$nextTick(() => {
        this.updatingTimeLog = {...timeLog}
      })
    },
    handleSave() {
      this.loadingSave = true
      timeLogService.update({
        id: this.updatingTimeLog.id,
        openedAt: this.updatingTimeLog.openedAt,
        closedAt: this.updatingTimeLog.closedAt,
      }).then(() => {
        this.loadingSave = false
        this.closeMenu(this.updatingTimeLog)
        this.$emit('reload-timelogs')
      })
    },
    closeMenu(timeLog) {
      this.menus[timeLog.id] = false
    }
  },
  watch: {
    value(newVal) {
      this.value = newVal
    },
    dialog(newVal) {
      this.localDialog = newVal
    }
  }
}
</script>

<style>

</style>