<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Assenze"
    :route-father="routeFather"
  >
    <template v-slot:content>
      <div
        class="d-flex justify-center align-center"
        v-if="loadingOperators"
      >
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <template v-else>
        <v-list 
          v-if="operators.length > 0" 
          two-line 
          color="transparent"
        >
          <div
            v-ripple
            @click="openOperatorAbsences(operator)"
            v-for="operator in operators"
            v-bind:key="operator.id"
          >
            <v-list-item v-if="searchMatch(operator)">
              <v-list-item-avatar class="my-0">
                <OperatorAvatar
                  :value="operator"
                ></OperatorAvatar>
              </v-list-item-avatar>
              <v-list-item-content class="text-left">
                <v-row class="ml-2">
                  <v-col class="pa-0" md="auto">
                    <v-list-item-title>
                      {{ operator.firstname }} {{ operator.lastname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <div
          v-else
          class="font-weight-light font-italic d-flex align-content-center"
          style="width: 100%"
        >
          <span style="margin: auto">Nessun operatore</span>
        </div>
      </template>
    </template>
  </FullScreenDialog>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import operatorsService from "@/services/operators/operators.service.js"
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";

import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import StandardMenu from '@/components/common/StandardMenu.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';

export default {
  name: "Absences",
  components: { 
    OperatorAvatar,
    StandardMenu,
    StandardDialog,
    FullScreenDialog
  },
  data: function () {
    let props = this.$router.resolve({name: 'Presence'});

    return {
      operators: [],
      operatorEditFormDialog: false,
      loadingOperators: false,
      loading: false,
      operatorFormBus: new Vue(),
      dialog: true,
      routeFather: props.href,
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    searchText: {
      type: String,
    }
  },
  mounted: function () {
    this.loadOperators();
  },
  methods: {
    searchMatch: function (operator) {
      if (
        operator == undefined ||
        operator == null ||
        this.searchText == undefined ||
        this.searchText == null ||
        this.searchText == ""
      )
        return true;
      else {
        return (
          operator.firstname.toLowerCase().includes(this.searchText.toLowerCase()) ||
          operator.lastname.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
    },
    loadOperators() {
      this.loadingOperators = true;
      operatorsService.cachedList().then((results) => {
        this.operators = results;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOperators = false;
      })
    },
    openOperatorAbsences(operator) {
      this.$router.push({
        name: "OperatorAbsences",
        params: {
          operator: operator,
          operatorId: operator.id
        }
      })
    }
  },
  computed: {},
  watch: {},
};
</script>