<template>
  <v-snackbar 
    v-model="snackbar"
    multi-line
    :timeout="timeout"
  >
    {{message}}
    <template v-slot:action="{ }">
      <v-btn
        color="error"
        text
        @click.stop.prevent="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import snackBarerDelegate from '@/delegates/snackBarer.delegate'

export default {
  data: function() {
    return {
      snackbar: false,
      message: undefined,
      timeout: undefined
    }
  },
  mounted: function() {
    let handler = ({ message, timeout }) => {
      this.message = message
      if(!!timeout) {
        this.timeout = timeout
      } else {
        this.timeout = undefined
      }
      this.snackbar = true
    }
    handler = handler.bind(this)
    snackBarerDelegate.on('call', handler)
  }
}
</script>

<style>

</style>