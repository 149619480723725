import Database from '@/services/offline/database.js'

import calendarCache from "./calendar/calendar.cache"
import operatorCache from "./operators/operator.cache"
import billsCache from "./bills/bills.cache"
import jobsCache from "./jobs/jobs.cache"
import shiftsCache from "./presences/shifts/shifts.cache"
import absencesCache from "./presences/absences/absences.cache"
import todayBillsCache from "./bills/todayBills.cache"
import qualificationsCache from "./qualifications/qualifications.cache"
import frequentServicesCache from "./bills/frequentServices.cache"
import colorLinesCache from "./colorLines/colorLines.cache"
import countryPrefixesCache from "./countries/countryPrefixes.cache"

class AllCache extends Database {
  constructor() {
    super()
  }

  async clear() {
    console.log('cache.clear() called')
    await calendarCache.deleteAllCache()
    await operatorCache.deleteAllCache()
    await billsCache.deleteAllCache()
    await jobsCache.deleteAllCache()
    await shiftsCache.deleteAllCache()
    await absencesCache.deleteAllCache()
    await todayBillsCache.deleteAllCache()
    await qualificationsCache.deleteAllCache()
    await frequentServicesCache.deleteAllCache()
    await colorLinesCache.deleteAllCache()
    await countryPrefixesCache.deleteAllCache()
    console.log('cache.clear() done')
  }
}

export default new AllCache()