class AnalyticNamesMappings {
  constructor() {
    this.nameMappings = {
      'financialByTags': ['Riepilogo', 'Finanziario'],
      'balanceByTags': ['Riepilogo', 'Periodo', 'Commerciale'],
      //'compareBalanceByTags': ['Confronta', 'Periodi', 'Commerciale'],
      'customersByFidelization': ['Fidelizzazione/', 'Frequenza Clienti'],
      'customersTurnover': ['Turnover', 'Clienti'],
      'customersFrequencyList': ['Frequenza', 'Clienti'],
      'customersByAgeGroup': ['Clienti per', 'Fasce d\'età'],
      'operatorsBalance': ['Riepilogo', 'Commerciale', 'Operatore'],
      'tags': ['Tags'],
      'tagsCategories': ['Categorie', 'Tag'],
      'topSellingItems': ['Prodotti', 'Best', 'Seller'],
      'timeRanges': ['Fasce', 'Orarie'],
      'historyOperations': ['Storico', 'Operazioni'],
      'serviceConsume': ['Efficientamento', 'Prodotti'],
      'warehouseStatus': ['Stato', 'Magazzino'],
      'bistrotBalance': ['Riepilogo', 'Bistrot'],
      'balanceByYear': ['Confronta', 'Periodi', 'Commerciale'],
      'serviceTypesBalance' : ['Riepilogo', 'Servizi'],
      'goalsBalance': ['Analisi', 'Obiettivi'],
    }

    this.iconAnalyticsMappings = {
      'financialByTags': 'mdi-scale-balance',
      'balanceByTags': 'mdi-tag-text-outline',
      //'compareBalanceByTags': 'mdi-cash-register',
      'operatorsBalance': "mdi-account-cash",
      'customersByFidelization': 'mdi-account-heart',
      'customersTurnover': 'mdi-account-cash',
      'customersFrequencyList': 'mdi-account-multiple',
      'customersByAgeGroup': 'mdi-book-open-page-variant',
      'tags': 'mdi-tag-multiple',
      'tagsCategories': 'mdi-tag',
      'topSellingItems': 'mdi-cart',
      'timeRanges': 'mdi-clock-outline',
      'historyOperations': 'mdi-clipboard-text-clock-outline',
      'serviceConsume': 'mdi-speedometer-slow',
      'warehouseStatus': 'mdi-package',
      'bistrotBalance': 'mdi-tea',
      'balanceByYear': 'mdi-abacus',
      'serviceTypesBalance': 'mdi-seat',
      'goalsBalance': 'mdi-target',
    }

    this.operatorsBalanceByTagsMapping = {
      'averageFish': 'F. Media',
      'billLogNumber': 'Numero P. Cassa',
      'perc': '%',
      'total': 'Totale',
      'averageExpense': 'Spesa Media',
      'averageExpensePerson': 'Spesa Media a Persona',
      'averagePieceNumber': 'Numero Servizi/Pezzi Medio',
      'averageNumber': 'N. Medio su Passaggi in Cassa',
      'customerNumber': 'N. Clienti',
      'customerNumberPerc': '% N. Clienti',
      'number': 'Numero',
      'numberItems': 'Numero Prodotti',
      'numberServices': 'Numero Servizi',
      'service': 'Servizi',
      'item': 'Prodotti'
    }

    this.balangeByTagsMappings = {
      'averageItemExpenseByCustomer': { header: { text: 'SPESA MEDIA CLIENTE PER ACQUISTO PROD.', tooltip: "SI INTENDE LA MEDIA DELLE SPESA EFFETTUATA DAL CLIENTE, RICAVATA DAL TOTALE GENERATRO DALLE VENDITE DIVISO IL NUMERO DEI CLIENTI CHE HANNO COMPRATO" }, symbol: ' €'},
      'averageItemsValue': { header: { text: 'VAL. MEDIO PROD. VENDUTO', tooltip: "SI INTENDE LA MEDIA VALORE DIVIDENDO IL TOTALE GENERATO DALLE VENDITE DIVISO IL NUMERO DI PRODOTTI VENDUTI" }, symbol: ' €'},
      'averageServicesByTrans': { header: { text: 'MEDIA DI SERVIZI EROGATI PER TRANS. DI CASSA', tooltip: "SI INTENDE LA MEDIA DI SERVIZI (ESCLUSI I PRODOTTI) PER I PASSAGGI CASSA EFFETTUATI" }, symbol: ''},
      'averageServicesValue': { header: { text: 'VAL. MEDIO SERVIZI EFFETTUATI', tooltip: "SI INTENDE LA MEDIA VALORE DIVIDENDO IL TOTALE GENERATO DALLE VENDITE DIVISO IL NUMERO DI PRODOTTI VENDUTI"}, symbol: ' €'},
      'billLogNumber': { header: { text: 'N° TRANS. TOTALI', tooltip: "SI INTENDE IL NUMERO DI PERSONE TRANSITATE NEL PERIODO RICHIAMATO, QUINDI SE LA STESSA PERSONA E' TRANSITATA PIU VOLTE NEL PERIODO VERRA' CONTEGGIATA UNA SOLA VOLTA" }, symbol: ''},
      'customerNumber': { header: { text: 'N° CLIENTI SERVITI', tooltip: "SI INTENDE L'INCIDENZA DELL'IMPORTO GENERATO DALLE VENDIRE RISPETTO IL TOTALE FINANZIARIO GENERATO DAL REPARTO" }, symbol: ''},
      'itemsNumber': { header: {text: 'N° PRODOTTI VENDUTI', tooltip: "SI INTENDE IL TOTALE DEI PRODOTTI VENDUTI PER IL PERIODO RICHIAMATO" }, symbol: ''},
      'permanenceTime': { header: { text: 'TEMPI MEDI DI PERMANENZA IN STORE', tooltip: "SI INTENDE LA MEDIA DI PERMANENZA DEL CLIENTE PRESSO LA STRUTTURA OTTENUTA DALLA SOMMA DEI TEMPI SUDDIVISI IL NUMERO DI PASSAGGI CASSA" }, symbol: 'min'},
      'effectiveTime': { header: { text: 'TEMPI EFFETTIVI DEL SERVIZIO EROGATO', tooltip: "SI INTENDE LA COMPARATIVA DEI TEMPI EFFETTUATI SUL CLIENTE IN FUNZIONE DEI SERVISI CHE HA RICEVUTO CON IL DELTA IN MINUS O PLUS % E NUMERICO" }, symbol: 'min'},
      'ratioCustomerItem': { header: { text: '% CLIENTI CHE HANNO ACQUISTATO', tooltip: "SI ESPRIME LA PERCENTUALE DI CLIENTI CHE HANNO ACQUISTATO ALMENO UN PRODOTTO (NON SI INTENDE VS PASSAGGI CASSA)" }, symbol: ' %'},
      'ratioCustomerWithPromo': { header: { text: '% CLIENTI CON PROMO ASSEGNATE', tooltip: "SI INTENEDE LA % DI CLIENTI CHE GODONO DI UNA PROMOZIONE SUL TOTALE DEI CLIENTI TRANSITATI (NON PASSAGGI CASSA)" }, symbol: ' %'},
      'ratioDiscounts': { header: { text: 'INCIDENZA MEDIA % SCONTI EFFETTUATI', tooltip: "SI INTENDE LA MEDIA % DI PERDITA DI ECONOMIA DESTINATA A PROMOZIONI E SCONTI COMPRESI SCONTI TEMPORANEI" }, symbol: ' %'},
      'ratioTotalItem': { header: { text: '% DI RIVENDITA SU TOT. FINANZIARIO', tooltip: "SI INTENDE L'INCIDENZA DELL'IMPORTO GENERATO DALLE VENDIRE RISPETTO IL TOTALE FINANZIARIO GENERATO DAL REPARTO" }, symbol: ' %'},
      'ratioTotalService': { header: { text: '% FINANZIARIA DI SERVIZI EFFETTUATI SU TOT', tooltip: "SI INTENDE IL TOTALE FINANZIARIO GENERATO COME INCIDENZA SUL TOTALE DEL REPARTO" }, symbol: ' %'},
      'servicesNumber': { header: { text: 'N° SERVIZI EFFETTUATI', tooltip: "IL TOTALE DEI SERVIZI EFFATTUATI DAI CLIENTI PRESSO IL REPARTO" }, symbol: ''},
      'total': { header: { text: 'TOTALE GENERALE PRODUZIONE', tooltip: "TOTALE DELLE TRANSAZIONI DI CASSA ANCHE SE MOLTI PASSAGGI EFFETTUATI DALLO STESSO CLIENTE" }, symbol: ' €'},
      'percentageOverTotal': { header: { text: '% INCIDENZA', tooltip: "SARA' IL DELTA TRA LA % DI PRODUZIONE SUL TOTALE GENERALE" }, symbol: ' %'},
      'tagCategoryName': { header: { text: 'REPARTO', tooltip: "NOME REPARTO" }, symbol: ''},
      'averageValue': { header: { text: 'VAL. MEDIO PER TRANS.', tooltip: "SI INTENDE IL VALORE TOTALE DELLE TRANSAZIONI DIVISO IL NUMERO DELLE TRANSAZION" }, symbol: ' €'},
      'cashedInvoices': { header: { text: 'FATTURE INCASSATE', tooltip: "SI INTENDE IL NUMERO DI FATTURE INCASSATE" }, symbol: ''},
    }

    this.historyOperationsOpsMappings = {
      'create': 'Creazione',
      'update': 'Modifica',
      'delete': 'Eliminazione',
      'permission': 'Permessi'
    }

    this.historyOperationsContextMappings = {
      'bills': 'Transazioni', 
      'bill_services': 'Transazioni',
      'bill_items': 'Transazioni',
      'bill_customers': 'Transazioni', 
      'payment_transactions': 'Transazioni',
      'cash_desks': 'Casse',
      'invoices': 'Fatture', 
      'invoice_customers': 'Fatture', 
      'invoice_items': 'Fatture', 
      'invoice_services': 'Fatture', 
      'invoice_generic_elements': 'Fatture',
      'invoice_suppliers': 'Fatture',
      'payment_debits': 'Spese',
      'promos': 'Promozioni Marketing',
      'promos_customers': 'Promo su clienti',
      'absences': 'Assenze',
      'absence_types': 'Tipologie Assenze',
      'shifts': 'Turno Operatore',
      'time_tables': 'Turno',
      'customers': 'Clienti',
      'consent_forms': 'GDPR',
      'operators': 'Operatori',
      'suppliers': 'Fornitori',
      'items': 'Prodotti',
      'movements': 'Movimenti',
      'ghost': 'Corrispettivi',
      'warehouse': 'Magazzino',
      'timelog': 'Presenze',
      'applydiscounts': 'Sconti',
      'registries': 'Anagrafiche',
      'payments': 'Pagamenti',
      'barcode': 'Badge Operatore',
      'gdpr': 'GDPR',
      'closecashdesk': 'Chiusura Cassa',
      'historyoperations': 'Visualizzazione Storico Op.',
      'skipsuspendedpayments': 'Salta Sospesi',
      'intermediaryoperator': 'Procacciatore d\'affari'
    }

    this.historyOperationsDetailsMapping = {
      'target': 'A obiettivo',
      'simple': 'Semplice',
      'price': 'Prezzo',
      'discount': 'Sconto',
      'amount': 'Valore',
      'services': 'Servizi',
      'servicesDiscounted': 'Servizi scontati',
      'othertocash': 'Da incassare',
      'creditnote': 'Nota a Credito',
      'othertopay': 'Da pagare',
      'cashdesk': 'Cassa',
      'cashed': 'Incassata',
      'pending': 'Da Pagare/Incassare',
      'paid': 'Pagata',
      'unload': 'Scarico',
      'upload': 'Carico'
    }
  }

  getHumanName(codedName) {
    return this.nameMappings[codedName]
  }

  getKeyNameFromValue(humanName) {
    let arrayName = humanName.split(' ')
    return Object.keys(this.nameMappings).find(key => (this.nameMappings[key].length === arrayName.length
      && this.nameMappings[key].every((el, idx) => el.toLowerCase().includes(arrayName[idx]))))
  }

  getAnalyticIcon(analyticName) {
    return this.iconAnalyticsMappings[analyticName]
  }

  getBalanceTagsName(codeName) {
    return this.balangeByTagsMappings[codeName]
  }

  getOperatorBalanceByTags(codeName) {
    return this.operatorsBalanceByTagsMapping[codeName]
  }

  getHistoryOperationsOp(codename) {
    return this.historyOperationsOpsMappings[codename]
  }

  getHistoryOperationsContext(codename) {
    return this.historyOperationsContextMappings[codename]
  }

  getHistoryOperationsDetails(codename) {
    return this.historyOperationsDetailsMapping[codename]
  }
}

export default new AnalyticNamesMappings()