import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import openedCashDeskDatabase from './cashDesk.database'
import _ from 'lodash'

class cashDesksManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/cashDesks/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(cashDesk) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/cashDesks/create', cashDesk).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(cashDesk) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/payments/cashDesks/update', cashDesk).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  disable(cashDesk) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!cashDesk || !cashDesk.id) {
        reject(new Error('cash desk not specified'))
        return
      }

      axios.post(apiUrl + '/payments/cashDesks/disable', {
        id: cashDesk.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  enable(cashDesk) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!cashDesk.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/payments/cashDesks/enable', {
        id: cashDesk.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  open(cashDesk, operator) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!cashDesk || !cashDesk.id || !cashDesk.name) {
        reject(new Error('cash desk not specified'))
        return
      } else if (!operator || !operator.id || !operator.firstname || !operator.lastname) {
        reject(new Error('operator not specified'))
        return
      }

      axios.post(apiUrl + '/payments/cashDesks/' + cashDesk.id + '/open', {
        id: operator.id
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          delete cashDesk['status']

          this.saveOpened(cashDesk, operator, response.results).then((results) => {
            axios.setHeader("cashdesk-token", results["cashDeskLog"]["token"], 365)
            resolve(results)
          }).catch((error) => {
            reject(error)
          })
        }
      })
    })
  }

  close(cashDesk, operator, force = false) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!cashDesk || !cashDesk.id) {
        reject(new Error('cash desk not specified'))
        return
      } else if (!operator || !operator.id) {
        reject(new Error('operator not specified'))
        return
      }

      axios.post(apiUrl + '/payments/cashDesks/' + cashDesk.id + '/close', {
        id: operator.id,
        force: force
      }).then((response) => {
        if (!response.success) {
          reject(response.results)
        } else {
          this.clearOpened().then(() => {
            axios.setHeader("cashdesk-token", null, 0)
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        }
      })
    })
  }

  getOpened() {
    return openedCashDeskDatabase.getOpenedCashDesk()
  }

  saveOpened(cashDesk, operator, log) {
    if(!cashDesk || !cashDesk.id || !cashDesk.name) return Promise.reject(new Error("cash desk is not specified"))
    return openedCashDeskDatabase.saveOpenedCashDesk(cashDesk, operator, log)
  }

  clearOpened() {
    return openedCashDeskDatabase.clearOpenedCashDesk()
  }

  get(cashDeskId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/payments/cashDesks/get', { id: cashDeskId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }


  fields() {
    return Promise.resolve([
      { text: 'Nome', value: 'name' },
      { text: '', value: 'button', type: 'custom' },
    ])
  }
}

export default new cashDesksManagment();
