import EventsHandler from '@/services/common/eventsHandler'

class WarningDialog extends EventsHandler {
  constructor() {
    super()
  }

  call(params) {
    if(!params.message && !params.title) {
      return
    } else {
      this._executeAsyncCallbacksOf('call', params)
    }
  }
}

export default new WarningDialog()