import authService from "@/services/auth/auth.service";

const invoicesToCashRoutes = [
  {
    path: "/payments/invoicesToCash/list",
    name: "InvoicesToCashList",
    component: () =>
      import(
        "@/components/areas/payments/invoicesToCash/InvoicesToCashList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  },
  {
    path: "/payments/invoicesToCash/:id/edit",
    name: "InvoicesToCashEditForm",
    component: () => import("@/components/areas/payments/invoicesToCash/InvoicesToCashEditForm.vue"),
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        openDialog: true,
        variant: route.params.variant,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/invoicesToCash/:id/editCreditNote",
    name: "InvoicesCreditNoteEditForm",
    component: () => import("@/components/areas/payments/invoicesToCash/InvoicesCreditNoteEditForm.vue"),
    props: (route) => {
      return {
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        openDialog: true,
        variant: route.params.variant,
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/invoicesToCash",
    name: "InvoicesToCashNewForm",
    component: () => import("@/components/areas/payments/invoicesToCash/InvoicesToCashNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/invoicesToCash/CreditNote",
    name: "InvoicesCreditNoteNewForm",
    component: () => import("@/components/areas/payments/invoicesToCash/InvoicesCreditNoteNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
];

export default invoicesToCashRoutes;