import Database from '@/services/offline/database.js'

class DevicesDatabase extends Database {
  constructor() {
    super()
  }

  _storeName() {
    return 'devices'
  }

  saveDevice(category, device) {
    let self = this
    return new Promise((resolve, reject) => {
      this.dbPromise.then((db) => {
        db.put(self._storeName(), device, category)
        resolve()
      })
    })
  }

  getDevice(category) {
    let self = this
    return new Promise((resolve, reject) => {
      this.dbPromise.then((db) => {
        db.get(self._storeName(), category).then((results) => {
          resolve(results)
        })
      })
    })
  }
}

export default new DevicesDatabase()