import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import shiftsCache from "./shifts.cache";

class ShiftService {
  constructor() {
    this._alreadyLoaded = false
  }

  async cachedList({ start, end }) {
    if (!this.alreadyLoaded) {
      await shiftsCache.deleteAllCache()
      this.alreadyLoaded = true
    }

    let differenceInTime = new Date(end).getTime() - new Date(start).getTime()
    let differenceInDays = differenceInTime / (1000 * 3600 * 24)

    let results = []
    for (let i = 0; i <= differenceInDays; i += 1) {
      const d = new Date(new Date(start).getTime() + (i * (1000 * 3600 * 24)))
      const month = d.getMonth() + 1
      const date = d.getDate()
      const timestamp = d.getFullYear().toString() + (month < 9 ? '0' + month : month).toString() + (date < 9 ? '0' + date : date).toString()

      if (await shiftsCache.cachePresent({ day: timestamp })) {
        results = results.concat(await shiftsCache.getDay({
          day: timestamp
        }))
      } else {
        const startFilter = new Date(d)
        startFilter.setHours(0, 0, 0, 0)
        const endFilter = new Date(d)
        endFilter.setHours(23, 59, 59, 999)

        await this.reloadDayCache({ date: d })
        results = results.concat(await shiftsCache.getDay({
          day: timestamp
        }))
      }
    }

    return results
  }

  async reloadDayCache({ date }) {
    if (!date) throw new Error('date should be provided')
    const startFilter = new Date(date)
    startFilter.setHours(0, 0, 0, 0)
    const endFilter = new Date(date)
    endFilter.setHours(23, 59, 59, 999)

    const shifts = await this.list(1, 1000, {
      start: startFilter,
      end: endFilter,
    })

    const month = new Date(date).getMonth() + 1
    const day = new Date(date).getDate()
    const timestamp = new Date(date).getFullYear().toString() + (month < 9 ? '0' + month : month).toString() + (day < 9 ? '0' + day : day).toString()

    await shiftsCache.cacheDay({
      day: timestamp,
      shifts: shifts.rows
    })
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/shifts/list', {page, rowPerPage, filters}).then((response) => {
        if(response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(timeTable, operator, shift) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if(!timeTable || !timeTable.id) {
        reject(new Error('time table not specified'))
        return
      } else if(!operator || !operator.id) {
        reject(new Error('operator not specified'))
        return
      } else if(!shift || !shift.date) {
        reject(new Error('shift date not specified'))
        return
      }

      axios.post(apiUrl + '/presences/shifts/create', {
        timeTableId: timeTable.id,
        operatorId: operator.id,
        date: shift.date
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(shift) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if(!shift && !shift.id) {
        reject(new Error("shift is not specified")) 
      }
      axios.get(apiUrl + '/presences/shifts/delete', {
        id: shift.id
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default new ShiftService()