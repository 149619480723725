import settingService from './settings.service'

export default {
  install: (Vue, options) => {
    Vue.mixin({
      created: function() {
        if (!!this.$options.settings) {
          let bindings = []
          for(const [settingKey, value] of Object.entries(this.$options.settings)) {
            if (!!value.initial) {
              let bindFunction = value.initial.bind(this)
              bindFunction(settingService.getFromCache(settingKey))
            }

            if(!!value.change) {
              let bindFunction = value.change.bind(this)
              settingService.on('update-' + settingKey, bindFunction)
            }

            if(!!value.bind) {
              if (bindings.includes(value.bind))
                console.warn('Try to bind a variable that has been already bind (' + value.bind + ')')
              else
                bindings.push(value.bind)

              this[value.bind] = settingService.getFromCache(settingKey)
              let updateCallback = (newValue) => {
                this[value.bind] = newValue
              }
              updateCallback = updateCallback.bind(this)
              settingService.on('update-' + settingKey, updateCallback)
            }

          }
        }
      }
    })

    Vue.prototype.$settings = {
      set: function (key, value) {
        return new Promise((resolve, reject) => {
          settingService.set(key, value).then((result) => {
            resolve(result)
          }).catch((err) => {
            reject(err)
          })
        })
      },
      get(key) {
        return new Promise((resolve, reject) => {
          settingService.get(key).then((result) => {
            resolve(result)
          }).catch((err) => {
            reject(err)
          })
        })
      }
    }
  }
}