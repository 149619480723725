import authService from "@/services/auth/auth.service";
const schedulePaymentsSettingsRoutes = [
  {
    path: "/payments/schedulePaymentsSettings",
    name: "SchedulePaymentsSettingsNewForm",
    component: () => import("@/components/areas/payments/schedulePaymentsSettings/SchedulePaymentsSettingsNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/schedulePaymentsSettings/:id/edit",
    name: "SchedulePaymentsSettingsEditForm",
    component: () => import("@/components/areas/payments/schedulePaymentsSettings/SchedulePaymentsSettingsEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/schedulePaymentsSettings/list",
    name: "SchedulePaymentsSettingsList",
    component: () =>
      import(
        "@/components/areas/payments/schedulePaymentsSettings/SchedulePaymentsSettingsList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  },
]
export default schedulePaymentsSettingsRoutes;
