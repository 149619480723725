import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class serviceTypesManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypes/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  archive(serviceType) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!serviceType.id)
        reject('id undefined')
      
      axios.post(apiUrl + 'registries/serviceTypes/archive', serviceType).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(serviceType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypes/create', serviceType).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(serviceType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypes/update', serviceType).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(serviceType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!serviceType.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/serviceTypes/delete', {
        id: serviceType.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(serviceTypeId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/serviceTypes/get', { id: serviceTypeId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  getServices(serviceTypeId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/serviceTypes/getServices', { id: serviceTypeId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  updateOrderServices(services){
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/serviceTypes/updateOrderServices', services).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }

  fields() {
    return Promise.resolve([
      { text: 'Nome', value: 'name' },
      { text: 'Descrizione', value: 'description' },
      { text: 'Limite Giorni Alta Fidelizzazione', value: 'highFidelityDays' },
      { text: 'Limite Giorni Bassa Fidelizzazione', value: 'mediumFidelityDays' },
      { text: 'Colore', value: 'color', type: 'color' },
    ])
  }
}

export default new serviceTypesManagment();
