import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import dataSheetService from '@/services/dataSheets/dataSheets.service.js'
import recentOperationsDatabase from '@/services/offline/registries/registriesRecentOperations.database.js'
import paymentCreditsService from "@/services/paymentCredits/paymentCredits.service";
import DateHandler from "@/services/common/dateHandler";
import billsService from "@/services/bills/bills.service";

class CustomerManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }
  list_pagination(page, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/list', {
        page: page,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  create(customer, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      let dataSheets = []
      if (!!customer.beuticianDataSheets) {
        dataSheets = [...dataSheets, ...customer.beuticianDataSheets]
        delete customer.beuticianDataSheets
      }

      if (!!customer.barberDataSheets) {
        dataSheets = [...dataSheets, ...customer.barberDataSheets]
        delete customer.barberDataSheets
      }

      if (!!customer.pmuDataSheets) {
        dataSheets = [...dataSheets, ...customer.pmuDataSheets]
        delete customer.pmuDataSheets
      }

      axios.post(apiUrl + '/registries/customers/create', customer).then((response) => {
        if (response.success) {
          let promises = []

          if (recentOperation) {
            promises.push(recentOperationsDatabase.pushOperation(this._buildRecentOperationObject('create', customer)))
          }

          if (dataSheets.length > 0) {
            for (let i = 0; i < dataSheets.length; i++) {
              promises.push(dataSheetService.create(response.results, dataSheets[i]))
            }
          }

          Promise.all(promises).then(() => {
            resolve(response.results)
          }).catch((error) => {
            reject(error)
          })
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(customer, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/update', customer).then(async (response) => {
        if (response.success) {

          if (recentOperation) {
            await recentOperationsDatabase.pushOperation(this._buildRecentOperationObject('update', customer))
              .then(() => { })
          }

          await billsService.reloadAllTodayCachedFilterList()
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  applyAllWhatsapp(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/applyAllWhatsapp', { filters }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getPaymentCredits(customer, filters) {
    if (!customer || !customer.id) return Promise.reject(new Error('customer is not specified'))
    let newFilters = [{ type: 'custom', operator: 'custom', field: 'customer', value: customer.id }]
    if (!!filters) newFilters = [...newFilters, ...filters]
    return paymentCreditsService.list(1, 300, newFilters)
  }

  get(customerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/customers/get', { id: customerId }).then((response) => {
        if (response.success) {
          if (!!response.results.customerCards && response.results.customerCards.length > 0) {
            response.results.customerCard = response.results.customerCards[0]
          }

          response.results.preferredColorOperatorId = response.results.preferredOperatorColor.map(a => a.id)
          response.results.preferredHairCutOperatorId = response.results.preferredOperatorCut.map(a => a.id)
          response.results.preferredHairCurlerOperatorId = response.results.preferredOperatorCurler.map(a => a.id)

          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  archive(customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customer.id)
        reject('id undefined')

      axios.post(apiUrl + 'registries/customers/archive', customer).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customer.id)
        reject('id undefined')

      axios.get(apiUrl + 'registries/customers/delete', customer).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  search(searchText) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/search', { searchText: searchText }).then((response) => {
        if (response.success) {
          for (let i = 0; i < response.results.length; i++) {
            response.results[i].text = response.results[i].firstname + ' ' + response.results[i].lastname
          }
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  _buildRecentOperationObject(operation, customer) {
    let routeParams;
    if (operation == 'update') {
      routeParams = { id: customer.id }
    }

    let extraText;
    if (operation == 'update') {
      extraText = customer.firstname + ' ' + customer.lastname
    }

    return {
      "text": operation == "create" ? "Aggiungi nuovo Cliente" : "Modifica Cliente",
      "extraText": extraText,
      "name": operation == "create" ? "add_customers" : "edit_customers",
      "operationName": operation == "create" ? "add" : "edit",
      "registry": "customers",
      "params": {
        supplier: customer,
        routeName: operation == "create" ? 'CustomersRegistryNewForm' : 'CustomersRegistryEditForm',
        routeParams: routeParams
      }
    }
  }

  printGdpr(customer) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + '/registries/customers/printGdpr', undefined, 'GDPR.pdf').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getGdpr(customerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + '/registries/customers/getGdpr/' + customerId, undefined, 'GDPR_' + customerId + '.pdf').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  deleteSignature(customerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/deleteGdpr/' + customerId).then(response => {
        if (response.success)
          resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  listCustomerCardPayments(customerId, page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/customers/customerCards/listTotalPayments', { id: customerId, page: page, rowPerPage: rowPerPage }).then((response) => {
        resolve(response.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }

  listPaymentsByIntermediaryCompanyId(companyId, from, to, ghost = false, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/listPaymentsByIntermediary/' + companyId, { 
        from: from, 
        to: to,
        ghost: ghost,
        filters: filters
      }).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  listBarberColoringSettings(customerId, page, rowPerPage) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/customers/listBarberColoringSettings/' + customerId, {
        page: page,
        rowPerPage: rowPerPage
      }).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  uploadSignature(customerId, data) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customerId) {
        reject('id not specified')
        return
      }


      axios.post(apiUrl + '/registries/customers/' + customerId + '/uploadSignature', data).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  uploadAvatar(customerId, data) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customerId) {
        reject('id not specified')
        return
      }

      axios.post(apiUrl + '/registries/customers/' + customerId + '/uploadAvatar', data).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  reloadAvatar(customerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customerId) {
        reject('id not specified')
        return
      }

      axios.get(apiUrl + '/registries/customers/reloadAvatar/' + customerId).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  deleteAvatar(customerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/deleteAvatar/' + customerId).then(response => {
        if (response.success)
          resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getCustomerPromosById(customerId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!customerId) {
        reject('id not specified')
        return
      }

      axios.get(apiUrl + '/registries/customers/getCustomerPromosById/' + customerId).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getFromListIds(listIds) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!listIds) {
        reject('ids not specified')
        return
      }
      let filters = {}
      filters.ids = listIds

      axios.post(apiUrl + '/registries/customers/list', {
        page: 1,
        rowPerPage: listIds.length,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  monthList() {
    return new Promise((resolve, reject) => {
      let months = {
        rows: [
          { value: 'Gennaio', id: 0 },
          { value: 'Febbraio', id: 1 },
          { value: 'Marzo', id: 2 },
          { value: 'Aprile', id: 3 },
          { value: 'Maggio', id: 4 },
          { value: 'Giugno', id: 5 },
          { value: 'Luglio', id: 6 },
          { value: 'Agosto', id: 7 },
          { value: 'Settembre', id: 8 },
          { value: 'Ottobre', id: 9 },
          { value: 'Novembre', id: 10 },
          { value: 'Dicembre', id: 11 }]
      }
      resolve(months)
    })
  }


  getAll() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/customers/list', {
        page: 1,
        rowPerPage: 50000,
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  fields() {
    return Promise.resolve([
      { text: 'Cognome', value: 'lastname' },
      { text: 'Nome', value: 'firstname' },
      { text: 'Citta', value: 'city' },
      { text: 'Codice Fiscale', value: 'fiscalCode' },
      { text: 'Data di nascita', value: 'birthday', type: 'date' },
      { text: 'Telefono', value: 'telephone' },
      { text: 'Cellulare', value: 'cellphone' },
      { text: 'Email', value: 'email' },
      { text: 'Invio messaggi tramite Whatsapp', value: 'sendByWhatsapp', type: 'bool' },
    ])
  }

  fastFields() {
    return new Promise((resolve, reject) => {
      this.fields().then((fields) => {
        resolve(fields.filter((el, index) => { return index < 4 }))
      })
    })
  }

  _fieldsToExport() {
    return {
      'Cognome': 'lastname',
      'Nome': 'firstname',
      'Sesso': {
        field: 'gender',
        callback: (value) => {
          return value == 'male' ? 'Maschio' : 'Femmina'
        }
      },
      'CAP': 'cap',
      'Provincia': 'province',
      'Citta': 'city',
      'Codice Fiscale': 'fiscalCode',
      "Data di nascita": {
        field: "birthday",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      'Telefono': 'telephone',
      'Cellulare': 'cellphone',
      'Email': 'email',
      'Professione': {
        field: 'job',
        callback: (value) => {
          return value ? value.name : ''
        }
      }
    }
  }
}

export default new CustomerManagment()