<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Modelli SMS/Whatsapp"
      :route-father="routeFather"
    >
      <template v-slot:content>
        <div 
          class="d-flex justify-center align-center mb-3 flex-column" 
          :style="{
              'height': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? 
                '28%' : '40%',
              'width': '100%'
            }" 
        >
          <v-row style="width: 100%;">
            <v-col>
              <AdvancedFilter
                style="width:99%; height: 100%"
                v-model="filtersValue"
                :filters="filterTypes"
                :advance-filters="advanceFilters"
                :external-filter="externalFilter"
                @apply-filters="applyFilters"
              >

                <template v-slot:chip-custom-smsName="{filter}">
                  Nome: {{ filter.value }}
                </template>

                <template v-slot:chip-custom-categories="{filter}">
                  Reparti: {{ filter.value.map(el => el.name).join(', ') }}
                </template>

                <template v-slot:chip-custom-smsText="{filter}">
                  Descrizione: {{ filter.value }}
                </template>

                <template v-slot:custom-categories="{filter}">
                  <ServiceTypesCategoryAutocomplete
                    v-model="filter.value"
                    :multiple="true"
                    :return-object="true"
                  ></ServiceTypesCategoryAutocomplete>
                </template>

                <template v-slot:custom-smsText="{filter}">
                  <v-text-field
                    v-model="filter.value"
                    :label="filter.label"
                    :placeholder="filter.label"
                    :outlined="true"
                    :clearable="true"
                    :rows="1"
                    :dense="true"
                    :filled="true"
                    :hide-details="true"
                    :max-height="100"
                    :max-width="300"
                    :min-height="100"
                    :min-width="300"
                  ></v-text-field>
                </template>
              </AdvancedFilter>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <ResponsiveCardSelector
                :items="goals"
                v-model="selectedGoal"
                item-key="name"
                card-max-height="80"
                card-min-height="80"
                :card-max-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                  $vuetify.breakpoint.lg ? '200' : '250'"
                :card-min-width="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md) ? '130' : 
                  $vuetify.breakpoint.lg ? '200' : '250'"
                align-items-item="center"
                :justify-center="true"
                :colors="['#ada2e0']"
                :colorOnSelected="true"
                @input="() => {
                  fetchSmsModels()
                }"
              >
                <template v-slot:item="{ item }">
                  <div class="pa-2 d-flex flex-column justify-center align-center">
                    <div 
                      class="text-center"
                      style="font-size: 0.85rem"
                    >
                      {{ item.text }}
                  </div>
                  </div>
                </template>
              </ResponsiveCardSelector>
            </v-col>
          </v-row>
        </div>

        <div 
          :style="{
            'height': $vuetify.breakpoint.xl || $vuetify.breakpoint.lg ? 
              '70%' : '58%',
            'overflow-y': 'auto'
          }" 
          ref="dataTableWrapper"
        >
          <TypeDataTable
            loading-text="Caricamento modelli ..."
            :headers="headers"
            :loading="loading"
            :items="paginatedSmsModelPresets"
            :page.sync="page"
            :row-per-page.sync="rowPerPage"
            :total-pages.sync="totalPages"
            :height="dataTableHeight"
            :show-select="false"
            :show-actions="true"
          >
            <template v-slot:actions="{ item }">
              <div class="d-flex align-center justify-center">
                <v-btn
                  icon
                  @click.stop="() => {
                    selectedSmsPreset = item
                    previewDialog = true
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </div>
            </template>
          </TypeDataTable>
        </div>
      </template>
    </FullScreenDialog>

    <StandardDialog
      v-model="previewDialog"
      :dialog-height="null"
      :title="!!selectedSmsPreset ? selectedSmsPreset.name : ''"
      :dialog-max-width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '90%' : '50%'"
    >
      <div
        class="d-flex justify-center align-center mt-12"
      >
        <SmsMobilePreviewForm
          width="250px"
          height="500px"
          :sms-model="selectedSmsPreset"
          :send-time="!!selectedSmsPreset ? selectedSmsPreset.sendTime : undefined"
          :selected-image="!!selectedSmsPreset ? !!selectedSmsPreset.selectedImages &&
            selectedSmsPreset.selectedImages.length > 0 ? selectedSmsPreset.selectedImages[0] : undefined
            : undefined"
        ></SmsMobilePreviewForm>
      </div>
    </StandardDialog>
  </div>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import ServiceTypesCategoryAutocomplete from '@/components/common/ServiceTypesCategoryAutocomplete.vue'
import StandardDialog from '@/components/common/StandardDialog.vue';
import SmsMobilePreviewForm from '@/components/areas/marketing/actions/previews/SmsMobilePreviewForm.vue';

import marketingActionsService from "@/services/marketingActions/marketingActions.service.js";

export default {
  name: "SmsModelPresetList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    ResponsiveCardSelector,
    ServiceTypesCategoryAutocomplete,
    StandardDialog,
    SmsMobilePreviewForm
  },
  data: function() {
    let props = this.$router.resolve({ name: 'Marketing' });

    return {
      routeFather: props.href,
      dialog: true,
      filterTypes: [
        { type: 'custom', operator: 'custom', field: 'smsText', name: 'Descrizione', label: 'Descrizione', color: "", value: [], icon: 'mdi-message-text' },
        { type: 'custom', operator: 'custom', field: 'categories', name: 'Reparti', label: 'Reparti', color: "", value: [], icon: 'mdi-tag-multiple' }
      ],
      advanceFilters: [
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'smsName', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      loading: false,
      dataTableHeightValue: 400,
      headers: [
        { text: 'Nome modello messaggio', value: 'name' },
        { text: 'Descrizione', value: 'text' },
        { text: 'Obiettivo', value: 'goal.text' },
        { text: 'Reparto', value: 'categories'}
      ],
      goals: [
        {
          name: 'increaseCustomerFrequency',
          text: 'Aumenta Frequenza Clienti',
          icon: 'mdi-human-capacity-increase'
        },
        {
          name: 'developSales',
          text: 'Sviluppa vendite',
          icon: 'mdi-cash-plus'
        },
        {
          name: 'developServiceTypeCategory',
          text: 'Promuovi un servizio o un reparto',
          icon: 'mdi-bullseye-arrow'
        },
        {
          name: 'newCustomers',
          text: 'Acquisisci nuovi Clienti',
          icon: 'mdi-account-plus'
        },
        {
          name: 'mostLoyalCustomers',
          text: 'Premia clienti più fedeli',
          icon: 'mdi-account-heart'
        },
        {
          name: 'informational',
          text: 'Informazionale ',
          icon: 'mdi-email-newsletter'
        },
      ],
      page: 1,
      rowPerPage: 20,
      totalPages: 0,
      smsModelPresets: [],
      paginatedSmsModelPresets: [],
      selectedSmsPreset: undefined,
      selectedGoal: undefined,
      previewDialog: false,
    };
  },
  mounted() {
    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.9
    
    this.fetchSmsModels()
  },
  methods: {
    goBack() {
      this.$router.push(this.routeFather);
    },
    calculatePaginatedSmsModels() {
      if (!this.smsModelPresets || this.smsModelPresets.length == 0) {
        this.paginatedSmsModelPresets = []
      }

      this.totalPages = Math.ceil(this.smsModelPresets.length / this.rowPerPage)

      let offset = (this.page - 1) * this.rowPerPage
      this.paginatedSmsModelPresets = this.smsModelPresets.slice(offset, (this.rowPerPage * this.page))
    },
    fetchSmsModels() {
      this.loading = true
      let advancedSearchFilters = [
        ...this.filtersValue,
      ]
      
      marketingActionsService.importedList({
        advancedSearchFilters,
        licenseId: true,
        tool: 'sms',
        goal: !!this.selectedGoal ? this.selectedGoal : undefined
      }).then((results) => {
        this.smsModelPresets = !!results ? results.map((el) => {
          return {
            'id': el.smsModel.id,
            'name': el.smsModel.name,
            'text': !!el.smsModel.text ? el.smsModel.text : '',
            'goal': this.goals.find((g) => {
              return g.name == el.goal
            }),
            'categories': !!el.categories ? el.categories.join(', ')
              : '',
            'sendTime': el.sendTime,
            'selectedImageId': !!el.selectedImageId ? el.selectedImageId : null,
            'selectedImages': !!el.selectedImages ? el.selectedImages : [],
            'imageTags': !!el.imageTags ? el.imageTags : [],
          }
        }) : []

        this.loading = false
      })
    },
    applyFilters() {
      this.fetchSmsModels()
    },
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    },
  },
  watch: {
    smsModelPresets() {
      this.calculatePaginatedSmsModels()
    },
    page() {
      this.calculatePaginatedSmsModels()
    },
    rowPerPage() {
      this.page = 1
      this.calculatePaginatedSmsModels()
      this.totalPages = Math.ceil(this.smsModelPresets.length / this.rowPerPage)
    },
  }
};
</script>

<style>
  .title-section {
    height: 2em;
    padding-right: 1em;
    padding-left: 1em;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: var(--background-color);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: none;
  }
</style>