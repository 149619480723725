import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth/auth.module";
import suppliers from "./registries/suppliers.module.js";
import operators from "./registries/operators.module.js";
import customers from "./registries/customers.module.js";
import items from "./warehouse/items.module.js";
import orders from "./warehouse/orders.module";
import movements from "./warehouse/movements.module";
import tables from "./bar/tables.module";
import zones from "./bar/zones.module";
import reservations from "./bar/reservations.module";

Vue.use(Vuex);
export default new Vuex.Store({
  getters: {
    user(state){
      return state.user
    }
  },
  modules: { 
    auth, 
    registries: {
      namespaced: true,
      modules: {
        suppliers,
        operators,
        customers
      }
    },
    warehouse: {
      namespaced: true,
      modules: {
        items,
        orders,
        movements
      }
    },
    bar:{
      namespaced: true,
      modules: {
        tables,
        zones,
        reservations
      }
    }
  },
});
