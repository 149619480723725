import Vue from "vue";
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import hljs from 'highlight.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import settingsPlugin from "@/services/settings/settings.plugin";
import licenseSettingsPlugin from "./services/licenseSettings/licenseSettings.plugin";
import permissionsPlugin from "@/services/auth/permissions.plugin";
import translationsPlugin from "@/services/translations/translations.plugin";
import barcodeScannerPlugin from "@/services/devices/barcodeScanner.plugin";
import currentUserPlugin from "@/services/currentUser.plugin";
import blockedLicensePlugin from "@/services/auth/blockedLicense.plugin";
import delegatesPlugin from "@/delegates/delegates.plugin";
import "./registerServiceWorker";
import DateHandler from "./services/common/dateHandler";
import VueTouch from 'vue-touch';

import Prism from 'prismjs';

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});
VueMarkdownEditor.lang.use('en-US', enUS);
VMdPreview.use(githubTheme, {
  Hljs: hljs,
});

require('../public/style.min.css')

Vue.config.productionTip = false;
Vue.prototype.dateHandler = DateHandler;

Vue.use(settingsPlugin);
Vue.use(licenseSettingsPlugin);
Vue.use(permissionsPlugin);
Vue.use(translationsPlugin);
Vue.use(barcodeScannerPlugin);
Vue.use(delegatesPlugin)
Vue.use(currentUserPlugin)
Vue.use(blockedLicensePlugin)
Vue.use(VueMarkdownEditor);
Vue.use(VMdPreview);
VueTouch.registerCustomEvent('doubletap', {
  type: 'tap',
  taps: 2
});
Vue.use(VueTouch, {name: 'v-touch'})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
