/*
 %d = day (01)
 %p = day (1)
 %m = month (01)
 %Y = full year
 %H = hour (01)
 %M = minute (01)

 %w = weekday (monday)
 %t = month name (january)
*/

const DATE_FORMATS = {
    short: '%d/%m/%Y',
    american: '%m-%d-%Y',
    reverse: '%Y-%m-%d',
    extended: '%p %t, %Y',
    long: '%d/%m/%Y long'
  }
  
  const DATETIME_FORMATS = {
    short: '%d/%m/%Y, %H:%M',
    long: '%d/%m/%Y long'
  }
  
  const TIME_FORMATS = {
    short: '%H:%M',
    long: '%H:%M long'
  }
  
  const WEEKDAYS = [
    'Domenica',
    'Lunedì',
    'Martedì',
    'Mercoledì',
    'Giovedì',
    'Venerdì',
    'Sabato',
  ]
  
  const MONTHS = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ]

var DateHandler = {}

  
DateHandler.getMonth = function(date) {
    return ("0" + (date.getMonth() + 1)).slice(-2)
  }
  
DateHandler.getDate = function(date) {
    return ("0" + (date.getDate())).slice(-2)
  }
  
DateHandler.getHours = function(date) {
    return ("0" + (date.getHours())).slice(-2)
  }
  
DateHandler.getMinutes = function(date) {
    return ("0" + (date.getMinutes())).slice(-2)
  }
  
DateHandler.replace = function replace(date, format) {
    format = format.replace(/%d/g, getDate(date));
    format = format.replace(/%m/g, getMonth(date));
    format = format.replace(/%Y/g, date.getFullYear());
    format = format.replace(/%H/g, getHour(date));
    format = format.replace(/%M/g, getMinute(date));
    format = format.replace(/%p/g, date.getDate())
    format = format.replace(/%w/g, WEEKDAYS[date.getDay()])
    format = format.replace(/%t/g, MONTHS[date.getMonth()])
    return format
  }
  
  
  DateHandler.printDate = function (date, style) {
    if (date == undefined) { return undefined }
  
    if (style == undefined) { style = 'short' }
    var format = DATE_FORMATS[style]
  
    if(typeof(date) == 'string') {
      date = new Date(date)
    }
  
    format = replace(date, format);
    return format;
  }
  
  DateHandler.printDateTime = function (date, style) {
    if (date == undefined) { return undefined }
  
    if (style == undefined) { style = 'short' }
    var format = DATETIME_FORMATS[style]
  
    if (typeof (date) == 'string') {
      date = new Date(date)
    }
  
    format = replace(date, format);
    return format;
  }
  
  DateHandler.WEEKDAYS = WEEKDAYS
  DateHandler.MONTHS = MONTHS
  
  DateHandler.strfDatetime = function (date, format) {
    return replace(date, format)
  }
  
  DateHandler.printTime = function(date, style) {
    if (date == undefined) { return undefined }
  
    if (style == undefined) { style = 'short' }
    var format = TIME_FORMATS[style]
  
    if (typeof (date) == 'string') {
      date = new Date(date)
    }
    
    format = replace(date, format);
    return format;
  }
  
  DateHandler.parseDate = function(date, style) {
    if (style == undefined) { style = 'short' }
    var format = DATE_FORMATS[style]
  
    if (typeof (date) != 'string') {
      return date
    }
  
    var day, month, year;
    switch (style) {
      case 'reverse':
        day = date.substring(8, 10)
        month = date.substring(5, 7)
        year = date.substring(0, 4)
        break;
      default:
        day = format.indexOf('%d')
        day = date.substr(day, 2)
        month = format.indexOf('%m')
        month = date.substr(month, 2)
        year = format.indexOf('%Y')
        year = date.substr(year, 4)
        break;
    }
  
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  }
  
  DateHandler.inTheMorning = function (date) {
    if (typeof (date) == 'string') {
      date = new Date(date)
    }
  
    return date.getHours() < 14
  }
  
  DateHandler.inTheAfternoon = function (date) {
    if (typeof (date) == 'string') {
      date = new Date(date)
    }
    
    return date.getHours() >= 14
  }

  DateHandler._buildDateToExport = function (value, withTime = false) {
    if (value) {
      var date = new Date(value);
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();

      if(withTime) {
        var hh = String(date.getHours()).padStart(2, "0");
        var min = String(date.getMinutes()).padStart(2, "0");
        return yyyy + "-" + mm + "-" + dd + " " + hh + ":" + min;
      }
      else
        date = dd + "/" + mm + "/" + yyyy;
      
      return date;
    } else return;
  }

  
  export default DateHandler;