<template>
  <div>
    <TitleWithBackButton
      title="Gestione Presenze"
      back-route="Homepage"
      icon="mdi-calendar-account"
    ></TitleWithBackButton>
    <PresenceHome></PresenceHome>
  </div>
</template>

<script>
import PresenceHome from '@/components/areas/presences/PresencesHome.vue'
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';

export default {
  components:{
    PresenceHome,
    TitleWithBackButton
  },
  data: function () {
    return {
    };
  },
};
</script>

<style>
</style>