var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-popup",attrs:{"id":"chat"}},[_c('VoiceflowChat',{attrs:{"project-id":"6704dd927d4190b9b12578aa","open-chat":_vm.openChat,"extensions":[
      _vm.videoExtension, 
      _vm.timerExtension, 
      _vm.formExtension, 
      _vm.mapExtension, 
      _vm.fileUploadExtension, 
      _vm.kBUploadExtension, 
      _vm.dateExtension, 
      _vm.confettiExtension, 
      _vm.feedbackExtension
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }