<template>
  <FullScreenDialog
    v-model="otherCostDialog"
    @input="$emit('update:open-dialog', $event)"
    title="Nuovo Costo"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in otherCostsTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in otherCostsTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="() => { if(!!pathToGoBack) { $router.push({path: pathToGoBack}) } else { $emit('update:open-dialog', false) } }"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
        :loading="loading"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "@/components/areas/payments/otherCosts/form/GeneralFormTab.vue"
import otherCostsService from '@/services/otherCosts/otherCosts.service.js'
import otherCostsForm from '@/services/otherCosts/otherCosts.form.js'

export default {
  name: "OtherCostNewForm",
  components: {
    FullScreenDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      otherCostDialog: this.openDialog,
      otherCostsTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      formValid: false,
      otherCost: undefined,
      originalUrl: window.location.pathname,
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    otherCostsForm.resetOtherCosts()
    otherCostsForm.on('update', function(data) {
      self.otherCost = data.otherCost
    })

    otherCostsForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/payments"
    },
    tabName: {
      type: String,
      default: 'general'
    },
    precompiledForm: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.otherCostsTabs.length; i++) {
        if(this.otherCostsTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      otherCostsService.create(this.otherCost).then((result) => {
        if(this.pathToGoBack !== null) this.$router.push({path: this.pathToGoBack})
        this.loading = false
        this.$emit('update:openDialog', false)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.otherCostDialog = newVal;

      if(!!newVal) {
        if(this.precompiledForm.description !== undefined)
          otherCostsForm.updateField('description', this.precompiledForm.description)

        if(this.precompiledForm.emittedAt !== undefined)
          otherCostsForm.updateField('emittedAt', this.precompiledForm.emittedAt)

        if(this.precompiledForm.expirationDate !== undefined)
          otherCostsForm.updateField('expirationDate', this.precompiledForm.expirationDate)

        if(this.precompiledForm.otherCostAmounts !== undefined) {
          otherCostsForm.updateField('otherCostAmounts', this.precompiledForm.otherCostAmounts)
        }

        if(this.precompiledForm.supplier !== undefined && this.precompiledForm.supplierId !== undefined) {
          otherCostsForm.updateField('supplierId', this.precompiledForm.supplierId)
          otherCostsForm.updateField('supplier', this.precompiledForm.supplier)
        }

        if(this.precompiledForm.type !== undefined) {
          otherCostsForm.updateField('type', this.precompiledForm.type)
        }

        if(this.precompiledForm.intermediaryServicePercentage !== undefined) {
          otherCostsForm.updateField('intermediaryServicePercentage', this.precompiledForm.intermediaryServicePercentage)
        }
        
        if(this.precompiledForm.intermediaryProductPercentage !== undefined) {
          otherCostsForm.updateField('intermediaryProductPercentage', this.precompiledForm.intermediaryProductPercentage)
        }

        if (this.precompiledForm.servicesWithPercentages !== undefined) {
          otherCostsForm.updateField('servicesWithPercentages', this.precompiledForm.servicesWithPercentages)
        }
      }
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.otherCostsTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>