import authService from "@/services/auth/auth.service";
const otherCashesRoutes = [
  {
    path: "/payments/otherCashes",
    name: "OtherCashesNewForm",
    component: () => import("@/components/areas/payments/otherCashes/OtherCashesNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'general'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/payments/otherCashes/:id/edit",
    name: "OtherCashesEditForm",
    component: () => import("@/components/areas/payments/otherCashes/OtherCashesEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'general',
        filters: route.params.filters
      }
    },
  },
  {
    path: "/payments/otherCashes/list",
    name: "OtherCashesList",
    component: () =>
      import(
        "@/components/areas/payments/otherCashes/OtherCashesList.vue"
      ),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  },
]

export default otherCashesRoutes;
