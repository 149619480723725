<template>
  <v-form v-model="formValid" :disabled="disabled">
    <v-checkbox
      class="mb-5"
      v-model="factoryData"
      style="width: fit-content"
      @change="$emit('input', localValue)"
    >
      <template v-slot:label>
        <span
          class="title-section-receipt"
         > 
          INSERISCI DATI AZIENDA
        </span>
      </template>
    </v-checkbox>

    <div
      class="mb-5"
    >
      <span class="title-section-receipt">TITOLO</span>
      <v-text-field
        v-model="title"
        class="mt-1"
        filled
        dense
        hide-details="auto"
        placeholder="Inserisci titolo"
        :rules="[max46CharRule, presenceRule]"
        @input="$emit('input', localValue)"
      ></v-text-field>
    </div>

    <div
      class="mb-5"
    >
      <span class="title-section-receipt">DESCRIZIONE</span>
      <v-textarea
        v-model="description"
        class="mt-1"
        filled
        dense
        hide-details="auto"
        placeholder="Inserisci descrizione"
        :rules="[presenceRule]"
        @input="() => {
          descriptionSplitted = handleTextData(description)
          $emit('input', localValue)
        }"
      ></v-textarea>
    </div>  

    <div>
      <span 
        v-if="!!localType && localType == 'barcode'"
        class="title-section-receipt"
      > 
        BARCODE
      </span>
      <v-img
        class="mt-1"
        v-if="!!localType && localType == 'barcode'"
        style="width: 50%; margin-left: -1vw"
        src="@/assets/code-39.png"
      >
      </v-img>
      
      <span 
        v-if="!!localType && localType == 'qrcode'"
        class="title-section-receipt"
      > 
        QRCODE
      </span>
      <v-img
        class="mt-1"
        v-if="!!localType && localType == 'qrcode'"
        style="width: 20%;"
        src="@/assets/qr-code.png"
      >
      </v-img>
    </div>

    <v-text-field
      v-if="!!localType && localType == 'qrcode'"
      class="mt-2 mb-5"
      filled
      dense
      hide-details="auto"
      label="Contenuto"
      :rules="[presenceRule]"
      v-model="qrcode"
      @input="$emit('input', localValue)"
    ></v-text-field>
    
    <v-select
      v-if="!!localType && localType == 'barcode'"
      class="mt-2 mb-5"
      v-model="selectedPromo"
      label="Seleziona Promozione"
      :items="promoWithBarcode"
      return-object
      item-text="name"
      item-value="id"
      filled
      dense
      :rules="[presenceRule]"
      hide-details="auto"
      @input="$emit('input', localValue)"
    ></v-select>

    <div
      class="mb-5"
    >
      <span class="title-section-receipt">ALTRO TESTO</span>
      <v-textarea
        v-model="additionalText"
        class="mt-1"
        filled
        dense
        hide-details="auto"
        placeholder="Specifiche e ringraziamenti"
        @input="() => {
          additionalTextSplitted = handleTextData(additionalText)
          $emit('input', localValue)
        }"
      ></v-textarea>
    </div>

    <v-checkbox
      v-model="cashDeskOperatorThanks"
      class="mb-5"
      style="width: fit-content"
      @change="$emit('input', localValue)"
    >
      <template v-slot:label>
        <span
          class="title-section-receipt"
         > 
          INSERISCI AUTOMATICAMENTE NOME OPERATORE NEI RINGRAZIAMENTI
        </span>
      </template>
    </v-checkbox>

    <span
      class="title-section-receipt"
    > 
      DATA, ORA, NUMERO DOCUMENTO E CASSA SONO DATI OBBLIGATORI
    </span>
  </v-form>
</template>

<script>
import promosService from '@/services/promos/promos.service.js'

export default {
  Name: "ReceiptModelForm",
  data: function() {
    return {
      formValid: false,
      descriptionSplitted: [],
      description: undefined,
      nTextRows: 0,
      localType: this.type,
      qrcode: "",
      receiptOptions: [
        {
          value: 'barcode',
          text: 'Barcode'
        }, 
        {
          value: 'qrcode',
          text: 'QR Code'
        },
        {
          value: 'onlyText',
          text: 'Solo Testo'
        }
      ],
      promoWithBarcode: [],
      selectedPromo: undefined,
      title: undefined,
      factoryData: false,
      additionalText: undefined,
      additionalTextSplitted: [],
      cashDeskOperatorThanks: undefined,
      max46CharRule: (v) => {
        if(v) {
          return v.length <= 46 || 'Al massimo 46 caratteri'
        } else {
          return true
        }
      },
      max34CharRule: (v) => {
        if(v) {
          return v.length <= 34 || 'Al massimo 34 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    }
  },
  props: {
    value: {
      type: Object,
    },
    valid: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: undefined
    }
  },
  mounted: function() {
    this.$emit('update:valid', this.formValid)
    this.loadPromoWithBarcode()
    
    if (!!this.value) {
      this.description = this.value.text.join("")
      this.descriptionSplitted = this.value.text
      this.additionalText = !!this.value.additionalText ? this.value.additionalText.join("") : undefined
      this.additionalTextSplitted = this.value.additionalText
      this.nTextRows = this.value.text.length - 1
      this.localType = this.value.type
      this.qrcode = this.value.qrcode
      this.factoryData = this.value.factoryData
      this.title = this.value.title
      this.cashDeskOperatorThanks = this.value.cashDeskOperatorThanks
      if (this.value.type == 'barcode' && !!this.value.barcode)
        this.selectedPromo = this.value.barcode.promo
    }

  },
  methods: {
    loadPromoWithBarcode() {
      promosService.list(1, 1000, undefined).then((results) => {
        this.promoWithBarcode = results.rows.filter(el => !!el.barcode)
      })
    },
    handleTextData(text) {
      let words = text.split(" ")
      let countChar = 0
      let localRow = ""
      let localArray = []
      for (let i = 0; i < words.length; i++) {
        let word

        word = words[i]

        if (i != words.length - 1)
          word = words[i] + ' '

        if (!!word)
          countChar += word.length

        if (countChar > 46) {
          localArray.push(localRow)
          localRow =  word
          countChar = word.length
        } else {
          localRow += word
        }

        if (word.includes('\n')) {
          let newLineRow = localRow.split(/\r?\n/)
          const all = newLineRow.slice(0, newLineRow.length - 1)
          localArray.push(...all)
          localRow = newLineRow[newLineRow.length - 1]
          countChar = newLineRow[newLineRow.length - 1].length
        }
      }

      localArray.push(localRow)
      return localArray
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
    },
    type(newVal) {
      this.selectedPromo = undefined
      this.qrcode = undefined
      this.localType = newVal
    },
    value(newVal) {
      if (!!newVal) {
        this.descriptionSplitted = newVal.text
        this.additionalTextSplitted = newVal.additionalText
        this.localType = newVal.type
        this.qrcode = newVal.qrcode
        this.factoryData = newVal.factoryData
        this.title = newVal.title
        this.cashDeskOperatorThanks = newVal.cashDeskOperatorThanks
      }
    }
  },
  computed: {
    localValue() {
      return {
        id: !!this.value ? this.value.id : undefined,
        factoryData: this.factoryData,
        title: this.title,
        text: this.descriptionSplitted,
        additionalText: this.additionalTextSplitted,
        type: this.localType,
        qrcode: this.qrcode,
        barcodeId: !!this.selectedPromo ? !!this.selectedPromo.barcode ? 
          this.selectedPromo.barcode.id : undefined : undefined,
        cashDeskOperatorThanks: this.cashDeskOperatorThanks
      }
    },
  }
}
</script>

<style>
  .title-section-receipt {
    font-weight: 600;
    font-size: 1.1rem;
  }
</style>
