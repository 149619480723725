import { cloneDeep } from 'lodash'
let { js2xml } = require('xml-js');

class Builder {
  constructor() {
    this._object = {
      "elements": [
        {
          "type": "element",
          "name": "s:Envelope",
          "attributes": {
            "xmlns:s": "http://schemas.xmlsoap.org/soap/envelope/",
          },
          "elements": [
            {
              "type": "element",
              "name": "s:Body",
              "elements": [

              ]
            }
          ]
        }
      ]
    }

    this._paymentTypes = {
      'cash': 0, 
      'cheque': 1,
      'card': 2,
      'credit': 2,
      'ticket': 3,
      'debit' : 4,
    }

    this._messageTypes = {
      'header': 1,
      'row': 2,
      'promo': 3,
      'debit': 4,
    }

    this._directIOCommands = {
      'zreport': '3001',
      'zreportSchedule': '9013',
      'enableLogo': '4015',
      'setHeader': '3016',
      'selectLogo': '4034',
      'status': '1074',
      'printByNumber': '3098'
    }

    this._index = 1
  }

  get object() {
    return cloneDeep(this._object)
  }

  get xml() {
    return js2xml(this._object)
  }

  beginFiscalReceipt() {
    let elements = this._getBodiesElement()
    if(elements.length == 0) {
      this._object["elements"][0]["elements"][0]["elements"] = [
        {
          'type': 'element',
          'name': 'printerFiscalReceipt',
          'attributes': {
            'operator': ''
          },
          'elements': [
            {
              'type': 'element',
              'name': 'beginFiscalReceipt',
              'attributes': {
                'operator': ''
              },
            }
          ]
        }
      ]
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'beginFiscalReceipt',
          'attributes': {
            'operator': ''
          }  
        }
      )
    }
  }

  beginNonFiscal() {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      this._object["elements"][0]["elements"][0]["elements"] = [
        {
          'type': 'element',
          'name': 'printerNonFiscal',
          'attributes': {
            'operator': ''
          },
          'elements': [
            {
              'type': 'element',
              'name': 'beginNonFiscal',
              'attributes': {
                'operator': ''
              },
            }
          ]
        }
      ]
    } else {
      throw new Error('already present element')
    }
  }

  endFiscalReceipt() {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'endFiscalReceipt',
          'attributes': {
            'operator': ''
          }
        }
      )
    }
  }

  endNonFiscal() {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'endNonFiscal',
          'attributes': {
            'operator': ''
          }
        }
      )
    }
  }

  printNormal(font, text) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else if(!!text) {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printNormal',
          'attributes': {
            'operator': '',
            'font':font,
            'data': text.slice(0, 46)
          }
        }
      )
    }
  }

  printRecItem(description, quantity, department, unitPrice, additionalDescription, discount, discountDescription) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printRecItem',
          'attributes': {
            'operator': '',
            'description': description,
            'quantity': quantity,
            'department': department,
            'unitPrice': unitPrice
          }
        }
      )

      if(!!discount && Number(discount) != 0) {
        this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
          {
            'type': 'element',
            'name': 'printRecItemAdjustment',
            'attributes': {
              'operator': '',
              "adjustmentType": "0",
              "description": !!discountDescription ? discountDescription : "Sconto",
              "amount": Number(discount)
            }
          }
        )
      }

      if(!!additionalDescription) {
        this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
          {
            'type': 'element',
            'name': 'printRecMessage',
            'attributes': {
              "operator": "",
              "messageType": "4",
              "message": additionalDescription,
            }
          }
        )
      }
    }
  }

  printRecItemAdjustment(description, amount, department) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printRecItemAdjustment',
          'attributes': {
            "operator": "",
            "adjustmentType": 3,
            "description": description,
            "amount": amount,
            "department": department
          }
        }
      )
    }
  }

  printLotteryCode(code) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printRecLotteryID',
          'attributes': {
            "operator": "",
            "code": code,
          }
        }
      )
    }
  }

  printRecTotal(paidAmount, paymentType) {
    let elements = this._getBodiesElement()
    if (!paymentType || !this._paymentTypeValid(paymentType)) {
      throw new Error('payment type invalid')
    } else if(paidAmount == undefined || paidAmount == null) {
      throw new Error('paid amount not specified')
    } else if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      let index, paymentTypeIndex = this._paymentTypes[paymentType]
      if(paymentType == 'credit') 
        index = 0
      

      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printRecTotal',
          'attributes': {
            "operator": "",
            "payment": '' + paidAmount,
            "paymentType": paymentTypeIndex,
            "index": index,
            "justification": paymentType == 'debit' ? 2 : undefined,
          }
        }
      )
    }
  }

  printRecRefund(item, department) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printRecRefund',
          'attributes': {
            "operator": "",
            "description": !!item.serviceId ? '' + item.name  : '' + item.description,
            "quantity": '' + item.quantity,
            "unitPrice": !!item.serviceId ? '' + item.price : item.priceSelling != undefined ? '' + item.priceSelling
              : '' + item.price,
            "justification": "1",
            "department": department, // iva al 22% con department da 1 a 3 per il 10 % settare department a 4
          }
        }
      )
    }
  }

  printQRCode(code) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printBarCode',
          'attributes': {
            "operator": "",
            "qRCodeAlignment": "1",
            "qRCodeSize": "10",
            "qRCodeErrorCorrection": "0",
            "qRCodeDataType": "0",
            "codeType": "92",
            "code": code,
          }
        }
      )
    }
  }

  printBarcode(code) {
    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      throw new Error('format not valid')
    } else {
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printBarCode',
          'attributes': {
            "operator": "",
            "position": "20",
            "width": "2",
            "height": "80",
            "hRIPosition": "2",
            "hRIFont": "A",
            "codeType": "69",
            "code": code,
          }
        }
      )
    }
  }

  printRecMessage(message, messageType) {
    if(!this._messageTypeValid(messageType)) {
      throw new Error('message type not valid')
    } else {
      let elements = this._getBodiesElement()
      if(elements.length == 0) {
        this._object["elements"][0]["elements"][0]["elements"] = [
          {
            'type': 'element',
            'name': 'printerFiscalReceipt',
            'attributes': {
              'operator': ''
            },  
            'elements': []
          }
        ]
      }
      this._object["elements"][0]["elements"][0]["elements"][0]['elements'].push(
        {
          'type': 'element',
          'name': 'printRecMessage',
          'attributes': {
            'operator': '',
            'message': message,
            'messageType': this._messageTypes[messageType],
            'index': messageType != 'debit' ? this._index : undefined,
            'font': messageType != 'debit' ? (messageType != 'promo' ? 1 : 3) : undefined
          }
        }
      )
      this._index++
    }
  }

  directIO(commandName, data) {
    const commandCode = this._directIOCommands[commandName]

    let elements = this._getBodiesElement()
    if (elements.length == 0) {
      this._object["elements"][0]["elements"][0]["elements"] = [
        {
          'type': 'element',
          'name': 'printerCommand',
          'elements': [
            {
              'type': 'element',
              'name': 'directIO',
              'attributes': {
                'command': commandCode,
                'data': data
              },
            }
          ]
        }
      ]
    } else {
      throw new Error('already present element')
    }
  }

  _paymentTypeValid(paymentType) {
    return Object.keys(this._paymentTypes).includes(paymentType)
  }

  _messageTypeValid(messageType) {
    return Object.keys(this._messageTypes).includes(messageType)
  }

  _getBodiesElement() {
    return this._object["elements"][0]["elements"][0]["elements"]
  }
}

export default Builder