<template>
  <div>
    <JsonExcel
      :fetch="fetch"
      :fields="fieldsToExport"
      :worksheet="worksheetName"
      :name="fileName"
      :type="type"
    >
      <slot name="button">
        <v-btn text :disabled="disable"><v-icon> mdi-microsoft-excel </v-icon></v-btn>
      </slot>
    </JsonExcel>
  </div>
</template>

<script>
import JsonExcel from "vue-json-excel";
export default {
  props: {
    disable:{
      type: Boolean,
      default: false,
    },
    worksheetName: {
      type: String,
      default: "My Worksheet",
    },
    fileName: {
      type: String,
      default: "Excel.xls",
    },
    fieldsToExport: {
      type: Object,
      default: function () {
        return {
          "Complete name": "name",
          City: "city",
          Telephone: "phone.mobile",
          "Telephone 2": {
            field: "phone.landline",
            callback: (value) => {
              return `Landline Phone - ${value}`;
            },
          },
        };
      },
    },

    dataToExport: {
      type: Array,
      default: function () {
        return [
          {
            name: "Tony Peña",
            city: "New York",
            country: "United States",
            birthdate: "1978-03-15",
            phone: {
              mobile: "1-541-754-3010",
              landline: "(541) 754-3010",
            },
          },
          {
            name: "Thessaloniki",
            city: "Athens",
            country: "Greece",
            birthdate: "1987-11-23",
            phone: {
              mobile: "+1 855 275 5071",
              landline: "(2741) 2621-244",
            },
          },
        ];
      },
    },
    type: {
      type: String,
      default: "",
    },
    fetch: {
      type: Function,
      default: function () {
        return this.dataToExport;
      },
    },
  },
  data() {
    return {
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  components: {
    JsonExcel,
  },
  methods: {},
};
</script>

<style>
</style>