<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    :title="'Invio Sms a ' + customerSelected.length + ' clienti'"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn :disabled="customerSelected.length == 0" text @click="sendMassive()">
        <v-icon class="mr-2">mdi-message-text-fast-outline</v-icon> Invia Messaggio ai Selezionati
      </v-btn>
      <v-btn text @click="sendMassiveAll()">
        <v-icon class="mr-2">mdi-message-text-fast-outline</v-icon> Invia Messaggio a Tutti
      </v-btn>
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Descrizione: {{ filter.value }}
          </template>

          <template v-slot:custom-gender="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  false-value="male"
                  true-value="female"
                  color="pink"
                  :label="filter.value == 'male' ? 'Uomo' : 'Donna'"
                  :class="filter.value == 'male' ? 'custom-blue-switch' : 'custom-pink-switch'"
                  flat
                >
                </v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-gender="{filter}">
            Sesso: {{filter.value == 'male' ? 'Uomo' : 'Donna'}}
          </template> 

          <template v-slot:custom-interests="{filter}">
            <InterestsAutocomplete
              v-model="filter.value"
              :multiple="true"
              :dense="true"
              :return-object="false"
            ></InterestsAutocomplete>  
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="customerSelected"
          loading-text="Caricamento clienti"
          :headers="headers"
          :loading="loading || loadingHeaders"
          :translator="translator"
          :items="customers"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          actions-width="80px"
          :single-select="false"
          :show-actions="false"
        >
        </TypeDataTable>
      </div>
      
      <StandardDialog
        v-model="sendSmsDialog"
        :dialog-height="null"
        :title="customersSms ? 'Invia Messaggio a ' + customersSms.length : 'Invia Messaggio a circa ' + rowPerPage * totalPages  + ' clienti'"
        dialog-max-width="1000px"
      >
        <SendPromoSmsModel
          :with-list="true"
          :send-all="sendAll"
          :customers="customersSms"
          :filters="filtersValue"
          alert-text="Stai per fare un invio massivo di messaggi. Sei sicuro di volero fare?"
          @close="handleSmsClose"
        >
        </SendPromoSmsModel>
        <template v-slot:footer>
          <v-spacer></v-spacer>
            <v-btn 
              text 
              color="error" 
              @click="sendSmsDialog = false" 
            >Annulla</v-btn>
        </template>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import SendPromoSmsModel from "@/components/areas/marketing/SendPromoSmsModel.vue";
import InterestsAutocomplete from '@/components/common/InterestsAutocomplete.vue'
import StandardDialog from '@/components/common/StandardDialog.vue';
import customerService from "@/services/customers/customers.service.js";

export default {
  name: "SendMassiveSms",
  components: {
    TypeDataTable,
    DateTimeFormattor,
    AdvancedFilter,
    FullScreenDialog,
    SendPromoSmsModel,
    StandardDialog,
    InterestsAutocomplete
  },
  data: function () {
    let props = this.$router.resolve({ name: "Marketing" });
    return {
      dialog: true,
      routeFather: props.href,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      customers: [],
      headers: [],
      filterTypes: [
        { type: 'string', operator: 'like', field: 'city', name: 'Città', label: 'Città', color: "", value: undefined, icon: "mdi-city-variant" },
        { type: 'string', operator: 'like', field: 'fiscalCode', name: 'Codice Fiscale', label: 'Codice Fiscale', color: "", value: undefined },
        { type: 'date', operator: 'equal', field: 'birthday', name: 'Data di Nascita', label: 'Data di Nascita', color: "", value: undefined, icon: "mdi-cake-variant" },
        { type: 'string', operator: 'equal', field: 'cellphone', name: 'Cellulare', label: 'Cellulare', color: "", value: undefined, icon: "mdi-cellphone" },
        { type: 'string', operator: 'equal', field: 'telephone', name: 'Telefono', label: 'Telefono', color: "", value: undefined, icon: "mdi-deskphone" },
        { type: 'boolean', operator: 'equal', field: 'sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: "", value: false, icon: "mdi-whatsapp" },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'equal', field: 'gender', name: 'Sesso', label: 'Sesso', color: "", value: 'male', icon: "mdi-gender-male-female" },
        { type:'string', operator: 'like', field: 'email', name: 'E-Mail', label: 'E-Mail', color: "", value: undefined, icon: 'mdi-email' },
        { type: 'custom', operator: 'custom', field: 'interests', name: 'Interessi', label: 'Interessi', color: "", value: [], icon: 'mdi-heart-outline' }
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'customer', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      customerSelected: [],
      loading: false,
      loadingHeaders: false,
      page: 1,
      rowPerPage: 200,
      totalPages: 0,
      sendSmsDialog: false,
      sendAll: false,
      customersSms: [],
      filtersSms: []
    };
  },
  mounted: function () {
    this.loadHeaders();
    this.loadCustomers();

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.9;
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.9;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
  },
  methods: {
    loadHeaders() {
      this.loadingHeaders = true;
      customerService.fields().then((headers) => {
        this.headers = headers;
        this.loadingHeaders = false;
      });
    },
    loadCustomers() {
      this.loading = true;
      let filters = [...this.filtersValue];

      customerService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.customers = results.rows;
        this.loading = false;
      });
    },
    applyFilters(filters) {
      this.loadCustomers();
    },
    translator(fieldName, fieldValue) {
      if (fieldName == "job" && !!fieldValue) {
        return fieldValue["name"];
      }
      if (fieldName == "gender" && !!fieldValue) {
        return this.$translations.t("enums.customers.gender." + fieldValue);
      }
      if (fieldName == "hairColor" && !!fieldValue) {
        return fieldValue["name"];
      }
      if (fieldName == "hairType" && !!fieldValue) {
        return fieldValue["name"];
      }
    },
    sendMassive(){
      this.customersSms = this.customerSelected.map(c => c.id)
      this.sendAll = false
      this.filtersSms = undefined
      this.sendSmsDialog = true
    },
    sendMassiveAll(){
      this.customersSms = undefined
      this.sendAll = true
      this.filtersSms = this.filtersValue
      this.sendSmsDialog = true
    },
    handleSmsClose(){
      this.sendSmsDialog = false
      this.customerSelected = undefined
      this.customersSms = undefined
      this.sendAll = false
    }
  },
  computed: {
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.applyFilters();
    },
    rowPerPage() {
      this.applyFilters();
    },
  },
};
</script>


<style>
.custom-blue-switch .v-input--selection-controls__input div {
  color: blue !important;
  caret-color: blue !important;
}
.custom-orange-switch .v-input--selection-controls__input div {
  color: orange !important;
  caret-color: orange !important;
}
</style>