export default {
  methods: {
    blackOrWhiteContrast(background) {
      if (!background) return '#050505'
      const ratioBlack = this.getRatio(background, '#050505')
      const ratioWhite = this.getRatio(background, '#ffffff')
      return ratioWhite < ratioBlack ? '#ffffff' : '#050505'
    },
    isContrast(background, color) {
      return this.getRatio(background, color) < 1/3
    },
    getRatio(background, color) {
      let rgbBackground = this.hexToRgb(background)
      let rgbColor = this.hexToRgb(color)

      let backgroundLuminance = this.luminance(rgbBackground.r, rgbBackground.g, rgbBackground.b)
      let colorLuminance = this.luminance(rgbColor.r, rgbColor.g, rgbColor.b)
      return backgroundLuminance > colorLuminance
        ? ((colorLuminance + 0.05) / (backgroundLuminance + 0.05))
        : ((backgroundLuminance + 0.05) / (colorLuminance + 0.05));
    },
    hexToRgb(hex) {
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d]?)$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]?)$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    luminance(r, g, b) {
      var a = [r, g, b].map(function (v) {
        v /= 255;
        return v <= 0.03928
          ? v / 12.92
          : Math.pow((v + 0.055) / 1.055, 2.4);
      });
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
    }
  }
}