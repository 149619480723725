import { monthLabels, quarterLabels, weekLabels, dayLabels, getLabelKeyFromDate} from './labels'
import { colors, getHexColorWithOpacity } from './colors'

export default {
  methods: {
    getLabelKeyFromDate(date, granularity) {
      return getLabelKeyFromDate(date, granularity)
    },
    getColor(index) {
      return colors[index % colors.length].color 
    },
    getHexColorWithOpacity(hexColor, opacity) {
      return getHexColorWithOpacity(hexColor, opacity)
    },
    quarterLabels(from, to) {
      return quarterLabels(from, to, this.labelModifier)
    },
    monthLabels(from, to) {
      return monthLabels(from, to, this.labelModifier)
    },
    weekLabels(from, to) {
      return weekLabels(from, to, this.labelModifier)
    },
    dayLabels(from, to) {
      return dayLabels(from, to, this.labelModifier)
    },
  },
  computed: {
  },
  props: {
    labelModifier: { },
    tooltipLabelModifier: { },
  }
}