import permissionService from './permissions.service'

export default {
  install: (Vue, options) => {
    Vue.mixin({
      created: function () {
        if (!!this.$options.permissions) {
          for (const [resource, value] of Object.entries(this.$options.permissions)) {
            if (!!value.initial) {
              let bindFunction = value.initial.bind(this)
              bindFunction(permissionService.getActionsFromResource(resource))
            }

            if (!!value.change) {
              let bindFunction = value.change.bind(this)
              permissionService.on('update-' + resource, bindFunction)
            }

            if (!!value.bind) {
              this[value.bind] = permissionService.getActionsFromResource(resource)

              let updateCallback = ({action, isPermitted}) => {
                if (!this[value.bind] || !Array.isArray(this[value.bind])) this[value.bind] = [action]
                else if (this[value.bind].includes(action) && !isPermitted) this[value.bind] = this[value.bind].filter(el => el !== action)
                else if (!this[value.bind].includes(action) && isPermitted) this[value.bind].push(action)
              }
              updateCallback = updateCallback.bind(this)
              permissionService.on('update-' + resource, updateCallback)
            }

          }
        }
      }
    })
  }
}