import authService from "@/services/auth/auth.service";

const customersRoutes = [
  {
    path: "/registries/customers/new",
    name: "CustomersRegistryNewForm",
    component: () => import("@/components/areas/registries/customers/CustomersRegistryNewForm.vue"),
    props: (route) => {
      let precompiled;
      try {
        precompiled = JSON.parse(route.query.precompiled)
      } catch (error) {
        precompiled = {}
      }

      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry',
        precompiled: precompiled
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/customers/:id/edit",
    name: "CustomersRegistryEditForm",
    component: () => import("@/components/areas/registries/customers/CustomersRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry',
        pathName: route.query.pathName,
        customerId: route.params.id,
        filters: route.params.filters,
        barberDataSheetTabName: route.query.barberDataSheetTabName || 'barberInfo'
      }
    },
  },
  {
    path: "/registries/customers/list",
    name: "CustomersRegistryList",
    component: () => import("@/components/areas/registries/customers/CustomersRegistryList.vue"),
    props: (route) => {
      return {
        filters: route.params.filters
      }
    }
  }
];
export default customersRoutes;
