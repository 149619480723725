<template>
  <div 
    class="d-flex flex-wrap" 
    :key="componentKey" 
    :style="cssVariables"
    :class="{
      'justify-center': justifyCenter,
      'justify-space-around': justifySpaceAround,
      'align-center': alignCenter
    }"
  >
    <div 
      v-if="loading"
      :style="{
        'min-heght': cardMinHeight,
        'width': '100%'
      }"
      class="d-flex justify-center align-center"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-else>
      <div
        v-if="categorize && categories.length != 0"
        style="width: 100%; overflow-y: auto"
        class="d-flex justify-center align-center"
      >
        <v-btn-toggle 
          v-model="selectedCategory" 
          dense
          class="mb-2"
        >
          <v-btn
            v-for="category in categories"
            v-bind:key="category.value"
            :value="category.value"
          >
            <span :class="{
              'hidden-sm-and-down': !!category.icon
            }">{{category.text}}</span>
            <v-icon v-if="category.icon" right>{{category.icon}}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-hover v-slot="{hover}" v-if="paginate">
        <v-card
          class="animation-properties ma-1" 
          :min-width="cardMinWidth"
          :min-height="cardMinHeight"
          :max-width="cardMaxWidth"
          :class="{
            'hovered': hover,
          }"
        >
          <div
            class="pa-2 d-flex flex-column justify-center align-center"
            :style="{'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'}"
            @click="previousPage"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </div>
        </v-card>
      </v-hover>
      <div
        v-for="(item, i) in itemsToShow"
        :key="item[itemKey]"
        @click="select(item)"
        class="ma-1"
        :style="{cursor: getIfCardDisabled(item) ? 'initial' : 'pointer'}"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            class="animation-properties" 
            :min-width="cardMinWidth"
            :min-height="cardMinHeight"
            :max-width="cardMaxWidth"
            :max-height="cardMaxHeight"
            :class="{
              'selected': multiple ? localSelected.includes(item[itemKey]) : localSelected == item[itemKey],
              'hovered': hover && !getIfCardDisabled(item),
              'card-disabled': getIfCardDisabled(item)
            }"
            :elevation="(multiple ? localSelected.includes(item[itemKey]) : localSelected == item[itemKey]) ? 5 : 2"
            :shaped="shaped"
            :outlined="outlined"
            :color="colorOnSelected ? ((multiple ? localSelected.includes(item[itemKey]) : localSelected == item[itemKey]) ? getColor(i) : 'white') : getColor(i)"
            style="overflow: hidden;text-overflow: ellipsis;"
            >
            <div
              :class="{
                'd-flex' : applyDFlex
              }"
              :style="{
                'background-color': getItemColor(item),
                'min-height': cardMinHeight + 'px',
                'justify-content': justifyContentItem,
                'align-items': alignItemsItem,
              }"
            >
              <slot name="item" v-bind="{
                item, 
                cardMinWidth, 
                cardMinHeight, 
                cardMaxWidth, 
                cardMaxHeight, 
                color: getItemColor(item),
                textColor: adaptiveTextColor ? getItemTextColor(item) : '#000000'
              }">
                <div
                  class="pa-2 d-flex flex-column justify-center align-center"
                  :style="{
                    'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px',
                  }"
                >
                  <div class="text-center" :style="{
                    'color': getItemTextColor(item)
                  }">
                    {{item[itemText]}}
                  </div>
                </div>
              </slot>
            </div>
          </v-card>
        </v-hover>
      </div>
      <v-hover v-slot="{hover}" v-if="paginate">
        <v-card
          class="animation-properties ma-1" 
          :min-width="cardMinWidth"
          :min-height="cardMinHeight"
          :max-width="cardMaxWidth"
          :class="{
            'hovered': hover,
          }"
        >
          <div
            class="pa-2 d-flex flex-column justify-center align-center"
            :style="{'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'}"
            @click="nextPage"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </div>
        </v-card>
      </v-hover>
    </template>
  </div>
</template>

<script>
import colorConstrast from "@/mixins/common/colorContrast"
import { uniqBy } from 'lodash'

export default {
  name: "ResponsiveCardSelector",
  mixins: [colorConstrast],
  data: function() {
    return {
      componentKey: 0,
      localSelected: this.value || this.multiple ? [] : undefined,
      page: 0,
      selectedCategory: undefined,
      categories: []
    }
  },
  props: {
    height: {
      type: Number,
      default: 200
    },
    cardMinWidth: {
      type: String,
      default: "100"
    },
    cardMaxWidth: {
      type: String,
      default: "100"
    },
    cardMinHeight: {
      type: String,
      default: "100"
    },
    cardMaxHeight: {
      type: String,
      default: "100"
    },
    items: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemCategoryValue: {
      default: 'categoryValue'
    },
    itemCategoryText: {
      default: 'categoryText'
    },
    itemCategoryIcon: {
      default: 'categoryIcon'
    },
    itemColor: {},
    adaptiveTextColor: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    value: {},
    multiple: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    cardDisabled: {

    },
    categorize: {
      type: Boolean,
      default: false
    },
    paginate: {
      type: Boolean,
      default: false
    },
    cardPerPage: {
      type: Number,
      default: 10
    },
    justifyCenter: {
      type: Boolean,
      default: false
    },
    alignCenter: {
      type: Boolean,
      default: false
    },
    justifySpaceAround: {
      type: Boolean,
      default: false
    },
    justifyContentItem: {
      type: String,
      default: 'center'
    },
    applyDFlex: {
      type: Boolean,
      default: true
    },
    alignItemsItem: {
      type: String,
      default: 'baseline'
    },
    shaped:{
      type: Boolean,
      default: false
    },
    outlined:{
      type: Boolean,
      default: false
    },
    colors:{
      type: Array,
      default: undefined
    },
    colorOnSelected: {
      type: Boolean,
      default: false
    }
  },
  mounted: function() {
    if(this.categorize) {
      this.getCategories()
    }

    if(!!this.value) {
      if(this.returnObject && this.multiple) {
        this.localSelected = this.value.map((item) => item[this.itemKey])
      } else if(this.returnObject) {
        this.localSelected = this.value[this.itemKey]
      } else {
        this.localSelected = this.value
      }
    }
  },
  methods: {
    select(item) {
      if(this.getIfCardDisabled(item)) {
        return
      }

      if(this.multiple) {
        if(!this.localSelected.includes(item[this.itemKey])) {
          this.localSelected.push(item[this.itemKey])
        } else {
          if(this.localSelected.length == 1 && this.mandatory) return
          this.localSelected = this.localSelected.filter((el) => { return el != item[this.itemKey]})
        }
      } else {
        if(!this.mandatory && this.localSelected == item[this.itemKey]) {
          this.localSelected = undefined
        } else {
          this.localSelected = item[this.itemKey]
        }
      }

      if(this.returnObject) {
        if(this.multiple) {
          this.$emit('input', this.items.filter((el) => { 
            return this.localSelected.includes(el[this.itemKey])
          }))
        } else {
          this.$emit('input', this.items.filter((el) => { return this.localSelected == el[this.itemKey] })[0] )
        }
      } else {
        this.$emit('input', this.localSelected)
      }

      this.$emit('click', item)
    },
    previousPage() {
      if(this.page == 0) return
      this.page -= 1
    },
    nextPage() {
      if(this.page >= this.totalPages) return
      this.page += 1
    },
    getIfCardDisabled(item) {
      if(this.disabled) {
        return true
      } else if(!!this.cardDisabled && typeof this.cardDisabled == 'function') {
        return this.cardDisabled(item)
      } else {
        return false
      }
    },
    getCategoryValueOfItem(item) {
      if(typeof this.itemCategoryValue == 'function') {
        return this.itemCategoryValue(item)
      } else {
        return item[this.itemCategoryValue]
      }
    },
    getCategoryTextFromItem(item) {
      if(typeof this.itemCategoryText == 'function') {
        return this.itemCategoryText(item)
      } else {
        return item[this.itemCategoryText]
      }
    },
    getCategoryIconOfItem(item) {
      if(typeof this.itemCategoryIcon == 'function') {
        return this.itemCategoryIcon(item)
      } else {
        return item[this.itemCategoryIcon]
      }
    },
    getCategories() {
      this.categories = []
      for(let i = 0; i < this.items.length; i += 1) {
        const categoryText = this.getCategoryTextFromItem(this.items[i])
        const categoryValue = this.getCategoryValueOfItem(this.items[i])
        const categoryIcon = this.getCategoryIconOfItem(this.items[i])
        if(!!categoryText && !!categoryValue && !this.categories.map(el => el.value).includes(categoryValue)) {
          this.categories.push({
            text: categoryText,
            value: categoryValue,
            icon: categoryIcon
          })
        }
      }

      if(!!this.selectedCategory && !this.categories.some(cat => cat.value == this.selectedCategory)) {
        this.selectedCategory = undefined
      }
    },
    getItemColor(item) {
      if(!this.itemColor) {
        return undefined
      } else if(typeof this.itemColor === 'function') {
        return this.itemColor(item)
      } else {
        return item[this.itemColor]
      }
    },
    getItemTextColor(item) {
      const itemBackground = this.getItemColor(item)
      return this.adaptiveTextColor && !!itemBackground ? this.blackOrWhiteContrast(itemBackground) : '#000000'
    },
    getColor(key){
      if(!!this.colors){
        return this.colors[key%this.colors.length]
      } else
        return undefined
    }
  },
  watch: {
    value(newVal) {
      if(!newVal) {
        this.localSelected = this.multiple ? [] : undefined
        return
      }

      if(this.returnObject && this.multiple) {
        this.localSelected = this.value.map((item) => item[this.itemKey])
      } else if(this.returnObject) {
        this.localSelected = newVal[this.itemKey]
      } else {
        this.localSelected = newVal
      }
    },
    items() {
      this.getCategories()
    },
    selectedCategory() {
      if(this.page > this.totalPages) {
        this.page = this.totalPages
      }
    }
  },
  computed: {
    itemsFiltered() {
      let itf
      if(this.categorize && !!this.selectedCategory) {
        itf = this.items.filter((el) => {
          return this.getCategoryValueOfItem(el) == this.selectedCategory
        })
      } else {
        itf = this.items
      }

      return uniqBy(itf, this.itemKey)
    },
    itemsToShow() {
      let its
      if(this.paginate) {
        its = this.itemsFiltered.filter((el, index) => {
          return index >= this.page * this.cardPerPage && index < (this.page + 1) * this.cardPerPage
        })
      } else {
        its = this.itemsFiltered
      }

      return uniqBy(its, this.itemKey)
    },
    totalPages() {
      if(!this.paginate) return 0
      else {
        return Math.floor(this.itemsFiltered.length / this.cardPerPage)
      }
    },
    cssVariables() {
      let hexColor = this.color
      if(['primary', 'secondary', 'accent', 'error', 'success', 'warning', 'info', 'tertiary'].includes(hexColor)) {
        hexColor = this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? 'dark' : 'light'][hexColor]
      }
      return {
        '--selected-color': hexColor,
      }
    },
  }
}
</script>

<style scoped>
  .selected {
    border: solid 1px var(--selected-color);
  }

  .hovered {
    background: rgba(180, 180, 180, 0.164);
  }

  .card-disabled {
    opacity: 0.5;
  }

  .animation-properties {
    transition: all 0.2s;
  }
</style>