<template>
  <v-form>
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters >
      <v-col 
        cols="12"
        sm="8"
        md="2"
        lg="2"
        xl="2"
      >
        <div class="text-center text-subtitle-1">&nbsp;</div>
        <DateTimePicker
          v-model="openedAt"
          :rules="[presenceRule]"
          :readonly="readonly || typeProps === 'edit'"
          :disabled="typeProps === 'edit'"
          :show-time="false"
          dense
          filled
          label="Data spesa"
          hide-details="auto"
          @input="updateField('openedAt', $event)"
        ></DateTimePicker>
      </v-col> 
      <v-col 
        cols="12"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        class="pl-1"
      >
        <div class="text-center text-subtitle-1">&nbsp;</div>
        <NumericKeyboardInput
          v-model="amount"
          :rules="[presenceRule]"
          :readonly="readonly || typeProps === 'edit'"
          :disabled="typeProps === 'edit'"
          filled
          :dense="false"
          label="Ammontare"
          hide-details="auto"
          @input="updateField('amount', $event)"
          prepend-slot
        >
          <template v-slot:prepend>
            <div
              @dblclick="handleGhostChange">
              <v-icon
                :color=" !ghost ? 'primary' : 'error'"
              >mdi-cash</v-icon>
            </div>
          </template>
        </NumericKeyboardInput>
      </v-col>
      <v-col 
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <div class="text-center text-subtitle-1">Inserire beneficario:</div>
        <div class="d-flex ml-2">
          <v-btn-toggle
            v-model="paymentDebitSubject"
            rounded
            class="pa-1"
            mandatory
          >
            <v-btn @click="changeFromSupplierToOperator" :disabled="typeProps === 'edit'">
              <v-icon>mdi-account-hard-hat</v-icon>
            </v-btn>
            <v-btn @click="changeFromOperatorToSupplier" :disabled="typeProps === 'edit'">
              <v-icon>mdi-domain</v-icon>
            </v-btn>
          </v-btn-toggle>
          <OperatorsAutocomplete
            v-if="!paymentDebitSubject"
            style="min-width: 75%; margin: auto;"
            v-model="operator"
            :rules="[objectPresenceRule]"
            :readonly="readonly"
            :disabled="typeProps === 'edit'"
            return-object
            clearable
            :multiple="false"
            label="Inserire Persona Fisica..."
            :prependIcon="null"
            @input="handleSelectOperator"
          ></OperatorsAutocomplete>
          <SuppliersAutocomplete
            v-else-if="!!paymentDebitSubject"
            style="min-width: 75%; margin: auto"
            v-model="supplier"
            @input="handleSelectSupplier"
            :disabled="typeProps === 'edit'"
            return-object
            :append-outer-icon="!!supplier ? 'mdi-pencil' : undefined"
            :auto-detect-new-supplier="true"
            label="Inserire ragione sociale..."
          ></SuppliersAutocomplete> 
        </div>  
      </v-col> 
    </v-row>

    <v-row no-gutters class="py-1">
      <v-col 
        class="mt-5" 
        cols="12"
        sm="12"
        md="7"
        lg="7"
        xl="7"
      >
        <v-textarea
          v-model="reason"
          :readonly="readonly"
          label="Motivazione"
          filled
          hide-details="auto"
          @input="updateField('reason', $event)"
        ></v-textarea>
      </v-col>
      <v-col 
        class="mt-5"
        :class="{
          'ml-5': !$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs,
          'ml-15': !!$vuetify.breakpoint.lg || !!$vuetify.breakpoint.xl
        }"
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <div class="">
          <TagsAutocomplete
            :multiple="false"
            :readonly="readonly"
            v-model="tags"
            dense
            return-object
            @input="updateField('tags', $event)"
            :filters="{typeCost:true}"
          ></TagsAutocomplete>
          <!--<v-select
            v-model="modPayment"
            :items="paymentModItems"
            item-text="description"
            item-value="description"
            class="mt-1"
            dense 
            filled
            hide-details="auto"
            prepend-icon="mdi-cash-register"
            label="Modalità di pagamento"
            @input="updateField('modPayment', $event)"
          ></v-select>-->
          <OperatorsAutocomplete
            v-model="creator"
            :rules="[objectPresenceRule]"
            :readonly="readonly"
            return-object
            clearable
            :multiple="false"
            class="mt-1"
            dense 
            filled
            label="Operatore Responsabile dell'Operazione"      
            @input="updateField('creator', $event)"
            >
          </OperatorsAutocomplete>
        </div>  
      </v-col>
      <!--<v-col>
        <v-switch 
          v-model="ghost"
          :readonly="readonly"
          class="ml-8"
          :class="{ 'custom-green': !ghost }"
          color="red"
          hide-details
          @change="handleGhostChange"
        ></v-switch>
      </v-col>-->
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import paymentDebitForm from '@/services/paymentDebits/paymentDebit.form.js'
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'
import paymentTermsService from '@/services/paymentTerms/paymentTerms.service.js'
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import operatorService from "@/services/operators/operators.service.js"

export default {
  name: "GeneralFormTab",
  components: {
    DateTimePicker,
    NumericKeyboardInput,
    OperatorsAutocomplete,
    TagsAutocomplete,
    SuppliersAutocomplete,
  },
  data: function() {
    return {
      openedAt: new Date(),
      amount: undefined,
      customer: undefined,
      operator: undefined,
      creator: undefined,
      reason: undefined,
      loadingPaymentDebit: false,
      type: undefined,
      documentType: undefined,
      tags: undefined,
      ghost: false,
      modPayment: undefined,
      paymentModItems: undefined,
      supplier: undefined,
      paymentDebitSubject: 0,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      objectPresenceRule: (v) => {
        return (!!v && !!Object.keys(v).length) || 'Il campo è obbligatorio'
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    preCompiledForm: {
      type: Object,
      default: undefined
    },
    intermediaryOperator: {
      type: Boolean,
      default: false,
    },
    typeProps: {
      type: String,
      default: 'new',
    },
  },
  mounted: function() {
    this.handleObjectChanges(paymentDebitForm.paymentDebit)
    var self = this
    paymentDebitForm.on('update', function(data) {
      self.handleObjectChanges(data.paymentDebit)
    })
    this.updateField('openedAt', this.openedAt)
    if(!!this.preCompiledForm){
      operatorService.get(this.preCompiledForm.operatorId).then((operator) => {
        this.updateField('amount', this.preCompiledForm.amount)
        this.updateField('reason', this.preCompiledForm.reason)
        this.updateField('operator', operator)
        this.updateField('info', {from: this.preCompiledForm.from, to: this.preCompiledForm.to})
      })
      .catch((err) => {console.log(err)})
    }  
    if(!!this.intermediaryOperator)
      this.updateField('type', 'intermediaryOperator')
      paymentTermsService.list(this.page, this.rowPerPage).then((results) => {
        this.paymentModItems = results.rows
      })
      .catch(err => console.log(err))
    
  },
  methods: {
    handleObjectChanges(paymentDebit) {
      if(!!paymentDebit) {
        if(!!paymentDebit.openedAt) {
          this.openedAt = new Date(paymentDebit.openedAt)
        }

        if(!!paymentDebit.amount) {
          this.amount = Number(paymentDebit.amount)
        }

        if(!!paymentDebit.type) {
          this.type = paymentDebit.type

          if(paymentDebit.type == 'customer') {
            this.customer = !!paymentDebit.customer ? paymentDebit.customer : undefined
          } else if(paymentDebit.type == 'bill') {
            this.customer = !!paymentDebit.bills && paymentDebit.bills.length > 0 && !!paymentDebit.bills[0].billCustomers && paymentDebit.bills[0].billCustomers.length > 0 && !!paymentDebit.bills[0].billCustomers[0].customer ? 
              paymentDebit.bills[0].billCustomers[0].customer : undefined
          } else if(paymentDebit.type == 'operator' || paymentDebit.type == 'intermediaryOperator') {
            this.operator = !!paymentDebit.operator ? paymentDebit.operator : undefined
          }
        }

        if(!!paymentDebit.reason) {
          this.reason = paymentDebit.reason
        }

        if(!!paymentDebit.documentType) {
          this.documentType = paymentDebit.documentType
        }

        if(!!paymentDebit.tags) {
          this.tags = paymentDebit.tags
        }

        if(!!paymentDebit.ghost) {
          this.ghost = paymentDebit.ghost
        }

        if(!!paymentDebit.modPayment) {
          this.modPayment = paymentDebit.modPayment
        }

        if(!!paymentDebit.supplier && !!paymentDebit.type && paymentDebit.type == 'supplier') {
          this.supplier = paymentDebit.supplier
          this.paymentDebitSubject = 1
        }

        if(!!paymentDebit.creator) {
          this.creator = paymentDebit.creator
        }
      }
    },
    updateField(key, value) {
      paymentDebitForm.updateField(key, value)
      if(key == 'amount' && !!this.ghost)
        paymentDebitForm.updateField('ghostPaid', value)
      else if(key == 'amount' && !this.ghost)
        paymentDebitForm.updateField('cashPaid', value)
      
    },
    handleGhostChange(){
      if(this.typeProps === 'edit')
        return
      if(!!this.ghost){ 
        this.updateField('cashPaid', this.amount)
        this.updateField('ghostPaid', 0)
      } else {
        this.updateField('ghostPaid', this.amount)
        this.updateField('cashPaid',0)
      }
      this.ghost = !this.ghost
      this.updateField('ghost', this.ghost)
    },
    handleSelectOperator($event){
      paymentDebitForm.updateField('type', 'operator')
      this.updateField('operator', $event)
    },
    handleSelectSupplier($event){
      paymentDebitForm.updateField('type', 'supplier')
      this.updateField('supplier', $event)
    },
    changeFromOperatorToSupplier(){
      this.updateField('type', 'supplier')
      this.paymentDebitSubject = 1
    },
    changeFromSupplierToOperator(){
      this.updateField('type', 'operator')
      this.paymentDebitSubject = 0
    },
  },
  watch: {
    openedAt() {
      const newVal = (!!this.openedAt && !!this.amount && (!!this.operator || !!this.supplier) && !!this.tags && !!this.creator)
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
    amount() {
      const newVal = (!!this.openedAt && !!this.amount && (!!this.operator || !!this.supplier) && !!this.tags && !!this.creator)
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
    operator() {
      const newVal = (!!this.openedAt && !!this.amount && (!!this.operator || !!this.supplier) && !!this.tags && !!this.creator)
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
    creator(){
      const newVal = (!!this.openedAt && !!this.amount && (!!this.operator || !!this.supplier) && !!this.tags && !!this.creator)
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    }
  }
};
</script>
<style lang="scss">
.custom-green .v-input--switch__track {
  color: green !important;
}
.custom-green .v-input--switch__thumb {
  color: green !important;
}

</style>