<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      :title="'Entrate e Uscite: '+ operatorFullname"
      :route-father="pathToGoBack"
    >
      <template v-slot:header-actions>
        <v-btn
          icon
          :loading="printLoading"
          @click="handlePrint"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>
      <template v-slot:toolbar-extension>
        <div class="d-flex justify-center align-center" style="width: 100%">
          <v-btn
            icon
            @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <div style="width: 300px" class="text-center">
            <div>{{ calendarTitle }}</div>
          </div>
          <v-btn
            icon
            @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:content>
        <v-calendar
          ref="calendar"
          v-model="value"
          type="month"
          event-start="openedAt"
          event-end="openedAt"
          :events="timeLogs"
          color="primary"
          @change="handleCalendarChange"
          @click:more="handleMoreClick"
        >
          <template v-slot:day-label="{day}">
            <span>{{day}}</span>
          </template>
          <template v-slot:event="{event}">
            <StandardMenu @input="setUpdatingTimeLog(event)">
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  {{eventName(event)}}
                </div>
              </template>
              <v-card width="350px">
                <v-card-title>{{eventName(updatingTimeLog)}}</v-card-title>
                <v-divider></v-divider>
                <template v-if="canUpdateTimeLog">
                  <div>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          <DateTimePicker
                            filled
                            v-model="updatingTimeLog.openedAt"
                          ></DateTimePicker>
                        </v-col>
                      </v-row>
                      <v-row v-if="event.closedAt">
                        <v-col>
                          <DateTimePicker
                            filled
                            v-model="updatingTimeLog.closedAt"
                          ></DateTimePicker>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <v-divider></v-divider>
                  <div class="d-flex justify-end pa-2">
                    <v-btn 
                      color="default" 
                      text
                      @loading="loadingSave"
                      @click="handleSave"
                      :disabled="isLicenseBlocked"
                    >Salva</v-btn>
                  </div>
                </template>
                <div v-else class="d-flex justify-center">
                  <v-alert 
                    border="left"
                    class="py-2 ma-2"
                    colored-border
                    color="warning"
                    elevation="2"
                  >Il tuo utente non può modificare le timbrature</v-alert>
                </div>
              </v-card>
            </StandardMenu>
          </template>
        </v-calendar>
      </template>
      <template v-slot:footer-actions="{close}">
        <v-btn text color="error" @click="close">Chiudi</v-btn>
      </template>
    </FullScreenDialog>
    <StandardDialog
      v-model="showBarcode"
      title="Modifica Turno"
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <div>
        <v-row>
          <v-col>
            <ManualBarcodeInput
              v-model="handleTimeLogBarcode"
              @confirm="editTimeLogPermission"
            >
            </ManualBarcodeInput>
          </v-col>
        </v-row>
      </div>
    </StandardDialog>
    <DayTimeLogs
      v-model="dayTimeLogs"
      :dialog.sync="dayDialog"
      :date="dateDialog"
      @reload-timelogs="fetchTimeLogs"
    ></DayTimeLogs>
  </div>
</template>

<script>
import dateUtils from '@/mixins/common/dateUtils'
import timeLogService from '@/services/presences/timeLogs/timeLogs.service'
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import StandardMenu from '@/components/common/StandardMenu.vue'
import DayTimeLogs from '@/components/areas/presences/presence/DayTimeLogs'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import debounce from "@/mixins/common/debounce.js";
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import operatorService from '@/services/operators/operators.service.js';

export default {
  components: { 
    FullScreenDialog,
    StandardMenu,
    DateTimePicker,
    DayTimeLogs,
    ManualBarcodeInput,
    StandardDialog
  },
  name: "TimeLogsList",
  mixins: [dateUtils, debounce],
  data: function() {
    return {
      dialog: true,
      value: undefined,
      calendarTitle: undefined,
      operatorId: undefined,
      operator: undefined,
      timeLogs: [],
      dayTimeLogs: [],
      dayDialog: false,
      dateDialog: undefined,
      printLoading: false,
      loadingSave: false,
      updatingTimeLog: { },
      timeLogPermissions: [],
      handleTimeLogBarcode: false,
      showBarcode: false,
      isLicenseBlocked: undefined
    }
  },
  mounted: function() {
    this.calendarTitle = this.$refs.calendar.title

    this.operatorId = this.$route.params.operatorId
    if(!!this.operatorId) {
      this.fetchOperator()
      this.fetchTimeLogs()
    }
  },
  props: {
    pathToGoBack: {
      type: String,
      default: "/presence/operatorsTimeLogs"
    },
  },
  methods: {
    handleCalendarChange() {
      this.calendarTitle = this.$refs.calendar.title
      if (!!this.operatorId)
        this.fetchTimeLogs()
    },
    eventName(event) {
      let name = this.dateToTimeString(event.openedAt)
      if(!!event.closedAt) name += " - " + this.dateToTimeString(event.closedAt)
      return name
    },
    handlePrint() {
      let focusDate = new Date()
      if(!!this.value) focusDate = new Date(this.value)
      
      this.printLoading = true
      timeLogService.print({
        operator: {id: this.operatorId, name: this.operator.firstname + '_' + this.operator.lastname},
        month: focusDate.getMonth() + 1,
        year: focusDate.getFullYear()
      }).then(() => {
        this.printLoading = false
      })
    },
    fetchOperator() {
      operatorService.get(this.operatorId).then((result) => {
        this.operator = result
      })
    },
    fetchTimeLogs() {
      let focusDate = new Date()
      if(!!this.value) focusDate = new Date(this.value)
      
      timeLogService.list({
        operatorId: this.operatorId,
        month: focusDate.getMonth() + 1,
        year: focusDate.getFullYear(),
      }).then((timeLogs) => {
        for(let i = 0; i < timeLogs.rows.length; i += 1) {
          timeLogs.rows[i].allDay = true
          timeLogs.rows[i].openedAt = new Date(timeLogs.rows[i].openedAt)
          if(!!timeLogs.rows[i].closedAt) {
            timeLogs.rows[i].closedAt = new Date(timeLogs.rows[i].closedAt)
          }
        }
        this.timeLogs = timeLogs.rows
      })
    },
    openBarcodeDialog() {
      this.showBarcode = true
    },
    handleSave() {
      this.loadingSave = true
      timeLogService.update({
        id: this.updatingTimeLog.id,
        openedAt: this.updatingTimeLog.openedAt,
        closedAt: this.updatingTimeLog.closedAt,
      }).then(() => {
        this.loadingSave = false
        this.fetchTimeLogs()
      })
    },
    handleMoreClick(params) {
      this.dayTimeLogs = this.timeLogs.filter(el => {
        return (el.openedAt.getMonth() + 1) == params.month && el.openedAt.getDate() == params.day
      })
      this.dayDialog = true
      this.dateDialog = new Date(params.date)
    },
    setUpdatingTimeLog(timeLog) {
      this.$nextTick(() => {
        this.updatingTimeLog = {...timeLog}
      })
    },
    editTimeLogPermission() {      
      operatorService.canPerformOperation(this.handleTimeLogBarcode, "TimeLog").then((result) => {
        if (result) {
          this.handleSave();
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false;
      this.handleTimeLogBarcode = "";
    },
  },
  computed: {
    canUpdateTimeLog() {
      return this.timeLogPermissions.includes('manage')
    },
    operatorFullname() {
      return this.operator ? this.operator.firstname + ' ' + this.operator.lastname : ""
    },
  },
  permissions: {
    TimeLog: {
      bind: "timeLogPermissions",
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
}
</script>

<style>

</style>