import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class AbsenceTypeService {
  constructor() {
  }

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + '/presences/absenceTypes/list', { 
          page: page, 
          rowPerPage: rowPerPage, 
          filters: filters 
        }).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((error) => {
          reject(error)
        })
    })
  }

  create(params) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!params) reject(new Error('no params specified'))
      else {
        axios.post(apiUrl + '/presences/absenceTypes/create', params).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
        }).catch((error) => {
          reject(error)
        })
      }
    })
  }

  update(params) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/presences/absenceTypes/update', params).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(absenceType) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if(!absenceType || !absenceType.id) {
        reject(new Error("no absence specified"))
        return
      }

      axios.get(apiUrl + '/presences/absenceTypes/delete', {id: absenceType.id}).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  get(absenceTypeId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/presences/absenceTypes/get", { id: absenceTypeId })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  fields() {
    return Promise.resolve([
      // here the fields
      { text: "Nome", value: "name" },
    ]);
  }
}

export default new AbsenceTypeService()