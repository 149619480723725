
import analyticsList from './analytics.json'
import AnalyticNamesMappings from './analyticsNameMappings'


class AnalyticsManagment {
  constructor() {
    this.analytics = analyticsList
    this._calculateAnalyticsText()
  }

  list() {
    return Promise.resolve(this.analytics)
  }

  returnListRouteFromHumanName(humanName) {
    let key = AnalyticNamesMappings.getKeyNameFromValue(humanName)
    let objFounded = this.analytics.find(el => el.name == key)
    if (!!objFounded)
      return objFounded.listRoute
  }

  _calculateAnalyticsText() {
    for (let i = 0; i < this.analytics.length; i++) {
      this.analytics[i].text = AnalyticNamesMappings.getHumanName(this.analytics[i].name)
      this.analytics[i].icon = AnalyticNamesMappings.getAnalyticIcon(this.analytics[i].name)
    }
  }
  
}

export default new AnalyticsManagment()