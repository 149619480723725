import Vue from "vue";
import VueRouter from "vue-router";
import operatorsRoutes from "./registries/operators.routes.js";
import suppliersRoutes from "./registries/suppliers.routes.js";
import customersRoutes from "./registries/customers.routes.js";
import itemRoutes from "./warehouse/items.routes.js";
import orderRoutes from "./warehouse/orders.routes.js";
import movementRoutes from "./warehouse/movements.routes.js";
import serviceTypesRoutes from './registries/serviceTypes.routes.js'
import serviceTypesCategoriesRoutes from './registries/serviceTypesCategories.routes.js'
import servicesRoutes from './registries/services.routes.js'
import enablingTypeCategoriesRoutes from './registries/enablingTypeCategories.routes.js';
import itemTypesRoutes from './warehouse/itemTypes.routes.js';
import enablingTypesRoutes from "./registries/enablingTypes.routes.js";
import settingsRoutes from './settings/settings.routes.js';
import cashDesksRoutes from "./payments/cashDesks.routes.js";
import closureRoutes from "./payments/closure.routes.js";
import promosRoutes from "./payments/promos.routes";
import paymentCreditsRoutes from "./payments/paymentCredits.routes";
import paymentDebitsRoutes from "./payments/paymentDebits.routes";
import paymentTermsRoutes from "./payments/paymentTerms.routes";
import taxesRoutes from "./payments/taxes.routes";
import schedulePaymentsSettingsRoutes from "./payments/schedulePaymentsSettings.routes.js";
import paymentTransactionsCollectedRoutes from "./payments/paymentTransactionsCollected.routes.js";
import paymentTransactionsGivenRoutes from "./payments/paymentTransactionsGiven.routes.js";
import invoicesToCashRoutes from "./payments/invoicesToCash.routes.js";
import invoicesToPayRoutes from "./payments/invoicesToPay.route.js";
import otherCostsRoutes from "./payments/otherCosts.routes.js";
import otherCashesRoutes from "./payments/otherCashes.routes.js";
import analyticsRoutes from "./analytics/analytics.routes.js";
import marketingRoutes from "./marketing/marketing.routes.js";

import homepageRoutes from "./payments/homepage.routes.js";
import barPaymnetRoutes from "./payments/bar.routes.js";
import barRoutes from "./bar/bar.routes.js";
import presencesRoutes from "./presences/presences.routes.js";
import departmentsRoutes from "./registries/departments.routes";
import packageTypesRoutes from "./registries/packageTypes.routes";
import unitOfMeasuresRoutes from "./registries/unitOfMeasures.routes";
import jobsRoutes from "./registries/jobs.routes";
import hairColorsRoutes from "./registries/hairColors.routes";
import hairTypesRoutes from "./registries/hairTypes.routes";
import qualificationsRoutes from "./registries/qualifications.routes";
import colorLinesRoutes from "./registries/colorLines.routes";
import interestsRoutes from "./registries/interests.routes.js";

import authService from "@/services/auth/auth.service.js";
import initializer from "@/initializer.js"

Vue.use(VueRouter);

const baseRoutes = [
  {
    path: "/loading",
    name: "LoadingPage",
    component: () => import("@/views/LoadingPage.vue"),
  },
  {
    path: "/",
    name: "Homepage",
    component: () => import("@/views/Homepage.vue"),
    props: (route) => {
      return {
        addCustomerDialog: route.query.addCustomerDialog == 'true' || route.params.addCustomerDialog,
        customerFromVoice: route.params.customerFromVoice,
        selectedBill: route.params.selectedBill,
        openCardDetailDialog: route.query.openCardDetailDialog == 'true' || route.query.openCardDetailDialog == true,
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/Registration.vue"),
  },
  {
    path: "/sendResetPassword",
    name: "SendResetPassword",
    component: () => import("@/views/SendResetPassword.vue"),
  },
  {
    path: "/resetPassword/:token",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
    props: (route) => {
      return {
        token: route.params.token,
      }
    }
  },
  {
    path: "/crash",
    name: "Crash",
    component: () => import("@/views/Crash.vue"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: () => import("@/views/Calendar.vue"),
    props: (route) => {
      let newEventGroupPrecompiled;
      try {
        newEventGroupPrecompiled = JSON.parse(route.query.newEventGroupPrecompiled)
        if (newEventGroupPrecompiled.startDate) {
          newEventGroupPrecompiled.startDate = new Date(newEventGroupPrecompiled.startDate)
        }
      } catch (error) {
        newEventGroupPrecompiled = {}
      }

      let customerFromVoice;
      if (route.params.customerFromVoice) {
        customerFromVoice = route.params.customerFromVoice
      }

      let calendarDateFromVoice;
      if (route.params.calendarDateFromVoice) {
        calendarDateFromVoice = route.params.calendarDateFromVoice
      }

      return {
        newEventGroupFormDialog: route.query.newEventGroupFormDialog == 'true',
        newEventGroupPrecompiled: newEventGroupPrecompiled,
        customerFromVoice: customerFromVoice,
        calendarDateFromVoice: calendarDateFromVoice
      }
    }
  },
  /*{
    path: "/private-area",
    name: "PrivateArea",
    component: () => import("@/views/PrivateArea.vue"),
  },*/
  {
    path: "/warehouse",
    name: "Warehouse",
    component: () => import("@/views/PrivateSection/Warehouse.vue"),
  },
  {
    path: "/registries",
    name: "Registries",
    component: () => import("@/views/PrivateSection/Registries.vue"),
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: () => import("@/views/PrivateSection/Marketing.vue"),
  },
  {
    path: "/payments",
    name: "Payments",
    component: () => import("@/views/PrivateSection/Payments.vue"),
  },
  {
    path: "/bar",
    name: "Bar",
    component: () => import("@/views/Bar.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
    props: (route) => {
      return {
      }
    },
  },
];

const routes = baseRoutes
  .concat(operatorsRoutes)
  .concat(suppliersRoutes)
  .concat(customersRoutes)
  .concat(itemRoutes)
  .concat(orderRoutes)
  .concat(movementRoutes)
  .concat(settingsRoutes)
  .concat(enablingTypeCategoriesRoutes)
  .concat(enablingTypesRoutes)
  .concat(itemTypesRoutes)
  .concat(serviceTypesRoutes)
  .concat(serviceTypesCategoriesRoutes)
  .concat(servicesRoutes)
  .concat(cashDesksRoutes)
  .concat(promosRoutes)
  .concat(barRoutes)
  .concat(presencesRoutes)
  .concat(departmentsRoutes)
  .concat(packageTypesRoutes)
  .concat(unitOfMeasuresRoutes)
  .concat(jobsRoutes)
  .concat(hairTypesRoutes)
  .concat(qualificationsRoutes)
  .concat(hairColorsRoutes)
  .concat(homepageRoutes)
  .concat(barPaymnetRoutes)
  .concat(otherCostsRoutes)
  .concat(otherCashesRoutes)
  .concat(closureRoutes)
  .concat(paymentCreditsRoutes)
  .concat(paymentDebitsRoutes)
  .concat(paymentTermsRoutes)
  .concat(taxesRoutes)
  .concat(schedulePaymentsSettingsRoutes)
  .concat(paymentTransactionsGivenRoutes)
  .concat(paymentTransactionsCollectedRoutes)
  .concat(invoicesToCashRoutes)
  .concat(invoicesToPayRoutes)
  .concat(analyticsRoutes)
  .concat(marketingRoutes)
  .concat(colorLinesRoutes)
  .concat(interestsRoutes)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.name === "Crash") {
    initializer.removeLoader()
    next()
    return
  }

  initializer.initializeApp().then((status) => {
    if (status == 'completed' || status == 'access-denied' || status == 'not-found') {
      authService.isAuthenticated().then((isAuthenticated) => {
        if (to.name !== "Login" && to.name !== "Registration" && to.name !== "SendResetPassword" && to.name !== "ResetPassword" && !isAuthenticated) {
          next({
            name: "Login",
            params: {
              resolvePath: to.path
            }
          });
        } else {
          authService.redirectIfOnlyWheelOfFortune(to, from, next)
          next();
        }
      });
    } else {
      next({
        name: "Crash",
        query: {
          resolvePath: to.path
        }
      })
    }
  })
});

export default router;
