import { openDB } from 'idb';
import _ from 'lodash'

class recentOperationTracker {
  constructor() {
    this._createDatabase()
  }

  pushOperation(operation) { 
    if(!operation.performedAt) {
      operation.performedAt = new Date()
    }

    return new Promise((resolve, reject) => {
      // native validating operation
      let nativeValidateResult = this._nativeValidateOperation(operation)
      if (nativeValidateResult !== true) {
        reject(nativeValidateResult)
        return
      }

      // validating operation with custom method
      const validateResult = this._validateOperation(operation)
      if (validateResult !== true) {
        reject(validateResult)
        return
      }

      this.dbPromise.then((db) => {
        const operations = db.getAllFromIndex(this.name(), 'performedAt')
        if (operations.length >= this._operationsLimit()) {
          const elementsToRemoveNumber = operation.length - this._operationsLimit()
          this.getOlderOperations(elementsToRemoveNumber).then((elementsToRemove) => {
            for(let i = 0; i < elementsToRemove.length; i++) {
              db.delete(this.name, elementsToRemove.id)
            }
            db.put(this.name(), operation)
            resolve(operation)
          })
        } else {
          db.put(this.name(), operation)
          resolve(operation)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getOlderOperations(number = 10) {
    return new Promise((resolve, reject) => {
      this.dbPromise.then((db) => {
        db.getAllFromIndex(this.name(), 'performedAt').then((operations) => {
          operations = _.take(operations, number)
          this._formatResults(operations).then((newOperations) => {
            resolve(newOperations)
          }).catch((err) => {
            reject(err)
          })
        })
      }).catch((err) => {
        reject(err)
      })
    })
  }

  getLastOperations(number = 10) {
    return new Promise((resolve, reject) => {
      this.dbPromise.then((db) => {
        db.getAllFromIndex(this.name(), 'performedAt').then((operations) => {
          operations = _.take(_.reverse(operations), number)
          this._formatResults(operations).then((newOperations) => {
            resolve(newOperations)
          }).catch((err) => {
            reject(err)
          })
        })
      }).catch((err) => {
        reject(err)
      })
    })
  }

  _operationsLimit() {
    return 50
  }

  _validateOperation(operation) {
    return true
  }

  _nativeValidateOperation(operation) {
    if(!operation['operationName']) {
      return 'operationName must be defined'
    }

    if (!operation['performedAt']) {
      return 'performedAt must be defined'
    } else if (!(operation['performedAt'] instanceof Date)) {
      return 'performedAt must be a date'
    } else {
      return true
    }
  }

  _versionUpdate(db, oldVersion, newVersion, transaction) {
    return
  }

  _currentVersion() {
    return 1
  }

  _formatResults(results) {
    return new Promise((resolve, reject) => {
      resolve(results)
    })
  }


  _createDatabase() {
    var self = this
    this.dbPromise = openDB('one-ware-recent-operations-' + self.name(), self._currentVersion(), {
      upgrade(db, oldVersion, newVersion, transaction) {
        switch (oldVersion) {
          case 0:
            let store = db.createObjectStore(self.name(), {
              keyPath: 'id',
              autoIncrement: true,
            });
            store.createIndex('performedAt', 'performedAt');
          default:
            self._versionUpdate(db, oldVersion, newVersion, transaction)
        }
      },
    });
  }
}

export default recentOperationTracker