import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class marketingEventsService {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingEvents/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
   * @typedef {Object} License
   * @property {number} id - The ID of the license.
   */

  /**
   * @typedef {Object} SmsModelPlaceholder
   * @property {string} name - The name of the placeholder.
   * @property {string} text - Short description of the placeholder.
   */

  /**
   * @typedef {Object} SmsModel
   * @property {string} name - The name of the SMS model.
   * @property {string} text - The text of the SMS model.
   * @property {SmsModelPlaceholder[]} [values] - Optional array of placeholder objects.
   */

  /**
   * @typedef {Object} LandingPageBlock
   * @property {'header' | 'body' | 'rightSidebar' | 'leftSidebar'} position - The position of the block.
   * @property {'image' | 'paragraph' | 'imageAndParagraph'} type - The type of the block.
   * @property {number} order - The order of the block.
   * @property {string} title - The title of the block.
   * @property {string} subtitle - The subtitle of the block.
   * @property {string} body - The body content of the block.
   * @property {Object} image - The image object.
   * @property {string} image.path - The path of the image.
   */

  /**
   * @typedef {Object} LandingPage
   * @property {string} name - The name of the landing page.
   * @property {string} description - The description of the landing page.
   * @property {'headerImageAndSidebar' | 'sidebar' | 'fullHeaderImageAndSidebar'} layout - The layout of the landing page.
   * @property {LandingPageBlock[]} blocks - An array of landing page blocks.
   */

  /**
   * @typedef {Object} ReceiptModel
   * @property {string} title
   * @property {string[]} additionalText
   * @property {boolean} factoryData
   * @property {boolean} cashDeskOperatorThanks
   * @property {number} barcodeId - The ID of the barcode.
   * @property {string[]} text - An array of text elements.
   * @property {string} qrcode - The QR code string.
   * @property {'onlyText' | 'barcode' | 'qrcode'} type - The type of the receipt model.
   */

  /**
   * @typedef {Object} Action
   * @property {string} name - The name of the action.
   * @property {string} [description] - The description of the action.
   * @property {string} goal - The goal of the action.
   * @property {string[]} categories - An array of action categories.
   * @property {SmsModel} [smsModel] - Optional SMS model object.
   * @property {LandingPage} [landingPage] - Optional landing page object.
   * @property {ReceiptModel} [receiptModel] - Optional receipt model object.
   * @property {number} [selectedImageId] - Optional id of the selected image.
   * @property {number[]} [imageTags] - Optional array of image tags.
   */

  /**
   * @typedef {Object} RecurringDate
   * @property {Date} from - The starting date.
   * @property {Date} to - The ending date.
   */
  /**
   * @typedef {Object} MarketingEvent
   * @property {Date} from - The starting date.
   * @property {Date} to - The ending date.
   * @property {RecurringDate[]} [recurringDates] - Optional array of recurring dates.
   * @property {'draft' | 'approved'} status - The status.
   * @property {License[]} [licenses] - Optional array of licenses.
   * @property {Action} actions - Optional array of actions.
   * @property {number} selectedImageId - Optional id of the selected image.
   * @property {boolean} imageFromLocalSchema - Optional boolean value.
   */

  /**
   * Createan event marketing
   *
   * @param {MarketingEvent} marketingEvent - The marketing event.
   * @returns {MarketingEvent}
   */
  create(marketingEvent) {
    const apiUrl = UrlKeeper.getUrl()
    
    let localDateFrom = new Date(marketingEvent.from)
    localDateFrom.setHours(0, 0, 0, 0)

    let localDateTo = new Date(marketingEvent.to)
    localDateTo.setHours(23, 59, 59)

    var formData = new FormData();
    formData.append('marketingEvent.from', localDateFrom)
    formData.append('marketingEvent.to', localDateTo)
    formData.append('marketingEvent.status', marketingEvent.status)
    formData.append('marketingEvent.recurrenceType', marketingEvent.recurrenceType)
    formData.append(`marketingEvent.selectedImageId`, marketingEvent.selectedImageId);

    if (marketingEvent.imageFromLocalSchema !== undefined && marketingEvent.imageFromLocalSchema !== null)
      formData.append(`marketingEvent.imageFromLocalSchema`, marketingEvent.imageFromLocalSchema);

    if (marketingEvent.customRecurrence !== undefined && marketingEvent.customRecurrence !== null) {
      formData.append('marketingEvent.customRecurrence.repeatType', marketingEvent.customRecurrence.repeatType)
      formData.append('marketingEvent.customRecurrence.repeatNumber', marketingEvent.customRecurrence.repeatNumber)

      if (marketingEvent.customRecurrence.repeatDays !== undefined && marketingEvent.customRecurrence.repeatDays !== null) {
        marketingEvent.customRecurrence.repeatDays.forEach((day, index) => {
          formData.append(`marketingEvent.customRecurrence.repeatDays[${index}]`, day)
        })
      }

      formData.append('marketingEvent.customRecurrence.repeatEndType', marketingEvent.customRecurrence.repeatEndType)
      formData.append('marketingEvent.customRecurrence.repeatEndDate', marketingEvent.customRecurrence.repeatEndDate)
      formData.append('marketingEvent.customRecurrence.repeatEndOccurrences', marketingEvent.customRecurrence.repeatEndOccurrences)
    }

    const recurringDates = marketingEvent.recurringDates
    if (recurringDates !== undefined && recurringDates !== null) {
      recurringDates.forEach((date, index) => {
        let localDateFrom = new Date(date.from)
        localDateFrom.setHours(0, 0, 0, 0)

        let localDateTo = new Date(date.to)
        localDateTo.setHours(23, 59, 59)

        formData.append(`marketingEvent.recurringDates[${index}].from`, localDateFrom)
        formData.append(`marketingEvent.recurringDates[${index}].to`, localDateTo)
      })
    }
    
    const action = marketingEvent.actions

    formData.append(`marketingEvent.actions.name`, action.name)
    formData.append(`marketingEvent.actions.goal`, action.goal)
    
    if (action.sendTime !== undefined && action.sendTime !== null) {
      formData.append(`marketingEvent.actions.sendTime`, action.sendTime)
    }

    action.categories.forEach((category, index) => {
      formData.append(`marketingEvent.actions.categories[${index}]`, category)
    })


    if (action.description) {
      formData.append(`marketingEvent.actions.description`, action.description)
    }

    if (action.landingPageId) {
      formData.append(`marketingEvent.actions.landingPageId`, action.landingPageId)
    }

    if (action.recipients) {
      for (const [key, value] of Object.entries(action.recipients)) {
        if (Array.isArray(value)) {
          value.forEach((element, index) => {
            formData.append(`marketingEvent.actions.recipients.${key}[${index}]`, element)
          })
        }

        else if (typeof value === 'object' && !(value instanceof Date) 
          && !Array.isArray(value) && value !== null) {
            for (const [insideKey, insideValue] of Object.entries(value)) {
              if (Array.isArray(insideValue)) {
                insideValue.forEach((element, index) => {
                  formData.append(`marketingEvent.actions.recipients.${key}.${insideKey}[${index}]`, element)
                })
              }
              
              else {
                formData.append(`marketingEvent.actions.recipients.${key}.${insideKey}`, insideValue)
              }
            }
        }

        else {
          formData.append(`marketingEvent.actions.recipients.${key}`, value)
        }
      }
    }

    if (action.landingPage) {
      formData.append(`marketingEvent.actions.landingPage.name`, action.landingPage.name || '')
      formData.append(`marketingEvent.actions.landingPage.description`, action.landingPage.description || '')
      formData.append(`marketingEvent.actions.landingPage.layout`, action.landingPage.layout || '')

      // Append each block inside landingPage.blocks
      action.landingPage.blocks.forEach((block, index) => {
        formData.append(`marketingEvent.actions.landingPage.blocks[${index}].position`, block.position)
        formData.append(`marketingEvent.actions.landingPage.blocks[${index}].type`, block.type)
        if(block.order !== undefined && block.order !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].order`, block.order)
        }
        if(block.title !== undefined && block.title !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].title`, block.title)
        }
        if(block.subtitle !== undefined && block.subtitle !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].subtitle`, block.subtitle)
        }
        if(block.body !== undefined && block.body !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].body`, block.body)
        }

        if (!!block.image) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].image`, block.image, block.image.name)
        }
      })
    }

    if(!!action.smsModel) {
      if (action.smsModel.name !== undefined && action.smsModel.name !== null) {
        formData.append(`marketingEvent.actions.smsModel.name`, action.smsModel.name)
      }

      if (action.smsModel.text !== undefined && action.smsModel.text !== null) {
        formData.append(`marketingEvent.actions.smsModel.text`, action.smsModel.text)
      }
      
      if (action.smsModel.values !== undefined && action.smsModel.values !== null) {
        action.smsModel.values.forEach((value, index) => {
          formData.append(`marketingEvent.actions.smsModel.values[${index}].name`, value.name)
          formData.append(`marketingEvent.actions.smsModel.values[${index}].text`, value.text)
        })
      }
    }

    if(!!action.receiptModel) {
      if (action.receiptModel.barcodeId !== undefined && action.receiptModel.barcodeId  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.barcodeId`, action.receiptModel.barcodeId)
      }
      
      if (action.receiptModel.qrcode !== undefined && action.receiptModel.qrcode  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.qrcode`, action.receiptModel.qrcode)
      }

      formData.append(`marketingEvent.actions.receiptModel.type`, action.receiptModel.type)
      
      formData.append(`marketingEvent.actions.receiptModel.title`, action.receiptModel.title)

      if (action.receiptModel.additionalText !== undefined && action.receiptModel.additionalText  !== null
          && action.receiptModel.additionalText.length > 0) {
        action.receiptModel.additionalText.forEach((text, index) => {
          formData.append(`marketingEvent.actions.receiptModel.additionalText[${index}]`, text)
        })
      }

      if (action.receiptModel.factoryData !== undefined && action.receiptModel.factoryData  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.factoryData`, action.receiptModel.factoryData)
      }

      if (action.receiptModel.cashDeskOperatorThanks !== undefined && action.receiptModel.cashDeskOperatorThanks  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.cashDeskOperatorThanks`, action.receiptModel.cashDeskOperatorThanks)
      }

      action.receiptModel.text.forEach((text, index) => {
        formData.append(`marketingEvent.actions.receiptModel.text[${index}]`, text)
      })


    }

    if (action.selectedImageId !== undefined && action.selectedImageId !== null) {
      formData.append(`marketingEvent.actions.selectedImageId`, action.selectedImageId)
    }

    if (action.imageTags !== undefined && action.imageTags !== null) {
      action.imageTags.forEach((tagId, index) => {
        formData.append(`marketingEvent.actions.imageTags[${index}]`, tagId)
      })
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingEvents/create', formData).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }

      }).catch((error) => {
        reject(error)
      })
    })
  }

    /**
    * @param {Object} marketingEvent - The event to create
    * @param {Object} marketingEvent.fromMarketingEvent
    * @param {Date} marketingEvent.startDate
    * @param {Date} marketingEvent.endDate
    * @param {Object} marketingEvent.action
    */
    createFrom(marketingEvent) {
      const apiUrl = UrlKeeper.getUrl()

      let localDateFrom = new Date(marketingEvent.from)
      localDateFrom.setHours(0, 0, 0, 0)
      
      let localDateTo = new Date(marketingEvent.to)
      localDateTo.setHours(23, 59, 59)

      var formData = new FormData();
      formData.append('marketingEvent.from', localDateFrom)
      formData.append('marketingEvent.to', localDateTo)
      formData.append('marketingEvent.status', marketingEvent.status)
      formData.append('marketingEvent.fromMarketingEvent.id', marketingEvent.fromMarketingEvent.id)
      formData.append('marketingEvent.recurrenceType', marketingEvent.recurrenceType)
      formData.append(`marketingEvent.selectedImageId`, marketingEvent.selectedImageId);
      
      if (marketingEvent.imageFromLocalSchema !== undefined && marketingEvent.imageFromLocalSchema !== null)
        formData.append(`marketingEvent.imageFromLocalSchema`, marketingEvent.imageFromLocalSchema);

      if (marketingEvent.customRecurrence !== undefined && marketingEvent.customRecurrence !== null) {
        formData.append('marketingEvent.customRecurrence.repeatType', marketingEvent.customRecurrence.repeatType)
        formData.append('marketingEvent.customRecurrence.repeatNumber', marketingEvent.customRecurrence.repeatNumber)
        
        if (marketingEvent.customRecurrence.repeatDays !== undefined && marketingEvent.customRecurrence.repeatDays !== null) {
          marketingEvent.customRecurrence.repeatDays.forEach((day, index) => {
            formData.append(`marketingEvent.customRecurrence.repeatDays[${index}]`, day)
          })
        }
        
        formData.append('marketingEvent.customRecurrence.repeatEndType', marketingEvent.customRecurrence.repeatEndType)
        formData.append('marketingEvent.customRecurrence.repeatEndDate', marketingEvent.customRecurrence.repeatEndDate)
        formData.append('marketingEvent.customRecurrence.repeatEndOccurrences', marketingEvent.customRecurrence.repeatEndOccurrences)
      }

      const recurringDates = marketingEvent.recurringDates
      if (recurringDates !== undefined && recurringDates !== null) {
        recurringDates.forEach((date, index) => {
          let localDateFrom = new Date(date.from)
          localDateFrom.setHours(0, 0, 0, 0)
  
          let localDateTo = new Date(date.to)
          localDateTo.setHours(23, 59, 59)
  
          formData.append(`marketingEvent.recurringDates[${index}].from`, localDateFrom)
          formData.append(`marketingEvent.recurringDates[${index}].to`, localDateTo)
        })
      }
      
      const action = marketingEvent.actions
  
      formData.append(`marketingEvent.actions.name`, action.name)
      formData.append(`marketingEvent.actions.goal`, action.goal)

      if (action.sendTime !== undefined && action.sendTime !== null) {
        formData.append(`marketingEvent.actions.sendTime`, action.sendTime)
      }
  
      action.categories.forEach((category, index) => {
        formData.append(`marketingEvent.actions.categories[${index}]`, category)
      })
  
  
      if (action.description) {
        formData.append(`marketingEvent.actions.description`, action.description)
      }
  
      if (action.landingPageId) {
        formData.append(`marketingEvent.actions.landingPageId`, action.landingPageId)
      }

      if (action.recipients) {
        for (const [key, value] of Object.entries(action.recipients)) {
          if (Array.isArray(value)) {
            value.forEach((element, index) => {
              formData.append(`marketingEvent.actions.recipients.${key}[${index}]`, element)
            })
          }
  
          else if (typeof value === 'object' && !(value instanceof Date) 
            && !Array.isArray(value) && value !== null) {
              for (const [insideKey, insideValue] of Object.entries(value)) {
                if (Array.isArray(insideValue)) {
                  insideValue.forEach((element, index) => {
                    formData.append(`marketingEvent.actions.recipients.${key}.${insideKey}[${index}]`, element)
                  })
                }
                
                else {
                  formData.append(`marketingEvent.actions.recipients.${key}.${insideKey}`, insideValue)
                }
              }
          }
  
          else {
            formData.append(`marketingEvent.actions.recipients.${key}`, value)
          }
        }
      }
  
      if (action.landingPage) {
        formData.append(`marketingEvent.actions.landingPage.name`, action.landingPage.name || '')
        formData.append(`marketingEvent.actions.landingPage.description`, action.landingPage.description || '')
        formData.append(`marketingEvent.actions.landingPage.layout`, action.landingPage.layout || '')
  
        // Append each block inside landingPage.blocks
        action.landingPage.blocks.forEach((block, index) => {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].position`, block.position)
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].type`, block.type)
          if(block.order !== undefined && block.order !== null) {
            formData.append(`marketingEvent.actions.landingPage.blocks[${index}].order`, block.order)
          }
          if(block.title !== undefined && block.title !== null) {
            formData.append(`marketingEvent.actions.landingPage.blocks[${index}].title`, block.title)
          }
          if(block.subtitle !== undefined && block.subtitle !== null) {
            formData.append(`marketingEvent.actions.landingPage.blocks[${index}].subtitle`, block.subtitle)
          }
          if(block.body !== undefined && block.body !== null) {
            formData.append(`marketingEvent.actions.landingPage.blocks[${index}].body`, block.body)
          }
          if (block.copyImageUrl !== undefined && block.copyImageUrl !== null) {
            formData.append(`marketingEvent.actions.landingPage.blocks[${index}].copyImageUrl`, block.copyImageUrl)
          }
  
          if (!!block.image) {
            formData.append(`marketingEvent.actions.landingPage.blocks[${index}].image`, block.image, block.image.name)
          }
        })
      }
  
      if(!!action.smsModel) {
        if (action.smsModel.name !== undefined && action.smsModel.name !== null) {
          formData.append(`marketingEvent.actions.smsModel.name`, action.smsModel.name)
        }
  
        if (action.smsModel.text !== undefined && action.smsModel.text !== null) {
          formData.append(`marketingEvent.actions.smsModel.text`, action.smsModel.text)
        }
        
        if (action.smsModel.values !== undefined && action.smsModel.values !== null) {
          action.smsModel.values.forEach((value, index) => {
            formData.append(`marketingEvent.actions.smsModel.values[${index}].name`, value.name)
            formData.append(`marketingEvent.actions.smsModel.values[${index}].text`, value.text)
          })
        }
      }
  
      if(!!action.receiptModel) {
        if (action.receiptModel.barcodeId !== undefined && action.receiptModel.barcodeId  !== null) {
          formData.append(`marketingEvent.actions.receiptModel.barcodeId`, action.receiptModel.barcodeId)
        }
        
        if (action.receiptModel.qrcode !== undefined && action.receiptModel.qrcode  !== null) {
          formData.append(`marketingEvent.actions.receiptModel.qrcode`, action.receiptModel.qrcode)
        }
  
        formData.append(`marketingEvent.actions.receiptModel.type`, action.receiptModel.type)

        formData.append(`marketingEvent.actions.receiptModel.title`, action.receiptModel.title)

        if (action.receiptModel.additionalText !== undefined && action.receiptModel.additionalText  !== null
            && action.receiptModel.additionalText.length > 0) {
          action.receiptModel.additionalText.forEach((text, index) => {
            formData.append(`marketingEvent.actions.receiptModel.additionalText[${index}]`, text)
          })
        }
  
        if (action.receiptModel.factoryData !== undefined && action.receiptModel.factoryData  !== null) {
          formData.append(`marketingEvent.actions.receiptModel.factoryData`, action.receiptModel.factoryData)
        }
  
        if (action.receiptModel.cashDeskOperatorThanks !== undefined && action.receiptModel.cashDeskOperatorThanks  !== null) {
          formData.append(`marketingEvent.actions.receiptModel.cashDeskOperatorThanks`, action.receiptModel.cashDeskOperatorThanks)
        }
  
        action.receiptModel.text.forEach((text, index) => {
          formData.append(`marketingEvent.actions.receiptModel.text[${index}]`, text)
        })
      }

      if (action.selectedImageId !== undefined && action.selectedImageId !== null) {
        formData.append(`marketingEvent.actions.selectedImageId`, action.selectedImageId)
      }
  
      if (action.imageTags !== undefined && action.imageTags !== null) {
        action.imageTags.forEach((tagId, index) => {
          formData.append(`marketingEvent.actions.imageTags[${index}]`, tagId)
        })
      }

      return new Promise((resolve, reject) => {
        axios.post(apiUrl + '/marketing/marketingEvents/createFrom', formData).then((response) => {
          if (response.success) {
            resolve(response.results)
          } else {
            reject(response.results)
          }
  
        }).catch((error) => {
          reject(error)
        })
      })
    }

  /**
   * Createan event marketing
   *
   * @param {MarketingEvent} marketingEvent - The marketing event.
   * @returns {MarketingEvent}
   */
  duplicate(marketingEvent) {
    const apiUrl = UrlKeeper.getUrl()
    
    let localDateFrom = new Date(marketingEvent.from)
    localDateFrom.setHours(0, 0, 0, 0)

    let localDateTo = new Date(marketingEvent.to)
    localDateTo.setHours(23, 59, 59)

    const localMarketingEvent = {
      ...marketingEvent,
      from: localDateFrom,
      to: localDateTo
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingEvents/duplicate', localMarketingEvent).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }

      }).catch((error) => {
        reject(error)
      })
    })
  }

  import(marketingEvent) {
    const apiUrl = UrlKeeper.getUrl()
    
    let localDateFrom = new Date(marketingEvent.from)
    localDateFrom.setHours(0, 0, 0, 0)

    let localDateTo = new Date(marketingEvent.to)
    localDateTo.setHours(23, 59, 59)

    const masterEvent = {
      id: marketingEvent.id
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingEvents/import', {
        masterEvent: masterEvent
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }

      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
    * @param {Object} marketingEvent - Event to update
    * @property {number} marketingEvent.id - The ID of the object.
    * @param {Date} marketingEvent.startDate (optional)
    * @param {Date} marketingEvent.endDate (optional)
    * @param {Object} marketingEvent.action (optional)
   */
  update(marketingEvent) {
    const apiUrl = UrlKeeper.getUrl()
    var formData = new FormData();

    let localDateFrom = new Date(marketingEvent.from)
    localDateFrom.setHours(0, 0, 0, 0)

    let localDateTo = new Date(marketingEvent.to)
    localDateTo.setHours(23, 59, 59)

    formData.append('marketingEvent.id', marketingEvent.id)
    formData.append('marketingEvent.from', localDateFrom)
    formData.append('marketingEvent.to', localDateTo)
    formData.append('marketingEvent.status', marketingEvent.status)
    formData.append(`marketingEvent.selectedImageId`, marketingEvent.selectedImageId);
    
    if (marketingEvent.imageFromLocalSchema !== undefined && marketingEvent.imageFromLocalSchema !== null)
      formData.append(`marketingEvent.imageFromLocalSchema`, marketingEvent.imageFromLocalSchema);

    if (marketingEvent.recurrenceType !== undefined && marketingEvent.recurrenceType !== null) {
      formData.append('marketingEvent.recurrenceType', marketingEvent.recurrenceType)
    }

    if (marketingEvent.customRecurrence !== undefined && marketingEvent.customRecurrence !== null) {
      formData.append('marketingEvent.customRecurrence.repeatType', marketingEvent.customRecurrence.repeatType)
      formData.append('marketingEvent.customRecurrence.repeatNumber', marketingEvent.customRecurrence.repeatNumber)
      
      if (marketingEvent.customRecurrence.repeatDays !== undefined && marketingEvent.customRecurrence.repeatDays !== null) {
        marketingEvent.customRecurrence.repeatDays.forEach((day, index) => {
          formData.append(`marketingEvent.customRecurrence.repeatDays[${index}]`, day)
        })
      }
      
      formData.append('marketingEvent.customRecurrence.repeatEndType', marketingEvent.customRecurrence.repeatEndType)
      formData.append('marketingEvent.customRecurrence.repeatEndDate', marketingEvent.customRecurrence.repeatEndDate)
      formData.append('marketingEvent.customRecurrence.repeatEndOccurrences', marketingEvent.customRecurrence.repeatEndOccurrences)
    }

    const recurringDates = marketingEvent.recurringDates
    if (recurringDates !== undefined && recurringDates !== null) {
      recurringDates.forEach((date, index) => {
        let localDateFrom = new Date(date.from)
        localDateFrom.setHours(0, 0, 0, 0)

        let localDateTo = new Date(date.to)
        localDateTo.setHours(23, 59, 59)

        formData.append(`marketingEvent.recurringDates[${index}].from`, localDateFrom)
        formData.append(`marketingEvent.recurringDates[${index}].to`, localDateTo)
      })
    }
    
    const action = marketingEvent.actions

    formData.append(`marketingEvent.actions.id`, action.id)
    formData.append(`marketingEvent.actions.name`, action.name)
    formData.append(`marketingEvent.actions.goal`, action.goal)
    
    if (action.sendTime !== undefined && action.sendTime !== null) {
      formData.append(`marketingEvent.actions.sendTime`, action.sendTime)
    }

    action.categories.forEach((category, index) => {
      formData.append(`marketingEvent.actions.categories[${index}]`, category)
    })


    if (action.description) {
      formData.append(`marketingEvent.actions.description`, action.description)
    }

    if (action.landingPageId) {
      formData.append(`marketingEvent.actions.landingPageId`, action.landingPageId)
    }

    if (action.recipients) {
      for (const [key, value] of Object.entries(action.recipients)) {
        if (Array.isArray(value)) {
          value.forEach((element, index) => {
            formData.append(`marketingEvent.actions.recipients.${key}[${index}]`, element)
          })
        }

        else if (typeof value === 'object' && !(value instanceof Date) 
          && !Array.isArray(value) && value !== null) {
            for (const [insideKey, insideValue] of Object.entries(value)) {
              if (Array.isArray(insideValue)) {
                insideValue.forEach((element, index) => {
                  formData.append(`marketingEvent.actions.recipients.${key}.${insideKey}[${index}]`, element)
                })
              }
              
              else {
                formData.append(`marketingEvent.actions.recipients.${key}.${insideKey}`, insideValue)
              }
            }
        }

        else {
          formData.append(`marketingEvent.actions.recipients.${key}`, value)
        }
      }
    }

    if (action.landingPage) {
      if (action.landingPage.id !== undefined && action.landingPage.id !== null) {
        formData.append(`marketingEvent.actions.landingPage.id`, action.landingPage.id)
      }
      formData.append(`marketingEvent.actions.landingPage.name`, action.landingPage.name || '')
      formData.append(`marketingEvent.actions.landingPage.description`, action.landingPage.description || '')
      formData.append(`marketingEvent.actions.landingPage.layout`, action.landingPage.layout || '')

      // Append each block inside landingPage.blocks
      action.landingPage.blocks.forEach((block, index) => {
        formData.append(`marketingEvent.actions.landingPage.blocks[${index}].id`, block.id)
        formData.append(`marketingEvent.actions.landingPage.blocks[${index}].position`, block.position)
        formData.append(`marketingEvent.actions.landingPage.blocks[${index}].type`, block.type)
        if(block.order !== undefined && block.order !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].order`, block.order)
        }
        if(block.title !== undefined && block.title !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].title`, block.title)
        }
        if(block.subtitle !== undefined && block.subtitle !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].subtitle`, block.subtitle)
        }
        if(block.body !== undefined && block.body !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].body`, block.body)
        }
        if(block._new !== undefined && block._new !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}]._new`, block._new)
        }
        if (block._delete !== undefined && block._delte !== null) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}]._delete`, block._delete)
        }

        if (!!block.image) {
          formData.append(`marketingEvent.actions.landingPage.blocks[${index}].image`, block.image, block.image.name)
        }
      })
    }

    if (action.smsModelId) {
      formData.append(`marketingEvent.actions.smsModelId`, action.smsModelId)
    }

    if(!!action.smsModel) {
      if (action.smsModel.id !== undefined && action.smsModel.id !== null) {
        formData.append(`marketingEvent.actions.smsModel.id`, action.smsModel.id)
      }

      if (action.smsModel.name !== undefined && action.smsModel.name !== null) {
        formData.append(`marketingEvent.actions.smsModel.name`, action.smsModel.name)
      }

      if (action.smsModel.text !== undefined && action.smsModel.text !== null) {
        formData.append(`marketingEvent.actions.smsModel.text`, action.smsModel.text)
      }
      
      if (action.smsModel.values !== undefined && action.smsModel.values !== null) {
        action.smsModel.values.forEach((value, index) => {
          formData.append(`marketingEvent.actions.smsModel.values[${index}].name`, value.name)
          formData.append(`marketingEvent.actions.smsModel.values[${index}].text`, value.text)
        })
      }
    }

    if (action.receiptModelId) {
      formData.append(`marketingEvent.actions.receiptModelId`, action.receiptModelId)
    }

    if(!!action.receiptModel) {
      if (action.receiptModel.id !== undefined && action.receiptModel.id !== null) {
        formData.append(`marketingEvent.actions.receiptModel.id`, action.receiptModel.id)
      }

      if (action.receiptModel.barcodeId !== undefined && action.receiptModel.barcodeId  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.barcodeId`, action.receiptModel.barcodeId)
      }
      
      if (action.receiptModel.qrcode !== undefined && action.receiptModel.qrcode  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.qrcode`, action.receiptModel.qrcode)
      }

      formData.append(`marketingEvent.actions.receiptModel.type`, action.receiptModel.type)

      formData.append(`marketingEvent.actions.receiptModel.title`, action.receiptModel.title)

      if (action.receiptModel.additionalText !== undefined && action.receiptModel.additionalText  !== null
          && action.receiptModel.additionalText.length > 0) {
        action.receiptModel.additionalText.forEach((text, index) => {
          formData.append(`marketingEvent.actions.receiptModel.additionalText[${index}]`, text)
        })
      }

      if (action.receiptModel.factoryData !== undefined && action.receiptModel.factoryData  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.factoryData`, action.receiptModel.factoryData)
      }

      if (action.receiptModel.cashDeskOperatorThanks !== undefined && action.receiptModel.cashDeskOperatorThanks  !== null) {
        formData.append(`marketingEvent.actions.receiptModel.cashDeskOperatorThanks`, action.receiptModel.cashDeskOperatorThanks)
      }

      action.receiptModel.text.forEach((text, index) => {
        formData.append(`marketingEvent.actions.receiptModel.text[${index}]`, text)
      })
    }

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingEvents/update', formData).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  delete(marketingEvent) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!marketingEvent || !marketingEvent.id) {
        reject(new Error('marketing action is not specify'))
        return
      }

      axios.post(apiUrl + '/marketing/marketingEvents/delete', {
        id: marketingEvent.id
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  deleteRecurrentEvents(marketingEvent, deleteType) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!marketingEvent || !marketingEvent.id) {
        reject(new Error('marketing action is not specify'))
        return
      }

      if (!deleteType) {
        reject(new Error('deleteType is not specify'))
        return
      }

      axios.post(apiUrl + '/marketing/marketingEvents/deleteRecurrentEvents', {
        id: marketingEvent.id,
        deleteType: deleteType
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  archive(marketingEvent) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!marketingEvent || !marketingEvent.id) {
        reject(new Error('id is not specified'))
        return
      }

      let localDateTo = new Date()
      localDateTo.setHours(23, 59, 59)
      localDateTo.setMilliseconds(0)

      axios.post(apiUrl + '/marketing/marketingEvents/archive', {
        id: marketingEvent.id,
        newTo: localDateTo
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  get(marketingEventId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/marketing/marketingEvents/get', { id: marketingEventId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getMasterEvent(marketingEventId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/marketing/marketingEvents/getMasterEvent', { id: marketingEventId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getTodayEvents(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/marketing/marketingEvents/getTodayEvents', {
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((error) => {
        reject(error)
      })
    })
  }


}

export default new marketingEventsService();
