import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import recentOperationsDatabase from "@/services/offline/warehouse/warehouseRecentOperations.database.js";
import DateHandler from "@/services/common/dateHandler";

class itemManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    let totalFilters = []
    if (!!filters) totalFilters = [...filters]
    totalFilters = [
      ...totalFilters,
      {
        field: "active",
        operator: "equal",
        value: true,
      }
    ]

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/items/list", {
          page: page,
          rowPerPage: rowPerPage,
          filters: totalFilters,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  create(params, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/items/create", params).then((response) => {
        if (response.success) {
          resolve(response.results);
          if (recentOperation) {
            recentOperationsDatabase
              .pushOperation(
                this._buildRecentOperationObject("create", params.item)
              )
              .then(() => { });
          }
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  deactive(item, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/items/deactive", { id: +item.id })
        .then((response) => {
          if (response.success) {
            resolve(response.results);

            // if (recentOperation) {
            //   recentOperationsDatabase
            //     .pushOperation(this._buildRecentOperationObject("delete", item))
            //     .then(() => {});
            // }
          } else {
            reject(response.results.error);
          }
        });
    });
  }

  update(params, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/items/update", params).then((response) => {
        if (response.success) {
          resolve(response.results);
          if (recentOperation) {
            recentOperationsDatabase
              .pushOperation(
                this._buildRecentOperationObject("update", params.item)
              )
              .then(() => { });
          }
        } else {
          reject(response.results.error);
        }
      });
    });
  }

  get(itemId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/items/get", { id: itemId }).then((response) => {
        resolve(response.results);
      });
    });
  }

  getLastCode() {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/items/getLastCode", {}).then((response) => {
        if (response.success) {
          resolve(response.results);
        }
      });
    });
  }

  createOrderItem(orderId, itemId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/items/" + orderId + "/add", itemId)
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }

  getOrdersByItem(itemId, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .get(apiUrl + "/items/orders/" + itemId + "/list", { filters: filters })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          } else {
            reject("error during the call");
          }
        });
    });
  }

  printItem(itemIds) {
    const apiUrl = UrlKeeper.getUrl()

    let fileName
    if (Array.isArray(itemIds))
      fileName = itemIds.join('-')
    else
      fileName = itemIds
    return new Promise((resolve, reject) => {
      axios.postWithDownload(apiUrl + '/items/printItem/', { itemIds: itemIds }, 'Prodotto_' + fileName + '.pdf').then((response) => {
        resolve(response)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  uploadPhotoItem(itemId, data) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!itemId) {
        reject('id not specified')
        return
      }

      axios.post(apiUrl + '/items/' + itemId + '/uploadPhoto', data).then((response) => {
        if (!!response.success)
          resolve(response.results)
        else
          reject()
      }).catch((err) => {
        reject(err)
      })
    })
  }

  listItemsFromDepartment(departmentId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/items/listItemsFromDepartment", {
          departmentId: departmentId,
        })
        .then((response) => {
          if (response.success) {
            resolve(response.results);
          }
        });
    });
  }

  costOfCenterOptions() {
    return Promise.resolve([
      { value: 1, text: "Centro di Costo 1" },
      { value: 2, text: "Centro di Costo 2" },
      { value: 3, text: "Centro di Costo 3" },
    ]);
  }

  unitOfMeasureOptions() {
    return Promise.resolve([
      { value: 1, text: "Unità di misura 1" },
      { value: 2, text: "Unità di misura 2" },
      { value: 3, text: "Unità di misura 3" },
      { value: 4, text: "Unità di misura 4" },
      { value: 5, text: "Unità di misura 5" },
      { value: 6, text: "Unità di misura 6" },
      { value: 7, text: "Unità di misura 7" },
      { value: 8, text: "Unità di misura 8" },
    ]);
  }

  _fields() {
    return Promise.resolve([
      {
        text: "Barcode",
        value: "code",
      },
      {
        text: "Codice Interno",
        value: "internalCode",
      },
      {
        text: "Descrizione",
        value: "description",
      },

      {
        text: "Quantità Disponibile",
        value: "qtyInStock",
        type: "custom",
      },
      {
        text: "Tag Famiglia",
        value: "tags",
        type: "custom",
      },
      {
        text: "Fornitore",
        value: "supplier",
        type: "object",
        style: "chip",
      },
      {
        text: "Prezzo di Vendita",
        value: "priceSelling",
        type: "price"
      },
      {
        text: "Prezzo d'acquisto",
        value: "pricePurchase",
        type: "price"
      },
      {
        text: "Prezzo Netto d'acquisto",
        value: "priceNetPurchase",
        type: "price"
      },
      {
        text: "Data Ultimo Acquisto",
        value: "lastPurchaseDate",
        type: "date",
      },
      {
        text: "Data Creazione",
        value: "createdAt",
        type: "date",
      },
    ]);
  }

  _fieldsToUnload() {
    return Promise.resolve([
      {
        text: "Barcode",
        value: "code",
      },
      {
        text: "Codice Interno",
        value: "internalCode",
      },
      {
        text: "Descrizione",
        value: "description",
      },

      {
        text: "Quantità Disponibile",
        value: "qtyInStock",
        type: "custom",
      },
      {
        text: "Quantità da Scaricare",
        value: "qtyToUnload",
        type: "custom",
      },
      {
        text: "Fornitore",
        value: "supplier",
        type: "object",
        style: "chip",
      },
      {
        text: "Data Ultimo Acquisto",
        value: "lastPurchaseDate",
        type: "date",
      },
    ]);
  }
  _fieldsToUpload() {
    return Promise.resolve([
      {
        text: "Barcode",
        value: "code",
      },
      {
        text: "Codice Interno",
        value: "internalCode",
      },
      {
        text: "Descrizione",
        value: "description",
      },

      {
        text: "Quantità Disponibile",
        value: "qtyInStock",
        type: "custom",
      },
      {
        text: "Quantità da Caricare",
        value: "qtyToUpload",
        type: "custom",
      },
      {
        text: "Fornitore",
        value: "supplier",
        type: "object",
        style: "chip",
      },
      {
        text: "Data Ultimo Acquisto",
        value: "lastPurchaseDate",
        type: "date",
      },
    ]);
  }

  _fieldsToExport() {
    return {
      Barcode: {
        field: "code",
        callback: (value) => {
          return `${value}`.toString();
        },
      },
      "Descrizione": "description",
      "Famiglia": {
        field: "tags",
        callback: (value) => {
          if (value.length > 0)
            return value[0].description;
        }
      },
      "Fornitore": {
        field: "supplier",
        callback: (value) => {
          return `${value}` ? `${value.businessName}` : "";
        },
      },
      "Prezzo di Vendita": {
        field: "priceSelling",
        callback: (value) => {
          return Number(value).toFixed(2);
        },
      },
      "Prezzo d' Acquisto": {
        field: "pricePurchase",
        callback: (value) => {
          return Number(value).toFixed(2);
        },
      },
      "Prezzo netto d' Acquisto": {
        field: "priceNetPurchase",
        callback: (value) => {
          return Number(value).toFixed(2);
        },
      },
      "Sconto": "discount",
      "Data Ultimo Acquisto": {
        field: "lastPurchaseDate",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      "Data Creazione": {
        field: "createdAt",
        callback: (value) => {
          return DateHandler._buildDateToExport(`${value}`);
        },
      },
      // "Centro di costo": "costOfCenterSelected",
      "Tipo Confezione": "packageType.name",
      "Dipartimento": "department.name",
      // "Unità di Misura":"unitOfMeasure.code",
      "Quantità in giacenza": "qtyInStock",
      "Quantità minima in giacenza": "qtyMinStock",
      "Quantità da ordinare": "qtyToOrder",
      "Interno": {
        field: "isInternal",
        callback: (value) => {
          return `${value}` ? "SI" : "NO";
        },
      },
    };
  }

  _buildRecentOperationObject(operation, item) {
    let routeParams;
    if (operation == "update") {
      routeParams = { id: item.id };
    }

    let extraText;
    if (operation == "update") {
      extraText = item.code + "~" + item.description;
    }
    if (operation == "create") {
      extraText = item.code;
    }

    if (operation == "delete") {
      extraText = item.code;
    }

    return {
      text:
        operation == "create" ? "Aggiunta Nuovo Prodotto" : "Modifica Prodotto",
      extraText: extraText,
      name: operation == "create" ? "add_items" : "edit_items",
      operationName: operation == "create" ? "add" : "edit",
      registry: "items",
      params: {
        item: item,
        routeName: operation == "create" ? "ItemHome" : "ItemsList",
        routeParams: routeParams,
      },
    };
  }

}
export default new itemManagment();
