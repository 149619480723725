<template>
  <div class="pt-1 px-2 pb-0">
    <v-row class="d-flex justify-center align-center">
      <div v-if="showFilters">
        <v-tooltip button>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" @click="openFilterDialog" class="mt-0">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi filtri</span>
        </v-tooltip>
      </div>
      <div class="mx-3 mt-6">
        <slot name="external-filter">        
          <v-text-field
            dense
            outlined
            v-model="externalFilter.value" 
            :label="externalFilter.label" 
            @keyup.enter="addExternalFilter()"
            style="min-width: 25dvw"
          ></v-text-field>
        </slot>
      </div>
      <div>
        <v-tooltip button>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on" @click="addExternalFilter" class="mt-0">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Cerca</span>
        </v-tooltip>
      </div>
    </v-row>

    <v-row class="pr-2 pt-0 mt-0 pb-2 d-flex justify-start align-top">
      <div v-if="!!filtersValue.length" class="pl-4">
        <v-tooltip bottom button>
          <template v-slot:activator="{ on, attrs }">
            <v-chip dark color="red" v-bind="attrs" v-on="on" elevation="0" @click="clearFilters" medium label>
              <v-icon>mdi-close</v-icon>
            </v-chip>
          </template>
          <span>Rimuovi filtri</span>
        </v-tooltip>
      </div>
      <div v-for="(filter, idx) in filtersValue" class="d-flex justify-start align-top px-1">
        <v-chip v-if="filter.type != 'custom'" label close medium outlined @click:close="deleteFilter(filter.field)" :color="filter.color">
          {{ getStringFromFilter(filter) }}
        </v-chip>
        <v-chip v-else label close medium outlined @click:close="deleteFilter(filter.field)" :color="filter.color">
          <slot :name="'chip-custom-' + filter.field" v-bind="{ filter: filter }">
            {{ filter.name }}
          </slot>
        </v-chip>
      </div>
    </v-row>

    <StandardDialog 
      v-model="filterDialog" 
      :dialog-height="null" 
      :dialog-width="this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? 
       '90vw' : this.$vuetify.breakpoint.md ? '60vw' : '800px'" 
      :title="filterStep == 0 ? 'Filtri' : filterStep == 1 ? 'Filtri Avanzati' : editFilter.name"
    >
      <v-stepper v-model="filterStep" class="mt-2">
        <v-stepper-header>
          <v-stepper-step
            step="0"
            complete
            :editable="filterStep != 2"
            edit-icon="mdi-filter"
            complete-icon="mdi-pencil-lock"
          >
            Filtri
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="1"
            complete
            :editable="filterStep != 2"
            edit-icon="mdi-filter-multiple"
            complete-icon="mdi-pencil-lock"
          >
            Filtri Avanzati
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="0">
            <v-list>
              <v-list-item v-for="(filter, idx) in filters" @click="openEditFilter(filter)" :key="idx" dense>
                <v-list-item-icon>
                  <v-icon v-text="getIconFromFilter(filter)"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{filter.name}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-stepper-content>
          <v-stepper-content step="1">
            <v-list>
              <v-list-item v-for="(filter, idx) in advanceFilters" @click="openEditAdvanceFilter(filter)" :key="idx" dense>
                <v-list-item-icon>
                  <v-icon v-text="getIconFromFilter(filter)"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{filter.name}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div>
              <v-row class="pt-2">
                <v-col v-if="!!editFilter && editFilter.type != 'custom' && editFilter.type != 'boolean'" cols="2" class="d-flex align-center pt-2">
                  {{editFilter.name}}
                </v-col>
                <v-col cols="4" class="d-flex align-center mt-4" v-if="!!editFilter && editFilter.type != 'custom'">
                  <v-select v-if="!!editFilter && editFilter.type != 'boolean'" v-model="editFilter.operator"
                    :items="editFilter.type != 'date' && editFilter.type != 'number' && editFilter.type != 'cash' ? [...operators, ...likeOperators] : [...operators, ...betweenOperators]"
                    @input="changeTypeValue"
                    :disabled="editFilter.type == 'string'"
                    rounded
                    filled
                  ></v-select>
                </v-col>
                <v-col :cols="!!editFilter && editFilter.type != 'custom' && editFilter.type != 'boolean' ? 6 : 12" class="d-flex align-center">
                  <v-row v-if="!!editFilter && editFilter.type == 'string'" class="mt-2">
                    <v-col cols="12">
                      <v-text-field rounded filled v-model="editFilter.value" :label="editFilter.label"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="!!editFilter && editFilter.type =='date'" class="pb-4">
                    <v-col v-if="editFilter.operator != 'between' && editFilter.operator != 'not between'">
                      <DatePicker v-model="editFilter.value" :label="editFilter.label"></DatePicker>
                    </v-col>
                    <v-col v-if="editFilter.operator == 'between' || editFilter.operator == 'not between'">
                      <DatePicker v-model="editFilter.value[0]" label="Da"></DatePicker>
                    </v-col>
                    <v-col v-if="editFilter.operator == 'between' || editFilter.operator == 'not between'">
                      <DatePicker v-model="editFilter.value[1]" label="A"></DatePicker>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="!!editFilter && editFilter.type =='boolean'">
                    <v-col>
                      <v-switch v-model="editFilter.value" :label="editFilter.label"></v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="!!editFilter && (editFilter.type =='number' || editFilter.type =='cash')" class="mt-2">
                    <v-col v-if="editFilter.operator != 'between' && editFilter.operator != 'not between'">
                      <v-text-field  rounded filled v-model="editFilter.value" :label="editFilter.label" type="number">
                      </v-text-field>
                    </v-col>
                    <v-col v-if="editFilter.operator == 'between' || editFilter.operator == 'not between'">
                      <v-text-field v-model="editFilter.value[0]" label="Da" type="number">
                      </v-text-field>
                    </v-col>
                    <v-col v-if="editFilter.operator == 'between' || editFilter.operator == 'not between'">
                      <v-text-field v-model="editFilter.value[1]" label="A" type="number">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-else-if="!!editFilter && editFilter.type == 'custom'">
                    <v-col>
                      <slot :name="'custom-' + editFilter.field" v-bind="{ filter: editFilter }">
                        <v-row class="pa-3"><v-col>Not implemented yet</v-col></v-row>
                      </slot>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn v-if="filterStep != 2" color="error" text @click="closeAddFilter">
          Ricerca
        </v-btn>
        <v-btn v-if="filterStep == 2" text @click="closeEditFilter">
          Indietro
        </v-btn>
        <v-btn v-if="filterStep == 2" color="error" text @click="addFilter" :disabled="isEmpty">
          Conferma
        </v-btn>
      </template>
    </StandardDialog>

  </div>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog.vue';
import DatePicker from './DatePickerCommon.vue'

export default {
  name: "AdvancedFilter",
  components: {
    StandardDialog,
    DatePicker
  },
  props: {
    value: {
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    advanceFilters: {
      type: Array,
      default: function () {
        return [];
      },
    },
    externalFilter: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      filtersValue: this.value,
      filterDialog: false,
      editFilter: undefined,
      filterStep: 0,
      operators: [
        { text: 'uguale a', value: 'equal' },
        //{ text: 'diverso da', value: 'notEqual' },
        { text: 'maggiore di', value: 'more' },
        { text: 'minore di', value: 'less' },
        { text: 'maggiore uguale a', value: 'moreEqual' },
        { text: 'minore uguale di', value: 'lessEqual' },
        
      ],
      betweenOperators: [
        { text:'compreso tra', value: 'between' }, 
        //{ text:'non compreso tra', value: 'not between' }
        //controllare perchè il not between non cambia gli input, ne in maniera giusta ne in maniera sbagliata
      ],
      likeOperators: [
        { text: 'contiene', value: 'like' },
        //{ text: 'non contiene', value: 'notLike' },
      ],
      inOperators: [
        { text: 'è in', value: 'in' },
        { text: 'non è in', value: 'notIn' },
      ],
    };
  },
  mounted: function () {
  },
  beforeDestroy() {
    this.activeFilters = {};

    this.localFilters = [];
  },
  methods: {
    handleChanges() {
      this.$emit("input", this.filtersValue);
    },
    applyFilters() {
      this.$emit("apply-filters", this.filtersValue);
    },
    deleteFilter(field) {
      this.filtersValue = this.filtersValue.filter(f => f.field !== field);

      this.filters.filter(f => f.field === field).forEach(f => {
        if(f.operator == 'custom' && f.blank != undefined) {
          if(Array.isArray(f.blank))
            f.value = [...f.blank]
          else if(typeof f.blank === 'object' && !Array.isArray(f.blank) && 
            f.blank !== null)
            f.value = {...f.blank}
          else
            f.value = f.blank;
        }
        else if(f.operator == 'between' || f.operator == 'not between') {
          f.value = [undefined, undefined]
          f.label = ['Da', 'A']
        } else {
          f.value = undefined
          f.label = undefined
        }
      })

      this.advanceFilters.filter(f => f.field === field).forEach(f => {
        if(f.operator == 'custom' && f.blank != undefined) {
          if(Array.isArray(f.blank))
            f.value = [...f.blank]
          else if(typeof f.blank === 'object' && !Array.isArray(f.blank) && 
            f.blank !== null)
            f.value = {...f.blank}
          else
            f.value = f.blank;
        }
        else if(f.operator == 'between' || f.operator == 'not between') {
          f.value = [undefined, undefined]
          f.label = ['Da', 'A']
        } else {
          f.value = undefined
          f.label = undefined
        }
      })

      if (this.externalFilter.field === field) {
        this.externalFilter.value = undefined
      }

      this.handleChanges();
      this.applyFilters()
    },
    clearFilters() {
      this.filtersValue = [];
      this.filterStep = 0;
      this.clearFilterValues()
      this.handleChanges();
      this.applyFilters()
    },
    clearFilterValues() {
      this.filters.forEach(f => {
        if(f.operator == 'custom' && f.blank != undefined) {
          if(Array.isArray(f.blank))
            f.value = [...f.blank]
          else if(typeof f.blank === 'object' && !Array.isArray(f.blank) && 
            f.blank !== null)
            f.value = {...f.blank}
          else
            f.value = f.blank;
        }
        else if(f.operator == 'between' || f.operator == 'not between') {
          f.value = [undefined, undefined]
          f.label = ['Da', 'A']
        } else {
          f.value = undefined
          f.label = undefined
        }
      })
    },
    openFilterDialog() {
      this.filterDialog = true;
    },
    closeAddFilter() {
      this.filterDialog = false;
      this.advanceFilterDialog = false;
      this.applyFilters()
    },
    addFilter() {
      if (this.editFilter.type == 'date' && this.editFilter.operator != 'between') {
        let tmpDate = new Date(this.editFilter.value);
        tmpDate.setHours(0, 0, 0, 0);
        this.editFilter.value = new Date(tmpDate)
      } else if (this.editFilter.type == 'date' && this.editFilter.operator == 'between') {
        let tmpDate1 = new Date(this.editFilter.value[0]);
        tmpDate1.setHours(0, 0, 0, 0);
        this.editFilter.value[0] = new Date(tmpDate1)
        let tmpDate2 = new Date(this.editFilter.value[1]);
        tmpDate2.setHours(23, 59, 59, 999)
        this.editFilter.value[1] = new Date(tmpDate2)
      }

      this.filtersValue = this.filtersValue.filter(f => f.field !== this.editFilter.field)
      this.filtersValue.push(this.editFilter);
      this.handleChanges();
      this.closeEditFilter();
    },
    addExternalFilter(){
      this.filtersValue = this.filtersValue.filter(f => f.field !== this.externalFilter.field)
      if(!!this.externalFilter.value)
        this.filtersValue.push(this.externalFilter);
      this.handleChanges();
      this.applyFilters();
    },
    openEditFilter(filter) {
      this.editFilter = filter;
      this.filterStep = 2;
    },
    closeEditFilter() {
      this.editFilter = undefined
      this.filterStep = 0;
    },
    openEditAdvanceFilter(filter) {
      this.editFilter = filter;
      this.filterStep = 2;
    },
    changeTypeValue() {
      if(this.editFilter.blank != undefined)
        this.editFilter.value = this.editFilter.blank;
      else if (this.editFilter.operator != 'between') {
        this.editFilter.value = undefined;
        if(Array.isArray(this.editFilter.label))
          this.editFilter.label = this.editFilter.name
      } else {
        this.editFilter.value = [undefined, undefined];
        this.editFilter.label = ['Da', 'A']
      }
    },
    getIconFromFilter(filter) {
      if(filter.icon)
        return filter.icon;

      if (filter.type == 'string') {
        return 'mdi-text-search';
      } else if (filter.type == 'number') {
        return 'mdi-numeric';
      } else if (filter.type == 'date') {
        return 'mdi-calendar';
      } else if (filter.type == 'boolean') {
        return 'mdi-checkbox-marked';
      } else if (filter.type == 'cash') {
        return 'mdi-cash';
      } else {
        return 'mdi-help-circle';
      }
    },
    getStringFromFilter(filter) {
      if (filter.type == 'date') {
        return filter.name + ' ' + this.getOperatorString(filter.operator) + ' ' + (filter.operator != 'between' ? new Date(filter.value).toLocaleDateString() : new Date(filter.value[0]).toLocaleDateString() + ' - ' + new Date(filter.value[1]).toLocaleDateString())
      } else if (filter.type == 'boolean') {
        return filter.name + ' ' + this.getOperatorString(filter.operator) + ' ' + (!!filter.value ? 'Si' : 'No')
      } else if (filter.type == 'number') {
        return filter.name + ' ' + this.getOperatorString(filter.operator) + ' ' + (filter.operator != 'between' ? filter.value : filter.value[0] + ' - ' + filter.value[1])
      } else {
        return filter.name + ' ' + this.getOperatorString(filter.operator) + ' ' + filter.value
      }
    },
    getOperatorString(operator){
      let operators = [...this.operators, ...this.betweenOperators, ...this.likeOperators]

      return operators.find(o => o.value == operator).text
    }
  },
  watch: {
    value(newVal) {
      this.filtersValue = newVal;
    },
  },
  computed: {
    isEmpty(){
      if(!!this.editFilter)
        if(!isNaN(new Date(this.editFilter.value)))
          return false
        if(Array.isArray(this.editFilter.value))
          return !this.editFilter.value.length
        else if(typeof this.editFilter.value === 'object')
          return !Object.entries(this.editFilter.value).length
        else
          return this.editFilter.value == undefined || this.editFilter.value == null
    },
  }
};
</script>

<style  >
.filter-container .v-banner__wrapper {
  padding: 0px !important;
}
</style>