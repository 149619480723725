<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Presenze"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <v-btn text link @click="printTimeLogDialog = true">
        <v-icon class="mr-2">mdi-printer</v-icon> Stampa Cedolino
      </v-btn>
      <v-btn text link @click="printAllTimeLogDialog = true">
        <v-icon class="mr-2">mdi-printer-check</v-icon> Stampa Tutti i Cedolini
      </v-btn>
    </template>
    <template v-slot:content>
      <v-list 
        v-if="groupedOperatorTimeLogs.length > 0" 
        two-line 
        color="transparent"
      >
        <v-divider></v-divider>
        <div v-for="timeLogOperator in groupedOperatorTimeLogs" v-bind:key="timeLogOperator.id">
          <v-list-item v-if="searchMatch(timeLogOperator.operator)">
            <v-list-item-avatar class="my-0">
              <UserAvatar
                :value="timeLogOperator.operator"
              ></UserAvatar>
            </v-list-item-avatar>
            <v-list-item-content class="text-left">
              <v-row class="ml-2">
                <v-col class="pa-0 d-flex align-center" >
                  <v-list-item-title>
                    {{ timeLogOperator.operator.firstname }} {{ timeLogOperator.operator.lastname }}
                  </v-list-item-title>
                </v-col>
                <v-col >
                  <div class="d-flex align-center my-3">
                    <v-icon>mdi-location-enter</v-icon>
                    <div class="ml-3 font-weight-thin">
                      {{dateToString('dd/mm/yyyy', new Date(timeLogOperator.openedAt))}}, {{dateToTimeString(new Date(timeLogOperator.openedAt))}}
                    </div>
                  </div>
                  <div class="d-flex align-center my-3" v-if="!!timeLogOperator.closedAt">
                    <v-icon>mdi-location-exit</v-icon>
                    <div class="ml-3 font-weight-thin">
                      {{dateToString('dd/mm/yyyy', new Date(timeLogOperator.closedAt))}}, {{dateToTimeString(new Date(timeLogOperator.closedAt))}}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-action>
              <StandardMenu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :loading="printLoading">
                    <v-icon medium>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item 
                    link 
                    v-on:click="TimeLogList(timeLogOperator.operator)"
                  >
                    <v-list-item-title>Vedi entrate e uscite</v-list-item-title>
                  </v-list-item>
                  <v-list-item 
                    link 
                    v-on:click="handleCurrentMonthPrint(timeLogOperator.operator)"
                  >
                    <v-list-item-title>Stampa cedolino di questo mese</v-list-item-title>
                  </v-list-item>
                </v-list>
              </StandardMenu>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list>
      <div
        v-else
        class="font-weight-light font-italic d-flex align-content-center"
        style="width: 100%"
      >
        <span style="margin: auto">Nessun entrata/uscita</span>
      </div>
      <StandardDialog 
        v-model="printTimeLogDialog"
        title="Stampa Cedolino"
        :dialog-height="null"
        dialog-width="28vw"
      >
        <v-row justify="center">
          <v-col cols="10">
            <v-select
              v-model="operator"
              :items="operatorItems"
              item-value="id"
              item-text="fullname"
              return-object
              filled
              class="mt-2"
              label="Operatore"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="9">
            <v-date-picker
              locale="it"
              v-model="month"
              type="month"
              :max="currentMonth()"
              first-day-of-week="1"
            ></v-date-picker>
          </v-col>
        </v-row>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="printTimeLogDialog = false"
          >
            Chiudi
          </v-btn>
          <v-btn
            color="default"
            text
            @click="handlePrintOnDialog(operator, month)"
          >
            Stampa
          </v-btn>
        </template>
      </StandardDialog>
      <StandardDialog 
        v-model="printAllTimeLogDialog"
        title="Stampa Tutti i Cedolini"
        :dialog-height="null"
        dialog-width="28vw"
      >
        <v-row justify="center" class="pt-2">
          <v-col cols="9">
            <v-date-picker
              locale="it"
              v-model="month"
              type="month"
              :max="currentMonth()"
              first-day-of-week="1"
            ></v-date-picker>
          </v-col>
        </v-row>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="printAllTimeLogDialog = false"
          >
            Chiudi
          </v-btn>
          <v-btn
            color="default"
            text
            @click="handlePrintAll(month)"
          >
            Stampa
          </v-btn>
        </template>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import Vue from 'vue';
import timeLogsService from "@/services/presences/timeLogs/timeLogs.service"
import UserAvatar from '@/components/common/UserAvatar'
import StandardMenu from '@/components/common/StandardMenu'
import StandardDialog from '@/components/common/StandardDialog.vue'
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import dateUtils from '@/mixins/common/dateUtils'
import _ from 'lodash'

export default {
  name: "OperatorPresences",
  components: {
    UserAvatar,
    StandardMenu,
    StandardDialog,
    FullScreenDialog
  },
  mixins: [dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Presence'});

    return {
      groupedOperatorTimeLogs: [],
      loading: false,
      dialog: true,
      printLoading: false,
      routeFather: props.href,
      printTimeLogDialog: false,
      printAllTimeLogDialog: false,
      operator: undefined,
      month: undefined,
      operatorItems: [],
    }
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  mounted: function() {
    this.fetchTimeLogs()
    this.month = this.currentMonth()

    this.bus.$on('print-time-logs', (month) => {
      this.handlePrintAll(month)
    })
  },
  methods: {
    fetchTimeLogs() {
      timeLogsService.fastList().then((results) => {
        const groupedOperator = _.groupBy(results, el => el.operatorId)
        
        this.groupedOperatorTimeLogs = Object.values(groupedOperator).flat()
        this.groupedOperatorTimeLogs.sort((a, b) => {
          const orderA = a.operator.order;
          const orderB = b.operator.order;

          if (orderA === orderB)
            return 0

          if (orderA === null)
            return 1
          if (orderB === null)
            return -1

          return orderA < orderB ? -1 : 1;
        });

        for (let i = 0; i < this.groupedOperatorTimeLogs.length; i += 1) {
          if (this.operatorItems.find(el => el.id === this.groupedOperatorTimeLogs[i].operator.id) === undefined) {
            this.operatorItems.push({
              ...this.groupedOperatorTimeLogs[i].operator,
              "fullname": this.groupedOperatorTimeLogs[i].operator.firstname + ' ' + this.groupedOperatorTimeLogs[i].operator.lastname
            })
          }
        }
      })
    },
    searchMatch(operator) {
      return true
    },
    TimeLogList(operator) {
      this.$router.push({
        name: "TimeLogsList",
        params: {
          operatorId: operator.id,
        },
      })
    },
    handlePrint(operator, month, year) {
      this.printLoading = true
      timeLogsService.print({
        operator: {id: operator.id, name: operator.firstname + '_' + operator.lastname},
        month: month,
        year: year
      }).then(() => {
        this.printLoading = false
      })
    },
    handlePrintAll(monthAndYear) {
      this.printLoading = true

      let localMonthAndYear = monthAndYear.split('-')
      localMonthAndYear = localMonthAndYear.map(Number)

      timeLogsService.printAll({
        month: localMonthAndYear[1],
        year: localMonthAndYear[0]
      }).then(() => {
        this.printLoading = false
      })
    },
    handlePrintOnDialog(operator, monthAndYear) {
      if (!operator)
        alert("Selezionare un operatore!")
      else {
        let localMonthAndYear = monthAndYear.split('-')
        localMonthAndYear = localMonthAndYear.map(Number)
        this.handlePrint(operator, localMonthAndYear[1], localMonthAndYear[0])
        this.printTimeLogDialog = false
      }
    },
    handleCurrentMonthPrint(operator) {
      let focusDate = new Date()
      let month = focusDate.getMonth() + 1
      let year = focusDate.getFullYear()
      this.handlePrint(operator, month, year)
    },
    currentMonth() {
      let currentDate = new Date()
      return currentDate.getFullYear().toString() + '-' + (currentDate.getMonth() + 1).toString()
    }
  }
}
</script>

<style>

</style>