<template>
  <v-dialog v-model="localValue" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>
    <v-card class="flex-container" :id="idProp">
      <v-card-title class="pa-0 flex-grow-0 header">
        <v-toolbar
          height="fit-content"
          style="flex-wrap: wrap;"
          flat
        >
          <v-btn icon v-if="canClose" @click="close" :disabled="!canClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          
          <v-toolbar-title>
           <slot name="toolbar-title">
            {{ title }}
           </slot> 
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <slot name="header-actions">
          </slot>

          <template v-slot:extension v-if="!!$slots['toolbar-extension']">
            <slot name="toolbar-extension"></slot>
          </template>

          <v-btn icon v-if="canCloseRight" @click="close" :disabled="!canCloseRight">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text class="mt-1 flex-auto body dialog-card-text" :class="{'pa-0': withoutPadding}" style="overflow-y: auto">
        <slot name="content"></slot>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="flex-grow-0 footer">
        <v-spacer></v-spacer>
        <slot name="footer-actions" v-bind="{ close }">
          <v-btn text color="error" @click="close" :disabled="!canClose || !canCloseRight">Chiudi</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FullScreenDialog",
  data: function() {
    return {
      localValue: this.value,
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    withoutPadding: {
      type: Boolean,
      default: false
    },
    routeFather: {
      type: String,
      default: ""
    },
    routeFatherName: {
      type: String,
      default: ""
    },
    goBack: {
      type: Boolean,
      default: false
    },
    canClose: {
      type: Boolean,
      default: true
    },
    canCloseRight: {
      type: Boolean,
      default: false
    },
    otherGoBackParams: {
      type: Object,
      default: () => ({})
    },
    idProp: {
      type: String,
      default: undefined
    }
  },
  methods: {
    close() {
      var self = this
      this.localValue = false;
      if (this.routeFatherName) {
        setTimeout(function() {
          self.$router.push({name: self.routeFatherName, ...self.otherGoBackParams})
        }, 100)
      } else if(this.routeFather) {
        setTimeout(function() {
          self.$router.push({path: self.routeFather, ...self.otherGoBackParams})
        }, 100)
      } else if(this.goBack) {
        setTimeout(function() {
          self.$router.go(-1)
        }, 100)
      }
    }
  },

  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    localValue(newVal) {
      if (newVal != this.value) {
        this.$emit("input", newVal);
      }
    }
  }
};
</script>

<style scoped>

.flex-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  height: fit-content;
}

.body {
  flex: 0 0 auto;
  flex-shrink: 1;
  flex-grow: 1
}

.footer {
  height: fit-content;
}
</style>