<script>
import { Doughnut, mixins } from 'vue-chartjs'

export default {
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: ['options'],
  mounted() {
    const plugin = {
      id: 'emptyDoughnut',
      afterDraw(chart, args, options) {
        const { datasets } = chart.config.data;
        let hasData = false;

        let borderWidth = 2;

        for (let i = 0; i < datasets.length; i += 1) {
          const dataset = datasets[i];
          borderWidth = dataset.borderWidth;

          hasData |= dataset.data.reduce((acc, data) => {
            return acc + Number(data);
          }, 0) > 0;
        }

        if (!hasData) {
          const { chartArea: { left, top, right, bottom }, ctx } = chart;
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const r = Math.min(right - left, bottom - top) / 2;

          ctx.clearRect(0, 0, left + right, top + bottom);

          ctx.beginPath();
          ctx.lineWidth = borderWidth;
          ctx.strokeStyle = 'white';
          ctx.arc(centerX, centerY, r, 0, 2 * Math.PI);

          ctx.font = 'normal 0.75rem Roboto, sans-serif';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText("DATI INSUFFICIENTI", (left + right) / 2, (top + bottom) / 2);
          ctx.stroke();
        }
      }
    };

    this.addPlugin(plugin);
    this.renderChart(this.chartData, this.options)
  }
}
</script>

<style>

</style>