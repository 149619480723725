import Sender from './sender'
import settingsService from '@/services/settings/settings.service.js'
import marketingEventsService from '@/services/marketingEvents/marketingEvents.service.js';

class Printer {
  constructor() {

  }

  /* 
    params: {
      paymentTypes: [
        ...,
        {
          type: 'cash' | 'cheque' | 'card' | 'ticket' | 'credit',
          amount: Number
        }
      ],
      items: [
        ...,
        {
          name: String,
          price: Number,
          quantity: Number (optional),
          department: Number (optional),
          description: String (optional),
          discount: String (optional),
          discountDescription: String (optional),
        }
      ],
      lotteryCode: String,
      additionalRows: [
        ...,
        String
      ]
    }
  */
  printFiscalReceipt(params, message) {
    return new Promise(async (resolve, reject) => {
      let emulateRtPrinter = await settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }

      if (message == undefined) {
        message = "nessun messaggio passato"
      }

      const sender = new Sender()

      await sender.sendFiscalReceipt(params, message).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  reprintFiscalReceipt(params) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }

      const sender = new Sender()

      sender.reprintFiscalReceipt(params).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /*
    params: {
      amount: Number,
      invoiceData: {
        lastname
        firstname
        address
        cap
        city
        iva
        fiscalCode
        email
        destinataryCode
        paymentType
        paymentCondition
        noIva
      },
      items: [
        ...,
        {
          name: String,
          price: Number,
          quantity: Number (optional),
          description: String (optional),
          discount: String (optional),
          discountDescription: String (optional),
        }
      ]
    }
  */
  printSummary(params, isInvoice, message) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }

      if (message == undefined) {
        message = "nessun messaggio passato"
      }

      const sender = new Sender()
      sender.sendSummary(params, isInvoice, message).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  fiscalClosure(cashDeskIP) {
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.sendPrintZReport(cashDeskIP)
  }


  printPaymentDebit(params) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }

      const sender = new Sender()

      sender.sendPaymentDebit(params).then((results) => {
        resolve(results)
      }).catch((error) => {
        console.log(error)
        reject(error)
      })
    })
  }

  printVoucherReport(amount) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }
      const sender = new Sender()
      sender.sendVoucherReport(amount).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  printPromoReport(promo, customer) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }
      const sender = new Sender()
      sender.sendPromoReport(promo, customer).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  giftCardReport(promo, customer, customerGiftCard) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }

      const sender = new Sender()
      sender.giftCardReport(promo, customer, customerGiftCard).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  marketingReport(models) {
    return new Promise((resolve, reject) => {
      let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
      if (emulateRtPrinter) {
        resolve({
          attributes: {
            success: true
          }
        })
        return
      }

      const sender = new Sender()
      sender.marketingReport(models).then((results) => {
        resolve(results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  getStatus() {
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.getStatus()
  }

  /* 
    params: {
      time: String (hh:mm)
    }
  */
  scheduleFiscalClosure(params) {
    if (!params && !params.time) return Promise.reject('no time specified')

    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.sendSchedulePrintZReport(params.time, params.cashDeskIP)
  }

  scheduleFiscalClosureReset(params) {
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.sendSchedulePrintZReportReset(params.cashDeskIP)
  }

  setLogo() {
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.setLogo()
  }

  saveLogo() {
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.saveLogo()
  }

  setHeader(val) {
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.setHeader(val)
  }

  saveHeader() {
    let val = "99                                        "
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.setHeader(val)
  }

  testHeader() {
    let val = "98                                        "
    let emulateRtPrinter = settingsService.getFromCache('emulateRtPrinter')
    if (emulateRtPrinter) {
      return Promise.resolve({
        attributes: {
          success: true
        }
      })
    }

    const sender = new Sender()
    return sender.setHeader(val)
  }

  getSettingsUrl() {
    const sender = new Sender()
    return sender.settingUrl
  }
}

export default new Printer()