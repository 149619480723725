import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';

class unitOfMeasuresManagment {

  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/unitOfMeasures/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  create(unitOfMeasure) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/unitOfMeasures/create', unitOfMeasure).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  update(unitOfMeasure) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/unitOfMeasures/update', unitOfMeasure).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  delete(unitOfMeasure) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      if (!unitOfMeasure.id) {
        reject('id undefined')
      }

      axios.get(apiUrl + '/registries/unitOfMeasures/delete', {
        id: unitOfMeasure.id
      }).then((response) => {
        if(!response.success) {
          reject(response.results)
        } else {
          resolve(response)
        }
      })
    })
  }

  get(unitOfMeasureId) {
    const apiUrl = UrlKeeper.getUrl()
    
    return new Promise((resolve, reject) => {
      axios.get(apiUrl + '/registries/unitOfMeasures/get', { id: unitOfMeasureId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        }
      })
    })
  }


  fields() {
    return Promise.resolve([
      { text: "Nome", value: "name" },
      { text: "Descrizione", value: "description" },
    ])
  }
}

export default new unitOfMeasuresManagment();
