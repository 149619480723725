<template>
  <div id="content-to-print">
    <TitleWithBackButton
      title="Riepilogo Servizi"
      back-route="Analytics"
      icon="mdi-graphql"
    ></TitleWithBackButton>
    <div 
      class="d-flex align-center ml-3 mt-3 pr-5"
      :class="{'flex-wrap': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || !!this.$vuetify.breakpoint.md}"
    >
      <RangeDateSelector 
        :start-date.sync="startDate" 
        :end-date.sync="endDate" 
        :loading="loading" 
        :disabled="loading"
        :max-width="!!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm 
          ? 'fit-content' : this.$vuetify.breakpoint.md ? '60dvw' : '50dvw'"
      >
      </RangeDateSelector>
      <v-select
        v-model="categoriesIds"
        :items="serviceTypesCategories"
        multiple
        item-value="id"
        item-text="name"
        class="pt-6 pl-1"
        rounded
        filled
        dense
        :class="{'ml-7': !!this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm}"
        :style="{
          'width': !!this.$vuetify.breakpoint.sm ? '45dvw' : '20dvw'
        }"
      >
        <template v-slot:selection="{ item, index }">
					<span v-if="index === 0">
						{{ item['name'] }}
					</span>
					<span
						v-else-if="index < 1"
					>
						, {{ item['name'] }}
					</span>
					<span
						v-else-if="index === 1"
						class="grey--text text-caption"
					>
						&nbsp; (e altri {{ categoriesIds.length - 1 }})
					</span>
				</template>
      </v-select>
      <v-btn 
        class="mr-2 ml-2" 
        text 
        @click="calculate" 
        :loading="loading" 
        :disabled="!startDate || !endDate"
      >
        Calcola
      </v-btn>
      <v-btn 
        class="mr-2 ml-2" 
        text 
        @click="print" 
        :loading="loading" 
        :disabled="!startDate || !endDate"
      >
      <v-icon> mdi-printer </v-icon>
      </v-btn>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      ><v-icon> mdi-microsoft-excel </v-icon></ExportExcelTable>
      <v-spacer v-if="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.md"></v-spacer>
      <v-btn icon large :disabled="loading" style="float: right; margin-right: 2.5%"
        @click="() => { dialogBarcode = !dialogBarcode }"
        :color="!!isB ? 'red' : ''"
        >
        <v-icon>mdi-shield-account-variant</v-icon>
      </v-btn>
    </div>
    <div class="pa-4 mt-5">
      <v-btn-toggle
        v-model="transactionOrService"
        mandatory
        rounded
        dense
        borderless
        color="primary"
      >
        <v-btn color="primary lighten-2">
          <v-icon>mdi-receipt</v-icon>
        </v-btn>
        <v-btn color="primary lighten-2">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </v-btn-toggle>
      <v-window
        v-model="transactionOrService"
        reverse
      >
        <v-window-item>
          <v-row>
            <v-col cols="12" class="d-flex justify-center mb-5">
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;"
                color="primary"
              >
                Transazioni
              </v-chip>
            </v-col>
          </v-row>
          <GroupBasedChart 
            height="600px" 
            width="100%"
            transparent
            type="bar"
            :colors="colors"
            :point-colors="[]"
            :getYValue="
              (object) => {
                return object[`y`];
              }
            "
            :getXValue="
              (object) => {
                return object[`x`];
              }
            "
            :tooltip-label-modifier="
              (tooltipLabel) => {
                let serviceType = this.getServiceTypes(tooltipLabel.xLabel, 'transaction')
                return Number(tooltipLabel.yLabel).toFixed(2) + '%' + ' (' + serviceType.countTransactions + ' transazioni)';
              }
            "
            :data="[serviceTypesByTransactions]"
          >
          </GroupBasedChart>
        </v-window-item>
        <v-window-item>
          <v-row>
            <v-col cols="12" class="d-flex justify-center mb-5">
              <v-chip 
                class="text-button text-center" 
                style="font-size: 1.2rem !important;"
                color="primary"
              >
                Clienti
              </v-chip>
            </v-col>
          </v-row>
          <GroupBasedChart 
            height="600px" 
            width="100%"
            transparent
            type="bar"
            :colors="colors"
            :point-colors="[]"
            :getYValue="
              (object) => {
                return object[`y`];
              }
            "
            :getXValue="
              (object) => {
                return object[`x`];
              }
            "
            :tooltip-label-modifier="
              (tooltipLabel) => {
                let serviceType = this.getServiceTypes(tooltipLabel.xLabel, 'customer')
                return Number(tooltipLabel.yLabel).toFixed(2) + '%' + ' (' + serviceType.countCustomers + ' clienti)';
              }
            "
            :data="[serviceTypesByCustomers]"
          >
          </GroupBasedChart>
        </v-window-item>
      </v-window>
      <div class="pt-5">
        <TypeDataTable
          fixed-header
          headersColor="rgb(154, 154, 154)" 
          style="background-color: rgba(50,50,50,0.1);"
          item-key="name"
          :headers="[
            { text: '', value: 'name' },
            { text: '€', value: 'total', type: 'price' },
            { text: 'N°', value: 'count' },
            { text: '% su N°Servizi', value: 'onServices' },
            { text: '% su N°Clienti', value: 'onCustomers' },
            { text: '% su N°Transazioni', value: 'onTransactions' },
            { text: '% su €', value: 'onTotal' },
          ]" 
          :items="servicesData" 
          :show-select="false" 
          :select-on-row-click="false"
          :show-actions="false" 
          :disablePagination="true" 
          dense 
          disable-sort
          show-expand
        >
          <template v-slot:expanded-item="{headers, item}">
            <td :colspan="headers.length" class="pa-0 pl-5">
              <TypeDataTable
                fixed-header
                headersColor="rgb(154, 154, 154)" 
                style="background-color: rgba(50,50,50,0.1);"
                :headers="[
                  { text: '', value: 'name' },
                  { text: '€', value: 'total', type: 'price' },
                  { text: 'N°', value: 'count' },
                  { text: '% su N°Servizi', value: 'onServices' },
                  { text: '% su N°Clienti', value: 'onCustomers' },
                  { text: '% su N°Transazioni', value: 'onTransactions' },
                  { text: '% su €', value: 'onTotal' },
                ]" 
                :items="item.subItems" 
                :show-select="false" 
                :select-on-row-click="false"
                :show-actions="false" 
                :disablePagination="true" 
                dense 
                disable-sort
              >
              </TypeDataTable>
            </td>
          </template>
        </TypeDataTable>
      </div>
    </div>

    <StandardDialog 
      v-model="dialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import TitleWithBackButton from '@/components/common/TitleWithBackButton.vue';
import GroupBasedChart from '@/components/charts/GroupBasedChart.vue'
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import RangeDateSelector from '@/components/areas/analytics/RangeDateSelector.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import balanceService from '@/services/balance/balance.service.js'
import operatorService from '@/services/operators/operators.service.js'
import servicesService from '@/services/services/services.service.js'
import html2canvas from '@/assets/js/html2canvas.min.js'
import _ from "lodash";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";

export default {
  name: "ServiceTypesAnalytics",
  components: {
    TitleWithBackButton,
    GroupBasedChart,
    TypeDataTable,
    RangeDateSelector,
    ManualBarcodeInput,
    StandardDialog,
    ExportExcelTable
  },
  data: function() {
    let startDate = new Date();
    startDate.setDate(1)
    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);
    endDate.setDate(0); 

    return {
      loading: false,
      printLoading: false,
      startDate: startDate,
      endDate: endDate,
      serviceTypesCategories: [],
      categoriesIds: [],
      colorSet: ['#61E8E19c', '#F257579c', '#F2E8639c', '#6C6EA09c', '#FFC4EB9c'],
      colors: [],
      transactionOrService: 0,
      serviceTypesByTransactions: {
        name: 'Famiglie su Transazioni',
        label: 'Su Transazioni',
        data: [],
      },
      serviceTypesByCustomers: {
        name: 'Famiglie su Clienti',
        label: 'Su Clienti',
        data: [],
      },
      servicesData: [],
      isB: false,
      dialogBarcode: false,
      operatorBarcode: undefined,
      fieldsToExport: {},
      headers: [
        { text: '', value: 'name' },
        { text: '€', value: 'total', type: 'price' },
        { text: 'N°', value: 'count' },
        { text: '% su N°Servizi', value: 'onServices' },
        { text: '% su N°Clienti', value: 'onCustomers' },
        { text: '% su N°Transazioni', value: 'onTransactions' },
        { text: '% su €', value: 'onTotal' },
      ],
      worksheetName: "",
      fileName: "",
    }
  },
  async mounted() {
    this.serviceTypesCategories = await servicesService.getServiceTypeCategories()
    this.categoriesIds = this.serviceTypesCategories.map(stc => stc.id).slice(0, 2)

    this.calculate()
  },
  methods: {
    async fetchExcel(){
      let allServicesDatas = []

      for (let i = 0; i < this.servicesData.length; i++) {
        allServicesDatas.push(this.servicesData[i])
      }
      return allServicesDatas
    },
    async calculate(){
      const startDate = new Date(this.startDate),
      endDate = new Date(this.endDate)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      startDate.setMilliseconds(0)

      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      endDate.setMilliseconds(999)
      
      let res = await balanceService.serviceTypesBalance({
        from: startDate,
        to: endDate,
        categoriesIds: this.categoriesIds,
        ghost: this.isB
      })

      let nServiceTypes = res.serviceTypes.length
      this.colors = Array.from({length: nServiceTypes}, (_, i) => {
        return this.colorSet[i % this.colorSet.length]
      })

      this.serviceTypesByTransactions.data = res.serviceTypes.map(st => {
        let findedTotal = res.totals.find(t => t.serviceTypeCategoryId == st.serviceTypeCategoryId)
        let totalCountTransactions = findedTotal ? findedTotal.countTransactions : st.countTransactions
        return {
          x: st.name,
          y: Number(st.countTransactions) / totalCountTransactions * 100,
          countTransactions: st.countTransactions
        }
      })

      this.serviceTypesByCustomers.data = res.serviceTypes.map(st => {
        let findedTotal = res.totals.find(t => t.serviceTypeCategoryId == st.serviceTypeCategoryId)
        let totalCountCustomers = findedTotal ? findedTotal.countCustomers : st.countCustomers
        return {
          x: st.name,
          y: Number(st.countCustomers) / totalCountCustomers * 100,
          countCustomers: st.countCustomers
        }
      })



      let groupedServices = _.groupBy(res.services, 'serviceTypeId')
      this.servicesData = []

      for(let serviceType of Object.values(groupedServices)) {
        let findedServiceType = res.serviceTypes.find(st => st.id == serviceType[0].serviceTypeId)
        if(!findedServiceType){
          findedServiceType = {
            name: serviceType[0].serviceTypeName,
            quantity: 1,
            total: 1,
            countCustomers: 1,
            countTransactions: 1,
          }
        }

        let findedTotal = res.totals.find(t => t.serviceTypeCategoryId == findedServiceType.serviceTypeCategoryId)
        if(!findedTotal){
          findedTotal = {
            countTransactions: 1,
            countCustomers: 1,
            total: 1,
            quantity: 1,
          }
        }

        this.servicesData.push({
          name: findedServiceType.name,
          count: findedServiceType.quantity,
          total: findedServiceType.total,
          onServices: Number(findedServiceType.quantity) / Number(findedTotal.countServices) * 100,
          onCustomers: findedServiceType.countCustomers / Number(findedTotal.countCustomers) * 100,
          onTransactions: findedServiceType.countTransactions / Number(findedTotal.countTransactions) * 100,
          onTotal: findedServiceType.total / Number(findedTotal.total) * 100,
          subItems: serviceType.map(s => {
            return {
              name: s.name,
              count: s.quantity,
              total: s.total,
              onServices: s.quantity / Number(findedServiceType.quantity) * 100,
              onCustomers: s.countCustomers / Number(findedServiceType.countCustomers) * 100,
              onTransactions: s.countTransactions / Number(findedServiceType.countTransactions) * 100,
              onTotal: s.total / Number(findedServiceType.total) * 100,
            }
          })
        })

        this.servicesData[this.servicesData.length - 1].onServices = this.servicesData[this.servicesData.length - 1].onServices ? this.servicesData[this.servicesData.length - 1].onServices.toFixed(2) + '%' : 0 + '%'
        this.servicesData[this.servicesData.length - 1].onCustomers = this.servicesData[this.servicesData.length - 1].onCustomers ? this.servicesData[this.servicesData.length - 1].onCustomers.toFixed(2) + '%' : 0 + '%'
        this.servicesData[this.servicesData.length - 1].onTransactions = this.servicesData[this.servicesData.length - 1].onTransactions ? this.servicesData[this.servicesData.length - 1].onTransactions.toFixed(2) + '%' : 0 + '%'
        this.servicesData[this.servicesData.length - 1].onTotal = this.servicesData[this.servicesData.length - 1].onTotal ? this.servicesData[this.servicesData.length - 1].onTotal.toFixed(2) + '%' : 0 + '%'

        for(let subItem of this.servicesData[this.servicesData.length - 1].subItems){
          subItem.onServices = subItem.onServices ? subItem.onServices.toFixed(2) + '%' : 0 + '%'
          subItem.onCustomers = subItem.onCustomers ? subItem.onCustomers.toFixed(2) + '%' : 0 + '%'
          subItem.onTransactions = subItem.onTransactions ? subItem.onTransactions.toFixed(2) + '%' : 0 + '%'
          subItem.onTotal = subItem.onTotal ? subItem.onTotal.toFixed(2) + '%' : 0 + '%'
        }
      }

      if (!!this.servicesData && this.servicesData.length > 0) this.servicesData = this.servicesData.sort((a, b) => a.name.localeCompare(b.name))
      this.fieldsToExport = servicesService._fieldsToExcelExport();
      this.worksheetName = "Export Riepilogo Servizi";
      let formattedStartDate = new Date(this.startDate)
      formattedStartDate = formattedStartDate.toLocaleDateString('it-IT')
      let formattedEndDate = new Date(this.endDate)
      formattedEndDate = formattedEndDate.toLocaleDateString('it-IT')
      this.fileName = "RiepilogoServizi-da_" + formattedStartDate.toString() + "_a_" + formattedEndDate.toString() + ".xls";
    },
    getServiceTypes(x, type) {
      switch (type) {
        case 'transaction':
          return this.serviceTypesByTransactions.data.find(st => st.x == x)
        case 'customer':
          return this.serviceTypesByCustomers.data.find(st => st.x == x)
      }
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then((result) => {
        if (result) {
          this.isB = !this.isB
          this.calculate()
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.dialogBarcode = false
    },
    print() {
      let id = 'content-to-print'
      this.printLoading = true

      let today = new Date()
      html2canvas(document.getElementById(id), { scale: 2 }).then((canvas) => {
        let a = document.createElement("a");
        a.download = "Riepilogo_Servizi_" 
          + today.getDate() + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + today.getFullYear() + ".png";
        a.href = canvas.toDataURL("image/png");
        a.click(); // MAY NOT ALWAYS WORK!
        this.printLoading = false
      }).catch((error)=>{
        console.error(error)
        this.printLoading = false
      });
    }
  },
  computed: {
  }
}
</script>

<style>

</style>