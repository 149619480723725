import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'
import EventsHandler from "@/services/common/eventsHandler";
import cookiesService from "@/services/cookies.service"

class AuthService extends EventsHandler {
  constructor() {
    super()

    let self = this
    axios.on('access-denied', function () {
      return new Promise((resolve, reject) => {
        axios.setHeader("auth-token", null, 0);
        axios.setHeader("user-id", null, 0);
        self._executeAsyncCallbacksOf('logout').then(() => {
          resolve()
        }).catch((err) => {
          reject(err)
        })
      })
    })
  }

  login(params) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    const userCredential = {
      email: params.email,
      password: params.password,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/users/users/login", userCredential)
        .then((response) => {
          if (response.success) {
            UrlKeeper.setUrl('api', response.licenses[0].url)
            UrlKeeper.setUrl('cube', response.licenses[0].data['cubeUrl'])
            UrlKeeper.setUrl('socket', response.licenses[0].data['socketUrl'])
            UrlKeeper.setUrl('strapi', response.licenses[0].data['strapiUrl'])
            UrlKeeper.setUrl('bookingSettings', response.otherData['bookingSettingsUrl'])
            UrlKeeper.setUrl('wheelOfFortune', response.otherData['wheelOfFortuneUrl'])

            axios.setHeader("auth-token", response["auth-token"], response["token-max-age"]);
            axios.setHeader("user-id", response["user-id"], response["token-max-age"]);


            cookiesService.set('isLicenseNotBlocked', !!response.otherData['isNotExpired'], response["token-max-age"])
            cookiesService.set('isOnlyWheelOfFortune', !!response.otherData['isOnlyWheelOfFortune'], response["token-max-age"])
            resolve();
            this._executeAsyncCallbacksOf('login').then(() => { })
          } else {
            reject(response.error);
          }
        })
    });
  }

  getIsLicenseBlocked() {
    return Promise.resolve(!(cookiesService.get('isLicenseNotBlocked') == 'true'))
  }

  redirectIfLicenseBlocked() {
    if (!(cookiesService.get('isLicenseNotBlocked') == 'true')) {
      window.location.replace('/')
      //window.location.href = '/'
      throw new Error('License expired')
    }
  }

  getIsOnlyWheelOfFortune() {
    return Promise.resolve(!!(cookiesService.get('isOnlyWheelOfFortune') == 'true'))
  }

  redirectIfOnlyWheelOfFortune(to, from, next) {
    console.log(cookiesService.get('isOnlyWheelOfFortune'))
    if (!(cookiesService.get('isOnlyWheelOfFortune') == 'true')) {
      return
    }

    const aviableRoutes = [
      'Dashboard',
      'Homepage',
      'Calendar',
      'Registries',
      'Presence',
      'Marketing',
      'Warehouse',
      'Payments',
      'Analytics',
      'Settings',
      'Login',
      'SendResetPassword',
      'ResetPassword',
    ]

    if (aviableRoutes.includes(to.name)) {
      return
    } else {
      next({
        name: "Dashboard",
        params: {
          resolvePath: to.path
        }
      });
      throw new Error('License expired')
    }
  }

  updatePrivileges(params) {
    const userCredential = {
      email: params.email,
      password: params.password,
    };
    const apiUrl = UrlKeeper.getUrl('authenticator')

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/users/users/login", userCredential)
        .then((response) => {
          if (response.success) {
            axios.setTemporaryHeader("auth-token", response["auth-token"], response["token-max-age"]);
            axios.setTemporaryHeader("user-id", response["user-id"], response["token-max-age"]);
            this._executeAsyncCallbacksOf('login').then(() => {
              resolve();
            }).catch((error) => {
              reject(error)
            })
          } else {
            reject(response.results);
          }
        })
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      const apiUrl = UrlKeeper.getUrl('authenticator')

      axios.post(apiUrl + "/users/users/logout").then((response) => {
        if (response.success) {
          axios.setHeader("auth-token", null, 0);
          axios.setHeader("user-id", null, 0);
          resolve();
          this._executeAsyncCallbacksOf('logout').then(() => { })
        }
      });
    });
  }

  forgotPassword(params) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    const userCredential = {
      email: params.email,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/users/users/forgotPassword", userCredential)
        .then((response) => {
          if (response.success) {
            resolve();
          } else {
            reject();
          }
        })
    });
  }

  resetPassword(token, password) {
    const apiUrl = UrlKeeper.getUrl('authenticator')
    const userCredential = {
      token: token,
      password: password,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl + "/users/users/changePassword", userCredential)
        .then((response) => {
          if (response.success) {
            resolve();
          } else {
            reject(response.error);
          }
        })
    });
  }

  isAuthenticated() {
    return new Promise((resolve, reject) => {
      Promise.all([
        axios.headerPresent("auth-token"),
        axios.headerPresent("user-id"),
        Promise.resolve(!!UrlKeeper.getUrl())
      ]).then((results) => {
        resolve(results[0] && results[1] && results[2]);
      });
    });
  }
}

export default new AuthService();
