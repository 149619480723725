export const colors = [
  {
    color: '#8A77E2'
  },
  {
    color: '#50BFE1'
  },
  {
    color: '#363946'
  },
  {
    color: '#27ab4a'
  },
  {
    color: '#bbb891'
  },
  {
    color: '#d0ffd6'
  },
  {
    color: '#676f54'
  },
  {
    color: '#093a3e'
  },
  {
    color: '#9d8420'
  },
]

export function getHexColorWithOpacity(hexColor, opacity) {
  if (opacity < 0 || opacity > 1) {
    return hexColor
  }

  hexColor = hexColor.slice(0, 7)
  let opacityHex = Math.floor(opacity * 255).toString(16)
  return `${hexColor}${opacityHex}`
}

export function hexToRgbA(hex) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16),
    alpha = parseInt(hex.slice(7, 9), 16) / 255

  if (alpha !== undefined && alpha !== null) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}