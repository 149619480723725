const enablingTypeCategoriesRoutes = [
  {
    path: "/registries/enablingTypeCategories",
    name: "EnablingTypeCategoriesRegistryNewForm",
    component: () =>
      import(
        "@/components/areas/registries/enablingTypeCategories/EnablingTypeCategoriesRegistryNewForm.vue"
      ),
    children: [
      {
        path: "new/general-tab",
        name: "EnablingTypeCategoriesGeneralNewFormTab",
        component: () =>
          import(
            "@/components/areas/registries/enablingTypeCategories/form/GeneralFormTab.vue"
          ),
      },
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack
      }
    },
  },
  {
    path: "/registries/enablingTypeCategories/:id/edit",
    name: "EnablingTypeCategoriesRegistryEditForm",
    component: () => import("@/components/areas/registries/enablingTypeCategories/EnablingTypeCategoriesRegistryEditForm.vue"),
    children: [
      {
        path: "general-tab",
        name: "EnablingTypeCategoriesGeneralEditFormTab",
        component: () =>
          import(
            "@/components/areas/registries/enablingTypeCategories/form/GeneralFormTab.vue"
          ),
      }
    ],
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack
      }
    },
  },
  {
    path: "/registries/enablingTypeCategories/list",
    name: "EnablingTypeCategoriesRegistryList",
    component: () =>
      import(
        "@/components/areas/registries/enablingTypeCategories/EnablingTypeCategoriesRegistryList.vue"
      ),
  }
];

export default enablingTypeCategoriesRoutes;
