export default {
  methods: {
    dateToString(format, date) {
      let date_string = ""
      if (!date) {
        // return this.dateToString(format, new Date())
      } else if (!this.isDate(date)) {
        return "not valid date"
      } else if (format == "yyyy-mm-dd") {
        date_string += date.getFullYear() + '-'
        date_string += ('0' + (date.getMonth() + 1)).slice(-2) + '-'
        date_string += ('0' + date.getDate()).slice(-2)
      } else if (format == "dd/mm/yyyy") {
        date_string += ('0' + date.getDate()).slice(-2) + '/'
        date_string += ('0' + (date.getMonth() + 1)).slice(-2) + '/'
        date_string += date.getFullYear()
      } else {
        return "format_not_valid"
      }

      return date_string
    },
    stringToDate(format, date) {
      if (format == 'yyyy-mm-dd') {
        let info = date.split('-')
        let dateOutput = new Date()
        dateOutput.setFullYear(parseInt(info[0]))
        dateOutput.setMonth(parseInt(info[1]) - 1)
        dateOutput.setDate(parseInt(info[2]))
        return dateOutput
      } else if (format == 'dd/mm/yyyy') {
        let info = date.split('/')
        let dateOutput = new Date()
        dateOutput.setDate(info[0])
        dateOutput.setMonth(parseInt(info[1]) - 1)
        dateOutput.setFullYear(info[2])
        return dateOutput
      } else {
        return new Date()
      }
    },
    dateToTimeString(date) {
      if (!this.isDate(date)) {
        return "not valid date"
      } else {
        return ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
      }
    },
    humanReadableMinutes(minutes) {
      if(minutes == '' || minutes == undefined || minutes == null) {
        return ''
      }

      try {
        minutes = parseInt(minutes)
      } catch (err) {
        return 'Ops, not valid minutes'
      }

      if(isNaN(minutes)) {
        return 'Ops, not valid minutes'
      }

      let remainingMinutes = minutes % 60
      remainingMinutes = remainingMinutes == 0 ? undefined : `${remainingMinutes} minuti`

      let remainingHours = Math.floor(minutes / 60) % 24
      remainingHours = remainingHours == 0 ? undefined : `${remainingHours} ore`

      let remainingDays = Math.floor(minutes / (60 * 24))
      remainingDays = remainingDays == 0 ? undefined : `${remainingDays} giorni`

      return [remainingDays, remainingHours, remainingMinutes].filter(el => !!el).join(', ')
    },
    humanReadableDays(days) {
      if (days == '' || days == undefined || days == null) {
        return ''
      }

      try {
        days = parseInt(days)
      } catch (err) {
        return 'Ops, not valid days'
      }

      if (isNaN(days)) {
        return 'Ops, not valid days'
      }

      let remainingDays = days % 30
      remainingDays = remainingDays == 0 ? undefined : `${remainingDays} giorni`

      let remainingMonths = Math.floor((days) / 30) % 12
      remainingMonths = remainingMonths == 0 ? undefined : `${remainingMonths} mesi`

      let remainingYears = Math.floor((days) / (30 * 12))
      remainingYears = remainingYears == 0 ? undefined : `${remainingYears} anni`

      return [remainingYears, remainingMonths, remainingDays].filter(el => !!el).join(', ')
    },
    isDate(variable) {
      return Object.prototype.toString.call(variable) === "[object Date]"
    },
    addHours(date, hour) {
      let dateFormatted = new Date()
      dateFormatted.setTime(date.getTime() + (hour*60*60*1000));
      return dateFormatted;
    },
    _buildDateToExport(value) {
      if (value) {
        var date = new Date(value);
        var dd = String(date.getDate()).padStart(2, "0");
        var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = date.getFullYear();

        date = dd + "/" + mm + "/" + yyyy;
        return date;
      } else return;
    }
    
  }
}