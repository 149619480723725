<template>
  <div :style="cssVariables">
    <div class="d-flex">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon
            @click="addSlider"
            class="mb-4"
            :disabled="!timeTables[0] || lastHasReachedMaximum"
            v-bind="attrs" 
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span> Aggiungi fascia oraria </span>
      </v-tooltip>
      
    </div>
    <v-range-slider
      v-for="index in slidersNumber"
      :key="index"
      v-model="timeTables[slidersNumber - index]"
      vertical
      :step="step"
      :min="mins[slidersNumber - index]"
      :max="maxes[slidersNumber - index]"
      :height="containerHeight"
      style="width: 38px"
      class="large-slider"
      :style="{'margin-bottom': slidersNumber - index != 0 ? '50px' : undefined}"
      thumb-label="always"
      thumb-size="40"
      @change="handleSliderChange"
    >
      <template v-slot:thumb-label="{ value }">
        {{ formatMinutesToClock(value) }}
      </template>
    </v-range-slider>
    <div class="d-flex">
      
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn 
            icon
            @click="removeSlider"
            class="mt-4"
            :disabled="slidersNumber <= 1"
            v-bind="attrs" 
            v-on="on"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <span> Rimuovi fascia oraria </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeTablePicker",
  data: function() {
    return {
      slidersNumber: 1,
      timeTables: [],
      maxes: [24 * 60],
      mins: [0],
    }
  },
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    height: {
      type: Number,
      default: 500
    },
    step: {
      type: Number,
      default: 30,
      validate: function(value) {
        if((24 * 60) % value != 0) {
          return 'value not divider of 24 * 60'
        } else {
          return true
        }
      }
    }
  },
  mounted: function() {
    if(this.value) {
      this.calculcateTimeTablesFromValue()
    }
  },
  methods: {
    valueAndTimeTablesDiffers() {
      // value should be an array with [['08:00', '09:30'],['13:30', '15:00']]
      for(let i = 0; i < this.value.length || i < this.timeTables.length; i++) {
        let timeTableExists = !(!this.timeTables[i] || (!this.timeTables[i][0] && this.timeTables[i][0] !== 0) || !this.timeTables[i][1])
        let valuesExists = !(!this.value[i] || !this.value[i][0] || !this.value[i][0])
        if((timeTableExists && !valuesExists) || (valuesExists && !timeTableExists)) {
          return true
        }

        if(valuesExists && timeTableExists) {
          let startDiffer = this.value[i][0] != this.formatMinutesToClock(this.timeTables[i][0])
          let endDiffer = this.value[i][1] != this.formatMinutesToClock(this.timeTables[i][1])
          if(startDiffer || endDiffer) {
            return true
          }
        }
      }
      return false
    },
    calculcateTimeTablesFromValue() {
      let newTimeTables = []
      let newMaxes = []
      let newMins = []
      for(let i = 0; i < this.value.length; i++) {
        newTimeTables.push([this.calculateMinutesFromClock(this.value[i][0]), this.calculateMinutesFromClock(this.value[i][1])])
        if(i == 0) {
          newMins.push(0)
        } else {
          newMins.push(newTimeTables[i - 1][1] + this.step)
        }

        if(i == (this.value.length - 1)) {
          newMaxes.push(24 * 60)
        } else {
          newMaxes.push(newTimeTables[i][1])
        }
      }

      if(this.value.length == 1) {
        newMins = [0]
        newMaxes = [24 * 60]
      }

      this.slidersNumber = this.value.length
      this.timeTables = newTimeTables
      this.mins = newMins
      this.maxes = newMaxes
    },
    formatMinutesToClock(number) {
      let hours = Math.floor(number / 60)
      let minutes = number % 60
      return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}`
    },
    calculateMinutesFromClock(clock) {
      let x = clock.split(':')
      let minutes = parseInt(x[0]) * 60
      minutes += parseInt(x[1])
      return minutes
    },
    addSlider() {
      this.maxes[this.slidersNumber - 1] = this.timeTables[this.slidersNumber - 1][1]
      this.maxes.push(24 * 60)
      this.mins.push(this.timeTables[this.slidersNumber - 1][1] + this.step)
      this.slidersNumber += 1
      this.$emit('input', this.formattedTimeTables)
    },
    removeSlider() {
      if(this.slidersNumber > 1) {
        this.slidersNumber -= 1
        this.maxes.pop()
        this.maxes[this.slidersNumber - 1] = 24 * 60
        this.mins.pop()
        this.timeTables.pop()
      } else {
        this.slidersNumber = 1
        this.maxes = [24 * 60]
        this.mins = 0
        while(this.timeTables.length > 1) this.timeTables.pop()
      }
      this.$emit('input', this.formattedTimeTables)
    },
    handleSliderChange() {
      this.$emit('input', this.formattedTimeTables)
    }
  },
  watch: {
    value() {
      if(this.valueAndTimeTablesDiffers()) {
        this.calculcateTimeTablesFromValue()
      }
    },
  },
  computed: {
    formattedTimeTables() {
      let formatted = []
      for(let i = 0; i < this.timeTables.length; i++) {
        if(!!this.timeTables[i]) {
          formatted.push([this.formatMinutesToClock(this.timeTables[i][0]), this.formatMinutesToClock(this.timeTables[i][1])])
        }
      }
      return formatted
    },
    containerHeight() {
      return ((this.height - (50 * this.slidersNumber)) / this.slidersNumber) + 'px'
    },
    sliderHeight() {
      return this.containerHeight
    },
    cssVariables() {
      return {
        '--slider-height': this.sliderHeight,
        'min-width': '90px'
      }
    },
    lastHasReachedMaximum() {
      return !!this.timeTables[this.slidersNumber - 1] && !!this.timeTables[this.slidersNumber - 1][1] && this.timeTables[this.slidersNumber - 1][1] == 24 * 60
    }
  }
}
</script>

<style scoped>
.large-slider >>> .v-slider {
  height: var(--slider-height);
  min-height: var(--slider-height);
}
</style>