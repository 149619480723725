<template>
  <div 
    class="d-flex align-center" 
    :class="{'flex-wrap': !!this.$vuetify.breakpoint.xs || !!this.$vuetify.breakpoint.sm}"
    :style="{
      'max-width': maxWidth,
      'padding': '5px'
    }"
  >
    <div>
      <DateTimePicker
        v-model="localStartDate"
        filled
        dense
        hide-details="auto"
        label="Inizio"
        :show-time="false"
        :loading="loading"
        :disabled="disabled"
        @input="($event) => {
          $emit('update:start-date', $event)
          $emit('change-date')
        }"
      ></DateTimePicker>
    </div>
    <div class="ml-3">
      <DateTimePicker
        v-model="localEndDate"
        filled
        dense
        hide-details="auto"
        label="Fine"
        :show-time="false"
        :loading="loading"
        :disabled="disabled"
        @input="($event) => {
          $emit('update:end-date', $event)
          $emit('change-date')
        }"
      ></DateTimePicker>
    </div>
      <div 
        v-if="!!showChips && 
          (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs)"
        class="d-flex align-center justify-content ml-3" 
        :style="{
          'max-width': this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.md ? '80%' : '100%',
          'margin-top': this.$vuetify.breakpoint.xs || !!this.$vuetify.breakpoint.sm ? '10px' : '0px'
        }"
      >
        <v-select
          v-model="selectedPeriod"
          label="Seleziona Periodo"
          :items="chipItems"
          item-text="text"
          item-value="value"
          filled
          rounded
          dense
          :disabled="disabled"
          hide-details="auto"
          @input="() => {
            onClickSelect(selectedPeriod)
            $emit('change-date')
          }"
        ></v-select>
      </div>
      <div 
        v-if="!!showChips && (this.$vuetify.breakpoint.xl)" 
        class="d-flex align-center" 
        :style="{'max-width': this.$vuetify.breakpoint.xl ? '60%' : '50%'}"
      >
        <v-chip
          v-for="(item) in chipItems" 
          :key="item['value']"
          :disabled="disabled"
          style="font-size: 12px"
          color="primary"
          class="ml-2"
          @click="() => {
            onClickSelect(item['value'])
            $emit('change-date')
          }"
        >
          {{ item['text'] }}
        </v-chip>
      </div>
    <slot name="buttonConfirm"></slot>
  </div>
</template>

<script>
import DateTimePicker from "@/components/common/DateTimePicker.vue";

export default {
  name: "RangeDateSelecotr",
  components: {
    DateTimePicker,
  },
  data: function () {
    return {
      localStartDate: undefined,
      localEndDate: undefined,
      selectedPeriod: undefined
    };
  },
  props: {
    startDate: {
      type: Date,
      default: () => new Date(),
    },
    endDate: {
      type: Date,
      default: () => new Date(),
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: "100%"
    },
    showChips: {
      type: Boolean,
      default: true
    },
    chipItems: {
      type: Array,
      default: () => [
        {text: 'Oggi', value: 'today'},
        {text: 'Ieri', value: 'yesterday'},
        {text: 'Questo mese', value: 'thisMonth'},
        {text: 'Ultimi 3 mesi', value: 'lastThreeMonths'},
        {text: 'Ultimi 6 mesi', value: 'lastSixMonths'},
        {text: 'Scorso anno', value: 'lastYear'},
      ]
    }
  },
  mounted: function () {
    this.localStartDate = this.startDate
    this.localEndDate = this.endDate

    this.$emit('update:start-date', this.localStartDate)
    this.$emit('update:end-date', this.localEndDate)
  },
  methods: {
    onClickSelect(item) {
      switch (item) {
        case 'today':
          this.setToday()
          break;
        case 'yesterday':
          this.setYesterday()
          break;
        case 'thisMonth':
          this.setThisMonth()
          break;
        case 'lastThreeMonths':
          this.setLastThreeMonths()
          break;
        case 'lastSixMonths':
          this.setLastSixMonths()
          break;
        case 'lastYear':
          this.setLastYear()
          break;
        case 'thisYear':
          this.setThisYear()
          break;
        case 'last365Days':
          this.setLast365Days()
          break;
        default:
          break;
      }
    },
    setToday() {
      this.localStartDate = new Date();
      this.localEndDate = new Date();

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setYesterday() {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(-1);

      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0);
      yesterday.setMinutes(0);
      yesterday.setSeconds(0);

      this.localStartDate = yesterday;
      this.localEndDate = today;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setThisMonth() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      this.localStartDate = firstDay;

      var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      this.localEndDate = lastDay;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setLastThreeMonths() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth() - 3, 1);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      this.localStartDate = firstDay;

      var lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      this.localEndDate = lastDay;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setLastSixMonths() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth() - 6, 1);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      this.localStartDate = firstDay;

      var lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      this.localEndDate = lastDay;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setLastYear() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear() - 1, 0, 1);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      this.localStartDate = firstDay;

      var lastDay = new Date(today.getFullYear(), 0, 0);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      this.localEndDate = lastDay;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setLast365Days() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 364 - today.getDay());
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      this.localStartDate = firstDay;

      var lastDay = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay());
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      this.localEndDate = lastDay;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
    setThisYear() {
      var today = new Date();
      var firstDay = new Date(today.getFullYear(), 0, 1);
      firstDay.setHours(0);
      firstDay.setMinutes(0);
      this.localStartDate = firstDay;

      var lastDay = new Date(today.getFullYear() + 1, 0, 0);
      lastDay.setHours(0);
      lastDay.setMinutes(0);
      this.localEndDate = lastDay;

      this.$emit('update:start-date', this.localStartDate)
      this.$emit('update:end-date', this.localEndDate)
    },
  },
};
</script>