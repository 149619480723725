import authService from "@/services/auth/auth.service";
const operatorsRoutes = [
  {
    path: "/registries/operators/new",
    name: "OperatorsRegistryNewForm",
    component: () => import("@/components/areas/registries/operators/OperatorsRegistryNewForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        tabName: route.query.tabName || 'registry'
      }
    },
    beforeEnter: (to, from, next) => {
      authService.redirectIfLicenseBlocked()
      next()
    }
  },
  {
    path: "/registries/operators/:id/edit",
    name: "OperatorsRegistryEditForm",
    component: () => import("@/components/areas/registries/operators/OperatorsRegistryEditForm.vue"),
    props: (route) => {
      return {
        openDialog: true,
        pathToGoBack: route.query.pathToGoBack,
        pathName: route.query.pathName,
        tabName: route.query.tabName || 'registry',
        filters: route.params.filters,
      }
    },
  },
  {
    path: "/registries/operators/list",
    name: "OperatorsRegistryList",
    component: () => import("@/components/areas/registries/operators/OperatorsRegistryList.vue"),
    props: (route) => {
      return {
        filters: route.params.filters,
      }
    }
  }
];

export default operatorsRoutes;
