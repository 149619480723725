import cookiesService from "@/services/cookies.service"

class UrlKeeper {
  constructor() {
    /* 
      urls should be all the intrested endpoint.
      "authenticator" -> the api to authenticate,
      "api" -> the api to get all data,
      "socket" -> the socket to get data from server,
      "cube" -> the api to get cube analytics,
    */
    this.urls = {
      "authenticator": process.env.VUE_APP_AUTHENTICATOR_URL,
      "wino": process.env.VUE_APP_WINO_URL,
      "api": cookiesService.get('api-url'),
      "socket": cookiesService.get('socket-url'),
      "cube": cookiesService.get('cube-url'),
      "strapi": cookiesService.get('strapi-url'),
      "bookingSettings": cookiesService.get('bookingSettings-url'),
      "wheelOfFortune": cookiesService.get('wheelOfFortune-url'),
    }
  }

  getUrl(name='api') {
    return this.urls[name]
  }

  setUrl(name, url) {
    if(!name) throw new Error('need url name')
    cookiesService.set(`${name}-url`, url, 1)
    this.urls[name] = url
  }

  getUrls() {
    return this.urls
  }
}

export default new UrlKeeper();

