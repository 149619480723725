<template>
  <div v-html="localHtml"></div>
</template>

<script>
export default {
  name: "SmsTextVisualizer",
  data: function() {
    return {
      localHtml: undefined,
      possiblePlaceholders: [
        { name: 'customer.firstname', text: 'Nome Cliente' },
        { name: 'customer.lastname', text: 'Cognome Cliente' },
        { name: 'customer.fullname', text: 'Nome Completo Cliente' },
        { name: 'customer.appointment', text: 'Orario appuntamento' },
        { name: 'customer.operator', text: 'Nome Operatore' },
        { name: 'customer.service', text: 'Nome Servizio' },
        { name: 'customer.namePromo', text: 'Nome Promo' },
        { name: 'customer.descriptionPromo', text: 'Descrizione Promo' },
        { name: 'customer.date', text: 'Data appuntamento'}
      ],
    }
  },
  props: {
    value: {
      type: String
    }
  },
  mounted: function() {
    this.localHtml = this.calculateHtmlFromText(this.value)
  },
  methods: {
    getPrimaryColor() {
      const isDark = this.$vuetify.theme.isDark
      if(isDark) return this.$vuetify.theme.themes.dark.primary
      else return this.$vuetify.theme.themes.light.primary
    },
    calculateHtmlFromText(text) {
      let finalText = text, primaryColor = this.getPrimaryColor()

      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const text = this.possiblePlaceholders[i].text
        let spanElement = document.createElement('span')
        spanElement.style.color = primaryColor
        spanElement.innerHTML = text
        
        finalText = finalText.replaceAll('<' + name + '>', spanElement.outerHTML)
      }

      return finalText
    }
  },
  watch: {
    value(newValue) {
      this.localHtml = this.calculateHtmlFromText(newValue)
    }
  }
}
</script>

<style>

</style>