<template>
  <v-menu
    v-model="localValue"
    offset-y
    transition="scroll-y-transition"
    :close-on-content-click="closeOnContentClick"
    :min-width="fitWidth ? 'fit-content' : minWidth"
    :absolute="absolute"
    :style="{
      width: fitWidth ? 'fit-content' : undefined
    }"
    content-class="test"
  >
    <template v-slot:activator="{on, attrs}">
      <slot name="activator" v-bind="{on, attrs}">
      </slot>
    </template>
    <slot></slot>
  </v-menu>
</template>

<script>

export default {
  name: "StandardMenu",
  data: function() {
    return {
      localValue: this.value
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    closeOnContentClick: {
      type: Boolean,
      default: false
    },
    fitWidth: {
      type: Boolean,
      default: false
    },
    absolute: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: String,
      default: undefined
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    },
    localValue(newVal) {
      if(newVal != this.value) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style>
</style>