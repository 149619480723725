import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js';
import recentOperationsDatabase from '@/services/offline/registries/registriesRecentOperations.database.js'

class SupplierManagment {
  list(page, rowPerPage, filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/suppliers/list', {
        page: page,
        rowPerPage: rowPerPage,
        filters: filters
      }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }


  search(searchText, filters = undefined) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/suppliers/search', { searchText: searchText, filters: filters }).then((response) => {
        if (response.success) {
          for (let i = 0; i < response.results.length; i++) {
            response.results[i].text = response.results[i].businessName
          }
          resolve(response.results)
        }
      }).catch((err) => {
        reject('error during the call')
      })
    })
  }

  archive(supplier) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!supplier.id)
        reject('id undefined')

      axios.post(apiUrl + 'registries/suppliers/archive', supplier).then((response) => {
        if (!response.success)
          reject(response.results)
        else
          resolve(response.results)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  create(supplier, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + '/registries/suppliers/create', supplier).then((response) => {
        if (response.success) {
          resolve(response.results)
          if (typeof response.results == "string") {
            reject(response.results)
          } else {
            if (recentOperation) {
              recentOperationsDatabase.pushOperation({
                "text": "Aggiungi nuovo Fornitore",
                "name": "add_suppliers",
                "operationName": "add",
                "registry": "suppliers",
                "params": {
                  supplier: supplier,
                  routeName: 'SuppliersRegistryNewFormTab'
                }
              }).then(() => { })
            } else {
              reject(response.results)
            }
          }
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  update(supplier, recentOperation = true) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!supplier || !supplier.id) {
        reject(new Error('supplier must be specified'))
        return
      }

      axios.post(apiUrl + '/registries/suppliers/update', supplier).then((response) => {
        if (response.success) {
          resolve(response.results)

          if (recentOperation) {
            recentOperationsDatabase.pushOperation({
              "text": "Modifica Fornitore",
              "extraText": supplier.businessName,
              "name": "edit_suppliers",
              "operationName": "edit",
              "registry": "suppliers",
              "params": {
                supplier: supplier,
                routeName: 'SuppliersRegistryEditFormTab',
                routeParams: {
                  id: supplier.id
                }
              }
            }).then(() => { })
          }
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  get(supplierId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!supplierId) {
        reject(new Error('supplier id must be specified'))
        return
      }

      axios.get(apiUrl + '/registries/suppliers/get', { id: supplierId }).then((response) => {
        if (response.success) {
          resolve(response.results)
        } else {
          reject(response.results)
        }
      }).catch((err) => {
        reject(err)
      })
    })
  }

  fields() {
    return Promise.resolve([
      { text: 'Ragione Sociale', value: 'businessName' },
      { text: 'Indirizzo', value: 'address' },
      { text: 'Località', value: 'city' },
      //{ text: 'Forniture', value: 'supplies', type: 'string[]'},
      { text: 'Telefono', value: 'telephone' },
      { text: 'Cellulare', value: 'cellphone' },
      { text: 'Email', value: 'email' },
      { text: 'Notes', value: 'notes' },
      { text: 'Metodi di pagamento', value: 'paymentMethods', type: 'string[]' },
      { text: 'Codice fiscale', value: 'fiscalCode' },
      { text: 'Partita IVA', value: 'VATNumber' },
    ])
  }

  _fieldsToExport() {
    return {
      'Ragione Sociale': 'businessName',
      'Indirizzo': 'address',
      'Località': 'city',
      'Telefono': 'telephone',
      'Cellulare': 'cellphone',
      'Email': 'email',
      'Notes': 'notes',
      'Metodi di pagamento': {
        field: "paymentMethods",
        callback: (value) => {
          let mapping = [
            { value: 'check', text: 'Assegno' },
            { value: 'bankTransaction', text: 'Bonifico' },
            { value: 'cash', text: 'Contanti' },
          ]
          let res = value.map(elem => mapping.find(map => map.value == elem).text)
          return res.join(", ")
        },
      },
      'Codice fiscale': 'fiscalCode',
      'Partita IVA': 'VATNumber',
    }
  }
}

export default new SupplierManagment()