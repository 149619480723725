import currentUserService from './currentUser.service'

export default {
  install: (Vue, options) => {
    Vue.mixin({
      created: function () {
        if (!!this.$options.currentUser) {
          if (!!this.$options.currentUser.change) {
            this.$options.currentUser.change = this.$options.currentUser.change.bind(this)
            currentUserService.on('change', this.$options.currentUser.change)
          }

          if (!!this.$options.currentUser.bind) {
            currentUserService.getUser().then((currentUser) => {
              this[this.$options.currentUser.bind] = currentUser

              let updateCallback = (user) => {
                this[this.$options.currentUser.bind] = user
              }
              updateCallback = updateCallback.bind(this)
              currentUserService.on('change', updateCallback)
            })
          }
        }
      }
    })
  }
}